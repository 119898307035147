import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';
import dayjs from 'dayjs';
import Toast from 'light-toast';
import React, { Component } from 'react';
import Chart from 'react-apexcharts';
import Flags from './flags';

import cookies from '../utils/cookies.util';
import base_url from '../utils/request.util';

class OptionsCallWritingStrategy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: null,
      data: {},
      showGreeks: false,
      input_scrips: [],
      classification: {},
      suggestions: [],
      total: [],
      trade: [],
      underlying: [],
      conditions: []
    };
    this.handleSumbit = this.handleSumbit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.selectedScrip = this.selectedScrip.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.fetchSuggestions = this.fetchSuggestions.bind(this);
    this.toggleShowGreeks = this.toggleShowGreeks.bind(this);
  }

  toggleShowGreeks(event) {
    this.setState((prevState) => ({
      showGreeks: !prevState.showGreeks
    }));
  }

  handleChange(event) {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  selectedScrip(event) {
    this.setState({ code: event.target.id }, () => {
      this.handleSumbit();
    });
  }

  async handleSumbit() {
    const code = this.state.code;
    await this.fetchData(code);
  }

  async fetchSuggestions(event) {
    this.setState({
      code: event.target.value
    });
    if (event.target.value === '') return;

    await fetchAuthSession().then(() => {
      const config = {};
      config.params = { q: event.target.value };
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };

      axios
        .get(`${base_url}/product/suggest`, config)
        .then((res) => {
          this.setState({
            suggestions: res.data.items
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  async fetchData(code) {
    Toast.loading('In Progress', () => { });
    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      config.params = {};
      config.params.code = code;
      axios
        .get(`${base_url}/options-call-writing-strategy`, config)
        .then((res) => {
          this.setState({
            code,
            underlying: res.data.item.underlying,
            trade: res.data.item.trade,
            total: res.data.item.total,
            conditions: res.data.item.conditions
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            code: '',
            underlying: [],
            trade: [],
            total: [],
            conditions: []
          });
          Toast.fail('Error Performing Action', 3000);
        });
      config.params = {};
      axios
        .get(`${base_url}/product/${code}`, config)
        .then((res) => {
          this.setState({
            data: res.data.item,
            suggestions: []
          });
          Toast.success('Success', 1000, () => { });
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  async componentDidMount() {
    const search = window.location.search;
    const code = search.replaceAll('?Code=', '').replaceAll('%20', ' ');
    if (code === '') {
      return;
    }
    await this.fetchData(code);
  }

  render() {
    return (
      <div className="container-fluid">
        <form className="form-group row">
          <div className="col-sm-2">
            <select
              className="form-control"
              value="Code"
              onChange={this.handleChange}
              id="strategy_for"
            >
              <option selected>Code</option>
            </select>
          </div>
          <div className="col-sm-7">
            <input
              type="text"
              autoComplete="off"
              placeholder="Enter Scrip Name to add"
              className="form-control"
              id="code"
              onChange={this.fetchSuggestions}
              value={this.state.code}
            />
            <div className="dropdown">
              <ul className="list-group">
                {this.state.suggestions.map((value, index) => {
                  return (
                    <li
                      className="list-group-item list-group-flush"
                      onClick={this.selectedScrip}
                      id={value.code}
                      key={index}
                    >
                      {value.code}
                      &nbsp; ({value.name}) &nbsp;
                      <img src={Flags[value.country_iso]}></img>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>

          <div className="col-sm-3">
            <div className="btn-group" role="group" aria-label="Basic example">
              <a className="btn btn-danger" onClick={this.handleSumbit}>
                Submit
              </a>
            </div>
          </div>
          <div className="contaier row">
            <div className="col-sm-7">
              {this.state.input_scrips.map((value, index) => {
                return (
                  <div className="row">
                    <div className="col-md-10">
                      <input
                        type="text"
                        disabled="true"
                        className="form-control"
                        value={value.code}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </form>

        {this.state.trade.length !== 0 && (
          <>
            <hr />
            <div className="contaier">
              <h3>
                <b>{this.state.data.code}</b> (LTP:{this.state.underlying[0].last_price})
              </h3>
              <a className="btn btn-danger" onClick={this.toggleShowGreeks}>
                Show Greeks
              </a>
              <table className="table table-bordered">
                <thead
                  style={{
                    backgroundColor: '#002850',
                    color: '#ffffff',
                    textDecoration: 'none',
                    border: '1px solid white'
                  }}
                >
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                      rowSpan={2}
                    >
                      Highest CALL OI
                    </th>
                    {this.state.trade.map((value, index) => {
                      if (value.trades.length === 0) return;
                      return (
                        <th colSpan={this.state.showGreeks ? 4 : 3} key={index}>
                          {value.trades[0].expiry} (DTE:{value.trades[0].days_to_expiry})
                        </th>
                      );
                    })}
                  </tr>
                  <tr>
                    {this.state.trade.map((value, index) => {
                      if (value.trades.length === 0) return;
                      return (
                        <>
                          <th key={index}>Strike</th>
                          <th key={index}>DTS</th>
                          {this.state.showGreeks && <th>Greek</th>}
                          <th key={index}>Premium</th>
                        </>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      BUY CALL
                    </th>
                    {this.state.trade.map((value, index) => {
                      if (value.trades.length === 0) return;
                      return (
                        <>
                          <td>{value.trades[0].strike_call}</td>
                          <td>
                            {value.trades[0].dts_call.toLocaleString(undefined, {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2
                            })}
                            %
                          </td>
                          {this.state.showGreeks && (
                            <td>
                              Delta:
                              {value.trades[0].delta_call.toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                              })}
                              <br />
                              OI:
                              {value.trades[0].oi_call.toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                              })}
                              <br />
                              IV:
                              {value.trades[0].iv_call.toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                              })}
                            </td>
                          )}
                          <td>
                            {value.trades[0].call.toLocaleString(undefined, {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2
                            })}
                            <br />(
                            {value.trades[0].premium_percentage_call.toLocaleString(undefined, {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2
                            })}
                            %)
                            <br />
                            {value.trades[0].premium_percentage_pa_call.toLocaleString(undefined, {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2
                            })}
                            %PA
                          </td>
                        </>
                      );
                    })}
                  </tr>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      BUY PUT
                    </th>
                    {this.state.trade.map((value, index) => {
                      if (value.trades.length === 0) return;
                      return (
                        <>
                          <td>{value.trades[0].strike_put}</td>
                          <td>
                            {value.trades[0].dts_put.toLocaleString(undefined, {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2
                            })}
                            %
                          </td>
                          {this.state.showGreeks && (
                            <td>
                              Delta:
                              {value.trades[0].delta_put.toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                              })}
                              <br />
                              OI:
                              {value.trades[0].oi_put.toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                              })}
                              <br />
                              IV:
                              {value.trades[0].iv_put.toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                              })}
                            </td>
                          )}
                          <td>
                            {value.trades[0].put.toLocaleString(undefined, {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2
                            })}
                            <br />(
                            {value.trades[0].premium_percentage_put.toLocaleString(undefined, {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2
                            })}
                            %)
                            <br />
                            {value.trades[0].premium_percentage_pa_put.toLocaleString(undefined, {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2
                            })}
                            %PA
                          </td>
                        </>
                      );
                    })}
                  </tr>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      Net Premium
                    </th>
                    {this.state.total.map((value, index) => {
                      if (value[0] != null) {
                        return (
                          <>
                            <td></td>
                            <td></td>
                            {this.state.showGreeks && <td></td>}

                            <td>
                              {value[0].toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                              })}
                            </td>
                          </>
                        );
                      }
                    })}
                  </tr>
                </tbody>
              </table>

              <table className="table table-bordered">
                <thead
                  style={{
                    backgroundColor: '#002850',
                    color: '#ffffff',
                    textDecoration: 'none',
                    border: '1px solid white'
                  }}
                >
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                      rowSpan={2}
                    >
                      2nd Highest CALL OI
                    </th>
                    {this.state.trade.map((value, index) => {
                      if (value.trades.length < 2) return;
                      return (
                        <th colSpan={this.state.showGreeks ? 4 : 3} key={index}>
                          {value.trades[1].expiry} (DTE:{value.trades[1].days_to_expiry})
                        </th>
                      );
                    })}
                  </tr>
                  <tr>
                    {this.state.trade.map((value, index) => {
                      if (value.trades.length < 2) return;
                      return (
                        <>
                          <th key={index}>Strike</th>
                          <th key={index}>DTS</th>
                          {this.state.showGreeks && <th>Greek</th>}
                          <th key={index}>Premium</th>
                        </>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      BUY CALL
                    </th>
                    {this.state.trade.map((value, index) => {
                      if (value.trades.length < 2) return;
                      return (
                        <>
                          <td>{value.trades[1].strike_call}</td>
                          <td>
                            {value.trades[1].dts_call.toLocaleString(undefined, {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2
                            })}
                            %
                          </td>
                          {this.state.showGreeks && (
                            <td>
                              Delta:
                              {value.trades[1].delta_call.toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                              })}
                              <br />
                              OI:
                              {value.trades[1].oi_call.toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                              })}
                              <br />
                              IV:
                              {value.trades[1].iv_call.toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                              })}
                            </td>
                          )}
                          <td>
                            {value.trades[1].call.toLocaleString(undefined, {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2
                            })}
                            <br />(
                            {value.trades[1].premium_percentage_call.toLocaleString(undefined, {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2
                            })}
                            %)
                            <br />
                            {value.trades[1].premium_percentage_pa_call.toLocaleString(undefined, {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2
                            })}
                            %PA
                          </td>
                        </>
                      );
                    })}
                  </tr>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      BUY PUT
                    </th>
                    {this.state.trade.map((value, index) => {
                      if (value.trades.length < 2) return;
                      return (
                        <>
                          <td>{value.trades[1].strike_put}</td>
                          <td>
                            {value.trades[1].dts_put.toLocaleString(undefined, {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2
                            })}
                            %
                          </td>
                          {this.state.showGreeks && (
                            <td>
                              Delta:
                              {value.trades[1].delta_put.toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                              })}
                              <br />
                              OI:
                              {value.trades[1].oi_put.toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                              })}
                              <br />
                              IV:
                              {value.trades[1].iv_put.toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                              })}
                            </td>
                          )}
                          <td>
                            {value.trades[1].put.toLocaleString(undefined, {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2
                            })}
                            <br />(
                            {value.trades[1].premium_percentage_put.toLocaleString(undefined, {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2
                            })}
                            %)
                            <br />
                            {value.trades[1].premium_percentage_pa_put.toLocaleString(undefined, {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2
                            })}
                            %PA
                          </td>
                        </>
                      );
                    })}
                  </tr>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      Net Premium
                    </th>
                    {this.state.total.map((value, index) => {
                      if (value[1] != null) {
                        return (
                          <>
                            <td></td>
                            <td></td>
                            {this.state.showGreeks && <td></td>}

                            <td>
                              {value[1].toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                              })}
                            </td>
                          </>
                        );
                      }
                    })}
                  </tr>
                </tbody>
              </table>
            </div>
            <Chart
              options={{
                chart: {
                  height: 350,
                  type: 'area'
                },
                title: {
                  text: this.state.data.name + ' Weekly Chart',
                  align: 'left'
                },
                dataLabels: {
                  enabled: false
                },
                tooltip: {
                  enabled: true
                },
                xaxis: {
                  type: 'datetime',
                  labels: {
                    formatter: function (val) {
                      return dayjs(val).format('MMM YY');
                    }
                  }
                },
                yaxis: {
                  tooltip: {
                    enabled: true
                  }
                },
                annotations: {
                  yaxis: [
                    {
                      y: this.state.data.last_price,
                      opacity: 1,
                      width: '200%',
                      strokeDashArray: 3,
                      borderColor: '#000',
                      label: {
                        borderColor: '#4ACA2D',
                        style: {
                          fontSize: '12px',
                          color: '#fff',
                          background: '#4ACA2D'
                        },
                        orientation: 'horizontal',
                        offsetY: 7,
                        text: 'CMP: ' + this.state.data.last_price
                      }
                    },
                    {
                      y: this.state.trade[0].strike_call,
                      opacity: 1,
                      width: '200%',
                      strokeDashArray: 3,
                      borderColor: '#000',
                      label: {
                        borderColor: 'red',
                        style: {
                          fontSize: '12px',
                          color: '#fff',
                          background: 'red'
                        },
                        orientation: 'horizontal',
                        offsetY: 7,
                        text: 'call: ' + this.state.trade[0].strike_call
                      }
                    },
                    {
                      y: this.state.trade[0].strike_put,
                      opacity: 1,
                      width: '200%',
                      strokeDashArray: 3,
                      borderColor: '#000',
                      label: {
                        borderColor: 'red',
                        style: {
                          fontSize: '12px',
                          color: '#fff',
                          background: 'red'
                        },
                        orientation: 'horizontal',
                        offsetY: 7,
                        text: 'put: ' + this.state.trade[0].strike_put
                      }
                    }
                  ]
                }
              }}
              series={[
                {
                  name: this.state.data.name,
                  data: this.state.data.eod
                }
              ]}
              type="candlestick"
              height={350}
            />
          </>
        )}
      </div>
    );
  }
}

export default OptionsCallWritingStrategy;
