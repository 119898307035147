import axios from 'axios';
import Toast from 'light-toast';
import React, { Component } from 'react';
import './styles.css';

import { fetchAuthSession } from 'aws-amplify/auth';

import cookies from '../utils/cookies.util';
import base_url from '../utils/request.util';

class ViewPortfoliosInsights extends Component {
  constructor(props) {
    super(props);
    this.state = {
      portfolio_details: [],
      portfolio_id: 'All',
      portfolio_ids: [],
      final_asset_class: 'All',
      multiple_select: false,
      items_to_delete: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSumbit = this.handleSumbit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.navidgateToProduct = this.navidgateToProduct.bind(this);
    this.selectHolding = this.selectHolding.bind(this);
    this.toggleMultipleSelect = this.toggleMultipleSelect.bind(this);
  }

  toggleMultipleSelect() {
    this.setState((prevState) => ({
      multiple_select: !prevState.multiple_select
    }));
    this.setState({
      items_to_delete: []
    });
  }

  handleChange(event) {
    this.setState({ portfolio_id: event.target.value });
  }

  async handleSumbit(event) {
    if (event.target.id === 'portfolio_id') {
      const portfolio_id = event.target.value;

      Toast.loading('In Progress', () => { });
      await fetchAuthSession().then(() => {
        const config = {
          params: {
            portfolio_id: null,
            final_asset_class: null
          }
        };
        config.headers = {
          'Authorization': cookies.get('Authorization'),
          'User-Specified': cookies.get('User-Specified')
        };
        if (portfolio_id !== 'All') {
          if (this.state.final_asset_class !== 'All') {
            config.params.final_asset_class = this.state.final_asset_class;
          }
          config.params.portfolio_id = portfolio_id;
        } else if (this.state.final_asset_class !== 'All') {
          config.params.final_asset_class = this.state.final_asset_class;
        }
        axios
          .get(`${base_url}/portfolio/holding`, config)
          .then((res) => {
            Toast.success('Success', 1000, () => { });
            this.setState({
              portfolio_details: res.data.items,
              items_to_delete: [],
              portfolio_id
            });
          })
          .catch((error) => {
            Toast.fail('Error Performing Action', 3000);
          });
      });
    }
    if (event.target.id === 'final_asset_class') {
      const final_asset_class = event.target.value;

      Toast.loading('In Progress', () => { });
      await fetchAuthSession().then(() => {
        const config = {
          params: {
            portfolio_id: null,
            final_asset_class: null
          }
        };
        config.headers = {
          'Authorization': cookies.get('Authorization'),
          'User-Specified': cookies.get('User-Specified')
        };
        if (final_asset_class !== 'All') {
          if (this.state.portfolio_id !== 'All') {
            config.params.portfolio_id = this.state.portfolio_id;
          }
          config.params.final_asset_class = final_asset_class;
        } else if (this.state.portfolio_id !== 'All') {
          config.params.portfolio_id = this.state.portfolio_id;
        }
        axios
          .get(`${base_url}/portfolio/holding`, config)
          .then((res) => {
            Toast.success('Success', 1000, () => { });
            this.setState({
              portfolio_details: res.data.items,
              items_to_delete: [],
              final_asset_class
            });
          })
          .catch((error) => {
            Toast.fail('Error Performing Action', 3000);
          });
      });
    }
  }

  async handleClear() {
    Toast.loading('In Progress', () => { });
    let url = '/portfolio/holding/';
    if (this.state.portfolio_id !== 'All') url = `/portfolio/holding/${this.state.portfolio_id}`;

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      axios
        .get(`${base_url}`, config)
        .then((res) => {
          Toast.success('Success', 1000, () => { });
          this.setState({
            portfolio_details: res.data.items,
            portfolio_id: 'All',
            items_to_delete: []
          });
        })
        .catch((error) => {
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  navidgateToProduct(code, final_final_asset_class) {
    console.log(code, final_final_asset_class);
    if (final_final_asset_class !== 'Structured Product') {
      window.location.href = `/product/equity?Code=${code}`;
    } else {
      window.location.href = `/structure/autocallable/report?ISIN=${code}`;
    }
  }

  async selectHolding(portfolio_id, ISINCode) {
    let alreadyPresent = -1;
    this.state.items_to_delete.map((value, index) => {
      if (value.portfolio_id === portfolio_id && value.ISINCode === ISINCode) {
        alreadyPresent = index;
      }
    });
    if (alreadyPresent !== -1) {
      const items_to_delete = [...this.state.items_to_delete];
      items_to_delete.splice(alreadyPresent, 1);
      this.setState({
        items_to_delete
      });
    } else {
      this.setState((prevState) => ({
        items_to_delete: [
          ...prevState.items_to_delete,
          {
            portfolio_id,
            ISINCode
          }
        ]
      }));
    }
  }

  async handleDelete() {
    Toast.loading('In Progress', () => { });
    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      axios
        .delete(
          `${base_url}/portfolio/holding?`,
          {
            data: this.state.items_to_delete
          },
          config
        )
        .then((res) => {
          Toast.success('Success', 3000);
        })
        .catch((error) => {
          Toast.fail('Error Performing Action', 3000);
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  async componentDidMount() {
    const portfolio_id =
      cookies.get('Portfolio-Specified') != null
        ? cookies.get('Portfolio-Specified')
        : this.state.portfolio_id;

    Toast.loading('In Progress', () => { });

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      config.params = {
        email: cookies.get('User-Specified')
      };

      if (portfolio_id !== 'All') {
        config.params.portfolio_id = cookies.get('Portfolio-Specified');
      } else {
        config.params = {
          portfolio_id: this.state.portfolio_id
        };
      }

      axios
        .get(`${base_url}/portfolio/holding`, config)
        .then((res) => {
          this.setState({
            portfolio_details: res.data.items
          });
          Toast.success('Success', 500, () => { });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 1000);
        });
      axios
        .get(`${base_url}/portfolio`, config)
        .then((res) => {
          this.setState({
            portfolio_ids:
              cookies.get('Portfolio-Specified') == null
                ? [{ portfolio_id: 'All' }].concat(res.data.items)
                : [{ portfolio_id: cookies.get('Portfolio-Specified') }]
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 1000);
        });
    });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="container row">
          <h1>Client portfolio:</h1>
          <div className="col-sm-8 container">
            <div className="input-group mb-3">
              <select id="portfolio_id" onChange={this.handleSumbit} className="form-control">
                {this.state.portfolio_ids.map((value, index) => {
                  return <option>{value.portfolio_id}</option>;
                })}
              </select>
              <select id="final_asset_class" onChange={this.handleSumbit} className="form-control">
                <option>All</option>
                <option>Equity</option>
                <option>Structured Product</option>
                <option>Fixed Advance</option>
                <option>Bond</option>
              </select>
            </div>
            {/* <div className="input-group-append">
                            <a className="input-group-text btn btn-danger" onClick={this.handleSumbit}>Search</a>
                        </div>
                        <div className="input-group-append">
                            <a className="input-group-text btn btn-secondary" onClick={this.handleClear}>Clear</a>
                        </div> */}
          </div>
          <div className="col-sm-2 container">
            {!this.state.multiple_select ? (
              <button className="btn btn-primary" onClick={this.toggleMultipleSelect}>
                Select
              </button>
            ) : (
              <button className="btn btn-secondary" onClick={this.toggleMultipleSelect}>
                Cancel
              </button>
            )}
          </div>
          <div className="col-sm-2 container">
            {this.state.multiple_select && this.state.items_to_delete.length !== 0 && (
              <button className="btn btn-danger" onClick={this.handleDelete}>
                Delete
              </button>
            )}
          </div>
        </div>
        <table className="table table-sm">
          <thead className="table-dark">
            <tr>
              <th>Code</th>
              <th>Name</th>
              <th>Trailing PE</th>
              <th>Forward PE</th>
              <th>Market Capitalization To Revenue TTM</th>
              <th>Return On Equity TTM</th>
              <th>Dividend Yield</th>
              <th>Rating</th>
              <th>BUY</th>
              <th>HOLD</th>
              <th>HOLD</th>
              <th>Return Potential</th>
              <th>IV</th>
              {this.state.multiple_select && <th></th>}
            </tr>
          </thead>
          <tbody>
            {this.state.portfolio_details.map((item, index) => {
              return (
                <tr key={index}>
                  <td className="table-dark">
                    {item.code != null ? (
                      <a
                        className="btn btn-link"
                        onClick={() =>
                          this.navidgateToProduct(item.code, item.final_final_asset_class)
                        }
                      >
                        {item.code}
                      </a>
                    ) : (
                      '-'
                    )}
                  </td>
                  <td className="table-dark"> {item.name != null ? item.name : '-'} </td>
                  <td className="table-dark">
                    
                    {item.trailing_pe != null ? item.trailing_pe : '-'}
                  </td>
                  <td className="table-dark">
                    
                    {item.forward_pe != null ? item.forward_pe : '-'}
                  </td>
                  <td className="table-dark">
                    
                    {item.market_capitalization_to_revenue_ttm != null
                      ? item.market_capitalization_to_revenue_ttm.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })
                      : '-'}
                  </td>
                  <td className="table-dark">
                    
                    {item.return_on_equity_ttm != null
                      ? item.return_on_equity_ttm.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })
                      : '-'}
                  </td>
                  <td className="table-dark">
                    
                    {item.dividend_yield != null
                      ? item.dividend_yield.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })
                      : '-'}
                  </td>
                  <td className="table-dark">
                    
                    {item.rating != null
                      ? item.rating.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })
                      : '-'}
                  </td>
                  <td className="table-dark">
                    
                    {item.buy != null
                      ? item.buy.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })
                      : '-'}
                  </td>
                  <td className="table-dark">
                    
                    {item.hold != null
                      ? item.hold.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })
                      : '-'}
                  </td>
                  <td className="table-dark">
                    
                    {item.hold != null
                      ? item.hold.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })
                      : '-'}
                  </td>
                  <td className="table-dark">
                    
                    {item.return_potential != null
                      ? item.return_potential.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })
                      : '-'}
                  </td>
                  <td className="table-dark">
                    
                    {item.iv_3months != null
                      ? item.iv_3months.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })
                      : '-'}
                  </td>

                  {this.state.multiple_select && (
                    <td className="table-dark">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        onClick={() => this.selectHolding(item.portfolio_id, item.ISINCode)}
                      />
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default ViewPortfoliosInsights;
