import axios from 'axios';
import Toast from 'light-toast';
import React, { Component } from 'react';
import './styles.css';

import { fetchAuthSession } from 'aws-amplify/auth';
import { FaTrash } from 'react-icons/fa';
import Flags from './flags';

import cookies from '../utils/cookies.util';
import base_url from '../utils/request.util';

class StructureMatrix extends Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestions: [],
      underlying: [],
      data: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSumbit = this.handleSumbit.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.fetchsuggestions = this.fetchsuggestions.bind(this);
    this.selectedScrip = this.selectedScrip.bind(this);
    this.removeScrip = this.removeScrip.bind(this);
  }

  toggleMultipleSelect() {
    this.setState((prevState) => ({
      multiple_select: !prevState.multiple_select
    }));
    this.setState({
      items_to_delete: []
    });
  }

  handleChange(event) {
    this.setState({ portfolio_id: event.target.value });
  }

  async handleSumbit(event) {
    Toast.loading('In Progress', () => { });

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      const data = { codes: this.state.underlying };
      axios
        .post(`${base_url}/structure-matrix`, data, config)
        .then((res) => {
          Toast.success('Success', 1000, () => { });
          this.setState({
            data: res.data.items
          });
        })
        .catch((error) => {
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  async handleClear() {
    Toast.loading('In Progress', () => { });
    let url = '/structure-matrix';
    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      const data = this.state.underlying;
      axios
        .post(`${base_url}`, data, config)
        .then((res) => {
          Toast.success('Success', 1000, () => { });
          this.setState({
            data: res.data.items,
            suggestions: []
          });
        })
        .catch((error) => {
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  async addScrip() {
    const scrip = document.getElementById('scrip').value;
    const initial_price = document.getElementById('initial_price').value;
    if (this.state.underlying.indexOf({ code: scrip.toUpperCase() }) !== -1) return;
    this.setState((prevState) => ({
      underlying: [
        ...prevState.underlying,
        {
          code: scrip.toUpperCase(),
          initial_reference_price: initial_price
        }
      ],
      suggestions: []
    }));

    document.getElementById('scrip').value = '';
  }

  async fetchsuggestions(event) {
    if (event.target.id === '') return;

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      config.params = { q: event.target.value };
      axios
        .get(`${base_url}/product/suggest`, config)
        .then((res) => {
          this.setState({
            suggestions: res.data.items
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  selectedScrip(event) {
    this.setState((prevState) => ({
      underlying: [...prevState.underlying, event.target.id],
      suggestions: []
    }));
    document.getElementById('scrip').value = '';
  }

  removeScrip(scrip_index) {
    const scrip_array = [...this.state.underlying];
    scrip_array.splice(scrip_index, 1);
    console.log(scrip_index, scrip_array);
    this.setState({
      underlying: scrip_array
    });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="form-group row">
          <label>Underlyings:</label>
          <div className="col-sm-6">
            <input
              type="text"
              autoComplete="off"
              placeholder="Enter Scrip Name to add"
              className="form-control"
              id="scrip"
              onChange={this.fetchsuggestions}
            />

            <div className="dropdown">
              <ul className="list-group">
                {this.state.suggestions.map((value, index) => {
                  return (
                    <li
                      className="list-group-item list-group-flush"
                      onClick={this.selectedScrip}
                      id={value.code}
                      key={index}
                    >
                      {value.code}
                      &nbsp; ({value.name}) &nbsp;
                      <img src={Flags[value.country_iso]}></img>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="col-sm-1">
            <div className="btn-group" role="group" aria-label="Basic example">
              <a className="btn btn-primary" id="addScrip" onClick={this.handleSumbit}>
                Fetch Data
              </a>
            </div>
          </div>

          <div className="contaier">
            {this.state.underlying.map((code, index) => {
              return (
                <div className="row">
                  <div className="col-md-4">
                    <input type="text" disabled="true" className="form-control" value={code} />
                  </div>
                  <div className="col-md-2 btn-group" role="group" aria-label="Basic example">
                    <a className="btn btn-danger" onClick={() => this.removeScrip(index)}>
                      <FaTrash />
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <table className="table table-sm">
          <thead className="table-dark">
            <tr>
              <th style={{ position: 'sticky', top: 50 }}>#</th>
              <th style={{ position: 'sticky', top: 50 }}>Structure</th>
              <th style={{ position: 'sticky', top: 50 }}>Coupon</th>
              <th style={{ position: 'sticky', top: 50 }}>Cpn Type</th>
              <th style={{ position: 'sticky', top: 50 }}>CB</th>
              <th style={{ position: 'sticky', top: 50 }}>CT</th>
              <th style={{ position: 'sticky', top: 50 }}>Strike</th>
              <th style={{ position: 'sticky', top: 50 }}>AC</th>
              <th style={{ position: 'sticky', top: 50 }}>Tenor</th>
              <th style={{ position: 'sticky', top: 50 }}>Issuer</th>
              <th style={{ position: 'sticky', top: 50 }}>Daily</th>
              <th style={{ position: 'sticky', top: 50 }}>Setp Down</th>
              <th style={{ position: 'sticky', top: 50 }}>Delay</th>
            </tr>
          </thead>
          <tbody>
            {this.state.data.map((item, index) => {
              return (
                <tr key={index}>
                  <td className="table-dark"> {index + 1} </td>
                  <td className="table-dark">
                    
                    {item.isin_code != null
                      ? item.isin_code.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })
                      : '-'}
                  </td>
                  <td className="table-dark">
                    
                    {item.coupon != null
                      ? item.coupon.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })
                      : '-'}
                  </td>
                  <td className="table-dark">
                    
                    {item.coupon_type != null
                      ? item.coupon_type.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })
                      : '-'}
                  </td>
                  <td className="table-dark">
                    
                    {item.barrier != null
                      ? item.barrier.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })
                      : '-'}
                  </td>
                  <td className="table-dark">
                    
                    {item.coupon_trigger != null
                      ? item.coupon_trigger.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })
                      : '-'}
                  </td>
                  <td className="table-dark">
                    
                    {item.strike != null
                      ? item.strike.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })
                      : '-'}
                  </td>
                  <td className="table-dark">
                    
                    {item.autocall != null
                      ? item.autocall.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })
                      : '-'}
                  </td>
                  <td className="table-dark">
                    
                    {item.tenor != null
                      ? item.tenor.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })
                      : '-'}
                  </td>
                  <td className="table-dark"> {item.issuer} </td>
                  <td className="table-dark">
                    
                    {item.coupon_frequency != null
                      ? item.coupon_frequency.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      }) + ' Months'
                      : '-'}
                  </td>
                  <td className="table-dark">
                    
                    {item.autocall_step != null
                      ? item.autocall_step.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })
                      : '-'}
                  </td>
                  <td className="table-dark">
                    
                    {item.autocall_delay != null
                      ? item.autocall_delay.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })
                      : '-'}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default StructureMatrix;
