import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';
import Toast from 'light-toast';
import React, { Component } from 'react';
import './styles.css';

import cookies from '../utils/cookies.util';
import base_url from '../utils/request.util';

import { attr_to_col, attributes } from '../mappings/productBonds';

class ProductBonds extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }

  async componentDidMount() {
    Toast.loading('In Progress', () => { });
    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      axios
        .get(`${base_url}/external-bond-product`, config)
        .then((res) => {
          this.setState({
            data: res.data.items
          });
          Toast.success('Success', 500, () => { });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  render() {
    console.log(this.state);
    return (
      <div className="container-fluid">
        <div className="container row">
          <h1>External Bonds:</h1>
        </div>
        <table className="table table-sm">
          <thead className="table-dark">
            {attributes.map((attribute, index) => {
              return <th style={{ position: 'sticky', top: 50 }}>{attr_to_col[attribute]}</th>;
            })}
          </thead>
          <tbody>
            {this.state.data.map((item, index) => {
              return (
                <tr key={index}>
                  {attributes.map((attribute, index) => {
                    return (
                      <td className="table-dark">{item[attribute] ? item[attribute] : '-'}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default ProductBonds;
