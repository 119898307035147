export default {
  US: 'https://flags.fmcdn.net/data/flags/mini/us.png',
  GB: 'https://flags.fmcdn.net/data/flags/mini/gb.png',
  UK: 'https://flags.fmcdn.net/data/flags/mini/gb.png',
  FR: 'https://flags.fmcdn.net/data/flags/mini/fr.png',
  PT: 'https://flags.fmcdn.net/data/flags/mini/pt.png',
  IN: 'https://flags.fmcdn.net/data/flags/mini/in.png',
  CH: 'https://flags.fmcdn.net/data/flags/mini/ch.png',
  CN: 'https://flags.fmcdn.net/data/flags/mini/cn.png',
  NL: 'https://flags.fmcdn.net/data/flags/mini/nl.png',
  JP: 'https://flags.fmcdn.net/data/flags/mini/jp.png',
  AU: 'https://flags.fmcdn.net/data/flags/mini/au.png',
  HK: 'https://flags.fmcdn.net/data/flags/mini/jp.png',
  IT: 'https://flags.fmcdn.net/data/flags/mini/it.png',
  DE: 'https://flags.fmcdn.net/data/flags/mini/de.png'
};
