import axios from 'axios';
import Toast from 'light-toast';
import React, { Component } from 'react';
import './styles.css';

import { fetchAuthSession } from 'aws-amplify/auth';
import moment from 'moment';

// other codes

import cookies from '../utils/cookies.util';
import base_url from '../utils/request.util';

class LoginHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestions: [],
      underlying: [],
      data: []
    };
  }

  async componentDidMount() {
    Toast.loading('In Progress');

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      axios
        .get(`${base_url}/login-history`, config)
        .then((res) => {
          Toast.success('Success', 1000, () => { });
          this.setState({
            data: res.data.items
          });
        })
        .catch((error) => {
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  render() {
    return (
      <div className="container-fluid">
        <table className="table table-sm">
          <thead className="table-dark">
            <tr>
              <th style={{ position: 'sticky', top: 50 }}>#</th>
              <th style={{ position: 'sticky', top: 50 }}>Structure</th>
              <th style={{ position: 'sticky', top: 50 }}>Last Logged In</th>
            </tr>
          </thead>
          <tbody>
            {this.state.data.map((item, index) => {
              return (
                <tr key={index}>
                  <td className="table-dark"> {index + 1} </td>
                  <td className="table-dark"> {item.user_id} </td>
                  <td className="table-dark">
                    
                    {moment(
                      new Date(
                        new Date(item.last_login_timestamp)
                          .toLocaleString('en-US', { timeZone: 'Asia/Kolkata' })
                          .toString() + ' UTC'
                      ).toString()
                    ).fromNow()}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default LoginHistory;
