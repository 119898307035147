import axios from 'axios';
import Toast from 'light-toast';
import React, { useEffect, useState } from 'react';
import './styles.css';

import { fetchAuthSession } from 'aws-amplify/auth';

import cookies from '../utils/cookies.util';
import base_url from '../utils/request.util';

function returnKey(finalAssetClass) {
  switch (finalAssetClass) {
    case 'structured product':
      return 's_name';
    case 'funds':
      return 'b_name';
    default:
      return 'name';
  }
}

const CashView = () => {
  const [cashDetails, setCashDetails] = useState([]);
  const [portfolioId, setPortfolioId] = useState('All');
  const [portfolioIds, setPortfolioIds] = useState([]);
  const [multipleSelect, setMultipleSelect] = useState(false);
  const [itemsToDelete, setItemsToDelete] = useState([]);

  useEffect(() => {
    const fetchCashDetails = async () => {
      Toast.loading('In Progress', () => { });

      try {
        await fetchAuthSession();
        const config = {
          headers: {
            'Authorization': cookies.get('Authorization'),
            'User-Specified': cookies.get('User-Specified'),
            'Type-Specified': cookies.get('Type-Specified')
          }
        };

        const response = await axios.get(`${base_url}/portfolio/client-cash`, config);
        setCashDetails(response.data.items);
        Toast.success('Success', 500, () => { });
      } catch (error) {
        console.log(error);
        Toast.fail('Error Performing Action', 1000);
      }
    };

    fetchCashDetails();
  }, []);

  return (
    <div className="container-fluid">
      <br />
      <table className="table table-sm">
        <thead className="table-dark">
          <tr>
            <th style={{ position: 'sticky', top: 50 }}>User</th>
            <th style={{ position: 'sticky', top: 50 }}>Portfolio</th>
            <th style={{ position: 'sticky', top: 50 }}>Balance USD</th>
            <th style={{ position: 'sticky', top: 50 }}>Balance AUD</th>
            <th style={{ position: 'sticky', top: 50 }}>Balance EUR</th>
            <th style={{ position: 'sticky', top: 50 }}>Balance GBP</th>
            <th style={{ position: 'sticky', top: 50 }}>Balance INR</th>
            {/* <th style={{ position: 'sticky', top: 50 }}>Balance CHF</th> */}
          </tr>
        </thead>
        <tbody>
          {cashDetails.map((user) => (
            <React.Fragment key={user.user_id}>
              {user.p.map((item, index) => (
                <tr key={`${user.user_id}-${item.portfolio_id}`}>
                  {index === 0 && (
                    <td
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                      rowSpan={user.p.length}
                    >
                      <strong>{user.user_id}</strong>
                      <br />
                      <br />
                      <strong>USD: {user.total_usd != null ? user.total_usd.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      }) : "-"}</strong>
                      <br />
                      <strong>EUR: {user.total_eur != null ? user.total_eur.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      }) : "-"}</strong>
                      <br />
                      <strong>GBP: {user.total_gbp != null ? user.total_gbp.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      }) : "-"}</strong>
                      <br />
                      <strong>AUD: {user.total_aud != null ? user.total_aud.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      }) : "-"}</strong>
                      <br />
                      <strong>INR: {user.total_inr != null ? user.total_inr.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      }) : "-"}</strong>
                      <br />
                    </td>
                  )}
                  <td
                    style={{
                      backgroundColor: '#002850',
                      color: '#ffffff',
                      textDecoration: 'none',
                      border: '1px solid white'
                    }}
                  >{item.portfolio_id}</td>
                  <td>{item.balance_usd.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2
                  })}</td>
                  <td>{item.balance_aud.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2
                  })}</td>
                  <td>{item.balance_eur.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2
                  })}</td>
                  <td>{item.balance_gbp.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2
                  })}</td>
                  <td>{item.balance_inr.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2
                  })}</td>
                  {/* <td>{item.balance_chf}</td> */}
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CashView;