import React, { Component } from 'react';
import { FaBars } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

class EquityPopover extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userType: 'Client'
    };
  }

  render() {
    return (
      <div className="well">
        <Popup
          trigger={
            <button type="button" className="btn btn-small">
              
              <FaBars />
            </button>
          }
          position="right center"
        >
          <Link
            to={`/options/put-writing-strategy?Code=${this.props.code}`}
            type="button"
            className="btn btn-link"
          >
            Put Writing Strategy
          </Link>
          <Link
            to={`/options/option-matrix?Code=${this.props.code}`}
            type="button"
            className="btn btn-link"
          >
            Option Matrix
          </Link>
          <Link
            to={`/portfolio/exposure?Code=${this.props.code}`}
            type="button"
            className="btn btn-link"
          >
            Exposure
          </Link>
          {/* <button type="button" className="btn btn-primary">Samsung</button> */}
        </Popup>
      </div>
    );
  }
}

export default EquityPopover;
