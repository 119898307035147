import axios from 'axios';
import Toast from 'light-toast';
import React, { Component } from 'react';
import Flags from './flags';

import { fetchAuthSession } from 'aws-amplify/auth';
import { Link } from 'react-router-dom';
import TradingViewWidget from './tradingviewChart';

import cookies from '../utils/cookies.util';
import base_url from '../utils/request.util';

function divideArray(Arr, leftOver) {
  const CurrentArr = Array.from(Arr);
  if (!leftOver) {
    const halfArr = CurrentArr.splice(0, CurrentArr.length / 2);
    return halfArr;
  }
  CurrentArr.splice(0, CurrentArr.length / 2);
  return CurrentArr;
}

class EquityPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      code: '',
      news_data: [],
      suggestions: [],
      yearly_piotroski_scores: [],
      yearly_piotroski_values: [],
      quarterly_piotroski_scores: [],
      quarterly_piotroski_values: [],
      period: 'yearly'
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.fetchSuggestions = this.fetchSuggestions.bind(this);
    this.selectedScrip = this.selectedScrip.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.togglePiotroskiScores = this.togglePiotroskiScores.bind(this);
  }

  async fetchData(code) {
    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      config.params = {};
      axios
        .get(`${base_url}/product/${code}`, config)
        .then((res) => {
          this.setState({
            data: res.data.item
          });
          code = code.replaceAll('/', ' ');
          document.getElementById('code').value = code;
          Toast.success('Success', 2000, () => { });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });

      config.params = { period: 'yearly' };
      axios
        .get(`${base_url}/product-piotroski-score/${code}`, config)
        .then((res) => {
          this.setState({
            yearly_piotroski_scores: res.data.item.piotroski_scores,
            yearly_piotroski_values: res.data.item.piotroski_values
          });
        })
        .catch((error) => {
          console.log(error);
        });

      config.params = { period: 'quarterly' };
      axios
        .get(`${base_url}/product-piotroski-score/${code}`, config)
        .then((res) => {
          this.setState({
            quarterly_piotroski_scores: res.data.item.piotroski_scores,
            quarterly_piotroski_values: res.data.item.piotroski_values
          });
        })
        .catch((error) => {
          console.log(error);
        });

      config.params = {};
      axios
        .get(`${base_url}/product-news/${code}`, config)
        .then((res) => {
          this.setState({
            news_data: res.data.items != null ? res.data.items : {}
          });
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  async handleSubmit(event) {
    let code = this.state.code;
    Toast.loading('In Progress', () => { });
    code = code.toUpperCase();
    await this.fetchData(code);
  }

  handleChange(event) {
    let code = event.target.value;
    code = code.toUpperCase();
    this.setState({
      code
    });
  }

  async fetchSuggestions(event) {
    this.setState({
      code: event.target.value
    });
    if (event.target.value === '') return;

    await fetchAuthSession().then(() => {
      const config = {};
      config.params = { q: event.target.value };
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };

      axios
        .get(`${base_url}/product/suggest`, config)
        .then((res) => {
          this.setState({
            suggestions: res.data.items
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  selectedScrip(event) {
    this.setState(
      {
        code: event.target.id,
        suggestions: []
      },
      () => {
        this.handleSubmit();
      }
    );
  }

  togglePiotroskiScores(event) {
    this.setState({
      period: event.target.id
    });
  }

  async componentDidMount() {
    const search = window.location.search;
    const code = search.replaceAll('?code=', '').replaceAll('?Code=', '').replaceAll('%20', ' ');

    if (code === '') return;
    Toast.loading('In Progress', () => { });
    await this.fetchData(code);
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="container p-3 my-3 border">
          <div className="container input-group mb-3">
            <input
              type="text"
              id="code"
              value={this.state.code}
              className="form-control"
              placeholder="Type Something like AAPL US EQUITY ...."
              onChange={this.fetchSuggestions}
              autoComplete="off"
            />
            <div className="input-group-append">
              <button className="btn btn-success" type="submit" onClick={this.handleSubmit}>
                Search
              </button>
            </div>
          </div>
          <div className="dropdown">
            <ul className="list-group">
              {this.state.suggestions.map((value, index) => {
                return (
                  <li
                    className="list-group-item list-group-flush"
                    onClick={this.selectedScrip}
                    id={value.code}
                    key={index}
                  >
                    {value.code}
                    &nbsp; ({value.name}) &nbsp;
                    <img src={Flags[value.country_iso]}></img>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        {this.state.data != null && (
          <table className="table table-border">
            <tbody>
              <tr className="table-dark">
                <td>
                  <h3>
                    <img
                      height="35px"
                      src={'https://eodhistoricaldata.com' + this.state.data.logo_url}
                    />
                    &nbsp;
                    {this.state.data.name}
                  </h3>
                </td>
              </tr>
              <tr className="table-secondary">
                <td>
                  <div className="row">
                    <div className="col-sm-4">
                      <strong>Code: {this.state.data.code}</strong>
                    </div>
                    <div className="col-sm-4">
                      <strong>Country: {this.state.data.country_name}</strong>
                    </div>
                    <div className="col-sm-4">
                      <strong>IPO Date: {this.state.data.ipo_date}</strong>
                    </div>
                  </div>
                </td>
              </tr>
              <tr className="table-secondary">
                <td>
                  <div className="row">
                    <div className="col-sm-4">
                      <strong>Sector: {this.state.data.sector}</strong>
                    </div>
                    <div className="col-sm-4">
                      <strong>Industry: {this.state.data.industry}</strong>
                    </div>
                    {this.state.data.Officers != null && (
                      <div className="col-sm-4">
                        <strong>CEO: {this.state.data.ceo}</strong>
                      </div>
                    )}
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>Description</strong>: {this.state.data.description}
                  </p>
                </td>
              </tr>
              <tr className="table-dark">
                <td>
                  <center>
                    <strong>Chart</strong>
                  </center>
                </td>
              </tr>
              <tr>
                {/*
                                    <Chart
                                        options={{
                                            chart: {
                                                height: 350,
                                                type: 'candlestick',
                                            },
                                            title: {
                                                text: this.state.data["Name"]+" Weekly Chart",
                                                align: 'left'
                                            },
                                            tooltip: {
                                                enabled: true,
                                            },
                                            xaxis: {
                                                type: 'datetime',
                                                labels: {
                                                    formatter: function(val) {
                                                        return dayjs(val).format('MMM YY')
                                                    }
                                                }
                                            },
                                            yaxis: {
                                                tooltip: {
                                                enabled: true
                                                }
                                            }
                                        }}
                                        series={[{
                                            name: "candle",
                                            data: this.state.data["ChartData"]
                                        }]}
                                        type="candlestick"
                                        height={350}
                                    />
                                */}
                <TradingViewWidget code={`${this.state.data.exchange}:${this.state.data.symbol}`} />
              </tr>
              <tr className="table-dark">
                <td>
                  <center>
                    <strong>Highlights</strong>
                  </center>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="row">
                    <div className="col-sm-6">
                      <ul className="list-group">
                        <li className="list-group-item">
                          <strong>Last Price:</strong> {this.state.data.last_price}
                        </li>
                        <li className="list-group-item">
                          <strong>CurrencyCode:</strong> {this.state.data.currencyCode}
                        </li>
                        <li className="list-group-item">
                          <strong>Fiscal Year End:</strong> {this.state.data.FiscalYearEnd}
                        </li>
                      </ul>
                    </div>
                    <div className="col-sm-6">
                      <ul className="list-group">
                        <li className="list-group-item">
                          <strong>Market Capitalization:</strong>
                          {this.state.data.market_capitalization_mln} Mn
                        </li>
                        <li className="list-group-item">
                          <strong>Most Recent Quarter:</strong>
                          {this.state.data.most_recent_quarter}
                        </li>
                        <li className="list-group-item">
                          <strong>Shares Outstanding:</strong> {this.state.data.shares_outstanding}
                        </li>
                        <li className="list-group-item">
                          <strong>Forward Annual Dividend Yield:</strong>
                          {this.state.data.forward_annual_dividend_yield}%
                        </li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              {this.state.data.Holders != null && this.state.data.Holders.length !== 0 && (
                <>
                  <tr className="table-dark">
                    <td>
                      <center>
                        <strong>Institutional Holdings</strong>
                      </center>
                    </td>
                  </tr>
                  <div className="row">
                    <div className="col-sm-6">
                      <ul className="list-group">
                        {divideArray(this.state.data.Holders, false).map((value, index) => {
                          return (
                            <li className="list-group-item">
                              <strong>{value.name}:</strong> {value.currentShares}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="col-sm-6">
                      <ul className="list-group">
                        {divideArray(this.state.data.Holders, true).map((value, index) => {
                          return (
                            <li className="list-group-item">
                              <strong>{value.name}:</strong> {value.currentShares}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </>
              )}
              <tr className="table-dark">
                <td>
                  <center>
                    <strong>Technicals</strong>
                  </center>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="row">
                    <div className="col-sm-6">
                      <ul className="list-group">
                        <li className="list-group-item">
                          <strong>Beta:</strong> {this.state.data.beta}
                        </li>
                        <li className="list-group-item">
                          <strong>52 Week High:</strong> {this.state.data.week_52_high}
                        </li>
                        <li className="list-group-item">
                          <strong>52 Week Low:</strong> {this.state.data.week_52_low}
                        </li>
                        <li className="list-group-item">
                          <strong>50 DayMA:</strong> {this.state.data.dma_50}
                        </li>
                        <li className="list-group-item">
                          <strong>200 DayMA:</strong> {this.state.data.dma_200}
                        </li>
                      </ul>
                    </div>
                    <div className="col-sm-6">
                      <ul className="list-group">
                        <li className="list-group-item">
                          <strong>Payout Ratio:</strong> {this.state.data.payout_ratio}%
                        </li>
                        <li className="list-group-item">
                          <strong>Dividend Date:</strong> {this.state.data.dividend_date}
                        </li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr className="table-dark">
                <td>
                  <center>
                    <strong>Fundamentals</strong>
                  </center>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="row">
                    <div className="col-sm-6">
                      <ul className="list-group">
                        <li className="list-group-item">
                          <strong>EBITDA:</strong> {this.state.data.ebitda}
                        </li>
                        <li className="list-group-item">
                          <strong>PE Ratio:</strong> {this.state.data.pe_ratio}
                        </li>
                        <li className="list-group-item">
                          <strong>Profit Margin:</strong> {this.state.data.profit_margin}%
                        </li>
                        <li className="list-group-item">
                          <strong>PEG Ratio:</strong> {this.state.data.peg_ratio}
                        </li>
                        <li className="list-group-item">
                          <strong>Operating Margin TTM:</strong>
                          {this.state.data.operating_margin_ttm}%
                        </li>
                        <li className="list-group-item">
                          <strong>Book Value:</strong> {this.state.data.book_value}
                        </li>
                        <li className="list-group-item">
                          <strong>Return On Assets TTM:</strong>
                          {this.state.data.return_on_assets_ttm}%
                        </li>
                        <li className="list-group-item">
                          <strong>Dividend Share:</strong> {this.state.data.dividend_share}
                        </li>
                        <li className="list-group-item">
                          <strong>Return On Equity TTM:</strong>
                          {this.state.data.return_on_equity_ttm} %
                        </li>
                        <li className="list-group-item">
                          <strong>Dividend Yield:</strong> {this.state.data.dividend_yield}%
                        </li>
                        <li className="list-group-item">
                          <strong>Revenue TTM:</strong> {this.state.data.revenue_ttm}
                        </li>
                        <li className="list-group-item">
                          <strong>Earnings Share:</strong> {this.state.data.earnings_share}
                        </li>
                        <li className="list-group-item">
                          <strong>Gross Profit TTM:</strong> {this.state.data.gross_profit_ttm}
                        </li>
                      </ul>
                    </div>
                    <div className="col-sm-6">
                      <ul className="list-group">
                        <li className="list-group-item">
                          <strong>EPS Estimate CurrentYear:</strong>
                          {this.state.data.eps_estimate_current_year}
                        </li>
                        <li className="list-group-item">
                          <strong>EPS Estimate NextYear:</strong>
                          {this.state.data.eps_estimate_next_year}
                        </li>
                        <li className="list-group-item">
                          <strong>Quarterly Earnings GrowthYOY:</strong>
                          {this.state.data.quarterly_earnings_growth_yoy}%
                        </li>
                        <li className="list-group-item">
                          <strong>Revenue Per ShareTTM:</strong>
                          {this.state.data.revenue_per_share_ttm}
                        </li>
                        <li className="list-group-item">
                          <strong>Quarterly Revenue GrowthYOY:</strong>
                          {this.state.data.quarterly_revenue_growth_yoy}%
                        </li>
                        <li className="list-group-item">
                          <strong>Trailing PE:</strong> {this.state.data.trailing_pe}
                        </li>
                        <li className="list-group-item">
                          <strong>Forward PE:</strong> {this.state.data.forward_pe}
                        </li>
                        <li className="list-group-item">
                          <strong>Price To Sales TTM:</strong> {this.state.data.price_sales_ttm}
                        </li>
                        <li className="list-group-item">
                          <strong>Diluted EPS TTM:</strong> {this.state.data.diluted_eps_ttm}
                        </li>
                        <li className="list-group-item">
                          <strong>PriceBook MRQ:</strong> {this.state.data.price_book_mrq}
                        </li>
                        <li className="list-group-item">
                          <strong>Enterprise Value Revenue:</strong>
                          {this.state.data.enterprise_value_revenue}
                        </li>
                        <li className="list-group-item">
                          <strong>Enterprise Value Ebitda:</strong>
                          {this.state.data.enterprise_value_ebitda}
                        </li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>

              <tr className="table-dark">
                <td>
                  <center>
                    <strong>Shares Stat</strong>
                  </center>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="row">
                    <div className="col-sm-6">
                      <ul className="list-group">
                        <li className="list-group-item">
                          <strong>Shares Float:</strong> {this.state.data.shares_float}
                        </li>
                        <li className="list-group-item">
                          <strong>Shares Short:</strong> {this.state.data.shares_short}
                        </li>
                        <li className="list-group-item">
                          <strong>Percent Insiders:</strong> {this.state.data.percent_insiders}%
                        </li>
                        <li className="list-group-item">
                          <strong>Shares Short PriorMonth:</strong>
                          {this.state.data.shares_short_prior_month}
                        </li>
                      </ul>
                    </div>
                    <div className="col-sm-6">
                      <ul className="list-group">
                        <li className="list-group-item">
                          <strong>Percent Institutions:</strong>
                          {this.state.data.percent_institutions}%
                        </li>
                        <li className="list-group-item">
                          <strong>Short Ratio:</strong> {this.state.data.short_ratio}
                        </li>
                        <li className="list-group-item">
                          <strong>Short Percent Outstanding:</strong>
                          {this.state.data.short_percent_outstanding}%
                        </li>
                        <li className="list-group-item">
                          <strong>Short Percent Float:</strong>
                          {this.state.data.short_percent_float}%
                        </li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>

              <tr className="table-dark">
                <td>
                  <center>
                    <strong>Analysts Rating:</strong>
                  </center>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="row">
                    <div className="col-sm-6">
                      <ul className="list-group">
                        <li className="list-group-item">
                          <strong>Rating:</strong> {this.state.data.rating}
                        </li>
                        <li className="list-group-item">
                          <strong>WallStreet Target Price:</strong>
                          {this.state.data.wall_street_target_price}
                        </li>
                        <li className="list-group-item">
                          <strong>Strong Buy:</strong> {this.state.data.strong_buy}
                        </li>
                        <li className="list-group-item">
                          <strong>Buy:</strong> {this.state.data.buy}
                        </li>
                      </ul>
                    </div>
                    <div className="col-sm-6">
                      <ul className="list-group">
                        <li className="list-group-item">
                          <strong>Hold:</strong> {this.state.data.hold}
                        </li>
                        <li className="list-group-item">
                          <strong>Sell:</strong> {this.state.data.sell}
                        </li>
                        <li className="list-group-item">
                          <strong>Strong Sell:</strong> {this.state.data.strong_sell}
                        </li>
                        <li className="list-group-item">
                          <strong>Target Price:</strong> {this.state.data.target_price}
                        </li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        )}

        <div className="container-fluid">
          <div className="container-fluid">
            {((this.state.yearly_piotroski_scores &&
              this.state.yearly_piotroski_scores.length !== 0) ||
              (this.state.quarterly_piotroski_scores &&
                this.state.quarterly_piotroski_scores.length !== 0)) && (
                <>
                  <h4>{this.state.period.toUpperCase()} PIOTROSKI SCORES:</h4>

                  <div className="btn-group" style={{ width: '100%' }}>
                    <button
                      type="button"
                      className={
                        this.state.period === 'yearly' ? 'btn btn-primary' : 'btn btn-secondary'
                      }
                      id="yearly"
                      onClick={this.togglePiotroskiScores}
                    >
                      Yearly
                    </button>
                    <button
                      type="button"
                      className={
                        this.state.period === 'yearly' ? 'btn btn-secondary' : 'btn btn-primary'
                      }
                      id="quarterly"
                      onClick={this.togglePiotroskiScores}
                    >
                      Quarterly
                    </button>
                  </div>
                  {this.state.period === 'yearly' &&
                    this.state.yearly_piotroski_scores &&
                    this.state.yearly_piotroski_scores.length !== 0 && (
                      <table className="table">
                        <thead className="table-dark">
                          <tr>
                            <th>Parameter</th>
                            {this.state.yearly_piotroski_values.map((value, index) => {
                              return (
                                <td>
                                  <b>{value.date}</b>
                                </td>
                              );
                            })}
                          </tr>
                        </thead>
                        <tr>
                          <td
                            style={{
                              backgroundColor: '#002850',
                              color: '#ffffff',
                              textDecoration: 'none',
                              border: '1px solid white',
                              textAlign: 'center',
                              fontWeight: 'bold'
                            }}
                            colSpan={5}
                          >
                            PROFITABILITY
                          </td>
                        </tr>
                        <tr>
                          <td className="table-dark" rowSpan="2">
                            Net Income
                          </td>
                          {this.state.yearly_piotroski_scores.map((value, index) => {
                            return <td className="table-dark" >{value.net_income}</td>;
                          })}
                        </tr>
                        <tr>
                          {this.state.yearly_piotroski_values.map((value, index) => {
                            return (
                              <td className="table-dark" >
                                {value.net_income != null &&
                                  value.net_income.toLocaleString(undefined, {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 2
                                  })}
                              </td>
                            );
                          })}
                        </tr>
                        <tr>
                          <td className="table-dark" rowSpan="2">
                            Return On Assets TTM
                          </td>
                          {this.state.yearly_piotroski_scores.map((value, index) => {
                            return <td className="table-dark" >{value.return_on_assets_ttm}</td>;
                          })}
                        </tr>
                        <tr>
                          {this.state.yearly_piotroski_values.map((value, index) => {
                            return (
                              <td className="table-dark" >
                                {value.return_on_assets_ttm != null &&
                                  value.return_on_assets_ttm.toLocaleString(undefined, {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 2
                                  })}
                              </td>
                            );
                          })}
                        </tr>
                        <tr>
                          <td className="table-dark" rowSpan="2">
                            Total Cash From Operating Activities
                          </td>
                          {this.state.yearly_piotroski_scores.map((value, index) => {
                            return <td className="table-dark" >{value.total_cash_from_operating_activities}</td>;
                          })}
                        </tr>
                        <tr>
                          {this.state.yearly_piotroski_values.map((value, index) => {
                            return (
                              <td className="table-dark" >
                                {value.total_cash_from_operating_activities != null &&
                                  value.total_cash_from_operating_activities.toLocaleString(
                                    undefined,
                                    { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                  )}
                              </td>
                            );
                          })}
                        </tr>
                        <tr>
                          <td className="table-dark" rowSpan="2">
                            Total Cash From Operating Activities &gt; netIncome
                          </td>
                          {this.state.yearly_piotroski_scores.map((value, index) => {
                            return <td className="table-dark" >{value.total_cash_from_operating_activities}</td>;
                          })}
                        </tr>
                        <tr>
                          {this.state.yearly_piotroski_values.map((value, index) => {
                            return (
                              <td className="table-dark" >
                                {value.total_cash_from_operating_activities != null &&
                                  value.total_cash_from_operating_activities.toLocaleString(
                                    undefined,
                                    { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                  )}
                              </td>
                            );
                          })}
                        </tr>
                        <tr>
                          <td
                            style={{
                              backgroundColor: '#002850',
                              color: '#ffffff',
                              textDecoration: 'none',
                              border: '1px solid white',
                              textAlign: 'center',
                              fontWeight: 'bold'
                            }}
                            colSpan={5}
                          >
                            LIQUIDITY
                          </td>
                        </tr>
                        <tr>
                          <td className="table-dark" rowSpan="2">
                            Long Term Debt
                          </td>
                          {this.state.yearly_piotroski_scores.map((value, index) => {
                            return <td className="table-dark" >{value.long_term_debt}</td>;
                          })}
                        </tr>
                        <tr>
                          {this.state.yearly_piotroski_values.map((value, index) => {
                            return (
                              <td className="table-dark" >
                                {value.long_term_debt != null &&
                                  value.long_term_debt.toLocaleString(undefined, {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 2
                                  })}
                              </td>
                            );
                          })}
                        </tr>
                        <tr>
                          <td className="table-dark" rowSpan="2">
                            Total Current Assets - Total Current Liabilities
                          </td>
                          {this.state.yearly_piotroski_scores.map((value, index) => {
                            return <td className="table-dark" >{value.assets_liabiilites_diff}</td>;
                          })}
                        </tr>
                        <tr>
                          {this.state.yearly_piotroski_values.map((value, index) => {
                            return (
                              <td className="table-dark" >
                                {value.assets_liabiilites_diff != null &&
                                  value.assets_liabiilites_diff.toLocaleString(undefined, {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 2
                                  })}
                              </td>
                            );
                          })}
                        </tr>
                        <tr>
                          <td className="table-dark" rowSpan="2">
                            New Shares Issued
                          </td>
                          {this.state.yearly_piotroski_scores.map((value, index) => {
                            return <td className="table-dark" >{value.outstanding_shares}</td>;
                          })}
                        </tr>
                        <tr>
                          {this.state.yearly_piotroski_values.map((value, index) => {
                            return (
                              <td className="table-dark" >
                                {value.outstanding_shares != null &&
                                  value.outstanding_shares.toLocaleString(undefined, {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 2
                                  })}
                              </td>
                            );
                          })}
                        </tr>
                        <tr>
                          <td
                            style={{
                              backgroundColor: '#002850',
                              color: '#ffffff',
                              textDecoration: 'none',
                              border: '1px solid white',
                              textAlign: 'center',
                              fontWeight: 'bold'
                            }}
                            colSpan={5}
                          >
                            OPERATIONAL EFFICIENCY
                          </td>
                        </tr>
                        <tr>
                          <td className="table-dark" rowSpan="2">
                            Total Revenue - Cost Of Revenue
                          </td>
                          {this.state.yearly_piotroski_scores.map((value, index) => {
                            return <td className="table-dark" >{value.revenvue_cost_diff}</td>;
                          })}
                        </tr>
                        <tr>
                          {this.state.yearly_piotroski_values.map((value, index) => {
                            return (
                              <td className="table-dark" >
                                {value.revenvue_cost_diff != null &&
                                  value.revenvue_cost_diff.toLocaleString(undefined, {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 2
                                  })}
                              </td>
                            );
                          })}
                        </tr>
                        <tr>
                          <td className="table-dark" rowSpan="2">
                            Total Revenue / Total Assets
                          </td>
                          {this.state.yearly_piotroski_scores.map((value, index) => {
                            return <td className="table-dark" >{value.revenue_to_assets}</td>;
                          })}
                        </tr>
                        <tr>
                          {this.state.yearly_piotroski_values.map((value, index) => {
                            return (
                              <td className="table-dark" >
                                {value.revenue_to_assets != null &&
                                  value.revenue_to_assets.toLocaleString(undefined, {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 2
                                  })}
                              </td>
                            );
                          })}
                        </tr>
                        <tr>
                          <td
                            style={{
                              backgroundColor: '#002850',
                              color: '#ffffff',
                              textDecoration: 'none',
                              border: '1px solid white',
                              fontWeight: 'bold'
                            }}
                          >
                            Total
                          </td>
                          {this.state.yearly_piotroski_scores.map((value, index) => {
                            return (
                              <td
                                style={{
                                  backgroundColor: '#002850',
                                  color: '#ffffff',
                                  textDecoration: 'none',
                                  border: '1px solid white',
                                  fontWeight: 'bold'
                                }}
                              >
                                {value.total}
                              </td>
                            );
                          })}
                          <td
                            style={{
                              backgroundColor: '#002850',
                              color: '#ffffff',
                              textDecoration: 'none',
                              border: '1px solid white',
                              fontWeight: 'bold'
                            }}
                          >
                            -
                          </td>
                        </tr>
                      </table>
                    )}
                  {this.state.period === 'quarterly' &&
                    this.state.quarterly_piotroski_scores &&
                    this.state.quarterly_piotroski_scores.length !== 0 && (
                      <table className="table">
                        <thead className="table-dark">
                          <tr>
                            <th>Parameter</th>
                            {this.state.quarterly_piotroski_values.map((value, index) => {
                              return (
                                <td>
                                  <b>{value.date}</b>
                                </td>
                              );
                            })}
                          </tr>
                        </thead>
                        <tr>
                          <td
                            style={{
                              backgroundColor: '#002850',
                              color: '#ffffff',
                              textDecoration: 'none',
                              border: '1px solid white',
                              textAlign: 'center',
                              fontWeight: 'bold'
                            }}
                            colSpan={5}
                          >
                            PROFITABILITY
                          </td>
                        </tr>
                        <tr>
                          <td className="table-dark" rowSpan="2">
                            Net Income
                          </td>
                          {this.state.quarterly_piotroski_scores.map((value, index) => {
                            return <td className="table-dark" >{value.net_income}</td>;
                          })}
                        </tr>
                        <tr>
                          {this.state.quarterly_piotroski_values.map((value, index) => {
                            return (
                              <td className="table-dark" >
                                {value.net_income != null &&
                                  value.net_income.toLocaleString(undefined, {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 2
                                  })}
                              </td>
                            );
                          })}
                        </tr>
                        <tr>
                          <td className="table-dark" rowSpan="2">
                            Return On Assets TTM
                          </td>
                          {this.state.quarterly_piotroski_scores.map((value, index) => {
                            return <td className="table-dark" >{value.return_on_assets_ttm}</td>;
                          })}
                        </tr>
                        <tr>
                          {this.state.quarterly_piotroski_values.map((value, index) => {
                            return (
                              <td className="table-dark" >
                                {value.return_on_assets_ttm != null &&
                                  value.return_on_assets_ttm.toLocaleString(undefined, {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 2
                                  })}
                              </td>
                            );
                          })}
                        </tr>
                        <tr>
                          <td className="table-dark" rowSpan="2">
                            Total Cash From Operating Activities
                          </td>
                          {this.state.quarterly_piotroski_scores.map((value, index) => {
                            return <td className="table-dark" >{value.total_cash_from_operating_activities}</td>;
                          })}
                        </tr>
                        <tr>
                          {this.state.quarterly_piotroski_values.map((value, index) => {
                            return (
                              <td className="table-dark" >
                                {value.total_cash_from_operating_activities != null &&
                                  value.total_cash_from_operating_activities.toLocaleString(
                                    undefined,
                                    { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                  )}
                              </td>
                            );
                          })}
                        </tr>
                        <tr>
                          <td className="table-dark" rowSpan="2">
                            Total Cash From Operating Activities &gt; netIncome
                          </td>
                          {this.state.quarterly_piotroski_scores.map((value, index) => {
                            return <td className="table-dark" >{value.total_cash_from_operating_activities}</td>;
                          })}
                        </tr>
                        <tr>
                          {this.state.quarterly_piotroski_values.map((value, index) => {
                            return (
                              <td className="table-dark" >
                                {value.total_cash_from_operating_activities != null &&
                                  value.total_cash_from_operating_activities.toLocaleString(
                                    undefined,
                                    { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                  )}
                              </td>
                            );
                          })}
                        </tr>
                        <tr>
                          <td
                            style={{
                              backgroundColor: '#002850',
                              color: '#ffffff',
                              textDecoration: 'none',
                              border: '1px solid white',
                              textAlign: 'center',
                              fontWeight: 'bold'
                            }}
                            colSpan={5}
                          >
                            LIQUIDITY
                          </td>
                        </tr>
                        <tr>
                          <td className="table-dark" rowSpan="2">
                            Long Term Debt
                          </td>
                          {this.state.quarterly_piotroski_scores.map((value, index) => {
                            return <td className="table-dark" >{value.long_term_debt}</td>;
                          })}
                        </tr>
                        <tr>
                          {this.state.quarterly_piotroski_values.map((value, index) => {
                            return (
                              <td className="table-dark" >
                                {value.long_term_debt != null &&
                                  value.long_term_debt.toLocaleString(undefined, {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 2
                                  })}
                              </td>
                            );
                          })}
                        </tr>
                        <tr>
                          <td className="table-dark" rowSpan="2">
                            Total Current Assets - Total Current Liabilities
                          </td>
                          {this.state.quarterly_piotroski_scores.map((value, index) => {
                            return <td className="table-dark" >{value.assets_liabiilites_diff}</td>;
                          })}
                        </tr>
                        <tr>
                          {this.state.quarterly_piotroski_values.map((value, index) => {
                            return (
                              <td className="table-dark" >
                                {value.assets_liabiilites_diff != null &&
                                  value.assets_liabiilites_diff.toLocaleString(undefined, {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 2
                                  })}
                              </td>
                            );
                          })}
                        </tr>
                        <tr>
                          <td className="table-dark" rowSpan="2">
                            New Shares Issued
                          </td>
                          {this.state.quarterly_piotroski_scores.map((value, index) => {
                            return <td className="table-dark" >{value.outstanding_shares}</td>;
                          })}
                        </tr>
                        <tr>
                          {this.state.quarterly_piotroski_values.map((value, index) => {
                            return (
                              <td className="table-dark" >
                                {value.outstanding_shares != null &&
                                  value.outstanding_shares.toLocaleString(undefined, {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 2
                                  })}
                              </td>
                            );
                          })}
                        </tr>
                        <tr>
                          <td
                            style={{
                              backgroundColor: '#002850',
                              color: '#ffffff',
                              textDecoration: 'none',
                              border: '1px solid white',
                              textAlign: 'center',
                              fontWeight: 'bold'
                            }}
                            colSpan={5}
                          >
                            OPERATIONAL EFFICIENCY
                          </td>
                        </tr>
                        <tr>
                          <td className="table-dark" rowSpan="2">
                            Total Revenue - Cost Of Revenue
                          </td>
                          {this.state.quarterly_piotroski_scores.map((value, index) => {
                            return <td className="table-dark" >{value.revenvue_cost_diff}</td>;
                          })}
                        </tr>
                        <tr>
                          {this.state.quarterly_piotroski_values.map((value, index) => {
                            return (
                              <td className="table-dark" >
                                {value.revenvue_cost_diff != null &&
                                  value.revenvue_cost_diff.toLocaleString(undefined, {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 2
                                  })}
                              </td>
                            );
                          })}
                        </tr>
                        <tr>
                          <td className="table-dark" rowSpan="2">
                            Total Revenue / Total Assets
                          </td>
                          {this.state.quarterly_piotroski_scores.map((value, index) => {
                            return <td className="table-dark" >{value.revenue_to_assets}</td>;
                          })}
                        </tr>
                        <tr>
                          {this.state.quarterly_piotroski_values.map((value, index) => {
                            return (
                              <td className="table-dark" >
                                {value.revenue_to_assets != null &&
                                  value.revenue_to_assets.toLocaleString(undefined, {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 2
                                  })}
                              </td>
                            );
                          })}
                        </tr>
                        <tr>
                          <td
                            style={{
                              backgroundColor: '#002850',
                              color: '#ffffff',
                              textDecoration: 'none',
                              border: '1px solid white',
                              fontWeight: 'bold'
                            }}
                          >
                            Total
                          </td>
                          {this.state.quarterly_piotroski_scores.map((value, index) => {
                            return (
                              <td
                                style={{
                                  backgroundColor: '#002850',
                                  color: '#ffffff',
                                  textDecoration: 'none',
                                  border: '1px solid white',
                                  fontWeight: 'bold'
                                }}
                              >
                                {value.total}
                              </td>
                            );
                          })}
                          <td
                            style={{
                              backgroundColor: '#002850',
                              color: '#ffffff',
                              textDecoration: 'none',
                              border: '1px solid white',
                              fontWeight: 'bold'
                            }}
                          >
                            -
                          </td>
                        </tr>
                      </table>
                    )}
                </>
              )}
          </div>
        </div>

        <ol className="Container-fliud">
          {this.state.news_data && this.state.news_data.length !== 0 && (
            <>
              <h4>News:</h4>
              {this.state.news_data.map((value, index) => {
                return (
                  <li className="">
                    <h5>
                      <Link to={`/product/news/${value.id}`}>{value.title}</Link>
                    </h5>
                    <b>{value.date}</b>
                    <p>{value.content.substring(0, 500) + '...'}</p>
                    <p>
                      <a className="btn btn-light" href={value.link}>
                        Read full article
                      </a>
                    </p>
                  </li>
                );
              })}
            </>
          )}
        </ol>
      </div>
    );
  }
}

export default EquityPage;
