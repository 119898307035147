import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';
import Toast from 'light-toast';
import React, { Component } from 'react';
import Flags from './flags';


import cookies from '../utils/cookies.util';
import base_url from '../utils/request.util';

class OptionsStrategy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: null,
      input_scrips: [],
      classification: {},
      suggestions: [],
      total: [],
      trade: [],
      underlying: [],
      conditions: [],
      strategy_for: 'code'
    };
    this.handleSumbit = this.handleSumbit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.selectedScrip = this.selectedScrip.bind(this);
    this.fetchSuggestions = this.fetchSuggestions.bind(this);
  }

  handleChange(event) {
    if (event.target.id === 'code') {
      this.setState({
        [event.target.id]: event.target.value
      });
    } else if (event.target.id === 'strategy_for') {
      this.setState({
        [event.target.id]: event.target.value === 'ISIN Code' ? 'isin_code' : 'code',
        code: null,
        underlying: [],
        trade: []
      });
    }
  }

  selectedScrip(event) {
    this.setState({ code: event.target.id }, () => {
      this.handleSumbit();
    });
  }

  async handleSumbit() {
    const code = this.state.code;
    const type = this.state.strategy_for;

    Toast.loading('In Progress', () => { });

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      config.params = {};
      config.params[type] = code;
      console.log(config);
      axios
        .get(`${base_url}/option-strategy`, config)
        .then((res) => {
          this.setState({
            code,
            underlying: res.data.item.underlying,
            trade: res.data.item.trade,
            total: res.data.item.total,
            strategy_for: type,
            worst_performing_asset: res.data.item.worst_performing_asset,
            conditions: res.data.item.conditions != null ? res.data.item.conditions : [],
            suggestions: []
          });
          Toast.success('Success', 3000);
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  async fetchSuggestions(event) {
    const typed = event.target.value;

    if (typed === '' || this.state.strategy_for === 'isin_code') {
      return;
    }

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      config.params = {
        q: typed
      };
      axios
        .get(`${base_url}/product/suggest`, config)
        .then((res) => {
          console.log('updating code');
          this.setState({
            suggestions: res.data.items
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  async componentDidMount() {
    let code;
    let type;

    const search = window.location.search;
    if (search.indexOf('ISIN') !== -1) {
      code = search.replaceAll('?ISIN=', '');
      type = 'isin_code';
    } else if (search.indexOf('Code') !== -1) {
      code = search.replaceAll('?Code=', '');
      type = 'code';
    } else {
      return;
    }

    Toast.loading('In Progress', () => { });

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      config.params = {};
      config.params[type] = code;
      axios
        .get(`${base_url}/option-strategy`, config)
        .then((res) => {
          this.setState({
            code,
            underlying: res.data.item.underlying,
            trade: res.data.item.trade,
            total: res.data.item.total,
            strategy_for: type,
            worst_performing_asset: res.data.item.worst_performing_asset,
            conditions: res.data.item.conditions
          });
          Toast.success('Success', 3000);
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  render() {
    return (
      <div className="container-fluid">
        <form className="form-group row">
          <label>Codes:</label>
          <div className="col-sm-2">
            <select
              className="form-control"
              value={this.state.strategy_for === 'isin_code' ? 'ISIN Code' : 'Code'}
              onChange={this.handleChange}
              id="strategy_for"
            >
              <option selected>Code</option>
              <option>ISIN Code</option>
            </select>
          </div>
          <div className="col-sm-7">
            <input
              type="text"
              autoComplete="off"
              placeholder="Enter Scrip Name to add"
              className="form-control"
              id="code"
              onChange={this.fetchSuggestions}
              value={this.state.code}
            />
            <div className="dropdown">
              <ul className="list-group">
                {this.state.suggestions.map((value, index) => {
                  return (
                    <li
                      className="list-group-item list-group-flush"
                      onClick={this.selectedScrip}
                      id={value.code}
                      key={index}
                    >
                      {value.code}
                      &nbsp; ({value.name}) &nbsp;
                      <img src={Flags[value.country_iso]}></img>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>

          <div className="col-sm-3">
            <div className="btn-group" role="group" aria-label="Basic example">
              <a className="btn btn-danger" onClick={this.handleSumbit}>
                Submit
              </a>
            </div>
          </div>
          <div className="contaier row">
            <div className="col-sm-7">
              {this.state.input_scrips.map((value, index) => {
                return (
                  <div className="row">
                    <div className="col-md-10">
                      <input
                        type="text"
                        disabled="true"
                        className="form-control"
                        value={value.code}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </form>

        <div className="container">
          <ul className="list-group">
            {this.state.strategy_for === 'isin_code' && this.state.underlying.length !== 0 && (
              <>
                <hr />
                <h3>Underlying:</h3>
                {this.state.underlying.map((value, index) => {
                  return (
                    <li
                      className="list-group-item list-group-flush"
                      onClick={this.handleSumbit}
                      id={value.code}
                      key={index}
                    >
                      {value.code}
                      &nbsp; (Chg: {value.percentage_change}) &nbsp; (Ref Price:
                      {value.initial_reference_price})
                    </li>
                  );
                })}
              </>
            )}
          </ul>
        </div>

        <div className="container">
          <ul className="list-group">
            {this.state.conditions.length !== 0 && (
              <>
                <hr />

                <h3>Conditions:</h3>
                {this.state.conditions.map((value, index) => {
                  return (
                    <li
                      className={
                        value.met
                          ? 'list-group-item list-group-flush list-group-item-success'
                          : 'list-group-item list-group-flush list-group-item-danger'
                      }
                      key={index}
                    >
                      {value.condition}
                    </li>
                  );
                })}
              </>
            )}
          </ul>
        </div>
        {this.state.trade.length !== 0 && (
          <>
            <hr />
            <div className="contaier">
              {this.state.strategy_for === 'isin_code' ? (
                <h3>
                  
                  Worst Performing Asset in {this.state.code} is
                  <b>{this.state.worst_performing_asset}</b>
                </h3>
              ) : (
                <h3>
                  
                  Searching for <b>{this.state.worst_performing_asset}</b>
                </h3>
              )}
              <table className="table table-bordered">
                <thead
                  style={{
                    backgroundColor: '#002850',
                    color: '#ffffff',
                    textDecoration: 'none',
                    border: '1px solid white'
                  }}
                >
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                      rowSpan={2}
                    >
                      CASH NEUTRAL STRATEGY
                    </th>
                    {this.state.trade.map((value, index) => (
                      <th colSpan={2} key={index}>
                        {value.expiry}
                      </th>
                    ))}
                  </tr>
                  <tr>
                    {this.state.trade.map((value, index) => (
                      <>
                        <th key={index}>Strike</th>
                        <th key={index}>Premium</th>
                      </>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      BUY PUT
                    </th>
                    {this.state.trade.map((value, index) => (
                      <>
                        <td>{value.strike_put}</td>
                        <td>{value.put}</td>
                      </>
                    ))}
                  </tr>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      SELL CALL
                    </th>
                    {this.state.trade.map((value, index) => (
                      <>
                        <td>{value.strike_call}</td>
                        <td>{value.call}</td>
                      </>
                    ))}
                  </tr>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      Net Premium
                    </th>
                    {this.state.total.map((value, index) => (
                      <>
                        <td></td>
                        <td>
                          {value != null
                            ? value.toLocaleString(undefined, {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2
                            })
                            : '-'}
                        </td>
                      </>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default OptionsStrategy;
