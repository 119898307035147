import { fetchAuthSession } from 'aws-amplify/auth';
import AWS from 'aws-sdk';
import axios from 'axios';
import Toast from 'light-toast';
import React, { Component } from 'react';
import Flags from './flags';

import issuers from './constants/issuers.json';

import {
    FaCalendar,
    FaCopy,
    FaForward,
    FaPen,
    FaPersonBooth,
    FaPlus,
    FaSave,
    FaSync,
    FaTrash,
    FaWindowClose
} from 'react-icons/fa';
import './styles.css';

import dayjs from 'dayjs';
import cookies from '../utils/cookies.util';
import { formatDate } from '../utils/date.util';
import base_url, { bucket_name, region } from '../utils/request.util';

const unwantedAttributes = ['flush_dates'];

class StructureAutocallableUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isin_code: '',
      product_type: 'autocallable',
      issuer: '',
      autocall_percentage: '100',
      autocall_step: '0',
      coupon_step: '0',
      barrier_type: 'European',
      barrier: '65',
      coupon: '8',
      flat_coupon: false,
      strike: '65',
      coupon_type: 'Memory',
      coupon_percentage: '65',
      currency: 'USD',
      weightage: 'Worst Off',
      maturity: '24',
      autocall_frequency: '3',
      coupon_frequency: '3',
      autocall_delay: '0',
      coupon_delay: '0',
      is_pending: null,
      initial_fixing_date: formatDate(new Date()),
      issue_date: formatDate(new Date(new Date().setDate(new Date().getDate() + 5))),
      final_fixing_date: formatDate(new Date(new Date().setDate(new Date().getDate() + 730))),
      redemption: formatDate(new Date(new Date().setDate(new Date().getDate() + 735))),
      underlying: [],
      soft_callable: false,
      coupon_memory: true,
      suggestions: [],
      delayed_frequency: '0',
      autocall_table: null,
      coupon_table: null,
      new_isin_code: '',
      knockin_kickin: false,
      knockin_kickin_value: '0',
      asian_delay: false,
      asian_delay_value: '0',
      onestar: false,
      onestar_value: '0',
      twin_win: false,
      flush_dates: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDuplicate = this.handleDuplicate.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.addScrip = this.addScrip.bind(this);
    this.addScripSubmit = this.addScripSubmit.bind(this);
    this.removeScrip = this.removeScrip.bind(this);
    this.updateScrip = this.updateScrip.bind(this);
    this.fetchsuggestions = this.fetchsuggestions.bind(this);
    this.selectedScrip = this.selectedScrip.bind(this);
    this.discardScrips = this.discardScrips.bind(this);
    this.setProductData = this.setProductData.bind(this);
    this.promoteProduct = this.promoteProduct.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.invalidateTermsheet = this.invalidateTermsheet.bind(this);
    this.syncLatestPrices = this.syncLatestPrices.bind(this);
    this.expireProduct = this.expireProduct.bind(this);
    this.modifyStructureDate = this.modifyStructureDate.bind(this);
    this.modifyAutocallLevel = this.modifyAutocallLevel.bind(this);
    this.modifyCouponLevel = this.modifyCouponLevel.bind(this);
    this.updateStructureDates = this.updateStructureDates.bind(this);
    this.updateCouponDates = this.updateCouponDates.bind(this);
    this.handleMakePublic = this.handleMakePublic.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
    if (event.target.id === 'maturity') {
      this.setState((prevState) => ({
        final_fixing_date: formatDate(
          new Date(
            new Date(prevState.initial_fixing_date).setMonth(
              new Date(prevState.initial_fixing_date).getMonth() + parseInt(prevState.maturity)
            )
          )
        )
      }));
      this.setState((prevState) => ({
        redemption: formatDate(
          new Date(new Date(prevState.final_fixing_date).setDate(new Date().getDate() + 7))
        )
      }));
    }
    if (
      event.target.id === 'soft_callable' ||
      event.target.id === 'coupon_memory' ||
      event.target.id === 'knockin_kickin' ||
      event.target.id === 'asian_delay' ||
      event.target.id === 'onestar' ||
      event.target.id === 'twin_win' ||
      event.target.id === 'flush_dates'
    ) {
      this.setState({
        [event.target.id]: !this.state[event.target.id]
      });
    }
    if (event.target.id === 'coupon_type') {
      const coupon_memory_val = this.state.coupon_type !== 'No Memory';
      this.setState({
        coupon_memory: coupon_memory_val
      });
    }
  }

  async handleSubmit(event) {
    Toast.loading('In Progress', () => { });
    const attributes = JSON.parse(JSON.stringify(this.state));
    unwantedAttributes.forEach((key) => {
      delete attributes[key];
    });

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      const data = { structure: attributes, flush_dates: this.state.flush_dates };

      axios
        .patch(`${base_url}/autocallable-structure/${this.state.isin_code}`, data, config)
        .then((res) => {
          Toast.success('Success', 3000, () => {
            history.back();
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  async handleDuplicate() {
    Toast.loading('In Progress', () => { });
    this.syncLatestPrices();
    const attributes = JSON.parse(JSON.stringify(this.state));
    unwantedAttributes.forEach((key) => {
      delete attributes[key];
    });
    attributes.is_pending = true;

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      const data = { structure: attributes, flush_dates: this.state.flush_dates };
      axios
        .post(`${base_url}/autocallable-structure`, data, config)
        .then((res) => {
          Toast.success('Success', 6000, () => { });
          const isin_code = res.data.isin_code;
          window.location.href = `/structure/autocallable/report?ISIN=${isin_code}`;
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  async handleDelete(event) {
    Toast.loading('In Progress', () => { });

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };

      axios
        .delete(`${base_url}/autocallable-structure/${this.state.isin_code}`, config)
        .then((res) => {
          Toast.success(this.state.isin_code + ' deleted Successfully', 3000, () => {
            history.back();
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 2000);
        });
    });
  }

  addScrip() {
    const scrip = document.getElementById('scrip').value;
    const initial_price = document.getElementById('initial_price').value;
    if (this.state.underlying.indexOf({ code: scrip.toUpperCase() }) !== -1) return;
    this.setState((prevState) => ({
      underlying: [
        ...prevState.underlying,
        {
          code: scrip.toUpperCase(),
          initial_reference_price: initial_price
        }
      ],
      suggestions: []
    }));
    document.getElementById('scrip').value = '';
    document.getElementById('initial_price').value = '';
  }

  addScripSubmit(event) {
    if (event.charcode !== 13) return;
    this.addScrip();
  }

  removeScrip(scrip_index) {
    const scrip_array = [...this.state.underlying];
    scrip_array.splice(scrip_index, 1);
    this.setState({
      underlying: scrip_array
    });
  }

  updateScrip(scrip_index) {
    const scrip_array = [...this.state.underlying];
    let scrip_to_update = scrip_array.splice(scrip_index, 1);
    scrip_to_update = scrip_to_update[0];
    this.setState({
      underlying: scrip_array,
      CurrentScrip: scrip_to_update
    });
    document.getElementById('scrip').value = scrip_to_update.code;
    document.getElementById('initial_price').value = scrip_to_update.initial_reference_price;
  }

  async fetchsuggestions(event) {
    if (event.target.id === '') return;

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      config.params = { q: event.target.value };
      axios
        .get(`${base_url}/product/suggest`, config)
        .then((res) => {
          this.setState({
            suggestions: res.data.items
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  async selectedScrip(event) {
    const scrip = event.target.id;
    if (this.state.underlying.includes({ code: scrip.toUpperCase() })) return;
    Toast.loading('In Progress', () => { });

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      config.params = {
        attribute: 'last_price'
      };
      axios
        .get(`${base_url}/product/attribute/${scrip}`, config)
        .then((res) => {
          const last_price = res.data.last_price;
          document.getElementById('scrip').value = scrip;
          document.getElementById('initial_price').value = last_price;
          document.getElementById('initial_price').focus();
          this.setState({
            suggestions: []
          });
          Toast.hide();
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
      this.setState((prevState) => ({
        suggestions: []
      }));
    });
  }

  async openFactSheet(isin_code) {
    window.open(
      `https://${bucket_name}.s3.${region}.amazonaws.com/structures/term-sheets/${isin_code}.pdf`,
      '_blank'
    );
  }

  async syncLatestPrices() {
    Toast.loading('In Progress', () => { });
    const scrip_array = [...this.state.underlying];
    for (let i = 0; i < scrip_array.length; i++) {
      await fetchAuthSession().then(() => {
        const config = {};
        config.headers = {
          'Authorization': cookies.get('Authorization'),
          'User-Specified': cookies.get('User-Specified'),
          'Type-Specified': cookies.get('Type-Specified')
        };
        config.params = {
          attribute: 'last_price'
        };
        axios
          .get(`${base_url}/product/attribute/${scrip_array[i].code}`, config)
          .then((res) => {
            scrip_array[i] = {
              code: scrip_array[i].code,
              initial_reference_price: res.data.last_price
            };
            this.setState({ underlying: scrip_array });
          })
          .catch((error) => {
            console.log(error);
            Toast.fail('Error Performing Action', 3000);
          });
      });
    }
    Toast.success('Prices Fetched Successfully', 1000, () => { });
  }

  discardScrips(event) {
    const scrip = event.target.id;
    if (this.state.underlying.includes({ code: scrip.toUpperCase() })) return;
    this.setState((prevState) => ({
      underlying: [...prevState.underlying, prevState.CurrentScrip],
      suggestions: []
    }));
    document.getElementById('scrip').value = scrip;
    document.getElementById('initial_price').value = '';
  }

  async setProductData() {
    const search = window.location.search;
    let isin_code = search.replaceAll('?ISIN=', '');

    isin_code = isin_code.replaceAll('%20', ' ');

    if (isin_code === '') return;

    Toast.loading('In Progress', () => { });

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };

      axios
        .get(`${base_url}/autocallable-structure/${isin_code}`, config)
        .then((res) => {
          const data = res.data.item;

          this.setState({
            isin_code: data.isin_code,
            product_type: data.product_type,
            issuer: data.issuer,
            autocall_delay: data.autocall_delay,
            coupon_delay: data.coupon_delay,
            autocall_percentage: data.autocall_percentage,
            autocall_step: data.autocall_step,
            coupon_step: data.coupon_step,
            barrier_type: data.barrier_type,
            barrier: data.barrier,
            coupon: data.coupon,
            strike: data.strike,
            coupon_type: data.coupon_type,
            coupon_percentage: data.coupon_percentage,
            currency: data.currency,
            weightage: data.weightage,
            maturity: data.maturity,
            autocall_frequency: data.autocall_frequency,
            coupon_frequency: data.coupon_frequency,
            initial_fixing_date: data.initial_fixing_date,
            issue_date: data.issue_date,
            is_pending: data.is_pending,
            final_fixing_date: data.final_fixing_date,
            redemption: data.redemption,
            underlying: data.underlying,
            soft_callable: data.soft_callable,
            coupon_memory: data.coupon_memory,
            delayed_frequency: data.delayed_frequency,
            autocall_table: data.autocall_table,
            coupon_table: data.coupon_table,
            new_isin_code: data.isin_code,
            knockin_kickin: data.knockin_kickin,
            knockin_kickin_value: data.knockin_kickin_value,
            asian_delay: data.asian_delay,
            asian_delay_value: data.asian_delay_value,
            onestar: data.onestar,
            onestar_value: data.onestar_value,
            twin_win: data.twin_win,
            term_sheet: data.term_sheet,
            expired: data.expired
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });

      axios
        .get(`${base_url}/autocallable-structure-dates/${isin_code}`, config)
        .then((res) => {
          const data = res.data.items;

          this.setState({
            structure_table: data
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
      Toast.success('Success', 1000, () => { });
    });
  }

  async uploadFile(event) {
    Toast.loading('In Progress', () => { });
    const isin_code = this.state.isin_code;
    const bucketName = bucket_name;

    const credentials = await fetchAuthSession();

    AWS.config.region = region;
    AWS.config.update({
      accessKeyId: credentials.credentials.accessKeyId,
      secretAccessKey: credentials.credentials.secretAccessKey,
      sessionToken: credentials.credentials.sessionToken
    });
    const s3 = new AWS.S3({
      apiVersion: '2006-03-01',
      params: { Bucket: bucketName }
    });
    console.log(event.target.files);

    s3.upload(
      {
        Bucket: bucketName,
        Key: `structures/term-sheets/${isin_code}.pdf`,
        Body: event.target.files[0],
        ACL: 'private'
      },
      async function (err, data) {
        if (err) {
          Toast.fail('there was an error uploading your file', 1000, () => { });
        } else {
          await fetchAuthSession().then(() => {
            const config = {};
            config.headers = {
              'Authorization': cookies.get('Authorization'),
              'User-Specified': cookies.get('User-Specified'),
              'Type-Specified': cookies.get('Type-Specified')
            };
            config.params = {
              attribute: 'term_sheet',
              value: 'true'
            };

            axios
              .patch(`${base_url}/structure-attributes/${isin_code}`, {}, config)
              .then(async (res) => {
                this.setState({
                  term_sheet: 'true'
                });
                Toast.success('Success', 1000, () => { });
              })
              .catch((error) => {
                console.log(error);
                Toast.fail('Error Performing Action', 3000);
              });
          });
        }
      }.bind(this)
    );
  }

  async invalidateTermsheet(isin_code) {
    Toast.loading('In Progress', () => { });

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      config.params = {
        attribute: 'term_sheet',
        value: 'false'
      };

      axios
        .patch(`${base_url}/structure-attributes/${isin_code}`, {}, config)
        .then(async (res) => {
          this.setState({
            term_sheet: false
          });
          Toast.success('Success', 1000, () => { });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  async promoteProduct() {
    if (this.state.new_isin_code === '') return;
    Toast.loading('In Progress', () => { });

    const payload = this.state;
    payload.is_pending = false;
    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      axios
        .post(
          `${base_url}/promote-structure/${payload.isin_code}`,
          { new_isin: payload.new_isin_code, structure: payload },
          config
        )
        .then((res) => {
          Toast.success('Success', 3000, () => {
            window.location.href = '/structure/details';
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
      this.setState((prevState) => ({
        suggestions: []
      }));
    });
  }

  async expireProduct() {
    console.log(this.state.new_isin_code);
    if (this.state.new_isin_code === '') return;
    Toast.loading('In Progress', () => { });

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      const expired = this.state.expired;
      const apiPath = expired === -1 ? 'expire-structure' : 'unexpire-structure';
      axios
        .post(`${base_url}/${apiPath}/${this.state.isin_code}`, {}, config)
        .then((res) => {
          Toast.success('Success', 3000, () => { });
          this.setState({
            expired: expired * -1
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  modifyStructureDate(event, index) {
    const structure_table = [...this.state.structure_table];
    const date = event.target.value;
    const level = structure_table[index].autocall_level;
    const coupon_level = structure_table[index].coupon_level;
    structure_table[index] = {
      autocall_date: date,
      autocall_level: level,
      coupon_level
    };
    this.setState({
      structure_table
    });
  }

  modifyAutocallLevel(event, index) {
    const structure_table = [...this.state.structure_table];
    const level = event.target.value;
    const date = structure_table[index].autocall_date;
    const coupon_level = structure_table[index].coupon_level;
    console.log(date, level);
    structure_table[index] = {
      autocall_date: date,
      autocall_level: level,
      coupon_level
    };
    this.setState({
      structure_table
    });
  }

  modifyCouponLevel(event, index) {
    const structure_table = [...this.state.structure_table];
    const level = event.target.value;
    const date = structure_table[index].autocall_date;
    const autocall_level = structure_table[index].autocall_level;
    structure_table[index] = {
      autocall_date: date,
      autocall_level,
      coupon_level: level
    };
    this.setState({
      structure_table
    });
  }

  async updateStructureDates() {
    if (this.state.new_isin_code === '') return;
    Toast.loading('In Progress', () => { });

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      axios
        .patch(
          `${base_url}/autocallable-structure-dates/${this.state.isin_code}`,
          { structure_table: this.state.structure_table },
          config
        )
        .then((res) => {
          Toast.success('Success', 3000, () => { });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  async updateCouponDates() {
    if (this.state.new_isin_code === '') return;
    Toast.loading('In Progress', () => { });

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      axios
        .patch(
          `${base_url}/autocallable-structure-coupon-dates/${this.state.isin_code}`,
          { coupon_table: this.state.coupon_table },
          config
        )
        .then((res) => {
          Toast.success('Success', 3000, () => { });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  async handleMakePublic() {
    Toast.loading('In Progress', () => { });

    if (cookies.get('Type-Specified') !== 'Admin') {
      Toast.failed('You are not authorized to approve product', 3000, () => { });
    }

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };

      config.params = {
        attribute: 'is_public',
        value: 'true'
      };
      axios
        .patch(`${base_url}/structure-attributes/${this.state.isin_code}`, {}, config)
        .then(async (res) => {
          Toast.success('Success', 1000, () => {
            this.saveFilters();
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  componentDidMount() {
    this.setProductData();
  }

  render() {
    return (
      <div className="container">
        <table className="table">
          <tr>
            <td>
              <label>Type of Structure:</label>
            </td>
            <td>
              <select
                value={this.state.product_type}
                onChange={this.handleChange}
                className="form-control"
                id="product_type"
              >
                <option>autocallable</option>
              </select>
            </td>
            <td>
              <label>currency:</label>
            </td>
            <td>
              <input
                value={this.state.currency}
                onChange={this.handleChange}
                type="text"
                className="form-control"
                id="currency"
              />
            </td>
          </tr>
          <tr>
            <td>
              <label>maturity in Months:</label>
            </td>
            <td>
              <input
                type="number"
                value={this.state.maturity}
                onChange={this.handleChange}
                className="form-control"
                id="maturity"
              />
            </td>
            <td>
              <label>weightage:</label>
            </td>
            <td>
              <input
                value={this.state.weightage}
                onChange={this.handleChange}
                type="text"
                className="form-control"
                id="weightage"
              />
            </td>
          </tr>
          <tr>
            <td>
              <label>coupon:</label>
            </td>
            <td>
              <div className="input-group mb-3">
                <input
                  value={this.state.coupon}
                  onChange={this.handleChange}
                  type="text"
                  className="form-control"
                  id="coupon"
                />
                <div className="input-group-append">
                  <span className="input-group-text">
                    {this.state.flat_coupon ? 'flat' : '% pa'}
                  </span>
                </div>
              </div>
            </td>
            <td>
              <label>Flat:</label>
            </td>
            <td>
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <input
                    type="checkbox"
                    checked={this.state.flat_coupon}
                    id="flat_coupon"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <label>issuer:</label>
            </td>
            <td>
              <select
                value={this.state.issuer}
                onChange={this.handleChange}
                className="form-control"
                id="issuer"
              >
                <option>{this.state.issuer}</option>
                {
                  issuers.map(issuer => {
                    return <option key={issuer}>{issuer}</option>;
                  })
                }

              </select>
            </td>
            <td></td>
            <td></td>
          </tr>

          <tr>
            <td colSpan="4">
              <div className="form-group row">
                <div className="row">
                  <label className="col-sm-10">Underlyings:</label>

                  <button className="btn btn-primary col-sm-1" onClick={this.syncLatestPrices}>
                    <FaSync />
                  </button>
                </div>
                <div className="col-sm-6">
                  <input
                    type="text"
                    autoComplete="off"
                    placeholder="Enter Scrip Name to add"
                    className="form-control"
                    id="scrip"
                    onChange={this.fetchsuggestions}
                  />

                  <div className="dropdown">
                    <ul className="list-group">
                      {this.state.suggestions.map((value, index) => {
                        return (
                          <li
                            className="list-group-item list-group-flush"
                            key={index}
                            onClick={this.selectedScrip}
                            id={value.code}
                          >
                            {value.code}
                            &nbsp; ({value.name}) &nbsp;
                            <img src={Flags[value.country_iso]}></img>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <div className="col-sm-3">
                  <input
                    type="text"
                    autoComplete="off"
                    placeholder="Initial Price"
                    className="form-control"
                    id="initial_price"
                    onKeyPress={this.addScripSubmit}
                  />
                </div>
                <div className="col-sm-2">
                  <div className="btn-group" role="group" aria-label="Basic example">
                    <a className="btn btn-primary" id="addScrip" onClick={this.addScrip}>
                      <FaPlus />
                    </a>
                    {/* <a className="btn btn-danger" onClick={this.discardScrips}>
                                            Discard
                                        </a> */}
                  </div>
                </div>

                <div className="contaier">
                  {this.state.underlying.map((value, index) => {
                    return (
                      <div className="row" key={index}>
                        <div className="col-md-6">
                          <input
                            type="text"
                            disabled={true}
                            className="form-control"
                            value={value.code}
                          />
                        </div>
                        <div className="col-md-3">
                          <input
                            type="text"
                            disabled={true}
                            className="form-control"
                            value={value.initial_reference_price}
                          />
                        </div>
                        <div
                          className="col-md-2 btn-group"
                          role="group"
                          aria-label="Basic example"
                          key={index}
                        >
                          <a className="btn btn-primary" onClick={() => this.updateScrip(index)}>
                            <FaPen />
                          </a>
                          <a className="btn btn-danger" onClick={() => this.removeScrip(index)}>
                            <FaTrash />
                          </a>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <label>barrier:</label>
            </td>
            <td>
              <div className="input-group mb-3">
                <input
                  value={this.state.barrier}
                  onChange={this.handleChange}
                  type="text"
                  className="form-control"
                  id="barrier"
                />
                <div className="input-group-append">
                  <span className="input-group-text">%</span>
                </div>
              </div>
            </td>

            <td>
              <label>barrier Type:</label>
            </td>
            <td>
              <select
                value={this.state.barrier_type}
                onChange={this.handleChange}
                className="form-control"
                id="barrier_type"
              >
                <option>European</option>
                <option>American EOD</option>
                <option>American CTS</option>
                <option>No barrier</option>
              </select>
            </td>
          </tr>
          <tr>
            <td>
              <label>Strike:</label>
            </td>

            <td>
              <div className="input-group mb-3">
                <input
                  value={this.state.strike}
                  onChange={this.handleChange}
                  type="text"
                  className="form-control"
                  id="strike"
                />
                <div className="input-group-append">
                  <span className="input-group-text">%</span>
                </div>
              </div>
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>
              <label>coupon Type:</label>
            </td>

            <td>
              <select
                value={this.state.coupon_type}
                onChange={this.handleChange}
                className="form-control"
                id="coupon_type"
              >
                <option>No Memory</option>
                <option>Memory</option>
                <option>Guaranteed</option>
              </select>
            </td>
            <td>{this.state.coupon_type !== 'Guaranteed' && <label>coupon Trigger:</label>}</td>
            <td>
              {this.state.coupon_type !== 'Guaranteed' && (
                <div className="input-group mb-3">
                  <input
                    value={
                      this.state.coupon_type !== 'Guaranteed' ? this.state.coupon_percentage : '0'
                    }
                    onChange={this.handleChange}
                    type="text"
                    className="form-control"
                    id="coupon_percentage"
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">%</span>
                  </div>
                </div>
              )}
            </td>
          </tr>
          <tr>
            <td>
              <label>Coupon Frequency in Months:</label>
            </td>

            <td>
              <input
                value={this.state.coupon_frequency}
                onChange={this.handleChange}
                type="number"
                className="form-control"
                id="coupon_frequency"
              />
            </td>
            <td>
              <label>Coupon Step:</label>
            </td>
            <td>
              <div className="input-group mb-3">
                <input
                  value={this.state.coupon_step}
                  onChange={this.handleChange}
                  type="text"
                  className="form-control"
                  id="coupon_step"
                />
                <div className="input-group-append">
                  <span className="input-group-text">%</span>
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <label>Autocall:</label>
            </td>

            <td>
              <div className="input-group mb-3">
                <input
                  value={this.state.autocall_percentage}
                  onChange={this.handleChange}
                  type="text"
                  className="form-control"
                  id="autocall_percentage"
                />
                <div className="input-group-append">
                  <span className="input-group-text">%</span>
                </div>
              </div>
            </td>
            <td>
              <label>Autocall Frequency in Months:</label>
            </td>

            <td>
              <input
                value={this.state.autocall_frequency}
                onChange={this.handleChange}
                type="number"
                className="form-control"
                id="autocall_frequency"
              />
            </td>
          </tr>
          <tr>
            <td>
              <label>Autocall Step:</label>
            </td>
            <td>
              <div className="input-group mb-3">
                <input
                  value={this.state.autocall_step}
                  onChange={this.handleChange}
                  type="text"
                  className="form-control"
                  id="autocall_step"
                />
                <div className="input-group-append">
                  <span className="input-group-text">%</span>
                </div>
              </div>
            </td>
            <td>
              <label>Autocall Delay:</label>
            </td>

            <td>
              <input
                value={this.state.autocall_delay}
                onChange={this.handleChange}
                type="number"
                className="form-control"
                id="autocall_delay"
              />
            </td>
          </tr>
          <button className="btn btn-primary" data-toggle="collapse" data-target="#structuretable">
            Toggle Dates
          </button>
          <tr>
            <td colSpan="4">
              {this.state.structure_table && (
                <>
                  <table className="table collapse" id="structuretable">
                    <thead className="table-dark">
                      <tr>
                        <th>Autocall Date</th>
                        <th>Autocall Percentage</th>
                        <th>Coupon Percentage</th>
                      </tr>
                    </thead>
                    {this.state.structure_table.map((value, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <input
                              type="date"
                              className="form-control"
                              onChange={(event) => this.modifyStructureDate(event, index)}
                              value={dayjs(value.autocall_date).format('YYYY-MM-DD')}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              onChange={(event) => this.modifyAutocallLevel(event, index)}
                              value={value.autocall_level}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              onChange={(event) => this.modifyCouponLevel(event, index)}
                              value={value.coupon_level}
                            />
                          </td>
                        </tr>
                      );
                    })}
                    <tr>
                      <a className="btn btn-danger" onClick={this.updateStructureDates}>
                        Update Dates
                      </a>
                    </tr>
                  </table>
                </>
              )}
            </td>
          </tr>
          <tr>
            <td>
              <label>Initial Fixing Date:</label>
            </td>
            <td>
              <input
                value={this.state.initial_fixing_date}
                onChange={this.handleChange}
                type="date"
                className="form-control"
                id="initial_fixing_date"
              />
            </td>

            <td>
              <label>Final Fixing Date:</label>
            </td>
            <td>
              <input
                value={this.state.final_fixing_date}
                onChange={this.handleChange}
                type="date"
                className="form-control"
                id="final_fixing_date"
              />
            </td>
          </tr>
          <tr>
            <td>
              <label>Issue Date:</label>
            </td>
            <td>
              <input
                value={this.state.issue_date}
                onChange={this.handleChange}
                type="date"
                className="form-control"
                id="issue_date"
              />
            </td>
            <td>
              <label>redemption:</label>
            </td>
            <td>
              <input
                value={this.state.redemption}
                onChange={this.handleChange}
                type="date"
                className="form-control"
                id="redemption"
              />
            </td>
          </tr>
          <tr>
            <td>
              <label>Knockin Kickin:</label>
            </td>
            <td>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <div className="input-group-text">
                    <input
                      type="checkbox"
                      checked={this.state.knockin_kickin}
                      id="knockin_kickin"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                {this.state.knockin_kickin && (
                  <input
                    type="number"
                    className="form-control"
                    value={this.state.knockin_kickin_value}
                    id="knockin_kickin_value"
                    disabled={!this.state.knockin_kickin}
                    onChange={this.handleChange}
                  />
                )}
              </div>
            </td>
            <td>
              <label>Asian Delay:</label>
            </td>
            <td>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <div className="input-group-text">
                    <input
                      type="checkbox"
                      checked={this.state.asian_delay}
                      id="asian_delay"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                {this.state.asian_delay && (
                  <input
                    type="number"
                    className="form-control"
                    value={this.state.asian_delay_value}
                    id="asian_delay_value"
                    disabled={!this.state.asian_delay}
                    onChange={this.handleChange}
                  />
                )}
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <label>One Star:</label>
            </td>
            <td>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <div className="input-group-text">
                    <input
                      type="checkbox"
                      checked={this.state.onestar}
                      id="onestar"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                {this.state.onestar && (
                  <input
                    type="number"
                    className="form-control"
                    value={this.state.onestar_value}
                    id="onestar_value"
                    disabled={!this.state.onestar}
                    onChange={this.handleChange}
                  />
                )}
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <label>Twin Win:</label>
            </td>
            <td>
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <input
                    type="checkbox"
                    checked={this.state.twin_win}
                    id="twin_win"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
            </td>
            <td>
              <label>Term Sheet:</label>
            </td>
            <td>
              {this.state.term_sheet === 'true' ? (
                <div className="btn-group" role="group">
                  <a
                    className="btn btn-link"
                    onClick={() => this.openFactSheet(this.state.isin_code)}
                  >
                    <FaCopy /> Term Sheet
                  </a>
                  <a
                    className="btn btn-danger"
                    onClick={() => this.invalidateTermsheet(this.state.isin_code)}
                  >
                    <FaTrash /> Delete
                  </a>
                </div>
              ) : (
                <input
                  type="file"
                  id="termsheet"
                  onChange={this.uploadFile}
                  className="form-control"
                />
              )}
            </td>
          </tr>
          <tr>
            <td>
              <label>Flush Autocall Dates:</label>
            </td>
            <td>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <div className="input-group-text">
                    <input
                      type="checkbox"
                      checked={this.state.flush_dates}
                      id="flush_dates"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </table>

        <div className="row">
          <div className="btn-group" role="group">
            <a className="btn btn-primary" data-toggle="modal" data-target="#duplicateProduct">
              
              <FaSave /> Duplicate Product
            </a>
            <a className="btn btn-danger" onClick={this.setProductData}>
              
              <FaWindowClose /> Discard Changes
            </a>
            <a className="btn btn-primary" onClick={this.handleDelete}>
              
              <FaTrash /> Delete Product
            </a>
            <a className="btn btn-danger" onClick={this.handleMakePublic}>
              
              <FaPersonBooth /> Make Public
            </a>
            {this.state.is_pending ? (
              <a className="btn btn-danger" onClick={this.handleSubmit}>
                
                <FaPen /> Save Changes
              </a>
            ) : (
              <a className="btn btn-primary" data-toggle="modal" data-target="#promoteProduct">
                
                <FaForward /> Promote Product
              </a>
            )}
            {this.state.expired === -1 ? (
              <a className="btn btn-danger" onClick={this.expireProduct}>
                
                <FaCalendar /> Mature Product
              </a>
            ) : (
              <a className="btn btn-danger" onClick={this.expireProduct}>
                
                <FaCalendar /> Revert from Maturity
              </a>
            )}
          </div>
          <div id="promoteProduct" className="modal fade" role="dialog">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal">
                    &times;
                  </button>
                </div>
                <div className="modal-body">
                  <div className="form-group">
                    <label> Final ISIN code:</label>
                    <input
                      value={this.state.new_isin_code}
                      onChange={this.handleChange}
                      type="text"
                      className="form-control"
                      id="new_isin_code"
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-dismiss="modal"
                    onClick={this.promoteProduct}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div id="duplicateProduct" className="modal fade" role="dialog">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal">
                    &times;
                  </button>
                </div>
                <div className="modal-body">
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-primary btn-block"
                      data-dismiss="modal"
                      onClick={this.handleDuplicate}
                    >
                      Confirm Duplicate
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StructureAutocallableUpdate;
