import axios from 'axios';
import React, { Component } from 'react';

import { fetchAuthSession } from 'aws-amplify/auth';
import Toast from 'light-toast';
import { Link } from 'react-router-dom';
import Flags from './flags';

import cookies from '../utils/cookies.util';
import base_url from '../utils/request.util';

const columns = [
  'sector',
  'industry',
  'market_capitalization_mln',
  'currency_code',
  'last_price',
  'week_52_high',
  'week_52_low',
  'rating',
  'strong_buy',
  'buy',
  'hold',
  'sell',
  'strong_sell',
  'return_potential',
  'trailing_pe',
  'forward_pe',
  'operating_margin_ttm',
  'return_on_assets_ttm',
  'revenue_ttm',
  'market_capitalization_to_revenue_ttm',
  'price_book_mrq',
  'return_on_equity_ttm',
  'diluted_eps_ttm',
  'dividend_yield',
  'iv_3months',
  'wall_street_target_price',
  'piotroski_score',
  'earnings_date',
  'pe_ratio'
];

class ProductScreenerEquity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      market_capitalization_mln_min: 0,
      market_capitalization_mln_max: 1000000,
      iv_3months_min: -10000,
      iv_3months_max: -10000,
      dividend_yield_min: 0,
      dividend_yield_max: 0,
      market_capitalization_to_revenue_ttm_min: 0,
      market_capitalization_to_revenue_ttm_max: 0,
      pe_ratio_min: 0,
      pe_ratio_max: 0,
      price_book_mrq_min: 10000,
      price_book_mrq_max: 10000,
      piotroski_score_min: 0,
      piotroski_score_max: 10,
      rating_min: 0,
      rating_max: 5,
      industry: 'All',
      sector: 'All',
      equity_data: [],
      equity_columns: {
        market_capitalization_mln: true,
        iv_3months: true,
        dividend_yield: true,
        market_capitalization_to_revenue_ttm: true,
        pe_ratio: true,
        price_book_mrq: true,
        piotroski_score: true,
        industry: true,
        sector: true
      },
      offset: 0
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
  }

  async handleSubmit() {
    const screeners = {};
    Object.keys(this.state.equity_columns).forEach((attribute) => {
      if (this.state[`${attribute}_min`] != null) {
        screeners[attribute] = [this.state[`${attribute}_min`], this.state[`${attribute}_max`]];
      } else {
        screeners[attribute] = this.state[attribute];
      }
    });
    screeners.type = 'EQUITY';

    Toast.loading('In Progress', () => { });
    await fetchAuthSession().then(() => {
      const config = {};
      config.params = {
        offset: this.state.offset
      };
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };

      axios
        .post(`${base_url}/product-screener`, { screeners }, config)
        .then((res) => {
          this.setState((prevState) => ({
            equity_data: prevState.equity_data.concat(res.data.items),
            offset: prevState.offset + 50
          }));
          Toast.success('Success', 1000, () => { });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  async componentDidMount() {
    Toast.loading('In Progress', () => { });
    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      config.params = {
        fetch_all: true
      };

      axios
        .post(
          `${base_url}/product-attribute/high-low`,
          { attributes: Object.keys(this.state.equity_columns) },
          config
        )
        .then((res) => {
          this.setState(() => {
            const data = {};
            Object.keys(res.data.item).forEach((element) => {
              data[element] = res.data.item[element];
            });
            return data;
          });
          Toast.success('Success', 1000, () => { });
        });
    });
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-4">
            <table className="table table-sm">
              <thead>
                <th></th>
                <th>Min</th>
                <th>Max</th>
              </thead>
              <tbody>
                <tr>
                  <td>Market Cap (Mln)</td>
                  <td>
                    <input
                      type="text"
                      value={this.state.market_capitalization_mln_min}
                      onChange={this.handleChange}
                      className="form-control"
                      id="market_capitalization_mln_min"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={this.state.market_capitalization_mln_max}
                      onChange={this.handleChange}
                      className="form-control"
                      id="market_capitalization_mln_max"
                    />
                  </td>
                </tr>
                <tr>
                  <td>Implied Volaltilty</td>
                  <td>
                    <input
                      type="text"
                      value={this.state.iv_3months_min}
                      onChange={this.handleChange}
                      className="form-control"
                      id="iv_3months_min"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={this.state.iv_3months_max}
                      onChange={this.handleChange}
                      className="form-control"
                      id="iv_3months_max"
                    />
                  </td>
                </tr>
                <tr>
                  <td>Dividend Yield</td>
                  <td>
                    <input
                      type="text"
                      value={this.state.dividend_yield_min}
                      onChange={this.handleChange}
                      className="form-control"
                      id="dividend_yield_min"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={this.state.dividend_yield_max}
                      onChange={this.handleChange}
                      className="form-control"
                      id="dividend_yield_max"
                    />
                  </td>
                </tr>
                <tr>
                  <td>Mcap To Revenue</td>
                  <td>
                    <input
                      type="text"
                      value={this.state.market_capitalization_to_revenue_ttm_min}
                      onChange={this.handleChange}
                      className="form-control"
                      id="market_capitalization_to_revenue_ttm_min"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={this.state.market_capitalization_to_revenue_ttm_max}
                      onChange={this.handleChange}
                      className="form-control"
                      id="market_capitalization_to_revenue_ttm_max"
                    />
                  </td>
                </tr>
                <tr>
                  <td>Piotroski Score</td>
                  <td>
                    <input
                      type="text"
                      value={this.state.piotroski_score_min}
                      onChange={this.handleChange}
                      className="form-control"
                      id="piotroski_score_min"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={this.state.piotroski_score_max}
                      onChange={this.handleChange}
                      className="form-control"
                      id="piotroski_score_max"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-4">
            <table className="table">
              <thead>
                <th>Other Parameters</th>
              </thead>
              <tbody>
                <tr>
                  <td>Sector</td>
                  <td>
                    <select
                      type="text"
                      value={this.state.sector}
                      onChange={this.handleChange}
                      className="form-control"
                      id="sector"
                    >
                      <option>All</option>
                      <option>Basic Materials</option>
                      <option>Communication Services</option>
                      <option>Consumer Cyclical</option>
                      <option>Consumer Defensive</option>
                      <option>Energy</option>
                      <option>Financial Services</option>
                      <option>Healthcare</option>
                      <option>Industrials</option>
                      <option>Other</option>
                      <option>Real Estate</option>
                      <option>Technology</option>
                      <option>Utilities</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>Industry</td>
                  <td>
                    <select
                      type="text"
                      value={this.state.industry}
                      onChange={this.handleChange}
                      className="form-control"
                      id="industry"
                    >
                      <option>All</option>
                      <option>Other</option>
                      <option>Utilities-Regulated Electric</option>
                      <option>Household & Personal Products</option>
                      <option>Thermal Coal</option>
                      <option>Drug Manufacturers-General</option>
                      <option>Medical Distribution</option>
                      <option>Real Estate-Development</option>
                      <option>Auto Manufacturers</option>
                      <option>Resorts & Casinos</option>
                      <option>Biotechnology</option>
                      <option>Engineering & Construction</option>
                      <option>Banks-Diversified</option>
                      <option>Insurance-Life</option>
                      <option>Insurance-Property & Casualty</option>
                      <option>Banks-Diversified</option>
                      <option>Biotechnology</option>
                      <option>Banks-Regional</option>
                      <option>Engineering & Construction</option>
                      <option>Consumer Electronics</option>
                      <option>Real Estate-Development</option>
                      <option>Utilities-Independent Power Producers</option>
                      <option>Luxury Goods</option>
                      <option>Real Estate-Development</option>
                      <option>Resorts & Casinos</option>
                      <option>Auto Manufacturers</option>
                      <option>Real Estate Services</option>
                      <option>Real Estate Services</option>
                      <option>Real Estate-Development</option>
                      <option>Communication Equipment</option>
                      <option>Leisure</option>
                      <option>Drug Manufacturers-Specialty & Generic</option>
                      <option>Real Estate-Development</option>
                      <option>Packaged Foods</option>
                      <option>Packaged Foods</option>
                      <option>Beverages-Non-Alcoholic</option>
                      <option>Biotechnology</option>
                      <option>Packaged Foods</option>
                      <option>Resorts & Casinos</option>
                      <option>Insurance-Life</option>
                      <option>Packaged Foods</option>
                      <option>Insurance-Property & Casualty</option>
                      <option>Auto Manufacturers</option>
                      <option>Electronic Components</option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="col-md-4">
            <table className="table">
              <thead>
                <th></th>
                <th>Min</th>
                <th>Max</th>
              </thead>
              <tbody>
                <tr>
                  <td>PE Ratio</td>
                  <td>
                    <input
                      type="text"
                      value={this.state.pe_ratio_min}
                      onChange={this.handleChange}
                      className="form-control"
                      id="pe_ratio_min"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={this.state.pe_ratio_max}
                      onChange={this.handleChange}
                      className="form-control"
                      id="pe_ratio_max"
                    />
                  </td>
                </tr>
                <tr>
                  <td>PB Ratio</td>
                  <td>
                    <input
                      type="text"
                      value={this.state.price_book_mrq_min}
                      onChange={this.handleChange}
                      className="form-control"
                      id="price_book_mrq_min"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={this.state.price_book_mrq_max}
                      onChange={this.handleChange}
                      className="form-control"
                      id="price_book_mrq_max"
                    />
                  </td>
                </tr>
                <tr>
                  <td>Debt/Equity</td>
                  <td>
                    <input
                      type="text"
                      value={this.state.dividend_yield_min}
                      onChange={this.handleChange}
                      className="form-control"
                      id="dividend_yield_min"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={this.state.dividend_yield_max}
                      onChange={this.handleChange}
                      className="form-control"
                      id="dividend_yield_max"
                    />
                  </td>
                </tr>
                <tr>
                  <td>Analyst Recommendation</td>
                  <td>
                    <input
                      type="text"
                      value={this.state.rating_min}
                      onChange={this.handleChange}
                      className="form-control"
                      id="rating_min"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={this.state.rating_max}
                      onChange={this.handleChange}
                      className="form-control"
                      id="rating_max"
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan="3">
                    <button className="btn btn-danger" onClick={this.handleSubmit}>
                      Search
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {this.state.equity_data.length !== 0 && (
          <>
            <table className="table">
              <thead>
                <tr>
                  <th
                    style={{
                      backgroundColor: '#002850',
                      color: '#ffffff',
                      textDecoration: 'none',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    Code
                  </th>
                  <th
                    style={{
                      backgroundColor: '#002850',
                      color: '#ffffff',
                      textDecoration: 'none',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    Name
                  </th>
                  {columns.map((value, index) => {
                    return (
                      <th
                        style={{
                          backgroundColor: '#002850',
                          color: '#ffffff',
                          textDecoration: 'none'
                        }}
                      >
                        {value}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {this.state.equity_data.map((value, index) => {
                  return (
                    <tr>
                      <td
                        className="table-dark"
                        style={{
                          backgroundColor: '#002850',
                          color: '#ffffff',
                          textDecoration: 'none',
                          border: '1px solid white',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        <img src={Flags[value.country_iso]} />
                        &nbsp;
                        <Link to={`/product/equity?code=${value.code}`}>{value.code}</Link>
                      </td>
                      <td
                        className="table-dark"
                        style={{
                          backgroundColor: '#002850',
                          color: '#ffffff',
                          textDecoration: 'none',
                          border: '1px solid white'
                        }}
                      >
                        {value.name}
                      </td>
                      {columns.map((key, index) => {
                        return (
                          <td>
                            {value[key]
                              ? value[key].toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                              })
                              : '-'}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <button className="btn btn-success btn-block" onClick={this.handleSubmit}>
              Fetch More Results
            </button>
          </>
        )}
      </div>
    );
  }
}

export default ProductScreenerEquity;
