import React, { Component } from 'react';

let tvScriptLoadingPromise;

class TradingViewWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: ''
    };
    this.onLoadScriptRef = React.createRef();
    this.createWidget = this.createWidget.bind(this);
  }

  componentDidMount() {
    this.onLoadScriptRef.current = this.createWidget;

    if (!tvScriptLoadingPromise) {
      tvScriptLoadingPromise = new Promise((resolve) => {
        const script = document.createElement('script');
        script.id = 'tradingview-widget-loading-script';
        script.src = 'https://s3.tradingview.com/tv.js';
        script.type = 'text/javascript';
        script.onload = resolve;

        document.head.appendChild(script);
      });
    }

    tvScriptLoadingPromise.then(
      () => this.onLoadScriptRef.current && this.onLoadScriptRef.current()
    );
  }

  componentWillUnmount() {
    this.onLoadScriptRef.current = null;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.code !== this.props.code) {
      // Code to handle prop change
      this.setState({ code: this.props.code }, () => {
        this.createWidget();
      });
    }
  }

  createWidget() {
    if (document.getElementById('chartDiv') && 'TradingView' in window) {
      new window.TradingView.widget({
        autosize: true,
        symbol: this.props.code,
        interval: 'D',
        timezone: 'Etc/UTC',
        theme: 'dark',
        style: '1',
        locale: 'en',
        toolbar_bg: '#f1f3f6',
        enable_publishing: false,
        allow_symbol_change: true,
        container_id: 'chartDiv'
      });
    }
  }

  render() {
    return (
      <div className="tradingview-widget-container">
        <div id="chartDiv" style={{ height: '600px' }} />
        <div className="tradingview-widget-copyright">
          <a
            key={this.props.code}
            href={`https://www.tradingview.com/symbols/NASDAQ-${this.props.code}/`}
            rel="noopener noreferrer"
            target="_blank"
          >
            <span className="blue-text">{this.props.code} stock chart</span>
          </a>
          by TradingView
        </div>
      </div>
    );
  }
}

export default TradingViewWidget;
