import axios from 'axios';
import Toast from 'light-toast';
import React, { Component } from 'react';

import { fetchAuthSession } from 'aws-amplify/auth';

import cookies from '../utils/cookies.util';
import base_url from '../utils/request.util';

class UserUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: '',
      email: ''
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
    if (event.target.id === 'portfolio_id') this.suggestPortfolios(event);
    if (event.target.id === 'isin_code') this.fetchSuggestions(event);
    if (event.target.id === 'currency') this.updateEchangeRate(event);
    if (event.target.id === 'face_value') {
      this.setState((prevState) => {
        return { notional_quantity: prevState.quantity * event.target.value };
      });
    }
  }

  async handleSubmit() {
    Toast.loading('In Progress', () => { });

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      const data = {
        name: this.state.name,
        user_id: this.state.user_id,
        email: this.state.email,
        user_type: this.state.user_type
      };

      axios
        .patch(`${base_url}/user/${this.state.user_id}`, data, config)
        .then(() => {
          Toast.success('Success', 1000, () => {
            window.location.href = '/users';
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  async handleDelete() {
    Toast.loading('In Progress', () => { });

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };

      axios
        .delete(`${base_url}/user/${this.state.user_id}`, {}, config)
        .then(() => {
          Toast.success('Success', 1000, () => {
            window.location.href = '/users';
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  async componentDidMount() {
    const search = window.location.search;
    const user_id = search
      .replaceAll('?user_id=', '')
      .replaceAll('?user_id=', '')
      .replaceAll('%20', ' ');

    if (user_id === '') return;

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      axios
        .get(`${base_url}/user/${user_id}`, config)
        .then((res) => {
          const user_data = res.data.item;

          this.setState({
            name: user_data.name,
            user_id: user_data.user_id,
            email: user_data.email,
            user_type: user_data.user_type
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
    Toast.success('Success', 1000, () => { });
  }

  render() {
    return (
      <div className="container">
        <div className="container hidden" id="userData">
          <div className="form-group">
            <label>User Id:</label>
            <input
              type="text"
              disabled
              value={this.state.user_id}
              onChange={this.handleChange}
              className="form-control"
              required
              id="user_id"
            />
          </div>
          <div className="form-group">
            <label>Name:</label>
            <input
              type="text"
              value={this.state.name}
              onChange={this.handleChange}
              className="form-control"
              required
              id="name"
            />
          </div>
          <div className="form-group">
            <label>Email:</label>
            <input
              type="text"
              value={this.state.email}
              onChange={this.handleChange}
              className="form-control"
              required
              id="email"
            />
          </div>
          <div className="form-group">
            <label>User Type:</label>
            <select
              className="form-control"
              required
              id="user_type"
              value={this.state.user_type}
              onChange={this.handleChange}
            >
              <option>Client</option>
              <option>Admin</option>
              <option>BackOffice</option>
              <option>External</option>
              <option>RelationshipManager</option>
            </select>
          </div>
          <a className="btn btn-block btn-primary" onClick={this.handleSubmit}>
            Submit
          </a>
          <a className="btn btn-block btn-danger" onClick={this.handleDelete}>
            Delete
          </a>
        </div>
      </div>
    );
  }
}

export default UserUpdate;
