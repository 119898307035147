import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';
import Toast from 'light-toast';
import React, { Component } from 'react';
import Flags from './flags';

import { FaPlus, FaTrash } from 'react-icons/fa';

import cookies from '../utils/cookies.util';
import base_url from '../utils/request.util';

class OptionsAnalyser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input_scrips: [],
      underlying: [],
      classification: {},
      suggestions: []
    };
    this.addScrip = this.addScrip.bind(this);
    this.handleSumbit = this.handleSumbit.bind(this);
    this.fetchSuggestions = this.fetchSuggestions.bind(this);
    this.selectedScrip = this.selectedScrip.bind(this);
    this.removeScrip = this.removeScrip.bind(this);
  }

  addScrip() {
    const code = document.getElementById('code').value;
    if (this.state.input_scrips.indexOf({ code: code.toUpperCase() }) !== -1) return;
    this.setState((prevState) => ({
      input_scrips: [
        ...prevState.input_scrips,
        {
          code: code.toUpperCase()
        }
      ],
      suggestions: []
    }));
    document.getElementById('code').value = '';
  }

  removeScrip(event) {
    const scrip_index = event.target.id;
    const scrip_array = [...this.state.input_scrips];
    scrip_array.splice(scrip_index, 1);
    this.setState({
      input_scrips: scrip_array
    });
  }

  async handleSumbit() {
    Toast.loading('In Progress');

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      config.params = { codes: this.state.input_scrips.join(',') };
      axios
        .get(`${base_url}/structure-risk/null`, config)
        .then((res) => {
          this.setState({
            underlying: res.data.item.underlying,
            classification: res.data.item.classification
          });
          Toast.hide();
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  async fetchSuggestions(event) {
    if (event.target.value === '') return;

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      config.params = {
        q: event.target.value
      };
      axios
        .get(`${base_url}/product/suggest`, config)
        .then((res) => {
          this.setState({
            suggestions: res.data.items
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  selectedScrip(event) {
    const code = event.target.id;
    if (this.state.underlying.includes({ code: code.toUpperCase() })) return;
    document.getElementById('code').value = code;
    Toast.hide();
    this.setState((prevState) => ({
      suggestions: []
    }));
  }

  async componentDidMount() {
    const search = new URLSearchParams(window.location.search);
    const isin_code = search.get('ISIN');

    if (isin_code === '') return;

    Toast.loading('In Progress', () => { });

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      config.params = { codes: this.state.input_scrips.join(',') };
      axios
        .get(`${base_url}/structure-risk/${isin_code}`, config)
        .then((res) => {
          this.setState({
            input_scrips: res.data.item.underlying.map((value) => {
              return { code: value.code };
            }),
            underlying: res.data.item.underlying,
            classification: res.data.item.classification
          });
          Toast.hide();
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  render() {
    return (
      <div className="container-fluid">
        <form className="form-group row">
          <label>Underlyings:</label>
          <div className="col-sm-7">
            <input
              type="text"
              autoComplete="off"
              placeholder="Enter Scrip Name to add"
              className="form-control"
              id="code"
              onChange={this.fetchSuggestions}
            />
            <div className="dropdown">
              <ul className="list-group">
                {this.state.suggestions.map((value, index) => {
                  return (
                    <li
                      className="list-group-item list-group-flush"
                      onClick={this.selectedScrip}
                      id={value.code}
                      key={index}
                    >
                      {value.code}
                      &nbsp; ({value.name}) &nbsp;
                      <img src={Flags[value.country_iso]}></img>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="col-sm-5">
            <div className="btn-group" role="group" aria-label="Basic example">
              <a className="btn btn-primary" id="addScrip" onClick={this.addScrip}>
                <FaPlus />
              </a>
              <a className="btn btn-danger" onClick={this.handleSumbit}>
                Submit
              </a>
            </div>
          </div>
          <div className="contaier row">
            <div className="col-sm-7">
              {this.state.input_scrips.map((value, index) => {
                return (
                  <div className="row">
                    <div className="col-md-10">
                      <input
                        type="text"
                        disabled="true"
                        className="form-control"
                        value={value.code}
                      />
                    </div>
                    <div
                      className="col-md-2 btn-group"
                      role="group"
                      aria-label="Basic example"
                      key={index}
                    >
                      <a
                        className="btn btn-danger"
                        id={index}
                        key={index}
                        onClick={this.removeScrip}
                      >
                        <FaTrash />
                      </a>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </form>
        {this.state.underlying.length !== 0 && (
          <>
            <div className="contaier">
              <h3>Basket 1</h3>
              <table className="table table-bordered">
                <thead
                  style={{
                    backgroundColor: '#002850',
                    color: '#ffffff',
                    textDecoration: 'none',
                    border: '1px solid white'
                  }}
                >
                  <tr>
                    <th>Stock</th>
                    <th>Weightage</th>
                    {this.state.underlying.map((value, index) => (
                      <th key={index}>{value.code}</th>
                    ))}
                    <td></td>
                    {this.state.underlying.map((value, index) => (
                      <th key={index}>{value.code}</th>
                    ))}
                  </tr>
                  <tr>
                    <th>Last Price</th>
                    <th></th>
                    {this.state.underlying.map((value, index) => (
                      <th key={index}>
                        {value.last_price &&
                          value.last_price.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2
                          })}
                      </th>
                    ))}
                    <td></td>
                    {this.state.underlying.map((value, index) => (
                      <th key={index}></th>
                    ))}
                  </tr>
                  <tr>
                    <th>Stock Beta</th>
                    <th></th>
                    {this.state.underlying.map((value, index) => (
                      <th key={index}>
                        {value.beta &&
                          value.beta.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2
                          })}
                      </th>
                    ))}
                    <td></td>
                    {this.state.underlying.map((value, index) => (
                      <th key={index}></th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      50DMA
                    </th>
                    <td>50%</td>
                    {this.state.underlying.map((value, index) => (
                      <td key={index}>
                        {value.dma_50.toLocaleString(undefined, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2
                        })}
                      </td>
                    ))}
                    <td></td>
                    {this.state.underlying.map((value, index) => (
                      <td key={index}>{value.down_to_dma_50 + '%'}</td>
                    ))}
                  </tr>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      100D MA
                    </th>
                    <td>50%</td>
                    {this.state.underlying.map((value, index) => (
                      <td key={index}>
                        {value.dma_100.toLocaleString(undefined, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2
                        })}
                      </td>
                    ))}
                    <td></td>
                    {this.state.underlying.map((value, index) => (
                      <td key={index}>{value.down_to_dma_100 + '%'}</td>
                    ))}
                  </tr>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      200D MA
                    </th>
                    <td>50%</td>
                    {this.state.underlying.map((value, index) => (
                      <td key={index}>
                        {value.dma_200.toLocaleString(undefined, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2
                        })}
                      </td>
                    ))}
                    <td></td>
                    {this.state.underlying.map((value, index) => (
                      <td key={index}>{value.down_to_dma_200 + '%'}</td>
                    ))}
                  </tr>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      3rd Low Call OI
                    </th>
                    <td>50%</td>
                    {this.state.underlying.map((value, index) => (
                      <td key={index}>{value.call_oi[0]}</td>
                    ))}
                    <td></td>
                    {this.state.underlying.map((value, index) => (
                      <td key={index}>{value.down_to_call_oi[0] + '%'}</td>
                    ))}
                  </tr>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      2nd Low Call OI
                    </th>
                    <td>50%</td>
                    {this.state.underlying.map((value, index) => (
                      <td key={index}>{value.call_oi[1]}</td>
                    ))}
                    <td></td>
                    {this.state.underlying.map((value, index) => (
                      <td key={index}>{value.down_to_call_oi[1] + '%'}</td>
                    ))}
                  </tr>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      1st Low Call OI
                    </th>
                    <td>50%</td>
                    {this.state.underlying.map((value, index) => (
                      <td key={index}>{value.call_oi[2]}</td>
                    ))}
                    <td></td>
                    {this.state.underlying.map((value, index) => (
                      <td key={index}>{value.down_to_call_oi[2] + '%'}</td>
                    ))}
                  </tr>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      1st Low Put OI
                    </th>
                    <td>50%</td>
                    {this.state.underlying.map((value, index) => (
                      <td key={index}>{value.put_oi[0]}</td>
                    ))}
                    <td></td>
                    {this.state.underlying.map((value, index) => (
                      <td key={index}>{value.down_to_put_oi[0] + '%'}</td>
                    ))}
                  </tr>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      2nd Low Put OI
                    </th>
                    <td>50%</td>
                    {this.state.underlying.map((value, index) => (
                      <td key={index}>{value.put_oi[1]}</td>
                    ))}
                    <td></td>
                    {this.state.underlying.map((value, index) => (
                      <td key={index}>{value.down_to_put_oi[1] + '%'}</td>
                    ))}
                  </tr>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      3rd Low Put OI
                    </th>
                    <td>50%</td>
                    {this.state.underlying.map((value, index) => (
                      <td key={index}>{value.put_oi[2]}</td>
                    ))}
                    <td></td>
                    {this.state.underlying.map((value, index) => (
                      <td key={index}>{value.down_to_put_oi[2] + '%'}</td>
                    ))}
                  </tr>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      Support 1
                    </th>
                    <td>50%</td>
                    {this.state.underlying.map((value, index) => (
                      <td key={index}>{value.supports[2]}</td>
                    ))}
                    <td></td>
                    {this.state.underlying.map((value, index) => (
                      <td key={index}>{value.down_to_supports[2] + '%'}</td>
                    ))}
                  </tr>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      Support 2
                    </th>
                    <td>50%</td>
                    {this.state.underlying.map((value, index) => (
                      <td key={index}>{value.supports[1]}</td>
                    ))}
                    <td></td>
                    {this.state.underlying.map((value, index) => (
                      <td key={index}>{value.down_to_supports[1] + '%'}</td>
                    ))}
                  </tr>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      Support 3
                    </th>
                    <td>50%</td>
                    {this.state.underlying.map((value, index) => (
                      <td key={index}>{value.supports[0]}</td>
                    ))}
                    <td></td>
                    {this.state.underlying.map((value, index) => (
                      <td key={index}>{value.down_to_supports[0] + '%'}</td>
                    ))}
                  </tr>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      Resistance 1
                    </th>
                    <td>50%</td>
                    {this.state.underlying.map((value, index) => (
                      <td key={index}>{value.resistances[0]}</td>
                    ))}
                    <td></td>
                    {this.state.underlying.map((value, index) => (
                      <td key={index}>{value.down_to_resistances[0] + '%'}</td>
                    ))}
                  </tr>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      Resistance 2
                    </th>
                    <td>50%</td>
                    {this.state.underlying.map((value, index) => (
                      <td key={index}>{value.resistances[1]}</td>
                    ))}
                    <td></td>
                    {this.state.underlying.map((value, index) => (
                      <td key={index}>{value.down_to_resistances[1] + '%'}</td>
                    ))}
                  </tr>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      Resistance 3
                    </th>
                    <td>50%</td>
                    {this.state.underlying.map((value, index) => (
                      <td key={index}>{value.resistances[2]}</td>
                    ))}
                    <td></td>
                    {this.state.underlying.map((value, index) => (
                      <td key={index}>{value.down_to_resistances[2] + '%'}</td>
                    ))}
                  </tr>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      High Price Target
                    </th>
                    <td>50%</td>
                    {this.state.underlying.map((value, index) => (
                      <td key={index}>{value.high_target}</td>
                    ))}
                    <td></td>
                    {this.state.underlying.map((value, index) => (
                      <td key={index}>{value.down_to_high_target + '%'}</td>
                    ))}
                  </tr>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      Average Target
                    </th>
                    <td>50%</td>
                    {this.state.underlying.map((value, index) => (
                      <td key={index}>{value.average_target}</td>
                    ))}
                    <td></td>
                    {this.state.underlying.map((value, index) => (
                      <td key={index}>{value.down_to_average_target + '%'}</td>
                    ))}
                  </tr>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      Low Target
                    </th>
                    <td>50%</td>
                    {this.state.underlying.map((value, index) => (
                      <td key={index}>{value.low_target}</td>
                    ))}
                    <td></td>
                    {this.state.underlying.map((value, index) => (
                      <td key={index}>{value.down_to_low_target + '%'}</td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
            <hr />
            <div className="container">
              <table className="table table-bordered">
                <thead
                  style={{
                    backgroundColor: '#002850',
                    color: '#ffffff',
                    textDecoration: 'none',
                    border: '1px solid white'
                  }}
                >
                  <tr>
                    <th>Stock</th>
                    <th></th>
                    {this.state.underlying.map((value, index) => (
                      <th key={index}>{value.code}</th>
                    ))}
                    <th>Final</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th
                      rowSpan="5"
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      Conservative
                    </th>
                    <th
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      200D MA
                    </th>
                    {this.state.underlying.map((value, index) => (
                      <td key={index}>{value.down_to_dma_200 + '%'}</td>
                    ))}
                  </tr>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      PUT OI 3
                    </th>
                    {this.state.underlying.map((value, index) => (
                      <td key={index}>{value.down_to_put_oi[0] + '%'}</td>
                    ))}
                  </tr>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      Support 3
                    </th>
                    {this.state.underlying.map((value, index) => (
                      <td key={index}>{value.down_to_supports[0] + '%'}</td>
                    ))}
                  </tr>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      Low Target
                    </th>
                    {this.state.underlying.map((value, index) => (
                      <td key={index}>{value.down_to_low_target + '%'}</td>
                    ))}
                  </tr>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      Final
                    </th>
                    {this.state.underlying.map((value, index) => (
                      <td
                        style={{
                          backgroundColor: '#002850',
                          color: '#ffffff',
                          textDecoration: 'none',
                          border: '1px solid white'
                        }}
                        key={index}
                      >
                        {value.conservative + '%'}
                      </td>
                    ))}

                    <td
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      {this.state.classification.conservative + '%'}
                    </td>
                  </tr>
                  <tr>
                    <th
                      rowSpan="5"
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      Moderate
                    </th>
                    <th
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      100 DMA
                    </th>
                    {this.state.underlying.map((value, index) => (
                      <td key={index}>{value.down_to_dma_100 + '%'}</td>
                    ))}
                  </tr>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      PUT OI 2
                    </th>
                    {this.state.underlying.map((value, index) => (
                      <td key={index}>{value.down_to_put_oi[1] + '%'}</td>
                    ))}
                  </tr>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      Support 2
                    </th>
                    {this.state.underlying.map((value, index) => (
                      <td key={index}>{value.down_to_supports[1] + '%'}</td>
                    ))}
                  </tr>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      Average Target
                    </th>
                    {this.state.underlying.map((value, index) => (
                      <td key={index}>{value.down_to_average_target + '%'}</td>
                    ))}
                  </tr>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      Final
                    </th>
                    {this.state.underlying.map((value, index) => (
                      <td
                        style={{
                          backgroundColor: '#002850',
                          color: '#ffffff',
                          textDecoration: 'none',
                          border: '1px solid white'
                        }}
                        key={index}
                      >
                        {value.moderate + '%'}
                      </td>
                    ))}
                    <td
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      {this.state.classification.moderate + '%'}
                    </td>
                  </tr>
                  <tr>
                    <th
                      rowSpan="5"
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      Aggresive
                    </th>
                    <th
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      50 DMA
                    </th>
                    {this.state.underlying.map((value, index) => (
                      <td key={index}>{value.down_to_dma_50 + '%'}</td>
                    ))}
                  </tr>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      PUT OI 1
                    </th>
                    {this.state.underlying.map((value, index) => (
                      <td key={index}>{value.down_to_put_oi[2] + '%'}</td>
                    ))}
                  </tr>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      Support 1
                    </th>
                    {this.state.underlying.map((value, index) => (
                      <td key={index}>{value.down_to_supports[2] + '%'}</td>
                    ))}
                  </tr>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      High Target
                    </th>
                    {this.state.underlying.map((value, index) => (
                      <td key={index}>{value.down_to_high_target + '%'}</td>
                    ))}
                  </tr>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      Final
                    </th>
                    {this.state.underlying.map((value, index) => (
                      <td
                        style={{
                          backgroundColor: '#002850',
                          color: '#ffffff',
                          textDecoration: 'none',
                          border: '1px solid white'
                        }}
                        key={index}
                      >
                        {value.high_risk + '%'}
                      </td>
                    ))}
                    <td
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      {this.state.classification.high_risk + '%'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default OptionsAnalyser;
