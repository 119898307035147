import axios from 'axios';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Flags from './flags';


import Toast from 'light-toast';
import cookies from '../utils/cookies.util';
import base_url from '../utils/request.util';
import EquityPopover from './equityPopover';

let request = {};

class MainPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      GainersScrips: [],
      LosersScrips: []
    };
  }

  async componentDidMount() {
    console.log("calling", new Date().getTime());
    Toast.loading('In Progress', () => { });
    request = {
      url: `${base_url}/structure/top/gainers`,
      method: 'GET',
      headers: {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      },
    };
    // let signedRequest = aws4.sign(request,
    //     )
    axios(request)
      .then((res) => {
        this.setState({
          GainersScrips: res.data.items
        });
      })
      .catch((error) => {
        console.log(error);
        Toast.fail('Error Performing Action', () => { });
      });
    request = {
      url: `${base_url}/structure/top/losers`,
      method: 'GET',
      headers: {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      },
    };
    axios(request)
      .then((res) => {
        this.setState({
          LosersScrips: res.data.items
        });
        Toast.success('Success', 1000, () => { });
      })
      .catch((error) => {
        console.log(error);
        Toast.fail('Error Performing Action', 3000);
      });
  }

  render() {
    return (
      <div className="container-fluid row">
        <div className={'col-sm-12'}>
          <h3>Underlying Equities</h3>
          <table className="table table-sm">
            <thead className="table-dark">
              <tr>
                <th>Name</th>
                <th></th>
                <th>Close</th>
                <th>%</th>
                <th>News</th>
                <th>Next Earnings</th>
              </tr>
            </thead>
            <tbody>
              <th
                colSpan="6"
                style={{
                  backgroundColor: '#002850',
                  color: '#ffffff',
                  textDecoration: 'none',
                  whiteSpace: 'nowrap'
                }}
              >
                
                Top Gainers
              </th>
              {this.state.GainersScrips.length !== 0 ? (
                this.state.GainersScrips.map((scrip_data, index) => {
                  return (
                    <tr key={index}>
                      <td
                        style={{
                          backgroundColor: '#002850',
                          color: '#ffffff',
                          textDecoration: 'none',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        <Link to={`/product/equity?code=${scrip_data.code}`}>
                          {scrip_data.name} <img src={Flags[scrip_data.country_iso]} />
                        </Link>
                      </td>
                      <td>
                        <EquityPopover code={scrip_data.code} />
                      </td>
                      <td>
                        {scrip_data.last_price
                          ? scrip_data.last_price.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2
                          })
                          : '-'}
                      </td>
                      <td
                        style={{
                          color: scrip_data.day_change_p > 0 ? 'green' : 'red',
                          fontWeight: 'bold'
                        }}
                      >
                        {scrip_data.day_change_p
                          ? scrip_data.day_change_p.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2
                          }) + '%'
                          : '-'}
                      </td>
                      {
                        <td>
                          {scrip_data.news_items && scrip_data.news_items.length > 0 && (
                            <Link
                              className="btn btn-link"
                              style={{ textAlign: 'left' }}
                              to={'/product/news/' + scrip_data.news_items[0].id}
                            >
                              {scrip_data.news_items[0].title}
                            </Link>
                          )}
                        </td>
                      }
                      <td>
                        {scrip_data.earnings_date
                          ? scrip_data.earnings_date.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2
                          })
                          : '-'}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="6">
                    <b>No scrips</b>
                  </td>
                </tr>
              )}
              <th
                colSpan="6"
                style={{
                  backgroundColor: '#002850',
                  color: '#ffffff',
                  textDecoration: 'none',
                  whiteSpace: 'nowrap'
                }}
              >
                
                Top Losers
              </th>
              {this.state.LosersScrips.length !== 0 ? (
                this.state.LosersScrips.map((scrip_data, index) => {
                  return (
                    <tr key={index}>
                      <td
                        style={{
                          backgroundColor: '#002850',
                          color: '#ffffff',
                          textDecoration: 'none',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        <Link to={`/product/equity?code=${scrip_data.code}`}>
                          {scrip_data.name} <img src={Flags[scrip_data.country_iso]} />
                        </Link>
                      </td>
                      <td>
                        <EquityPopover code={scrip_data.code} />
                      </td>
                      <td>
                        {scrip_data.last_price
                          ? scrip_data.last_price.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2
                          })
                          : '-'}
                      </td>
                      <td
                        style={{
                          color: scrip_data.day_change_p > 0 ? 'green' : 'red',
                          fontWeight: 'bold'
                        }}
                      >
                        {scrip_data.day_change_p
                          ? scrip_data.day_change_p.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2
                          }) + '%'
                          : '-'}
                      </td>
                      {
                        <td>
                          {scrip_data.news_items && scrip_data.news_items.length > 0 && (
                            <Link
                              className="btn btn-link"
                              style={{ textAlign: 'left' }}
                              to={'/product/news/' + scrip_data.news_items[0].id}
                            >
                              {scrip_data.news_items[0].title}
                            </Link>
                          )}
                        </td>
                      }
                      <td>
                        {scrip_data.earnings_date
                          ? scrip_data.earnings_date.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2
                          })
                          : '-'}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="6">
                    <b>No scrips</b>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default MainPage;
