import axios from 'axios';
import Toast from 'light-toast';
import React, { Component } from 'react';

import { fetchAuthSession } from 'aws-amplify/auth';

import cookies from '../utils/cookies.util';
import base_url from '../utils/request.util';

class UserLoginAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: '',
      password: '',
      user_type: '',
      admin_user_id: '',
      users: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fetchUser = this.fetchUser.bind(this);
  }

  async setUsers(user_type) {
    Toast.loading('In Progress', () => {});
    await fetchAuthSession().then((user) => {
      const config = {};
      config.params = { user_type: user_type };
      config.headers = {
        Authorization: cookies.get('Authorization'),
        'User-Specified': user.tokens.accessToken.payload.username,
        'Type-Specified': user.tokens.accessToken.payload['cognito:groups']
      };
      axios
        .get(`${base_url}/user`, config)
        .then((res) => {
          this.setState({
            users: [{}].concat(res.data.items),
            user_name: user.tokens.accessToken.payload.username,
            user_type: user_type
          });
          Toast.success('Success', 1000, () => {});
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  async handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });

    if (event.target.id == 'user_type') {
      await this.setUsers(event.target.value);
    }
  }

  async handleSubmit() {
    Toast.loading('In Progress', () => {});

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        Authorization: cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      const data = {
        user_id: this.state.user_id,
        name: this.state.name,
        email: this.state.email,
        user_type: this.state.user_type,
        password: this.state.password
      };

      axios
        .post(`${base_url}/login`, data, config)
        .then(() => {
          Toast.success('Success', 1000, () => {});
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  async fetchUser(event) {
    Toast.loading('In Progress', () => {});

    await fetchAuthSession().then((user) => {
      const config = {};
      config.params = { user_type: this.state.user_type };
      config.headers = {
        Authorization: cookies.get('Authorization'),
        'User-Specified': user.tokens.accessToken.payload.username,
        'Type-Specified': user.tokens.accessToken.payload['cognito:groups']
      };
      axios
        .get(`${base_url}/user/${event.target.value.split(' | ')[0]}`, config)
        .then((res) => {
          this.setState({
            name: res.data.item.name,
            email: res.data.item.email,
            user_id: res.data.item.user_id,
            user_type: res.data.item.user_type
          });
          Toast.success('Success', 1000, () => {});
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }
  async componentDidMount() {
    await this.setUsers('Client');
    this.setState({
      admin_user_id: cookies.get('User-Specified')
    });
  }

  render() {
    console.log(this.state.user_type);

    return (
      <div className="container">
        <div className="container hidden" id="portfolioData">
          <div className="form-group">
            <label>User Type:</label>
            <select
              // disabled
              className="form-control"
              required
              id="user_type"
              value={this.state.user_type}
              onChange={this.handleChange}>
              <option>Client</option>
              <option>Admin</option>
              <option>BackOffice</option>
              <option>External</option>
              <option>RelationshipManager</option>
            </select>
          </div>
          <div className="form-group">
            <label>
              User (Please select user whos login is not already created or else request will fail):
            </label>
            {
              <select className="form-control" onChange={this.fetchUser}>
                {this.state.users.map((value, index) => {
                  return (
                    <option key={index}>
                      {value.user_id}
                      {value.name && ' | ' + value.name}
                    </option>
                  );
                })}
              </select>
            }
          </div>
          <div className="form-group">
            <label>Creator User ID:</label>
            <input
              disabled
              type="text"
              value={this.state.admin_user_id}
              onChange={this.handleChange}
              className="form-control"
              required
              id="admin_user_id"
            />
          </div>
          <div className="form-group">
            <label>
              Password (Please use uppercase, lowercase, symbol and a number [Min 8 Chars]):
            </label>
            <input
              type="password"
              value={this.state.password}
              onChange={this.handleChange}
              className="form-control"
              required
              autoComplete="off"
              id="password"
            />
          </div>
          <a className="btn btn-block btn-danger" onClick={this.handleSubmit}>
            Submit
          </a>
        </div>
      </div>
    );
  }
}

export default UserLoginAdd;
