import axios from 'axios';
import Toast from 'light-toast';
import React, { Component } from 'react';

import { fetchAuthSession } from 'aws-amplify/auth';
import { Link } from 'react-router-dom';
import EquityPopover from './equityPopover';

import cookies from '../utils/cookies.util';
import base_url from '../utils/request.util';

class IndexComponentsPivot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      code: '',
      news_data: [],
      suggestions: [],
      underlying_count: {},
      holding_count: {},
      indexComponentsPivotData: null
    };
  }

  async componentDidMount() {
    const search = window.location.search;
    let code = search.replaceAll('?code=', '').replaceAll('?Code=', '').replaceAll('%20', ' ');

    if (code === '') return;
    Toast.loading('In Progress', () => { });

    await fetchAuthSession().then(() => {
      const config = {};
      config.params = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };

      code = code.replaceAll('/', ' ');

      axios.get(`${base_url}/index-components-pivot/${code}`, config).then((res) => {
        this.setState({
          indexComponentsPivotData: res.data.items
        });
        Toast.success('Success', 1000, () => { });
      });

      axios
        .get(`${base_url}/structure-underlying-count`, config)
        .then(async (res) => {
          this.setState({
            underlying_count: res.data.item
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });

      config.params = {};
      axios.get(`${base_url}/structure-holding-count`, config).then(async (res) => {
        this.setState({
          holding_count: res.data.item
        });
      });
    });
  }

  render() {
    return (
      <div className="container-fluid">
        <table className="table table-sm">
          <thead className="table-dark">
            <tr>
              <th style={{ position: 'sticky', top: 50, whiteSpace: 'nowrap' }}>Sector</th>
              <th style={{ position: 'sticky', top: 50, whiteSpace: 'nowrap' }}>Industry</th>
              <th style={{ position: 'sticky', top: 50, whiteSpace: 'nowrap' }}>Name</th>
              <th style={{ position: 'sticky', top: 50, whiteSpace: 'nowrap' }}>&nbsp;</th>
              <th style={{ position: 'sticky', top: 50, whiteSpace: 'nowrap' }}>Code</th>
              <th style={{ position: 'sticky', top: 50, whiteSpace: 'nowrap' }}>&nbsp;</th>
              <th style={{ position: 'sticky', top: 50, whiteSpace: 'nowrap' }}>Mcap (Mln)</th>
              <th style={{ position: 'sticky', top: 50, whiteSpace: 'nowrap' }}>Mcap/Rev</th>
              <th style={{ position: 'sticky', top: 50, whiteSpace: 'nowrap' }}>PE</th>
              <th style={{ position: 'sticky', top: 50, whiteSpace: 'nowrap' }}>PB</th>
              <th style={{ position: 'sticky', top: 50, whiteSpace: 'nowrap' }}>Div Yield</th>
              <th style={{ position: 'sticky', top: 50, whiteSpace: 'nowrap' }}>IV</th>
              <th style={{ position: 'sticky', top: 50, whiteSpace: 'nowrap' }}>Piotroski</th>
              <th style={{ position: 'sticky', top: 50, whiteSpace: 'nowrap' }}>Rank</th>
            </tr>
          </thead>
          <tbody>
            {this.state.indexComponentsPivotData &&
              this.state.indexComponentsPivotData.map(
                (sectorWiseData, indexComponentsPivotDataIndex) => {
                  return (
                    <>
                      {sectorWiseData.product_data.map((item, index) => {
                        if (sectorWiseData.sector == null) {
                          return;
                        }
                        return (
                          <>
                            <tr key={index}>
                              {index === 0 && (
                                <td
                                  rowSpan={sectorWiseData.product_data.length}
                                  style={{
                                    backgroundColor: '#002850',
                                    color: '#ffffff',
                                    textDecoration: 'none'
                                  }}
                                >
                                  <strong>
                                    {sectorWiseData.sector != null ? sectorWiseData.sector : '-'}
                                  </strong>
                                </td>
                              )}
                              <td className="table-dark" style={{ whiteSpace: 'nowrap' }}>
                                
                                {item.industry != null
                                  ? item.industry.toLocaleString(undefined, {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 2
                                  })
                                  : '-'}
                              </td>
                              <td className="table-dark" style={{ whiteSpace: 'nowrap' }}>
                                {item.name != null ? item.name : '-'}
                              </td>
                              <td className="table-dark">
                                {this.state.underlying_count.length !== 0 &&
                                  this.state.holding_count.length !== 0 &&
                                  this.state.underlying_count[item.code] && (
                                    <span
                                      className={
                                        this.state.underlying_count.length !== 0 &&
                                          this.state.holding_count[item.code]
                                          ? 'badge badge-danger'
                                          : 'badge badge-primary'
                                      }
                                    >
                                      {this.state.underlying_count[item.code]}
                                    </span>
                                  )}
                              </td>
                              <td className="table-dark" style={{ whiteSpace: 'nowrap' }}>
                                {item.code != null ? (
                                  <Link
                                    className="btn btn-link"
                                    to={`/product/equity?code=${item.code}`}
                                  >
                                    {item.code}
                                  </Link>
                                ) : (
                                  '-'
                                )}
                              </td>
                              <td className="table-dark">
                                <EquityPopover code={item.code} />
                              </td>
                              <td className="table-dark">
                                
                                {item.market_capitalization_mln != null
                                  ? item.market_capitalization_mln.toLocaleString(undefined, {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0
                                  })
                                  : '-'}
                              </td>
                              <td className="table-dark">
                                
                                {item.market_capitalization_to_revenue_ttm != null
                                  ? item.market_capitalization_to_revenue_ttm.toLocaleString(
                                    undefined,
                                    { minimumFractionDigits: 0, maximumFractionDigits: 1 }
                                  )
                                  : '-'}
                              </td>
                              <td className="table-dark">
                                
                                {item.pe_ratio != null
                                  ? item.pe_ratio.toLocaleString(undefined, {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 1
                                  })
                                  : '-'}
                              </td>
                              <td className="table-dark">
                                
                                {item.price_book_mrq != null
                                  ? item.price_book_mrq.toLocaleString(undefined, {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 1
                                  })
                                  : '-'}
                              </td>
                              <td className="table-dark">
                                
                                {item.dividend_yield != null
                                  ? item.dividend_yield.toLocaleString(undefined, {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 1
                                  })
                                  : '-'}
                              </td>
                              <td className="table-dark">
                                
                                {item.iv_3months != null
                                  ? item.iv_3months.toLocaleString(undefined, {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 1
                                  })
                                  : '-'}
                              </td>
                              <td className="table-dark">
                                
                                {item.piotroski_score != null
                                  ? item.piotroski_score.toLocaleString(undefined, {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 1
                                  })
                                  : '-'}
                              </td>
                              <td className="table-dark">
                                
                                {item.index_ranking != null
                                  ? item.index_ranking.toLocaleString(undefined, {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 1
                                  })
                                  : '-'}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                      {sectorWiseData.sector != null && (
                        <>
                          <tr key={indexComponentsPivotDataIndex}>
                            <td
                              colSpan={3}
                              style={{
                                backgroundColor: '#002850',
                                color: '#ffffff',
                                textDecoration: 'none'
                              }}
                            >
                              <strong>{sectorWiseData.sector} Average</strong>
                            </td>
                            <td
                              style={{
                                backgroundColor: '#002850',
                                color: '#ffffff',
                                textDecoration: 'none'
                              }}
                            ></td>
                            <td
                              style={{
                                backgroundColor: '#002850',
                                color: '#ffffff',
                                textDecoration: 'none'
                              }}
                            ></td>
                            <td
                              style={{
                                backgroundColor: '#002850',
                                color: '#ffffff',
                                textDecoration: 'none'
                              }}
                            ></td>
                            <td
                              style={{
                                backgroundColor: '#002850',
                                color: '#ffffff',
                                textDecoration: 'none'
                              }}
                            >
                              <strong>
                                {sectorWiseData.average_data.market_capitalization_mln != null
                                  ? sectorWiseData.average_data.market_capitalization_mln.toLocaleString(
                                    undefined,
                                    { minimumFractionDigits: 0, maximumFractionDigits: 0 }
                                  )
                                  : '-'}
                              </strong>
                            </td>
                            <td
                              style={{
                                backgroundColor: '#002850',
                                color: '#ffffff',
                                textDecoration: 'none'
                              }}
                            >
                              <strong>
                                {sectorWiseData.average_data.market_capitalization_to_revenue_ttm !=
                                  null
                                  ? sectorWiseData.average_data.market_capitalization_to_revenue_ttm.toLocaleString(
                                    undefined,
                                    { minimumFractionDigits: 0, maximumFractionDigits: 1 }
                                  )
                                  : '-'}
                              </strong>
                            </td>
                            <td
                              style={{
                                backgroundColor: '#002850',
                                color: '#ffffff',
                                textDecoration: 'none'
                              }}
                            >
                              <strong>
                                {sectorWiseData.average_data.price_book_mrq != null
                                  ? sectorWiseData.average_data.price_book_mrq.toLocaleString(
                                    undefined,
                                    { minimumFractionDigits: 0, maximumFractionDigits: 1 }
                                  )
                                  : '-'}
                              </strong>
                            </td>
                            <td
                              style={{
                                backgroundColor: '#002850',
                                color: '#ffffff',
                                textDecoration: 'none'
                              }}
                            >
                              <strong>
                                {sectorWiseData.average_data.pe_ratio != null
                                  ? sectorWiseData.average_data.pe_ratio.toLocaleString(undefined, {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 1
                                  })
                                  : '-'}
                              </strong>
                            </td>
                            <td
                              style={{
                                backgroundColor: '#002850',
                                color: '#ffffff',
                                textDecoration: 'none'
                              }}
                            >
                              <strong>
                                {sectorWiseData.average_data.dividend_yield != null
                                  ? sectorWiseData.average_data.dividend_yield.toLocaleString(
                                    undefined,
                                    { minimumFractionDigits: 0, maximumFractionDigits: 1 }
                                  )
                                  : '-'}
                              </strong>
                            </td>
                            <td
                              style={{
                                backgroundColor: '#002850',
                                color: '#ffffff',
                                textDecoration: 'none'
                              }}
                            >
                              <strong>
                                {sectorWiseData.average_data.iv_3months != null
                                  ? sectorWiseData.average_data.iv_3months.toLocaleString(
                                    undefined,
                                    { minimumFractionDigits: 0, maximumFractionDigits: 1 }
                                  )
                                  : '-'}
                              </strong>
                            </td>
                            <td
                              style={{
                                backgroundColor: '#002850',
                                color: '#ffffff',
                                textDecoration: 'none'
                              }}
                            >
                              <strong>
                                {sectorWiseData.average_data.piotroski_score != null
                                  ? sectorWiseData.average_data.piotroski_score.toLocaleString(
                                    undefined,
                                    { minimumFractionDigits: 0, maximumFractionDigits: 1 }
                                  )
                                  : '-'}
                              </strong>
                            </td>
                            <td
                              style={{
                                backgroundColor: '#002850',
                                color: '#ffffff',
                                textDecoration: 'none'
                              }}
                            ></td>
                          </tr>
                        </>
                      )}
                    </>
                  );
                }
              )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default IndexComponentsPivot;
