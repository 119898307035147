import axios from 'axios';
import Toast from 'light-toast';
import React, { Component } from 'react';

import { fetchAuthSession } from 'aws-amplify/auth';
import './styles.css';

import cookies from '../utils/cookies.util';
import base_url from '../utils/request.util';

class BondPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {}
    };
    this.fetchData = this.fetchData.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  async fetchData(isin_code) {
    Toast.loading('In Progress', () => { });

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      axios
        .get(`${base_url}/external-bond-product/${isin_code}`, config)
        .then((res) => {
          this.setState({
            data: res.data.item,
            isin_code
          });
          Toast.success('Success', 500, () => { });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
  }

  async handleSubmit() {
    const isin_code = this.state.isin_code;

    if (isin_code === '') return;

    await this.fetchData(isin_code);
  }

  async componentDidMount() {
    const search = new URLSearchParams(window.location.search);
    const isin_code = search.get('ISIN');

    if (isin_code === '') return;

    await this.fetchData(isin_code);
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="container p-3 my-3 border">
          <div className="container input-group mb-3">
            <input
              type="text"
              id="isin_code"
              value={this.state.isin_code}
              className="form-control"
              placeholder="Type Something like 910047AG4"
              autoComplete="off"
              onChange={this.handleChange}
            />
            <div className="input-group-append">
              <button className="btn btn-success" type="submit" onClick={this.handleSubmit}>
                Search
              </button>
            </div>
          </div>
        </div>
        {this.state.data != null && (
          <table className="table table-border">
            <tbody>
              <tr className="table-dark">
                <td>
                  
                  <h3> {this.state.data.name}</h3>
                </td>
              </tr>
              <tr className="table-secondary">
                <td>
                  <div className="row">
                    <div className="col-sm-6">
                      <strong>Code: {this.state.data.isin_code}</strong>
                    </div>
                    <div className="col-sm-6">
                      <strong>Currency: {this.state.data.currency}</strong>
                    </div>
                  </div>
                </td>
              </tr>
              {/* <tr className="table-secondary">
                                <td>
                                    <div className="row">
                                        <div className="col-sm-6"><strong>Issuer: {this.state.data.issuer}</strong></div>
                                    </div>
                                </td>
                            </tr>
                            <tr className="table-secondary">
                                <td>
                                    <div className="row">
                                        <div className="col-sm-6"><strong>Sub Product Asset: {this.state.data.industry_group}</strong></div>
                                        <div className="col-sm-6"><strong>Sub Product Asset Type: {this.state.data.industry_sub_group}</strong></div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td><p><strong>Issuer Description: </strong> {this.state.data.issuer_description}</p></td>
                            </tr> */}
              {/* <tr className="table-dark">
                                <td><center><strong>Chart</strong></center></td>
                            </tr>
                            <tr>
                                <Chart
                                    options={{
                                        chart: {
                                            height: 350,
                                            type: 'area',
                                        },
                                        dataLabels: {
                                            enabled: false
                                        },
                                        tooltip: {
                                            enabled: true,
                                        },
                                        xaxis: {
                                            type: 'datetime',
                                            labels: {
                                                formatter: function (val) {
                                                    return dayjs(val).format('MMM YY')
                                                }
                                            }
                                        },
                                        yaxis: {
                                            tooltip: {
                                                enabled: true
                                            }
                                        }
                                    }}
                                    series={[{
                                        name: this.state.data["Name"],
                                        data: this.state.data["EOD"]
                                    }]}
                                    type="area"
                                    height={350}
                                />
                            </tr> */}
              <tr className="table-dark">
                <td>
                  <center>
                    <strong>PRICE INFO</strong>
                  </center>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="row">
                    <div className="col-sm-6">
                      <ul className="list-group">
                        <li className="list-group-item">
                          <strong>Account Int:</strong>
                          {this.state.data.acc_int
                            ? this.state.data.acc_int.toLocaleString(undefined, {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2
                            })
                            : '-'}
                        </li>
                        <li className="list-group-item">
                          <strong>Minimum:</strong>
                          {this.state.data.minimum
                            ? this.state.data.minimum.toLocaleString(undefined, {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2
                            })
                            : '-'}
                        </li>
                        <li className="list-group-item">
                          <strong>Increment:</strong>
                          {this.state.data.increment
                            ? this.state.data.increment.toLocaleString(undefined, {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2
                            })
                            : '-'}
                        </li>
                        <li className="list-group-item">
                          <strong>Bid:</strong>
                          {this.state.data.bid
                            ? this.state.data.bid.toLocaleString(undefined, {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2
                            })
                            : '-'}
                        </li>
                        <li className="list-group-item">
                          <strong>Offer:</strong>
                          {this.state.data.offer
                            ? this.state.data.offer.toLocaleString(undefined, {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2
                            })
                            : '-'}
                        </li>
                      </ul>
                    </div>
                    <div className="col-sm-6">
                      <ul className="list-group">
                        <li className="list-group-item">
                          <strong>Coupon Type:</strong>
                          {this.state.data.coupon_type
                            ? this.state.data.coupon_type.toLocaleString(undefined, {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2
                            })
                            : '-'}
                        </li>
                        <li className="list-group-item">
                          <strong>Coupon Percentage:</strong>
                          {this.state.data.coupon_percentage
                            ? this.state.data.coupon_percentage.toLocaleString(undefined, {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2
                            })
                            : '-'}
                          %
                        </li>
                        <li className="list-group-item">
                          <strong>Coupon Frequency:</strong>
                          {this.state.data.coupon_frequency
                            ? this.state.data.coupon_frequency.toLocaleString(undefined, {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2
                            })
                            : '-'}
                        </li>
                        <li className="list-group-item">
                          <strong>Ask:</strong>
                          {this.state.data.ask
                            ? this.state.data.ask.toLocaleString(undefined, {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2
                            })
                            : '-'}
                        </li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr className="table-dark">
                <td>
                  <center>
                    <strong>Rating</strong>
                  </center>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="row">
                    <div className="col-sm-6">
                      <ul className="list-group">
                        <li className="list-group-item">
                          <strong>S&P Rating:</strong> {this.state.data.snp}
                        </li>
                        <li className="list-group-item">
                          <strong>Fitch:</strong> {this.state.data.fitch}
                        </li>
                      </ul>
                    </div>
                    <div className="col-sm-6">
                      <ul className="list-group">
                        <li className="list-group-item">
                          <strong>Moodys:</strong> {this.state.data.moodys}
                        </li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr className="table-dark">
                <td>
                  <center>
                    <strong>ISSUE DATA</strong>
                  </center>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="row">
                    <div className="col-sm-6">
                      <ul className="list-group">
                        <li className="list-group-item">
                          <strong>Issue Date:</strong> {this.state.data.issue_date}
                        </li>
                        <li className="list-group-item">
                          <strong>Final Fixing Date:</strong> {this.state.data.final_fixing_date}
                        </li>
                      </ul>
                    </div>
                    <div className="col-sm-6">
                      <ul className="list-group">
                        <li className="list-group-item">
                          <strong>Last Updated :</strong> {this.state.data.last_updated}
                        </li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    );
  }
}

export default BondPage;
