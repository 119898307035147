import axios from 'axios';
import Toast from 'light-toast';
import React, { Component } from 'react';

import { fetchAuthSession } from 'aws-amplify/auth';

import cookies from '../utils/cookies.util';
import base_url from '../utils/request.util';

function divideArray(Arr, leftOver) {
  const CurrentArr = Array.from(Arr);
  if (!leftOver) {
    const halfArr = CurrentArr.splice(0, CurrentArr.length / 2);
    console.log('halfArr', halfArr.length, 'Arr', CurrentArr.length);
    return halfArr;
  }
  CurrentArr.splice(0, CurrentArr.length / 2);
  console.log('CurrentArr', CurrentArr.length);
  return CurrentArr;
}

class SearchQuote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      code: '',
      news_data: [],
      suggestions: []
    };
    this.fetchSuggestions = this.fetchSuggestions.bind(this);
  }

  async fetchSuggestions(event) {
    this.setState({
      code: event.target.value
    });
    if (event.target.value === '') return;

    await fetchAuthSession().then(() => {
      const config = {};
      config.params = { q: event.target.value };
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };

      axios
        .get(`${base_url}/eod-search`, config)
        .then((res) => {
          this.setState({
            suggestions: res.data.items
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="container p-3 my-3 border">
          <div className="container input-group mb-3">
            <input
              type="text"
              id="code"
              value={this.state.code}
              className="form-control"
              placeholder="Type Something like AAPL US EQUITY ...."
              onChange={this.fetchSuggestions}
              autoComplete="off"
            />
            <div className="input-group-append">
              <button className="btn btn-success" type="submit" onClick={this.handleSubmit}>
                Search
              </button>
            </div>
          </div>
          <div className="dropdown">
            <ul className="list-group">
              {this.state.suggestions.map((value, index) => {
                return (
                  <li
                    className="list-group-item list-group-flush"
                    onClick={this.selectedScrip}
                    id={value.code}
                    key={index}
                  >
                    {value.Code} {value.Exchange}
                    &nbsp; ({value.Type}) &nbsp; ({value.Name}) &nbsp;
                    <b>{value.ISIN}</b>
                    &nbsp; (CMP: {value.previousClose} {value.Currency})
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default SearchQuote;
