import axios from 'axios';
import Toast from 'light-toast';
import React, { useState, useEffect } from 'react';
import './styles.css';

import { fetchAuthSession } from 'aws-amplify/auth';

import cookies from '../utils/cookies.util';
import base_url from '../utils/request.util';
import dayjs from 'dayjs';

const PortfolioLedger = () => {
  const [data, setLedgerData] = useState([]);
  const [users, setUsers] = useState([]);

  const fetchLedgerData = async (clientId) => {
    try {
      Toast.loading('In Progress');
      await fetchAuthSession();
      const config = {
        headers: {
          Authorization: cookies.get('Authorization'),
          'User-Specified': cookies.get('User-Specified'),
          'Type-Specified': cookies.get('Type-Specified')
        }
      };
      const res = await axios.get(`${base_url}/portfolio/ledger/${clientId}`, config);
      setLedgerData(res.data.items);
      Toast.success('Success', 500);
    } catch (error) {
      console.log(error);
      Toast.fail('Error Performing Action', 1000);
    }
  };

  const handleSubmit = (e) => {
    fetchLedgerData(e.target.value.split(' | ')[0].replaceAll(' ', '%20'));
  };

  useEffect(() => {
    const initializeLedger = async () => {
      const search = new URLSearchParams(window.location.search);
      const urlClientId = (search.get('clientId') || '').replaceAll('%20', ' ');

      if (urlClientId !== '') {
        fetchLedgerData(urlClientId);
        setUsers([urlClientId]);
        return;
      } else if (cookies.get('Type-Specified') === 'Client') {
        fetchLedgerData(cookies.get('User-Specified'));
        setUsers([urlClientId]);
        return;
      }

      Toast.loading('In Progress');

      try {
        await fetchAuthSession();
        const config = {
          headers: {
            Authorization: cookies.get('Authorization'),
            'User-Specified': cookies.get('User-Specified'),
            'Type-Specified': cookies.get('Type-Specified')
          },
          params: {
            user_type: 'Client'
          }
        };
        const res = await axios.get(`${base_url}/user`, config);
        setUsers(res.data.items);

        Toast.success('Success', 500);
      } catch (error) {
        console.log(error);
        Toast.fail('Error Performing Action', 1000);
      }
    };

    initializeLedger();
  }, []);

  return (
    <React.Fragment>
      <div className="container" style={{ margin: 20 }}>
        <select className="form-control" onChange={handleSubmit}>
          {users.map((value, index) => (
            <option key={index}>{value.user_id + ' | ' + value.name}</option>
          ))}
        </select>
      </div>
      <div className="container-fluid">
        <table className="table table-dark table-sm">
          <thead>
            <tr>
              <th style={{ position: 'sticky', top: 50 }}>Portfolio Id</th>
              <th style={{ position: 'sticky', top: 50 }}>Date</th>
              <th style={{ position: 'sticky', top: 50 }}>Instrument</th>
              <th style={{ position: 'sticky', top: 50 }}>ISIN</th>
              <th style={{ position: 'sticky', top: 50 }}>Type</th>
              <th style={{ position: 'sticky', top: 50 }}>Qty</th>
              <th style={{ position: 'sticky', top: 50 }}>Currency</th>
              <th style={{ position: 'sticky', top: 50 }}>Credit</th>
              <th style={{ position: 'sticky', top: 50 }}>Debit</th>
              <th style={{ position: 'sticky', top: 50 }}>Current Balance</th>
              <th style={{ position: 'sticky', top: 50 }}>Remark</th>
            </tr>
          </thead>
          <tbody>
            {data.map((ledgerDetails) => (
              <React.Fragment key={ledgerDetails.portfolio_id}>
                {ledgerDetails.ledger_data.map((item, index) => (
                  <tr key={`${ledgerDetails.portfolio_id}-${index}`}>
                    {index === 0 && (
                      <td
                        rowSpan={ledgerDetails.ledger_data.length}
                        style={{
                          backgroundColor: '#002850',
                          color: '#ffffff',
                          textDecoration: 'none'
                        }}>
                        <strong>{item.portfolio_id != null ? item.portfolio_id : '-'}</strong>
                        {['USD', 'EUR', 'GBP', 'AUD', 'INR'].map((currency) => (
                          <React.Fragment key={currency}>
                            <br />
                            <strong>
                              {currency}:{' '}
                              {item[`final_balance_${currency.toLowerCase()}`] != null
                                ? item[`final_balance_${currency.toLowerCase()}`]
                                : '-'}{' '}
                              {currency === 'EUR'
                                ? '€'
                                : currency === 'GBP'
                                  ? '£'
                                  : currency === 'INR'
                                    ? '₹'
                                    : '$'}
                            </strong>
                          </React.Fragment>
                        ))}
                      </td>
                    )}

                    <td>
                      {item.is_purchase
                        ? dayjs(item.o_buying_datetime).format('DD-MM-YYYY')
                        : dayjs(item.pp_buying_datetime).format('DD-MM-YYYY')}
                    </td>
                    <td>
                      <a className="btn btn-link">
                        {item.is_purchase ? item.o_code : item.pp_currency}
                        {item.o_external_transaction && '[External]'}
                      </a>
                    </td>
                    <td>{item.is_purchase ? item.o_isin_code : item.pp_isin_code}</td>
                    <td>
                      {item.is_purchase
                        ? item.o_quantity > 0
                          ? 'Buy'
                          : 'Sell'
                        : item.pp_amount > 0
                          ? 'Payin'
                          : 'Payout'}
                    </td>
                    <td>
                      {item.is_purchase
                        ? item.o_quantity
                        : Math.abs(
                            item['credit_' + item.currency.toLowerCase()] +
                              item['debit_' + item.currency.toLowerCase()]
                          )}
                    </td>
                    <td>{item.currency}</td>
                    <td>{item['credit_' + item.currency.toLowerCase()]}</td>
                    <td>{item['debit_' + item.currency.toLowerCase()]}</td>
                    <td>{item['balance_' + item.currency.toLowerCase()]}</td>
                    <td>{item.is_purchase ? item.o_remark : item.pp_remark}</td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export default PortfolioLedger;
