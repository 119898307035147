import axios from 'axios';
import dayjs from 'dayjs';
import Toast from 'light-toast';
import React, { Component } from 'react';
import Chart from 'react-apexcharts';
import Flags from './flags';

import { fetchAuthSession } from 'aws-amplify/auth';

import cookies from '../utils/cookies.util';
import base_url from '../utils/request.util';

class EtfPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      code: '',
      suggestions: []
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.selectedScrip = this.selectedScrip.bind(this);
    this.fetchSuggestions = this.fetchSuggestions.bind(this);
  }

  async handleSubmit(event) {
    let code = this.state.code;
    Toast.loading('In Progress', () => { });
    code = code.toUpperCase();
    await this.fetchData(code);
  }

  handleChange(event) {
    let code = event.target.value;
    code = code.toUpperCase();
    this.setState({
      code
    });
  }

  selectedScrip(event) {
    this.setState(
      {
        code: event.target.id,
        suggestions: []
      },
      () => {
        this.handleSubmit();
      }
    );
  }

  async fetchSuggestions(event) {
    this.setState({
      code: event.target.value
    });
    if (event.target.value === '') return;

    await fetchAuthSession().then(() => {
      const config = {};
      config.params = { q: event.target.value };
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };

      axios
        .get(`${base_url}/product/suggest`, config)
        .then((res) => {
          this.setState({
            suggestions: res.data.items
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  async fetchData(code) {
    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      axios
        .get(`${base_url}/etf/${code}`, config)
        .then((res) => {
          this.setState({
            data: res.data.item
          });
          code = code.replaceAll('/', ' ');
          document.getElementById('code').value = code;
          Toast.success('Success', 2000, () => { });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  async componentDidMount() {
    const search = window.location.search;
    const code = search.replaceAll('?code=', '').replaceAll('?Code=', '').replaceAll('%20', ' ');

    if (code === '') return;
    Toast.loading('In Progress', () => { });
    await this.fetchData(code);
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="container p-3 my-3 border">
          <div className="container input-group mb-3">
            <input
              type="text"
              id="code"
              value={this.state.code}
              className="form-control"
              placeholder="Type Something like AAPL US EQUITY ...."
              onChange={this.fetchSuggestions}
              autoComplete="off"
            />
            <div className="input-group-append">
              <button className="btn btn-success" type="submit" onClick={this.handleSubmit}>
                Search
              </button>
            </div>
          </div>
          <div className="dropdown">
            <ul className="list-group">
              {this.state.suggestions.map((value, index) => {
                return (
                  <li
                    className="list-group-item list-group-flush"
                    onClick={this.selectedScrip}
                    id={value.code}
                    key={index}
                  >
                    {value.code}
                    &nbsp; ({value.name}) &nbsp;
                    <img src={Flags[value.country_iso]}></img>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>

        {this.state.data != null && (
          <table className="table table-border">
            <tbody>
              <tr className="">
                <td
                  style={{
                    backgroundColor: '#002850',
                    color: '#ffffff',
                    textDecoration: 'none',
                    border: '1px solid white'
                  }}
                >
                  <div className="row">
                    <div className="col-sm-8">
                      <h3> {this.state.data.name}</h3>
                      <strong>Type: {this.state.data.type ? this.state.data.type : 'N.A.'}</strong>
                      <br />
                      <strong>ISIN: {this.state.data.isin ? this.state.data.isin : 'N.A.'}</strong>
                    </div>
                    <div className="col-sm-4">
                      <strong>
                        Currency:
                        {this.state.data.currency_code ? this.state.data.currency_code : 'N.A.'}
                      </strong>
                      <br />
                      <strong>
                        NAV:
                        {this.state.data.nav
                          ? this.state.data.nav.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2
                          })
                          : 'N.A.'}
                      </strong>
                      <br />
                      <strong>
                        Update Date:
                        {this.state.data.last_updated
                          ? dayjs(this.state.data.last_updated).format('DD/MM/YY')
                          : 'N.A.'}
                      </strong>
                    </div>
                  </div>
                </td>
              </tr>
              <tr className="table-dark">
                <td>
                  <p>
                    <strong>Summary: </strong> {this.state.data.description}
                  </p>
                </td>
              </tr>
              <tr className="table">
                <td>
                  <center>
                    <strong>Chart</strong>
                  </center>
                </td>
              </tr>
              <tr>
                <Chart
                  options={{
                    chart: {
                      height: 350,
                      type: 'area'
                    },
                    dataLabels: {
                      enabled: false
                    },
                    tooltip: {
                      enabled: true
                    },
                    xaxis: {
                      type: 'datetime',
                      labels: {
                        formatter: function (val) {
                          return dayjs(val).format('MMM YY');
                        }
                      }
                    },
                    yaxis: {
                      tooltip: {
                        enabled: true
                      }
                    }
                  }}
                  series={[
                    {
                      name: this.state.data.name,
                      data: this.state.data.eod
                    }
                  ]}
                  type="area"
                  height={350}
                />
              </tr>
              <tr
                style={{
                  backgroundColor: '#002850',
                  color: '#ffffff',
                  textDecoration: 'none',
                  border: '1px solid white'
                }}
              >
                <td>
                  <center>
                    <strong>Performance and Basic Details</strong>
                  </center>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="row">
                    <div className="col-sm-6">
                      <ul className="list-group">
                        <li className="list-group-item">
                          <strong>
                            Yield: {this.state.data.yield ? this.state.data.yield : '-'}
                          </strong>
                        </li>
                        <li className="list-group-item">
                          <strong>
                            Volatility 1y:
                            {this.state.data.volatility_1y ? this.state.data.volatility_1y : '-'}
                          </strong>
                        </li>
                        <li className="list-group-item">
                          <strong>
                            Colatility 3y:
                            {this.state.data.volatility_3y ? this.state.data.volatility_3y : '-'}
                          </strong>
                        </li>
                        <li className="list-group-item">
                          <strong>
                            Expected Return 3y:
                            {this.state.data.exp_return_3y ? this.state.data.exp_return_3y : '-'}
                          </strong>
                        </li>
                        <li className="list-group-item">
                          <strong>
                            Shape Ratio 3y:
                            {this.state.data.sharp_ratio_3y ? this.state.data.sharp_ratio_3y : '-'}
                          </strong>
                        </li>
                        <li className="list-group-item">
                          <strong>
                            Returns Ytd:
                            {this.state.data.returns_ytd ? this.state.data.returns_ytd : '-'}
                          </strong>
                        </li>
                        <li className="list-group-item">
                          <strong>
                            Returns 1 year:
                            {this.state.data.returns_1y ? this.state.data.returns_1y : '-'}
                          </strong>
                        </li>
                        <li className="list-group-item">
                          <strong>
                            Returns 3 year:
                            {this.state.data.returns_3y ? this.state.data.returns_3y : '-'}
                          </strong>
                        </li>
                        <li className="list-group-item">
                          <strong>
                            Returns 5 year:
                            {this.state.data.returns_5y ? this.state.data.returns_5y : '-'}
                          </strong>
                        </li>
                        <li className="list-group-item">
                          <strong>
                            Returns 10 Year:
                            {this.state.data.returns_10y ? this.state.data.returns_10y : '-'}
                          </strong>
                        </li>
                      </ul>
                    </div>
                    <div className="col-sm-6">
                      <ul className="list-group">
                        <li className="list-group-item">
                          <strong>
                            Portfolio Net Asset:
                            {this.state.data.total_assets
                              ? this.state.data.total_assets.toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                              })
                              : '-'}
                          </strong>
                        </li>
                        <li className="list-group-item">
                          <strong>
                            Inception Date:
                            {this.state.data.inception_date
                              ? this.state.data.inception_date.toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                              })
                              : '-'}
                          </strong>
                        </li>
                        <li className="list-group-item">
                          <strong>
                            Domicile:
                            {this.state.data.domacile
                              ? this.state.data.domacile.toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                              })
                              : '-'}
                          </strong>
                        </li>
                        <li className="list-group-item">
                          <strong>
                            Total holding:
                            {this.state.data.holdings_count
                              ? this.state.data.holdings_count.toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                              })
                              : '-'}
                          </strong>
                        </li>
                        <li className="list-group-item">
                          <strong>
                            Beta:
                            {this.state.data.beta
                              ? this.state.data.beta.toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                              })
                              : '-'}
                          </strong>
                        </li>
                        <li className="list-group-item">
                          <strong>
                            52W High/Low:
                            {this.state.data.week_52_high && this.state.data.week_52_low
                              ? this.state.data.week_52_high.toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                              }) +
                              '/' +
                              this.state.data.week_52_low.toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                              })
                              : '-'}
                          </strong>
                        </li>

                        <li className="list-group-item">
                          <strong>
                            Net Expense Ratio:
                            {this.state.data.net_expense_ratio
                              ? this.state.data.net_expense_ratio.toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                              })
                              : '-'}
                          </strong>
                        </li>
                        <li className="list-group-item">
                          <strong>
                            Annual Holdings Turnover:
                            {this.state.data.annual_holdings_turnover
                              ? this.state.data.annual_holdings_turnover.toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                              })
                              : '-'}
                          </strong>
                        </li>
                        <li className="list-group-item">
                          <strong>
                            Average Mkt Cap Mil:
                            {this.state.data.average_mkt_cap_mil
                              ? this.state.data.average_mkt_cap_mil.toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                              })
                              : '-'}
                          </strong>
                        </li>
                        <li className="list-group-item">
                          <strong>
                            Dividend Paying Frequency:
                            {this.state.data.dividend_paying_frequency
                              ? this.state.data.dividend_paying_frequency.toLocaleString(
                                undefined,
                                { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                              )
                              : '-'}
                          </strong>
                        </li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr
                style={{
                  backgroundColor: '#002850',
                  color: '#ffffff',
                  textDecoration: 'none',
                  border: '1px solid white'
                }}
              >
                <td>
                  <center>
                    <strong>Rating</strong>
                  </center>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="col-sm-6">
                    <ul className="list-group">
                      <li className="list-group-item">
                        <strong>
                          Morning Star Ratio:
                          {this.state.data.morning_star_ratio
                            ? this.state.data.morning_star_ratio
                            : '-'}
                        </strong>
                      </li>
                      <li className="list-group-item">
                        <strong>
                          Morning Star Category Benchmark Rating:
                          {this.state.data.morning_star_category_benchmark
                            ? this.state.data.morning_star_category_benchmark
                            : '-'}
                        </strong>
                      </li>
                      <li className="list-group-item">
                        <strong>
                          Morning Star Sustainability Ratio:
                          {this.state.data.morning_star_sustainability_ratio
                            ? this.state.data.morning_star_sustainability_ratio
                            : '-'}
                        </strong>
                      </li>
                    </ul>
                  </div>
                  {/* {
                                    Object.keys(this.state.data.Sector_Weights).map((weight, index) => {
                                        return (
                                            <>
                                                <tr className="table-secondary">
                                                    <td colSpan="4"><center>{weight}</center></td>
                                                </tr>
                                                {
                                                    this.state.data.Sector_Weights[weight].map((value, index) => {
                                                        console.log(value);
                                                        return (
                                                            <tr>
                                                                <td>{value.Type}</td>
                                                                <td>{value.Category_Average}</td>
                                                                <td>{value["Amount_%"]}</td>
                                                                <td>{value.Benchmark}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </>

                                        )
                                    })
                                } */}
                </td>
              </tr>
              <tr
                style={{
                  backgroundColor: '#002850',
                  color: '#ffffff',
                  textDecoration: 'none',
                  border: '1px solid white'
                }}
              >
                <td>
                  <center>
                    <strong>Value Growth Portfolio</strong>
                  </center>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="row">
                    <div className="col-sm-6">
                      <h5>Growth Rates Portfolio:</h5>
                      <table className="table">
                        <tbody>
                          <tr className="table-dark">
                            <th>Name</th>
                            <th>Value</th>
                          </tr>
                          <tr>
                            <th>Long-Term Earnings %</th>
                            <th>
                              {this.state.data.grp_long_term_projected_earnings_growth &&
                                this.state.data.grp_long_term_projected_earnings_growth.toLocaleString(
                                  undefined,
                                  { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                )}
                              %
                            </th>
                          </tr>
                          <tr>
                            <th>Historical Earnings %</th>
                            <th>
                              {this.state.data.grp_historical_earnings_growth &&
                                this.state.data.grp_historical_earnings_growth.toLocaleString(
                                  undefined,
                                  { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                )}
                              %
                            </th>
                          </tr>
                          <tr>
                            <th>Sales Growth %</th>
                            <th>
                              {this.state.data.grp_sales_growth &&
                                this.state.data.grp_sales_growth.toLocaleString(undefined, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2
                                })}
                              %
                            </th>
                          </tr>
                          <tr>
                            <th>Cash-Flow Growth %</th>
                            <th>
                              {this.state.data.grp_cash_flow_growth &&
                                this.state.data.grp_cash_flow_growth.toLocaleString(undefined, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2
                                })}
                              %
                            </th>
                          </tr>
                          <tr>
                            <th>Book-Value Growth %</th>
                            <th>
                              {this.state.data.grp_book_value_growth &&
                                this.state.data.grp_book_value_growth.toLocaleString(undefined, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2
                                })}
                              %
                            </th>
                          </tr>
                          <tr>
                            <th>Price/Prospective Earnings</th>
                            <th>
                              {this.state.data.price_prospective_earnings &&
                                this.state.data.price_prospective_earnings.toLocaleString(
                                  undefined,
                                  { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                )}
                            </th>
                          </tr>
                          <tr>
                            <th>Price/Book</th>
                            <th>
                              {this.state.data.price_book &&
                                this.state.data.price_book.toLocaleString(undefined, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2
                                })}
                            </th>
                          </tr>
                          <tr>
                            <th>Price/Sales</th>
                            <th>
                              {this.state.data.price_sales &&
                                this.state.data.price_sales.toLocaleString(undefined, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2
                                })}
                            </th>
                          </tr>
                          <tr>
                            <th>Price/Cash Flow</th>
                            <th>
                              {this.state.data.price_cash_flow &&
                                this.state.data.price_cash_flow.toLocaleString(undefined, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2
                                })}
                            </th>
                          </tr>
                          <tr>
                            <th>Dividend Yield %</th>
                            <th>
                              {this.state.data.dividend_yield_factor &&
                                this.state.data.dividend_yield_factor.toLocaleString(undefined, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2
                                })}
                              %
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-sm-6">
                      <h5>Growth Rates Category:</h5>
                      <table className="table">
                        <tbody>
                          <tr className="table-dark">
                            <th>Name</th>
                            <th>Value</th>
                          </tr>
                          <tr>
                            <th>Long-Term Earnings %</th>
                            <th>
                              {this.state.data.grc_long_term_projected_earnings_growth &&
                                this.state.data.grc_long_term_projected_earnings_growth.toLocaleString(
                                  undefined,
                                  { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                )}
                              %
                            </th>
                          </tr>
                          <tr>
                            <th>Historical Earnings %</th>
                            <th>
                              {this.state.data.grc_historical_earnings_growth &&
                                this.state.data.grc_historical_earnings_growth.toLocaleString(
                                  undefined,
                                  { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                )}
                              %
                            </th>
                          </tr>
                          <tr>
                            <th>Sales Growth %</th>
                            <th>
                              {this.state.data.grc_sales_growth &&
                                this.state.data.grc_sales_growth.toLocaleString(undefined, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2
                                })}
                              %
                            </th>
                          </tr>
                          <tr>
                            <th>Cash-Flow Growth %</th>
                            <th>
                              {this.state.data.grc_cash_flow_growth &&
                                this.state.data.grc_cash_flow_growth.toLocaleString(undefined, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2
                                })}
                              %
                            </th>
                          </tr>
                          <tr>
                            <th>Book-Value Growth %</th>
                            <th>
                              {this.state.data.grc_book_value_growth &&
                                this.state.data.grc_book_value_growth.toLocaleString(undefined, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2
                                })}
                              %
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </td>
              </tr>
              <tr
                style={{
                  backgroundColor: '#002850',
                  color: '#ffffff',
                  textDecoration: 'none',
                  border: '1px solid white'
                }}
              >
                <td>
                  <center>
                    <strong>Holdings</strong>
                  </center>
                </td>
              </tr>
              <tr>
                <td className="row">
                  <div className="col-sm-6">
                    <h5>Top Holdings:</h5>
                    <table className="table">
                      <tbody>
                        <tr className="table-dark">
                          <th>Code</th>
                          <th>Asset Pct</th>
                        </tr>
                        {this.state.data.etf_top_holding.map((value, index) => {
                          return (
                            <tr id={index} key={index}>
                              <td>{value.holding_code}</td>
                              <td>
                                <div className="progress">
                                  <div
                                    className="progress-bar"
                                    role="progressbar"
                                    aria-valuenow={value.assets_pct}
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                    style={{ width: value.assets_pct }}
                                  >
                                    <span className="sr-only">{value.assets_pct}</span>
                                  </div>
                                </div>
                                {value.assets_pct}%
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="col-sm-6">
                    <h5>All Holdings:</h5>
                    <table className="table">
                      <tbody>
                        <tr className="table-dark">
                          <th>Code</th>
                          <th>Asset Pct</th>
                        </tr>
                        {this.state.data.etf_holding.map((value, index) => {
                          return (
                            <tr id={index} key={index}>
                              <td>{value.holding_code}</td>
                              <td>
                                <div className="progress">
                                  <div
                                    className="progress-bar"
                                    role="progressbar"
                                    aria-valuenow={value.assets_pct}
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                    style={{ width: value.assets_pct }}
                                  >
                                    <span className="sr-only">{value.assets_pct}</span>
                                  </div>
                                </div>
                                {value.assets_pct}%
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
              <tr
                style={{
                  backgroundColor: '#002850',
                  color: '#ffffff',
                  textDecoration: 'none',
                  border: '1px solid white'
                }}
              >
                <td>
                  <center>
                    <strong>Market Cap and Asset Allocation</strong>
                  </center>
                </td>
              </tr>
              <tr>
                <td className="row">
                  <div className="col-sm-6">
                    <h5>MCap Spread:</h5>

                    <Chart
                      options={{
                        chart: {
                          width: 380,
                          type: 'pie'
                        },
                        labels: ['Mega', 'Big', 'Medium', 'Small', 'MCap Micro'],
                        responsive: [
                          {
                            breakpoint: 480,
                            options: {
                              chart: {
                                width: 200
                              },
                              legend: {
                                position: 'bottom'
                              }
                            }
                          }
                        ]
                      }}
                      series={[
                        this.state.data.mcap_mega,
                        this.state.data.mcap_big,
                        this.state.data.mcap_medium,
                        this.state.data.mcap_small,
                        this.state.data.mcap_micro
                      ]}
                      type="pie"
                      width={380}
                    />
                  </div>
                  <div className="col-sm-6">
                    <h5>Asset Allocation:</h5>
                    <table className="table">
                      <tbody>
                        <tr className="table-dark">
                          <th>Asset</th>
                          <th>Long</th>
                          <th>Short</th>
                        </tr>
                        <tr>
                          <th>US Stocks</th>
                          <th>
                            {this.state.data.assetalloc_stock_us_long_pct &&
                              this.state.data.assetalloc_stock_us_long_pct.toLocaleString(
                                undefined,
                                { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                              )}
                            %
                          </th>
                          <th>
                            {this.state.data.assetalloc_stock_us_short_pct &&
                              this.state.data.assetalloc_stock_us_short_pct.toLocaleString(
                                undefined,
                                { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                              )}
                            %
                          </th>
                        </tr>
                        <tr>
                          <th>Non US Stocks</th>
                          <th>
                            {this.state.data.assetalloc_stock_non_us_long_pct &&
                              this.state.data.assetalloc_stock_non_us_long_pct.toLocaleString(
                                undefined,
                                { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                              )}
                            %
                          </th>
                          <th>
                            {this.state.data.assetalloc_stock_non_us_short_pct &&
                              this.state.data.assetalloc_stock_non_us_short_pct.toLocaleString(
                                undefined,
                                { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                              )}
                            %
                          </th>
                        </tr>
                        <tr>
                          <th>Cash</th>
                          <th>
                            {this.state.data.assetalloc_cash_long_pct &&
                              this.state.data.assetalloc_cash_long_pct.toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                              })}
                            %
                          </th>
                          <th>
                            {this.state.data.assetalloc_cash_short_pct &&
                              this.state.data.assetalloc_cash_short_pct.toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                              })}
                            %
                          </th>
                        </tr>
                        <tr>
                          <th>Bonds</th>
                          <th>
                            {this.state.data.assetalloc_bond_long_pct &&
                              this.state.data.assetalloc_bond_long_pct.toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                              })}
                            %
                          </th>
                          <th>
                            {this.state.data.assetalloc_bond_short_pct &&
                              this.state.data.assetalloc_bond_short_pct.toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                              })}
                            %
                          </th>
                        </tr>
                        <tr>
                          <th>Not Classified</th>
                          <th>
                            {this.state.data.assetalloc_not_classified_long_pct &&
                              this.state.data.assetalloc_not_classified_long_pct.toLocaleString(
                                undefined,
                                { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                              )}
                            %
                          </th>
                          <th>
                            {this.state.data.assetalloc_not_classified_short_pct &&
                              this.state.data.assetalloc_not_classified_short_pct.toLocaleString(
                                undefined,
                                { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                              )}
                            %
                          </th>
                        </tr>
                        <tr>
                          <th>Others</th>
                          <th>
                            {this.state.data.assetalloc_other_long_pct &&
                              this.state.data.assetalloc_other_long_pct.toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                              })}
                            %
                          </th>
                          <th>
                            {this.state.data.assetalloc_other_short_pct &&
                              this.state.data.assetalloc_other_short_pct.toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                              })}
                            %
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>

              <tr
                style={{
                  backgroundColor: '#002850',
                  color: '#ffffff',
                  textDecoration: 'none',
                  border: '1px solid white'
                }}
              >
                <td>
                  <center>
                    <strong>World Region and Sector Weights</strong>
                  </center>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="row">
                    <div className="col-sm-6">
                      <h5>World Region:</h5>
                      <table className="table">
                        <tbody>
                          <tr className="table-dark">
                            <th>Region</th>
                            <th>Equity%</th>
                            <th>Relative to Catagory</th>
                          </tr>
                          <tr>
                            <td>North America</td>
                            <td>
                              {this.state.data.wr_north_america_equity_pct &&
                                this.state.data.wr_north_america_equity_pct.toLocaleString(
                                  undefined,
                                  { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                )}
                              %
                            </td>
                            <td>
                              {this.state
                                .assetalloc_stock_us_wr_north_america_relative_to_categoryshort_pct &&
                                this.state.data.assetalloc_stock_us_wr_north_america_relative_to_categoryshort_pct.toLocaleString(
                                  undefined,
                                  { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                )}
                              %
                            </td>
                          </tr>
                          <tr>
                            <td>UK</td>
                            <td>
                              {this.state.data.wr_united_kingdom_equity_pct &&
                                this.state.data.wr_united_kingdom_equity_pct.toLocaleString(
                                  undefined,
                                  { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                )}
                              %
                            </td>
                            <td>
                              {this.state.data.wr_united_kingdom_relative_to_category &&
                                this.state.data.wr_united_kingdom_relative_to_category.toLocaleString(
                                  undefined,
                                  { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                )}
                              %
                            </td>
                          </tr>
                          <tr>
                            <td>Developed Europe</td>
                            <td>
                              {this.state.data.wr_europe_developed_equity_pct &&
                                this.state.data.wr_europe_developed_equity_pct.toLocaleString(
                                  undefined,
                                  { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                )}
                              %
                            </td>
                            <td>
                              {this.state.data.wr_europe_developed_relative_to_category &&
                                this.state.data.wr_europe_developed_relative_to_category.toLocaleString(
                                  undefined,
                                  { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                )}
                              %
                            </td>
                          </tr>
                          <tr>
                            <td>Emerging Europe</td>
                            <td>
                              {this.state.data.wr_europe_emerging_equity_pct &&
                                this.state.data.wr_europe_emerging_equity_pct.toLocaleString(
                                  undefined,
                                  { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                )}
                              %
                            </td>
                            <td>
                              {this.state.data.wr_europe_emerging_relative_to_category &&
                                this.state.data.wr_europe_emerging_relative_to_category.toLocaleString(
                                  undefined,
                                  { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                )}
                              %
                            </td>
                          </tr>
                          <tr>
                            <td>Middle East Africa</td>
                            <td>
                              {this.state.data.wr_africa_middle_east_equity_pct &&
                                this.state.data.wr_africa_middle_east_equity_pct.toLocaleString(
                                  undefined,
                                  { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                )}
                              %
                            </td>
                            <td>
                              {this.state.data.wr_africa_middle_east_relative_to_category &&
                                this.state.data.wr_africa_middle_east_relative_to_category.toLocaleString(
                                  undefined,
                                  { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                )}
                              %
                            </td>
                          </tr>
                          <tr>
                            <td>Japan</td>
                            <td>
                              {this.state.data.wr_japan_equity_pct &&
                                this.state.data.wr_japan_equity_pct.toLocaleString(undefined, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2
                                })}
                              %
                            </td>
                            <td>
                              {this.state.data.wr_japan_relative_to_category &&
                                this.state.data.wr_japan_relative_to_category.toLocaleString(
                                  undefined,
                                  { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                )}
                              %
                            </td>
                          </tr>
                          <tr>
                            <td>AustralAsia</td>
                            <td>
                              {this.state.data.wr_australasia_equity_pct &&
                                this.state.data.wr_australasia_equity_pct.toLocaleString(
                                  undefined,
                                  { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                )}
                              %
                            </td>
                            <td>
                              {this.state.data.wr_australasia_relative_to_category &&
                                this.state.data.wr_australasia_relative_to_category.toLocaleString(
                                  undefined,
                                  { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                )}
                              %
                            </td>
                          </tr>
                          <tr>
                            <td>Developed Asia</td>
                            <td>
                              {this.state.data.wr_asia_developed_equity_pct &&
                                this.state.data.wr_asia_developed_equity_pct.toLocaleString(
                                  undefined,
                                  { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                )}
                              %
                            </td>
                            <td>
                              {this.state.data.wr_asia_developed_relative_to_category &&
                                this.state.data.wr_asia_developed_relative_to_category.toLocaleString(
                                  undefined,
                                  { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                )}
                              %
                            </td>
                          </tr>
                          <tr>
                            <td>Emerging Asia</td>
                            <td>
                              {this.state.data.wr_asia_emerging_equity_pct &&
                                this.state.data.wr_asia_emerging_equity_pct}
                              %
                            </td>
                            <td>
                              {this.state.data.wr_asia_emerging_relative_to_category &&
                                this.state.data.wr_asia_emerging_relative_to_category}
                              %
                            </td>
                          </tr>
                          <tr>
                            <td>Latin America</td>
                            <td>
                              {this.state.data.wr_latin_america_equity_pct &&
                                this.state.data.wr_latin_america_equity_pct}
                              %
                            </td>
                            <td>
                              {this.state.data.wr_latin_america_relative_to_category &&
                                this.state.data.wr_latin_america_relative_to_category}
                              %
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-sm-6">
                      <h5>Sector Weights:</h5>
                      <table className="table">
                        <tbody>
                          <tr className="table-dark">
                            <th>Sector</th>
                            <th>Equity%</th>
                            <th>Relative to Catagory</th>
                          </tr>
                          <tr>
                            <td>BASIC MATERIALS</td>
                            <td>
                              {this.state.data.sw_basic_materials_equity_pct &&
                                this.state.data.sw_basic_materials_equity_pct.toLocaleString(
                                  undefined,
                                  { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                )}
                              %
                            </td>
                            <td>
                              {this.state.data.sw_basic_materials_relative_to_category &&
                                this.state.data.sw_basic_materials_relative_to_category.toLocaleString(
                                  undefined,
                                  { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                )}
                              %
                            </td>
                          </tr>
                          <tr>
                            <td>CONSUMER CYCLICALS</td>
                            <td>
                              {this.state.data.sw_consumer_cyclicals_equity_pct &&
                                this.state.data.sw_consumer_cyclicals_equity_pct.toLocaleString(
                                  undefined,
                                  { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                )}
                              %
                            </td>
                            <td>
                              {this.state.data.sw_consumer_cyclicals_relative_to_category &&
                                this.state.data.sw_consumer_cyclicals_relative_to_category.toLocaleString(
                                  undefined,
                                  { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                )}
                              %
                            </td>
                          </tr>
                          <tr>
                            <td>FINANCIAL SERVICES</td>
                            <td>
                              {this.state.data.sw_financial_services_equity_pct &&
                                this.state.data.sw_financial_services_equity_pct.toLocaleString(
                                  undefined,
                                  { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                )}
                              %
                            </td>
                            <td>
                              {this.state.data.sw_financial_services_relative_to_category &&
                                this.state.data.sw_financial_services_relative_to_category.toLocaleString(
                                  undefined,
                                  { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                )}
                              %
                            </td>
                          </tr>
                          <tr>
                            <td>REAL ESTATE</td>
                            <td>
                              {this.state.data.sw_real_estate_equity_pct &&
                                this.state.data.sw_real_estate_equity_pct.toLocaleString(
                                  undefined,
                                  { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                )}
                              %
                            </td>
                            <td>
                              {this.state.data.sw_real_estate_relative_to_category &&
                                this.state.data.sw_real_estate_relative_to_category.toLocaleString(
                                  undefined,
                                  { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                )}
                              %
                            </td>
                          </tr>
                          <tr>
                            <td>COMMUNICATION SERVICES</td>
                            <td>
                              {this.state.data.sw_communication_services_equity_pct &&
                                this.state.data.sw_communication_services_equity_pct.toLocaleString(
                                  undefined,
                                  { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                )}
                              %
                            </td>
                            <td>
                              {this.state.data.sw_communication_services_relative_to_category &&
                                this.state.data.sw_communication_services_relative_to_category.toLocaleString(
                                  undefined,
                                  { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                )}
                              %
                            </td>
                          </tr>
                          <tr>
                            <td>ENERGY</td>
                            <td>
                              {this.state.data.sw_energy_equity_pct &&
                                this.state.data.sw_energy_equity_pct.toLocaleString(undefined, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2
                                })}
                              %
                            </td>
                            <td>
                              {this.state.data.sw_energy_relative_to_category &&
                                this.state.data.sw_energy_relative_to_category.toLocaleString(
                                  undefined,
                                  { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                )}
                              %
                            </td>
                          </tr>
                          <tr>
                            <td>INDUSTRIALS</td>
                            <td>
                              {this.state.data.sw_industrials_equity_pct &&
                                this.state.data.sw_industrials_equity_pct.toLocaleString(
                                  undefined,
                                  { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                )}
                              %
                            </td>
                            <td>
                              {this.state.data.sw_industrials_relative_to_category &&
                                this.state.data.sw_industrials_relative_to_category.toLocaleString(
                                  undefined,
                                  { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                )}
                              %
                            </td>
                          </tr>
                          <tr>
                            <td>TECHNOLOGY</td>
                            <td>
                              {this.state.data.sw_technology_equity_pct &&
                                this.state.data.sw_technology_equity_pct.toLocaleString(undefined, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2
                                })}
                              %
                            </td>
                            <td>
                              {this.state.data.sw_technology_relative_to_category &&
                                this.state.data.sw_technology_relative_to_category.toLocaleString(
                                  undefined,
                                  { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                )}
                              %
                            </td>
                          </tr>
                          <tr>
                            <td>CONSUMER DEFENSIVE</td>
                            <td>
                              {this.state.data.sw_consumer_defensive_equity_pct &&
                                this.state.data.sw_consumer_defensive_equity_pct.toLocaleString(
                                  undefined,
                                  { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                )}
                              %
                            </td>
                            <td>
                              {this.state.data.sw_consumer_defensive_relative_to_category &&
                                this.state.data.sw_consumer_defensive_relative_to_category.toLocaleString(
                                  undefined,
                                  { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                )}
                              %
                            </td>
                          </tr>
                          <tr>
                            <td>HEALTHCARE</td>
                            <td>
                              {this.state.data.sw_healthcare_equity_pct &&
                                this.state.data.sw_healthcare_equity_pct.toLocaleString(undefined, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2
                                })}
                              %
                            </td>
                            <td>
                              {this.state.data.sw_healthcare_relative_to_category &&
                                this.state.data.sw_healthcare_relative_to_category.toLocaleString(
                                  undefined,
                                  { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                )}
                              %
                            </td>
                          </tr>
                          <tr>
                            <td>UTILITIES</td>
                            <td>
                              {this.state.data.sw_utilities_equity_pct &&
                                this.state.data.sw_utilities_equity_pct.toLocaleString(undefined, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2
                                })}
                              %
                            </td>
                            <td>
                              {this.state.data.sw_utilities_relative_to_category &&
                                this.state.data.sw_utilities_relative_to_category.toLocaleString(
                                  undefined,
                                  { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                )}
                              %
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        )}

        <button className="btn btn-primary" onClick={this.gotoReportPage}>
          Go Back
        </button>
      </div>
    );
  }
}

export default EtfPage;
