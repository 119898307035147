import React, { Component } from 'react';
import { AdvancedRealTimeChart } from 'react-ts-tradingview-widgets';

class TradingView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      symbol: 'NASDAQ:AAPL'
    };
  }

  componentDidMount() { }

  render() {
    return (
      <AdvancedRealTimeChart
        theme="dark"
        height={720}
        width={1500}
        interval={false}
      ></AdvancedRealTimeChart>
    );
  }
}

export default TradingView;
