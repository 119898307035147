import axios from 'axios';
import Toast from 'light-toast';
import React, { Component } from 'react';
import { FaPaperclip } from 'react-icons/fa';

import { fetchAuthSession } from 'aws-amplify/auth';

import cookies from '../utils/cookies.util';
import base_url from '../utils/request.util';

class NewsData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alreadyPinnedNews: false
    };
    this.fetchNews = this.fetchNews.bind(this);
    this.pinNews = this.pinNews.bind(this);
    this.unPinNews = this.unPinNews.bind(this);
  }

  async fetchNews(id) {
    Toast.loading('In Progress');

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      axios
        .get(`${base_url}/product-news-by-id/${id}`, config)
        .then((res) => {
          this.setState(
            {
              data: res.data.item
            },
            async () => {
              await fetchAuthSession().then(() => {
                const config = {};
                config.headers = {
                  'Authorization': cookies.get('Authorization'),
                  'User-Specified': cookies.get('User-Specified'),
                  'Type-Specified': cookies.get('Type-Specified')
                };
                axios
                  .get(`${base_url}/pinned-product-news`, config)
                  .then((res) => {
                    res.data.items.forEach((element) => {
                      if (this.state.data.id === element.id) {
                        this.setState({
                          alreadyPinnedNews: true
                        });
                      }
                    });
                  })
                  .catch((error) => {
                    return [];
                  });
              });
            }
          );
          Toast.success('Success', 500, () => { });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  async pinNews(link) {
    Toast.loading('In Progress');

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      axios
        .post(`${base_url}/pinned-product-news`, { link }, config)
        .then((res) => {
          this.setState({
            alreadyPinnedNews: true
          });
          Toast.success('Success', 500, () => { });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  async unPinNews(link) {
    Toast.loading('In Progress');

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      config.data = { link };
      axios
        .delete(`${base_url}/pinned-product-news`, config)
        .then((res) => {
          this.setState({
            alreadyPinnedNews: false
          });
          Toast.success('Success', 500, () => { });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  async componentDidMount() {
    const pathname = window.location.pathname;
    const news_id = pathname.replaceAll('/product/news/', '');

    if (news_id === '') return;

    await this.fetchNews(news_id);
  }

  render() {
    return (
      this.state.data != null && (
        <div className="container">
          <div className="card">
            <div className="card-header">
              <h5 className="card-link">{this.state.data.title}</h5>
            </div>
            <div>
              <div className="card-body">{this.state.data.content}</div>
              <div className="card-footer">
                <a className="btn btn-link" href={this.state.data.link}>
                  Open Link
                </a>
                {this.state.data.symbols.map((symbol, index) => {
                  return (
                    <>
                      <span className="badge badge-primary badge-pill">{symbol}</span>
                      &nbsp;
                    </>
                  );
                })}
                {this.state.alreadyPinnedNews ? (
                  <button
                    style={{ float: 'right' }}
                    className="btn btn-success"
                    onClick={() => this.unPinNews(this.state.data.link)}
                  >
                    Unpin the news
                  </button>
                ) : (
                  <button
                    style={{ float: 'right' }}
                    className="btn btn-success"
                    onClick={() => this.pinNews(this.state.data.link)}
                  >
                    <FaPaperclip /> Pin this news
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )
    );
  }
}

export default NewsData;
