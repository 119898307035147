import React, { Component } from 'react';



class Unauthorized extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="container">
        <div className="jumbotron">
          <h1>Error Page</h1>
          <p>Page not found, please try to use navigation bar to route to other pages</p>
        </div>
      </div>
    );
  }
}

export default Unauthorized;
