import axios from 'axios';
import Toast from 'light-toast';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './styles.css';

import { fetchAuthSession } from 'aws-amplify/auth';
import dayjs from 'dayjs';
import { FaTrash } from 'react-icons/fa';

import cookies from '../utils/cookies.util';
import base_url from '../utils/request.util';

class UserPortfolioMapping extends Component {
  constructor(props) {
    super(props);
    this.state = {
      portfolio_mappings: []
    };

    this.handleDeletePorffolio = this.handleDeletePorffolio.bind(this);
    this.handleDeleteUser = this.handleDeleteUser.bind(this);
  }

  async handleDeletePorffolio(portfolio_id) {
    Toast.loading('In Progress', () => { });

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      config.data = { email: cookies.get('User-Specified') };

      axios
        .delete(`${base_url}/portfolio/${portfolio_id}`, config)
        .then((res) => {
          axios
            .get(`${base_url}/portfolio-user-mapping`, config)
            .then((res) => {
              this.setState({
                portfolio_mappings: res.data.items
              });
              Toast.success('Success', 1000, () => { });
            })
            .catch((error) => {
              console.log(error);
              Toast.fail('Error Performing Action', 1000);
            });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 1000);
        });
    });
  }

  async handleDeleteUser(user_id) {
    Toast.loading('In Progress', () => { });

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      config.data = { email: cookies.get('User-Specified') };

      axios
        .delete(`${base_url}/user/${user_id}`, config)
        .then((res) => {
          config.data = { email: cookies.get('User-Specified') };

          axios
            .get(`${base_url}/portfolio-user-mapping`, config)
            .then((res) => {
              this.setState({
                portfolio_mappings: res.data.items
              });
              Toast.loading('In Progress', () => { });
            })
            .catch((error) => {
              console.log(error);
              Toast.fail('Error Performing Action', 1000);
            });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 1000);
        });
    });
  }

  async componentDidMount() {
    Toast.loading('In Progress', () => { });

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      config.data = { email: cookies.get('User-Specified') };

      axios
        .get(`${base_url}/portfolio-user-mapping`, config)
        .then((res) => {
          this.setState({
            portfolio_mappings: res.data.items
          });
          Toast.success('Success', 1000, () => { });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 1000);
        });
    });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="container row">
          <h1>Portfolio mappings:</h1>
        </div>
        <table className="table table-sm">
          <thead className="table-dark">
            <tr>
              <th>&nbsp;</th>
              <th>&nbsp;</th>
              <th>Portfolio</th>
              <th>Name</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.portfolio_mappings.map((mappingData, index) => {
              return (
                <>
                  <tr key={index}>
                    <td
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                      rowSpan={mappingData.portfolios.length + 1}
                    >
                      <Link to={`/user/update?user_id=${mappingData.user_id}`}>
                        {mappingData.user_id}
                      </Link>
                      <br />
                      Type: {mappingData.user_type != null ? mappingData.user_type : '-'}
                      <br />
                      Name: {mappingData.name != null ? mappingData.name : '-'}
                      <br />
                      Email: {mappingData.email != null ? mappingData.email : '-'}
                      <br />
                      Created on:
                      {mappingData.date_created != null
                        ? dayjs(mappingData.date_created).format('DD MMM YY')
                        : '-'}
                      <br />
                      <Link to={`/portfolio/ledger?clientId=${mappingData.user_id}`}>
                        View Ledger
                      </Link>
                    </td>
                    <td
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                      rowSpan={mappingData.portfolios.length + 1}
                    >
                      <a
                        className="btn btn-secondary"
                        data-dismiss="modal"
                        onClick={() => this.handleDeleteUser(mappingData.user_id)}
                      >
                        Archive User
                      </a>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  {mappingData.portfolios.map((portfolioData, index) => {
                    return (
                      <tr>
                        <td>
                          {portfolioData.portfolio_id != null ? portfolioData.portfolio_id : '-'}
                        </td>
                        <td>{portfolioData.name != null ? portfolioData.name : '-'}</td>
                        <td>
                          <a
                            className="btn btn-danger"
                            data-dismiss="modal"
                            onClick={() => this.handleDeletePorffolio(portfolioData.portfolio_id)}
                          >
                            <FaTrash />
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default UserPortfolioMapping;
