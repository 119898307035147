import axios from 'axios';
import Toast from 'light-toast';
import React, { Component } from 'react';

import { fetchAuthSession } from 'aws-amplify/auth';

import cookies from '../utils/cookies.util';
import base_url from '../utils/request.util';

class updatePurchase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PortfolioId: null,
      Type: null,
      ISINCode: null,
      Quantity: null,
      CostPrice: null,
      Date: null,
      Status: 'Complete',
      FinalAssetClass: 'Equity',
      FinalAssetSubClass: null
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setPurchaseData = this.setPurchaseData.bind(this);
    this.resetPurchaseData = this.resetPurchaseData.bind(this);
    this.deletePurchaseData = this.deletePurchaseData.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
  }

  async handleSubmit(event) {
    Toast.loading('In Progress', () => { });

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };

      axios
        .post(`${base_url}/portfolio-consolidation/client/trade`, this.state, config)
        .then((res) => {
          Toast.success('Success', 1000, () => {
            window.location.href = '/portfolio/all';
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  async setPurchaseData() {
    const search = window.location.search;
    const params = search
      .replaceAll('?', '')
      .replaceAll('PortfolioId=', '')
      .replaceAll('ISIN=', '')
      .split('&');
    const portFolioId = params[0];
    const ISINCode = params[1];
    ISINCode.replaceAll(' ', '/');

    if (ISINCode === '') return;
    if (portFolioId === '') return;

    Toast.loading('In Progress', () => { });

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      axios
        .get(
          `${base_url}/portfolio-consolidation/client/trade?PortfolioId=${portFolioId}&ISINCode=${ISINCode}&Classification=false`,
          config
        )
        .then((res) => {
          const data = res.data.portFolioData[0];
          this.setState({
            PortfolioId: data.PortfolioID,
            Type: data.Type,
            ISINCode: data.ISINCode,
            Quantity: data.Quantity,
            CostPrice: data.CostPrice,
            Date: data.Date,
            Status: data.Status,
            FinalAssetClass: data.FinalAssetClass == null ? 'Unassigned' : data.FinalAssetClass,
            FinalAssetSubClass: data.FinalAssetSubClass
          });

          Toast.success('Success', 3000, () => { });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  resetPurchaseData() {
    this.setPurchaseData();
  }

  componentDidMount() {
    this.setPurchaseData();
  }

  async deletePurchaseData() {
    Toast.loading('In Progress', () => { });

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      axios
        .delete(
          `${base_url}/portfolio-consolidation/client/trade?`,
          {
            data: {
              PortfolioId: this.state.PortfolioId,
              ISINCode: this.state.ISINCode
            }
          },
          config
        )
        .then((res) => {
          Toast.success('Success', 3000, () => {
            window.location.href = '/portfolio-consolidation/view-portfolios';
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  render() {
    return (
      <div className="container">
        <div className="container hidden" id="portfolioData">
          <div className="form-group">
            <label>PortfolioId:</label>
            <input
              type="text"
              value={this.state.PortfolioId}
              onChange={this.handleChange}
              className="form-control"
              required
              id="PortfolioId"
            />
          </div>
          <div className="form-group">
            <label>Type:</label>
            <select
              value={this.state.Type}
              onChange={this.handleChange}
              className="form-control"
              required
              id="Type"
            >
              <option>BUY</option>
              <option>SELL</option>
            </select>
          </div>
          <div className="form-group">
            <label>Final Asset Class:</label>
            <select
              className="form-control"
              required
              id="FinalAssetClass"
              value={this.state.FinalAssetClass}
              onChange={this.handleChange}
            >
              <option>equity</option>
              <option>structured product</option>
              <option>fixed income</option>
              <option>forex</option>
              <option>etf</option>
              <option>fixed advance</option>
              <option>funds</option>
              <option>bond</option>
              <option>commodity</option>
              <option>cryptocurrency</option>
              <option>futures, options and warrants</option>
              <option>unassigned</option>
            </select>
          </div>
          {this.state.FinalAssetClass === 'Structured Products' && (
            <div className="form-group">
              <label>Final Asset Sub Class:</label>
              <select
                className="form-control"
                required
                id="FinalAssetSubClass"
                value={this.state.FinalAssetSubClass}
                onChange={this.handleChange}
              >
                <option>equity</option>
                <option>indices</option>
                <option>etfs</option>
                <option>bonds</option>
                <option>fixed advance</option>
                <option>cash</option>
                <option>unassigned</option>
              </select>
            </div>
          )}

          <div className="form-group">
            {this.state.FinalAssetClass === 'Equity' || this.state.FinalAssetClass === 'Forex' ? (
              <label>Code:</label>
            ) : (
              <label>ISIN Code:</label>
            )}
            <input
              type="text"
              value={this.state.ISINCode}
              onChange={this.handleChange}
              className="form-control"
              required
              id="ISINCode"
            />
          </div>
          <div className="form-group">
            <label>Quantities:</label>
            <input
              type="text"
              value={this.state.Quantity}
              onChange={this.handleChange}
              className="form-control"
              required
              id="Quantity"
            />
          </div>
          <div className="form-group">
            <label>Cost Price:</label>
            <input
              type="text"
              value={this.state.CostPrice}
              onChange={this.handleChange}
              className="form-control"
              required
              id="CostPrice"
            />
          </div>
          <div className="form-group">
            <label>Date:</label>
            <input
              type="date"
              value={this.state.Date}
              onChange={this.handleChange}
              className="form-control"
              required
              id="Date"
            />
          </div>
          <div className="btn-group">
            <a className="btn btn-danger" onClick={this.resetPurchaseData}>
              Discard
            </a>
            <a className="btn btn-primary" onClick={this.handleSubmit}>
              Update
            </a>
            <a className="btn btn-danger" onClick={this.deletePurchaseData}>
              Delete
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default updatePurchase;
