import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';
import Toast from 'light-toast';
import React, { Component } from 'react';
import createPlotlyComponent from 'react-plotly.js/factory';
import Flags from './flags';

import cookies from '../utils/cookies.util';
import base_url from '../utils/request.util';

const Plotly = window.Plotly;
const Plot = createPlotlyComponent(Plotly);

function unpack(rows, key) {
  return rows.map(function (row) {
    return row[key];
  });
}

class OptionGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Options: {},
      Expiries: [],
      Strikes: [],
      suggestions: [],
      CurrentAttribute: 'openInterest'
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fetchSuggestions = this.fetchSuggestions.bind(this);
    this.selectedScrip = this.selectedScrip.bind(this);
  }

  async fetchSuggestions(event) {
    if (event.target.value === '') return;

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      config.params = {
        q: event.target.value
      };
      axios
        .get(`${base_url}/product/suggest`, config)
        .then((res) => {
          this.setState({
            suggestions: res.data.items
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  selectedScrip(event) {
    const scrip = event.target.id;
    document.getElementById('code').value = scrip;
    Toast.hide();
    this.setState((prevState) => ({
      code: scrip,
      suggestions: []
    }));
  }

  handleChange(event) { }

  async handleSubmit() {
    Toast.loading('In Progress', () => { });

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      axios.get(`${base_url}/option-matrix/${this.state.code}`, config).then((res) => {
        Toast.success('Success', 1000, () => { });
        this.setState({
          Options: res.data.item.Options,
          Expiries: res.data.item.Expiries,
          Strikes: res.data.item.Strikes
        });
        const x = [];
        const y = [];
        const z = [];
        this.state.Strikes.map((StrikePrice) => {
          const row = [];
          this.state.Expiries.map((Expiry) => {
            if (this.state.Options[StrikePrice][Expiry]) {
              const type =
                this.state.Options[StrikePrice][Expiry].CALL.inTheMoney === 'TRUE' ? 'CALL' : 'PUT';
              x.push(StrikePrice);
              y.push(row.push(this.state.Options[StrikePrice][Expiry][type].daysBeforeExpiration));
              z.push(row.push(this.state.Options[StrikePrice][Expiry][type].impliedVolatility));
            }
          });
        });
        const data = [
          {
            z: [x, y, z],
            type: 'surface'
          }
        ];

        console.log(data);

        const layout = {
          scene: {
            xaxis: { title: 'Strike Price' },
            yaxis: { title: 'Days Before Expiration' },
            zaxis: { title: 'Implied Volatility' }
          },
          title: 'Surface Charts',
          autosize: false,
          width: 1000,
          height: 700,
          margin: {
            l: 65,
            r: 50,
            b: 65,
            t: 90
          }
        };
        Plotly.newPlot('Chart', data, layout);
      });
    });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="container p-3 my-3 border">
          <div className="container input-group mb-3">
            <input
              type="text"
              autoComplete="off"
              placeholder="Enter Scrip Name to add"
              className="form-control"
              id="code"
              onChange={this.fetchSuggestions}
            />
            <div className="input-group-append">
              <button className="btn btn-success" onClick={this.handleSubmit}>
                Search
              </button>
            </div>
          </div>
          <div className="dropdown">
            <ul className="list-group">
              {this.state.suggestions.map((value, index) => {
                return (
                  <li
                    className="list-group-item list-group-flush"
                    onClick={this.selectedScrip}
                    id={value.code}
                    key={index}
                  >
                    {value.code}
                    &nbsp; ({value.name}) &nbsp;
                    <img src={Flags[value.country_iso]}></img>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div id="Chart"></div>
      </div>
    );
  }
}

export default OptionGraph;
