import React, { Component } from 'react';
import dayjs from 'dayjs';
import Chart from 'react-apexcharts';

class TradingChart extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const dateFormat = this.props.dateFormat;
    return (
      <Chart
        options={{
          chart: {
            height: 350,
            type: 'area'
          },
          title: {
            text: `${this.props.name} ${this.props.timeframe} Chart`,
            align: 'left'
          },
          dataLabels: {
            enabled: false
          },
          tooltip: {
            enabled: true
          },
          xaxis: {
            type: 'datetime',
            labels: {
              formatter: function (val) {
                return dayjs(val).format(dateFormat);
              }
            }
          },
          yaxis: {
            tooltip: {
              enabled: true
            }
          },

          annotations: {
            yaxis: this.props.resistances
              .map((level, index) => {
                return {
                  y: level,
                  opacity: 1,
                  width: '200%',
                  strokeDashArray: 0,
                  borderColor: '#cc2616',
                  label: {
                    borderColor: '#cc2616',
                    style: {
                      fontSize: '12px',
                      color: '#fff',
                      background: '#cc2616'
                    },
                    orientation: 'horizontal',
                    offsetY: 2,
                    text: `${level}`.toLocaleString(undefined, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 2
                    })
                  }
                };
              })
              .concat(
                this.props.supports.map((level, index) => {
                  return {
                    y: level,
                    opacity: 1,
                    width: '200%',
                    strokeDashArray: 0,
                    borderColor: '#00ff00',
                    label: {
                      borderColor: '#00ff00',
                      style: {
                        fontSize: '12px',
                        color: '#fff',
                        background: '#00ff00'
                      },
                      orientation: 'horizontal',
                      offsetY: 4,
                      text: `${level}`.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })
                    }
                  };
                })
              )
          }
        }}
        series={[
          {
            name: this.props.name != null ? this.props.name : '',
            data: this.props.eod != null ? this.props.eod : []
          }
        ]}
        type="candlestick"
        height={350}
      />
    );
  }
}

export default TradingChart;
