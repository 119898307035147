import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import MainPage from './components/mainPage';

function App() {
  const printStyles = `
    @media print {
      body {
        -webkit-print-color-adjust: exact;
      }
    }
  `;
  return (
    <>
      <MainPage style={printStyles} />
    </>
  );
}
export default App;
