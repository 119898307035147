import dayjs from 'dayjs';
import React, { Component } from 'react';
import Chart from 'react-apexcharts';

class FundPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        Code: 'SWPPX',
        Type: 'FUND',
        Name: 'Schwab® S&P 500 Index Fund',
        Exchange: 'NMFQS',
        CurrencyCode: 'USD',
        CurrencyName: 'US Dollar',
        CurrencySymbol: '$',
        CountryName: 'USA',
        CountryISO: 'US',
        ISIN: 'US8085098551',
        CUSIP: '808509855',
        Fund_Summary:
          'The investment seeks to track the total return of the S&P 500Â® Index.  The fund generally invests at least 80% of its net assets (including, for this purpose, any borrowings for investment purposes) in these stocks; typically, the actual percentage is considerably higher. It generally will seek to replicate the performance of the index by giving the same weight to a given stock as the index does.',
        Fund_Family: 'Schwab Funds',
        Fund_Category: 'Large Blend',
        Fiscal_Year_End: 'October',
        MarketCapitalization: 0,
        Fund_Style: 'Large Blend',
        Nav: '64.63',
        Prev_Close_Price: '64.63',
        Update_Date: '2021-06-03',
        Portfolio_Net_Assets: '59153200000',
        Share_Class_Net_Assets: '0',
        Morning_Star_Rating: null,
        Morning_Star_Risk_Rating: null,
        Morning_Star_Category: null,
        Inception_Date: '1997-05-19',
        Currency: 'USD',
        Domicile: 'United States',
        Yield: '0.0162',
        Yield_YTD: '-0.0826',
        Yield_1Year_YTD: '0.0819',
        Yield_3Year_YTD: '0.0984',
        Yield_5Year_YTD: '0.0916',
        Expense_Ratio: '0.0200',
        Expense_Ratio_Date: '2021-02-25',
        Asset_Allocation: [
          {
            Name: 'Price/Prospective Earnings',
            Category_Average: '21.57',
            Benchmark: null,
            Stock_Portfolio: '22.21'
          }
        ],
        Value_Growth: [
          {
            Name: 'Price/Prospective Earnings',
            Category_Average: '21.57',
            Benchmark: null,
            Stock_Portfolio: '22.21'
          },
          {
            Name: 'Price/Book',
            Category_Average: '4.01',
            Benchmark: null,
            Stock_Portfolio: '3.97'
          },
          {
            Name: 'Price/Sales',
            Category_Average: '2.59',
            Benchmark: null,
            Stock_Portfolio: '2.98'
          },
          {
            Name: 'Price/Cash Flow',
            Category_Average: '16.24',
            Benchmark: null,
            Stock_Portfolio: '16.36'
          },
          {
            Name: 'Dividend Yield %',
            Category_Average: '1.52',
            Benchmark: null,
            Stock_Portfolio: '1.50'
          },
          {
            Name: 'Long-Term Earnings %',
            Category_Average: '12.22',
            Benchmark: null,
            Stock_Portfolio: '12.97'
          },
          {
            Name: 'Historical Earnings %',
            Category_Average: '6.88',
            Benchmark: null,
            Stock_Portfolio: '1.85'
          },
          {
            Name: 'Sales Growth %',
            Category_Average: '2.43',
            Benchmark: null,
            Stock_Portfolio: '2.91'
          },
          {
            Name: 'Cash-Flow Growth %',
            Category_Average: '9.41',
            Benchmark: null,
            Stock_Portfolio: '6.51'
          },
          {
            Name: 'Book-Value Growth %',
            Category_Average: '5.03',
            Benchmark: null,
            Stock_Portfolio: '4.79'
          }
        ],
        Top_Holdings: [
          {
            Name: 'Apple Inc',
            Owned: '26,237,593',
            Change: '205,926',
            Weight: ' 5.80'
          },
          {
            Name: 'Microsoft Corp',
            Owned: '12,539,879',
            Change: '98,247',
            Weight: ' 5.32'
          },
          {
            Name: 'Amazon.com Inc',
            Owned: '711,646',
            Change: '5,576',
            Weight: ' 4.15'
          },
          {
            Name: 'Facebook Inc A',
            Owned: '3,999,328',
            Change: '31,334',
            Weight: ' 2.19'
          },
          {
            Name: 'Alphabet Inc A',
            Owned: '500,011',
            Change: '3,900',
            Weight: ' 1.98'
          },
          {
            Name: 'Alphabet Inc Class C',
            Owned: '479,249',
            Change: '3,773',
            Weight: ' 1.94'
          },
          {
            Name: 'Tesla Inc',
            Owned: '1,276,693',
            Change: '10,009',
            Weight: ' 1.52'
          },
          {
            Name: 'Berkshire Hathaway Inc Class B',
            Owned: '3,169,928',
            Change: '24,573',
            Weight: ' 1.47'
          },
          {
            Name: 'JPMorgan Chase & Co',
            Owned: '5,073,541',
            Change: '39,387',
            Weight: ' 1.31'
          },
          {
            Name: 'Johnson & Johnson',
            Owned: '4,370,251',
            Change: '33,948',
            Weight: ' 1.20'
          },
          {
            Name: 'Visa Inc Class A',
            Owned: '2,820,052',
            Change: '22,136',
            Weight: ' 1.11'
          },
          {
            Name: 'UnitedHealth Group Inc',
            Owned: '1,571,604',
            Change: '12,181',
            Weight: ' 1.05'
          },
          {
            Name: 'NVIDIA Corp',
            Owned: '1,030,829',
            Change: '8,083',
            Weight: ' 1.04'
          },
          {
            Name: 'The Home Depot Inc',
            Owned: '1,789,784',
            Change: '13,964',
            Weight: ' 0.97'
          },
          {
            Name: 'The Walt Disney Co',
            Owned: '3,018,475',
            Change: '24,137',
            Weight: ' 0.94'
          },
          {
            Name: 'Mastercard Inc A',
            Owned: '1,457,794',
            Change: '11,488',
            Weight: ' 0.94'
          },
          {
            Name: 'Procter & Gamble Co',
            Owned: '4,094,124',
            Change: '31,822',
            Weight: ' 0.92'
          },
          {
            Name: 'Bank of America Corp',
            Owned: '12,631,663',
            Change: '98,381',
            Weight: ' 0.86'
          },
          {
            Name: 'PayPal Holdings Inc',
            Owned: '1,947,284',
            Change: '15,335',
            Weight: ' 0.86'
          }
        ],
        Market_Capitalization: [
          {
            Size: 'Giant',
            Category_Average: '66.26',
            Benchmark: '45.62',
            'Portfolio_%': '51.07'
          },
          {
            Size: 'Large',
            Category_Average: '13.56',
            Benchmark: '32.52',
            'Portfolio_%': '34.34'
          },
          {
            Size: 'Medium',
            Category_Average: '17.68',
            Benchmark: '19.55',
            'Portfolio_%': '14.50'
          },
          {
            Size: 'Small',
            Category_Average: '2.46',
            Benchmark: '2.31',
            'Portfolio_%': '0.10'
          },
          {
            Size: 'Micro',
            Category_Average: '0.04',
            Benchmark: '0.00',
            'Portfolio_%': '0.00'
          }
        ],
        Sector_Weights: {
          Cyclical: [
            {
              Type: 'Basic Materials',
              Category_Average: '2.85',
              'Amount_%': '2.34',
              Benchmark: '2.56'
            },
            {
              Type: 'Consumer Cyclical',
              Category_Average: '11.25',
              'Amount_%': '12.43',
              Benchmark: '11.66'
            },
            {
              Type: 'Financial Services',
              Category_Average: '14.83',
              'Amount_%': '14.42',
              Benchmark: '14.46'
            },
            {
              Type: 'Real Estate',
              Category_Average: '2.37',
              'Amount_%': '2.53',
              Benchmark: '3.32'
            }
          ],
          Defensive: [
            {
              Type: 'Consumer Defensive',
              Category_Average: '7.19',
              'Amount_%': '6.40',
              Benchmark: '6.15'
            },
            {
              Type: 'Healthcare',
              Category_Average: '13.75',
              'Amount_%': '12.89',
              Benchmark: '13.15'
            },
            {
              Type: 'Utilities',
              Category_Average: '2.27',
              'Amount_%': '2.64',
              Benchmark: '2.43'
            }
          ],
          Sensitive: [
            {
              Type: 'Communication Services',
              Category_Average: '10.22',
              'Amount_%': '11.18',
              Benchmark: '10.92'
            },
            {
              Type: 'Energy',
              Category_Average: '2.47',
              'Amount_%': '2.68',
              Benchmark: '2.66'
            },
            {
              Type: 'Industrials',
              Category_Average: '10.61',
              'Amount_%': '9.05',
              Benchmark: '9.33'
            },
            {
              Type: 'Technology',
              Category_Average: '22.20',
              'Amount_%': '23.44',
              Benchmark: '23.36'
            }
          ]
        },
        World_Regions: {
          Americas: [
            {
              Name: 'North America',
              Category_Average: '97.14',
              'Stocks_%': '98.95',
              Benchmark: '98.96'
            },
            {
              Name: 'Latin America',
              Category_Average: '0.06',
              'Stocks_%': '0.00',
              Benchmark: '0.05'
            }
          ],
          'Greater Asia': [
            {
              Name: 'Japan',
              Category_Average: '0.11',
              'Stocks_%': '0.00',
              Benchmark: '0.00'
            },
            {
              Name: 'Australasia',
              Category_Average: '0.02',
              'Stocks_%': '0.00',
              Benchmark: '0.00'
            },
            {
              Name: 'Asia Developed',
              Category_Average: '0.20',
              'Stocks_%': '0.05',
              Benchmark: '0.00'
            },
            {
              Name: 'Asia Emerging',
              Category_Average: '0.35',
              'Stocks_%': '0.15',
              Benchmark: '0.18'
            }
          ],
          'Greater Europe': [
            {
              Name: 'United Kingdom',
              Category_Average: '0.92',
              'Stocks_%': '0.52',
              Benchmark: '0.52'
            },
            {
              Name: 'Europe Developed',
              Category_Average: '1.18',
              'Stocks_%': '0.33',
              Benchmark: '0.29'
            },
            {
              Name: 'Europe Emerging',
              Category_Average: '0.00',
              'Stocks_%': '0.00',
              Benchmark: '0.00'
            },
            {
              Name: 'Africa/Middle East',
              Category_Average: '0.01',
              'Stocks_%': '0.00',
              Benchmark: '0.00'
            }
          ],
          'Market Classification': [
            {
              Name: '% Developed Markets',
              Category_Average: '99.59',
              'Stocks_%': '99.85',
              Benchmark: '99.78'
            },
            {
              Name: '% Emerging Markets',
              Category_Average: '0.41',
              'Stocks_%': '0.15',
              Benchmark: '0.22'
            }
          ]
        },
        Top_Countries: {},
        market_capitalization: null,
        world_regions: null,
        sector_weights: null,
        asset_allocation: null
      }
    };
  }

  render() {
    console.log(this.state.data);
    return (
      <div className="container-fluid">
        <div className="container p-3 my-3 border">
          <div className="container input-group mb-3">
            <input
              type="text"
              id="ISINCode"
              value={this.state.ISINCode}
              className="form-control"
              placeholder="Type Something like 910047AG4"
              autoComplete="off"
            />
            <div className="input-group-append">
              <button className="btn btn-success" type="submit">
                Search
              </button>
            </div>
          </div>
        </div>
        <table className="table table-border">
          <tbody>
            <tr className="">
              <td
                style={{
                  backgroundColor: '#002850',
                  color: '#ffffff',
                  textDecoration: 'none',
                  border: '1px solid white'
                }}
              >
                <div className="row">
                  <div className="col-sm-3">
                    <strong>
                      Morning Star Rating:
                      {this.state.data.Morning_Star_Rating
                        ? this.state.data.Morning_Star_Rating
                        : 'N.A.'}
                    </strong>
                    <br />
                    <strong>
                      Morning Star Risk Rating:
                      {this.state.data.Morning_Star_Rating
                        ? this.state.data.Morning_Star_Risk_Rating
                        : 'N.A.'}
                    </strong>
                  </div>
                  <div className="col-sm-6">
                    <h3> {this.state.data.Name}</h3>
                    <strong>Type: {this.state.data.Type ? this.state.data.Type : 'N.A.'}</strong>
                    <br />
                    <strong>ISIN: {this.state.data.ISIN ? this.state.data.ISIN : 'N.A.'}</strong>
                  </div>
                  <div className="col-sm-3">
                    <strong>
                      Currency:
                      {this.state.data.CurrencyCode ? this.state.data.CurrencyCode : 'N.A.'}
                    </strong>
                    <br />
                    <strong>NAV: {this.state.data.NAV ? this.state.data.NAV : 'N.A.'}</strong>
                    <br />
                    <strong>
                      Update Date:
                      {this.state.data.Update_Date ? this.state.data.Update_Date : 'N.A.'}
                    </strong>
                  </div>
                </div>
              </td>
            </tr>
            <tr className="table-dark">
              <td>
                <p>
                  <strong>Summary: </strong> {this.state.data.Fund_Summary}
                </p>
              </td>
            </tr>
            <tr className="table">
              <td>
                <center>
                  <strong>Chart</strong>
                </center>
              </td>
            </tr>
            <tr>
              <Chart
                options={{
                  chart: {
                    height: 350,
                    type: 'area'
                  },
                  dataLabels: {
                    enabled: false
                  },
                  tooltip: {
                    enabled: true
                  },
                  xaxis: {
                    type: 'datetime',
                    labels: {
                      formatter: function (val) {
                        return dayjs(val).format('MMM YY');
                      }
                    }
                  },
                  yaxis: {
                    tooltip: {
                      enabled: true
                    }
                  }
                }}
                series={[
                  {
                    name: this.state.data.Name,
                    data: this.state.data.EOD
                  }
                ]}
                type="area"
                height={350}
              />
            </tr>
            <tr
              style={{
                backgroundColor: '#002850',
                color: '#ffffff',
                textDecoration: 'none',
                border: '1px solid white'
              }}
            >
              <td>
                <center>
                  <strong>Rating</strong>
                </center>
              </td>
            </tr>
            <tr>
              <td>
                <div className="row">
                  <div className="col-sm-6">
                    <ul className="list-group">
                      <li className="list-group-item">
                        <strong>
                          Yield: {this.state.data.Yield ? this.state.data.Yield : '-'}
                        </strong>
                      </li>
                      <li className="list-group-item">
                        <strong>
                          Yield_YTD: {this.state.data.Yield_YTD ? this.state.data.Yield_YTD : '-'}
                        </strong>
                      </li>
                      <li className="list-group-item">
                        <strong>
                          Yield_1YEAR_YTD:
                          {this.state.data.Yield_1YEAR_YTD ? this.state.data.Yield_1YEAR_YTD : '-'}
                        </strong>
                      </li>
                      <li className="list-group-item">
                        <strong>
                          Yield_3YEAR_YTD:
                          {this.state.data.Yield_3YEAR_YTD ? this.state.data.Yield_3YEAR_YTD : '-'}
                        </strong>
                      </li>
                      <li className="list-group-item">
                        <strong>
                          Yield_5YEAR_YTD:
                          {this.state.data.Yield_5YEAR_YTD ? this.state.data.Yield_5YEAR_YTD : '-'}
                        </strong>
                      </li>
                    </ul>
                  </div>
                  <div className="col-sm-6">
                    <ul className="list-group">
                      <li className="list-group-item">
                        <strong>
                          Portfolio_Net_Asset:
                          {this.state.data.Portfolio_Net_Asset
                            ? this.state.data.Portfolio_Net_Asset
                            : '-'}
                        </strong>
                      </li>
                      <li className="list-group-item">
                        <strong>
                          Inception Date:
                          {this.state.data.Inception_Date ? this.state.data.Inception_Date : '-'}
                        </strong>
                      </li>
                      <li className="list-group-item">
                        <strong>
                          Domicile: {this.state.data.Domicile ? this.state.data.Domicile : '-'}
                        </strong>
                      </li>
                      <li className="list-group-item">
                        <strong>
                          Total holding:
                          {this.state.data.Total_holding ? this.state.data.Total_holding : '-'}
                        </strong>
                      </li>
                    </ul>
                  </div>
                </div>
              </td>
            </tr>
            <tr
              style={{
                backgroundColor: '#002850',
                color: '#ffffff',
                textDecoration: 'none',
                border: '1px solid white'
              }}
            >
              <td>
                <center>
                  <strong>Asset Allocation and Value Growth</strong>
                </center>
              </td>
            </tr>
            <tr>
              <td>
                <div className="row">
                  <div className="col-sm-6">
                    <h5>Asset Allocation:</h5>
                    <table className="table">
                      <tbody>
                        <tr className="table-dark">
                          <th>Name</th>
                          <th>Category_Average</th>
                          <th>Benchmark</th>
                          <th>Stock_Portfolio</th>
                        </tr>
                        {this.state.data.Asset_Allocation.map((value, index) => {
                          return (
                            <tr id={index} key={index}>
                              <td>{value.Name}</td>
                              <td>{value.Category_Average}</td>
                              <td>{value.Benchmark}</td>
                              <td>{value.Stock_Portfolio}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="col-sm-6">
                    <h5>Value Growth:</h5>
                    <table className="table">
                      <tbody>
                        <tr className="table-dark">
                          <th>Name</th>
                          <th>Category_Average</th>
                          <th>Benchmark</th>
                          <th>Stock_Portfolio</th>
                        </tr>
                        {this.state.data.Value_Growth.map((value, index) => {
                          return (
                            <tr id={index} key={index}>
                              <td>{value.Name}</td>
                              <td>{value.Category_Average}</td>
                              <td>{value.Benchmark}</td>
                              <td>{value.Stock_Portfolio}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </td>
            </tr>
            <tr
              style={{
                backgroundColor: '#002850',
                color: '#ffffff',
                textDecoration: 'none',
                border: '1px solid white'
              }}
            >
              <td>
                <center>
                  <strong>Top Holdings</strong>
                </center>
              </td>
            </tr>
            <tr>
              <td>
                <table className="table">
                  <tbody>
                    <tr className="table-dark">
                      <th>Name</th>
                      <th>Owned</th>
                      <th>Change</th>
                      <th>Weight</th>
                    </tr>
                    {this.state.data.Top_Holdings.map((value, index) => {
                      return (
                        <tr id={index} key={index}>
                          <td>{value.Name}</td>
                          <td>{value.Owned}</td>
                          <td>{value.Change}</td>
                          <td>{value.Weight}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </td>
            </tr>
            <tr
              style={{
                backgroundColor: '#002850',
                color: '#ffffff',
                textDecoration: 'none',
                border: '1px solid white'
              }}
            >
              <td>
                <center>
                  <strong>Sector Weights and World Region</strong>
                </center>
              </td>
            </tr>
            <tr>
              <td>
                <div className="row">
                  <div className="col-sm-6">
                    <h5>Top Holdings:</h5>
                    <table className="table">
                      <tbody>
                        <tr className="table-dark">
                          <th>Name</th>
                          <th>Owned</th>
                          <th>Change</th>
                          <th>Weight</th>
                        </tr>
                        {this.state.data.Top_Holdings.map((value, index) => {
                          return (
                            <tr id={index} key={index}>
                              <td>{value.Name}</td>
                              <td>{value.Owned}</td>
                              <td>{value.Change}</td>
                              <td>{value.Weight}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="col-sm-6">
                    <h5>Value Growth:</h5>
                    <table className="table">
                      <tbody>
                        <tr className="table-dark">
                          <th>Type</th>
                          <th>Category Average</th>
                          <th>Amount %</th>
                          <th>Benchmark</th>
                        </tr>
                        {Object.keys(this.state.data.Sector_Weights).map((weight, index) => {
                          return (
                            <>
                              <tr className="table-secondary">
                                <td colSpan="4">
                                  <center>{weight}</center>
                                </td>
                              </tr>
                              {this.state.data.Sector_Weights[weight].map((value, index) => {
                                console.log(value);
                                return (
                                  <tr>
                                    <td>{value.Type}</td>
                                    <td>{value.Category_Average}</td>
                                    <td>{value['Amount_%']}</td>
                                    <td>{value.Benchmark}</td>
                                  </tr>
                                );
                              })}
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <button className="btn btn-primary" onClick={this.gotoReportPage}>
          Go Back
        </button>
      </div>
    );
  }
}

export default FundPage;
