import exportFromJSON from 'export-from-json';
import React, { Component } from 'react';
import { FaFileExcel } from 'react-icons/fa';

class ExportExcel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <a
        className="btn btn-primary"
        onClick={() =>
          exportFromJSON({
            data: this.props.data,
            fileName: this.props.fileName,
            exportType: this.props.exportType
          })
        }
      >
        <FaFileExcel />
      </a>
    );
  }
}

export default ExportExcel;
