import axios from 'axios';
import Toast from 'light-toast';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './styles.css';

import { fetchAuthSession } from 'aws-amplify/auth';

import cookies from '../utils/cookies.util';
import base_url from '../utils/request.util';

class PutWritingStrategies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: []
    };
  }

  async componentDidMount() {
    Toast.loading('Finding trades', () => { });

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      config.data = {
        email: cookies.get('User-Specified')
      };

      const page =
        window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];

      config.params = {
        requesting_page: page
      };

      if (page === 'index') {
        const search = new URLSearchParams(window.location.search);
        const index = (search.get('Code') || search.get('code')).replaceAll('%20', ' ');
        config.params.index = index;
      }
      axios
        .get(`${base_url}/options-put-writing-strategies`, config)
        .then((res) => {
          this.setState({
            orders: res.data.items
          });
          Toast.success('Success', 500, () => { });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  async handleDelete() {
    Toast.loading('In Progress', () => { });

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      axios
        .delete(
          `${base_url}/portfolio/order?`,
          {
            data: this.state.items_to_delete
          },
          config
        )
        .then((res) => {
          Toast.success('Success', 3000);
        })
        .catch((error) => {
          Toast.fail('Error Performing Action', 3000);
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="container row">
          <h1>Put Writing Strategy:</h1>
        </div>
        <table className="table table-sm">
          <thead className="table-dark">
            <tr>
              <th>Order</th>
              <th>Code</th>
              <th>expiry</th>
              {/* <th>Strike CALL</th> */}
              {/* <th>OI CALL</th> */}
              <th>Strike</th>
              <th>OI</th>
              <th>LTP</th>
              {/* <th>CALL</th> */}
              <th>Delta</th>
              {/* <th>Delta CALL</th> */}
              {/* <th>IV CALL</th> */}
              <th>IV</th>
              <th>DTE</th>
              <th>Prem %</th>
              {/* <th>Prem % CALL</th> */}
              <th>DTS</th>
              {/* <th>DTS CALL</th> */}
              <th>Prem % PA</th>
              {/* <th>Prem % PA CALL</th> */}
            </tr>
          </thead>
          <tbody>
            {this.state.orders.map((item, index) => {
              return (
                <tr key={index}>
                  <td
                    style={{
                      backgroundColor: '#002850',
                      color: '#ffffff',
                      textDecoration: 'none',
                      border: '1px solid white',
                      '': 'no-wrap'
                    }}
                  >
                    {
                      `
                                                    SELL 
                                                    ${item.code.split(' ')[0]}
                                                    ${item.expiry}
                                                    P${item.strike_put}
                                                    @${(item.put * -1).toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 1
                      })}
                                                `
                      // SELL BABA 18MAR22 P90 @1.5%
                    }
                  </td>
                  <td className="table-dark">
                    {item.code != null ? (
                      <Link to={`/product/equity?code=${item.code}`}>{item.code}</Link>
                    ) : (
                      'N.A.'
                    )}
                  </td>
                  <td className="table-dark">
                    
                    {item.expiry != null
                      ? item.expiry.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 1
                      })
                      : 'N.A.'}
                  </td>
                  {/* <td className="table-dark"> {(item["strike_call"] != null) ? item["strike_call"].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 1 }) : "N.A."} </td> */}
                  {/* <td className="table-dark"> {(item["oi_call"] != null) ? item["oi_call"].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 1 }) : "N.A."} </td> */}
                  <td className="table-dark">
                    
                    {item.strike_put != null
                      ? item.strike_put.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 1
                      })
                      : 'N.A.'}
                  </td>
                  <td className="table-dark">
                    
                    {item.oi_put != null
                      ? item.oi_put.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 1
                      })
                      : 'N.A.'}
                  </td>
                  <td className="table-dark">
                    
                    {item.put != null
                      ? item.put.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 1
                      })
                      : 'N.A.'}
                  </td>
                  {/* <td className="table-dark"> {(item["call"] != null) ? item["call"].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 1 }) : "N.A."} </td> */}
                  <td className="table-dark">
                    
                    {item.delta_put != null
                      ? item.delta_put.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 1
                      })
                      : 'N.A.'}
                  </td>
                  {/* <td className="table-dark"> {(item["delta_call"] != null) ? item["delta_call"].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 1 }) : "N.A."} </td> */}
                  {/* <td className="table-dark"> {(item["iv_call"] != null) ? item["iv_call"].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 1 }) : "N.A."} </td> */}
                  <td className="table-dark">
                    
                    {item.iv_put != null
                      ? item.iv_put.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 1
                      })
                      : 'N.A.'}
                  </td>
                  <td className="table-dark">
                    
                    {item.days_to_expiry != null
                      ? item.days_to_expiry.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 1
                      })
                      : 'N.A.'}
                  </td>
                  <td className="table-dark">
                    
                    {item.premium_percentage_put != null
                      ? item.premium_percentage_put.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 1
                      })
                      : 'N.A.'}
                  </td>
                  {/* <td className="table-dark"> {(item["premium_percentage_call"] != null) ? item["premium_percentage_call"].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 1 }) : "N.A."} </td> */}
                  <td className="table-dark">
                    
                    {item.dts_put != null
                      ? item.dts_put.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 1
                      })
                      : 'N.A.'}
                  </td>
                  {/* <td className="table-dark"> {(item["dts_call"] != null) ? item["dts_call"].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 1 }) : "N.A."} </td> */}
                  <td className="table-dark">
                    
                    {item.premium_percentage_pa_put != null
                      ? item.premium_percentage_pa_put.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 1
                      })
                      : 'N.A.'}
                  </td>
                  {/* <td className="table-dark"> {(item["premium_percentage_pa_call"] != null) ? item["premium_percentage_pa_call"].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 1 }) : "N.A."} </td> */}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default PutWritingStrategies;
