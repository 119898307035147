import { fetchAuthSession } from 'aws-amplify/auth';
import AWS from 'aws-sdk';
import axios from 'axios';
import Toast from 'light-toast';
import React, { Component } from 'react';
import Flags from './flags';

import issuers from './constants/issuers.json';

import {
    FaCalendar,
    FaForward,
    FaPen,
    FaPlus,
    FaSave,
    FaTrash,
    FaWindowClose
} from 'react-icons/fa';
import './styles.css';

import cookies from '../utils/cookies.util';
import { formatDate } from '../utils/date.util';
import base_url, { bucket_name, region } from '../utils/request.util';

const unwantedAttributes = ['flush_dates'];

class StructureWtinWinUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isin_code: '',
      product_type: 'dispersion-warrent',
      strike: '21',
      premium: '5',
      currency: 'USD',
      issuer: '',
      issue_date: formatDate(new Date(new Date().setDate(new Date().getDate() + 7))),
      final_fixing_date: formatDate(new Date(new Date().setDate(new Date().getDate() + 730))),
      underlying: [],
      new_isin_code: '',
      net_payoff: '-2.2',
      underlying_count: {},
      maturity: '12',
      is_pending: true,
      suggestions: []
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDuplicate = this.handleDuplicate.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.addScrip = this.addScrip.bind(this);
    this.addScripSubmit = this.addScripSubmit.bind(this);
    this.removeScrip = this.removeScrip.bind(this);
    this.updateScrip = this.updateScrip.bind(this);
    this.fetchsuggestions = this.fetchsuggestions.bind(this);
    this.selectedScrip = this.selectedScrip.bind(this);
    this.discardScrips = this.discardScrips.bind(this);
    this.setProductData = this.setProductData.bind(this);
    this.promoteProduct = this.promoteProduct.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.syncLatestPrices = this.syncLatestPrices.bind(this);
    this.expireProduct = this.expireProduct.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
    if (event.target.id === 'maturity') {
      this.setState((prevState) => ({
        final_fixing_date: formatDate(
          new Date(
            new Date(prevState.initial_fixing_date).setMonth(
              new Date(prevState.initial_fixing_date).getMonth() + parseInt(prevState.maturity)
            )
          )
        )
      }));
      this.setState((prevState) => ({
        redemption: formatDate(
          new Date(new Date(prevState.final_fixing_date).setDate(new Date().getDate() + 7))
        )
      }));
    }
    if (
      event.target.id === 'soft_callable' ||
      event.target.id === 'coupon_memory' ||
      event.target.id === 'knockin_kickin' ||
      event.target.id === 'asian_delay' ||
      event.target.id === 'onestar' ||
      event.target.id === 'twin_win' ||
      event.target.id === 'flush_dates'
    ) {
      this.setState({
        [event.target.id]: !this.state[event.target.id]
      });
    }
    if (event.target.id === 'coupon_type') {
      const coupon_memory_val = this.state.coupon_type !== 'No Memory';
      this.setState({
        coupon_memory: coupon_memory_val
      });
    }
  }

  async handleSubmit(event) {
    Toast.loading('In Progress', () => { });
    const attributes = JSON.parse(JSON.stringify(this.state));
    unwantedAttributes.forEach((key) => {
      delete attributes[key];
    });

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      const data = { structure: attributes, flush_dates: this.state.flush_dates };

      axios
        .patch(`${base_url}/participation-type-structure/${this.state.isin_code}`, data, config)
        .then((res) => {
          Toast.success('Success', 3000, () => {
            history.back();
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  async handleDuplicate() {
    Toast.loading('In Progress', () => { });
    this.syncLatestPrices();
    const attributes = JSON.parse(JSON.stringify(this.state));
    unwantedAttributes.forEach((key) => {
      delete attributes[key];
    });
    attributes.is_pending = true;

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      const data = { structure: attributes, flush_dates: this.state.flush_dates };
    });
  }

  async handleDelete(event) {
    Toast.loading('In Progress', () => { });

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };

      axios
        .delete(`${base_url}/participation-type-structure/${this.state.isin_code}`, config)
        .then((res) => {
          Toast.success(this.state.isin_code + ' deleted Successfully', 3000, () => {
            history.back();
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 2000);
        });
    });
  }

  addScrip() {
    const scrip = document.getElementById('scrip').value;
    const initial_price = document.getElementById('initial_price').value;
    if (this.state.underlying.indexOf({ code: scrip.toUpperCase() }) !== -1) return;
    this.setState((prevState) => ({
      underlying: [
        ...prevState.underlying,
        {
          code: scrip.toUpperCase(),
          initial_reference_price: initial_price
        }
      ],
      suggestions: []
    }));
    document.getElementById('scrip').value = '';
    document.getElementById('initial_price').value = '';
  }

  addScripSubmit(event) {
    if (event.charcode !== 13) return;
    this.addScrip();
  }

  removeScrip(scrip_index) {
    const scrip_array = [...this.state.underlying];
    scrip_array.splice(scrip_index, 1);
    this.setState({
      underlying: scrip_array
    });
  }

  updateScrip(scrip_index) {
    const scrip_array = [...this.state.underlying];
    let scrip_to_update = scrip_array.splice(scrip_index, 1);
    scrip_to_update = scrip_to_update[0];
    this.setState({
      underlying: scrip_array,
      CurrentScrip: scrip_to_update
    });
    document.getElementById('scrip').value = scrip_to_update.code;
    document.getElementById('initial_price').value = scrip_to_update.initial_reference_price;
  }

  async fetchsuggestions(event) {
    if (event.target.id === '') return;

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      config.params = { q: event.target.value };
      axios
        .get(`${base_url}/product/suggest`, config)
        .then((res) => {
          this.setState({
            suggestions: res.data.items
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  async selectedScrip(event) {
    const scrip = event.target.id;
    if (this.state.underlying.includes({ code: scrip.toUpperCase() })) return;
    Toast.loading('In Progress', () => { });

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      config.params = {
        attribute: 'last_price'
      };
      axios
        .get(`${base_url}/product/attribute/${scrip}`, config)
        .then((res) => {
          const last_price = res.data.last_price;
          document.getElementById('scrip').value = scrip;
          document.getElementById('initial_price').value = last_price;
          document.getElementById('initial_price').focus();
          this.setState({
            suggestions: []
          });
          Toast.hide();
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
      this.setState((prevState) => ({
        suggestions: []
      }));
    });
  }

  async openFactSheet(isin_code) {
    window.open(
      `https://${bucket_name}.s3.${region}.amazonaws.com/structures/term-sheets/${isin_code}.pdf`,
      '_blank'
    );
  }

  async syncLatestPrices() {
    Toast.loading('In Progress', () => { });
    const scrip_array = [...this.state.underlying];
    for (let i = 0; i < scrip_array.length; i++) {
      await fetchAuthSession().then(() => {
        const config = {};
        config.headers = {
          'Authorization': cookies.get('Authorization'),
          'User-Specified': cookies.get('User-Specified'),
          'Type-Specified': cookies.get('Type-Specified')
        };
        config.params = {
          attribute: 'last_price'
        };
        axios
          .get(`${base_url}/product/attribute/${scrip_array[i].code}`, config)
          .then((res) => {
            scrip_array[i] = {
              code: scrip_array[i].code,
              initial_reference_price: res.data.last_price
            };
            this.setState({ underlying: scrip_array });
          })
          .catch((error) => {
            console.log(error);
            Toast.fail('Error Performing Action', 3000);
          });
      });
    }
    Toast.success('Prices Fetched Successfully', 1000, () => { });
  }

  discardScrips(event) {
    const scrip = event.target.id;
    if (this.state.underlying.includes({ code: scrip.toUpperCase() })) return;
    this.setState((prevState) => ({
      underlying: [...prevState.underlying, prevState.CurrentScrip],
      suggestions: []
    }));
    document.getElementById('scrip').value = scrip;
    document.getElementById('initial_price').value = '';
  }

  async setProductData() {
    const search = window.location.search;
    let isin_code = search.replaceAll('?ISIN=', '');
    isin_code = isin_code.replaceAll('%20', ' ');

    if (isin_code === '') return;

    Toast.loading('In Progress', () => { });

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };

      axios
        .get(`${base_url}/participation-type-structure/${isin_code}`, config)
        .then((res) => {
          const data = res.data.item;

          this.setState(
            {
              isin_code: data.isin_code,
              product_type: data.product_type,
              issuer: data.issuer,
              barrier_type: data.barrier_type,
              strike: data.strike,
              currency: data.currency,
              weightage: data.weightage,
              maturity: data.maturity,
              initial_fixing_date: data.initial_fixing_date,
              issue_date: data.issue_date,
              final_fixing_date: data.final_fixing_date,
              redemption: data.redemption,
              underlying: data.underlying,
              soft_callable: data.soft_callable,
              delayed_frequency: data.delayed_frequency,
              knockin_kickin: data.knockin_kickin,
              knockin_kickin_value: data.knockin_kickin_value,
              asian_delay: data.asian_delay,
              asian_delay_value: data.asian_delay_value,
              onestar: data.onestar,
              onestar_value: data.onestar_value,
              upper_barrier: data.upper_barrier,
              lower_barrier: data.lower_barrier,
              participation: data.participation,
              rebate: data.rebate,
              is_pending: data.is_pending
            },
            () => {
              Toast.success('Success', 1000, () => { });
            }
          );
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  async uploadFile(event) {
    Toast.loading('In Progress', () => { });
    const isin_code = this.state.isin_code;
    const bucketName = bucket_name;

    const credentials = await fetchAuthSession();

    AWS.config.region = region;
    AWS.config.update({
      accessKeyId: credentials.credentials.accessKeyId,
      secretAccessKey: credentials.credentials.secretAccessKey,
      sessionToken: credentials.credentials.sessionToken
    });
    const s3 = new AWS.S3({
      apiVersion: '2006-03-01',
      params: { Bucket: bucketName }
    });
    console.log(event.target.files);

    s3.upload(
      {
        Bucket: bucketName,
        Key: `structures/term-sheets/${isin_code}.pdf`,
        Body: event.target.files[0],
        ACL: 'private'
      },
      function (err, data) {
        if (err) {
          Toast.fail('there was an error uploading your file', 1000, () => { });
        } else {
          Toast.success('Sheet updated Successfully', 1000, () => { });
          console.log(data);
        }
      }
    );

    this.setState({
      term_sheet: true
    });
  }

  async promoteProduct() {
    if (this.state.new_isin_code === '') return;
    Toast.loading('In Progress', () => { });

    const payload = this.state;
    payload.is_pending = false;
    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      axios
        .post(
          `${base_url}/promote-structure/${payload.isin_code}`,
          { new_isin: payload.new_isin_code, structure: payload },
          config
        )
        .then((res) => {
          Toast.success('Success', 3000, () => {
            window.location.href = '/structure/details';
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
      this.setState((prevState) => ({
        suggestions: []
      }));
    });
  }

  async expireProduct() {
    console.log(this.state.new_isin_code);
    if (this.state.new_isin_code === '') return;
    Toast.loading('In Progress', () => { });

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      const expired = this.state.expired;
      const apiPath = expired === -1 ? 'expire-structure' : 'unexpire-structure';
      axios
        .post(`${base_url}/${apiPath}/${this.state.isin_code}`, {}, config)
        .then((res) => {
          Toast.success('Success', 3000, () => { });
          this.setState({
            expired: expired * -1
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  componentDidMount() {
    this.setProductData();
  }

  render() {
    return (
      <div className="container">
        <table className="table">
          <tr>
            <td>
              <label>Type of Structure:</label>
            </td>
            <td>
              <select
                value={this.state.product_type}
                onChange={this.handleChange}
                className="form-control"
                id="product_type"
              >
                <option>twin-win</option>
              </select>
            </td>
            <td>
              <label>Currency:</label>
            </td>
            <td>
              <input
                value={this.state.currency}
                onChange={this.handleChange}
                type="text"
                className="form-control"
                id="currency"
              />
            </td>
          </tr>
          <tr>
            <td>
              <label>Maturity In Months:</label>
            </td>
            <td>
              <input
                type="number"
                value={this.state.maturity}
                onChange={this.handleChange}
                className="form-control"
                id="maturity"
              />
            </td>
            <td>
              <label>Weightage:</label>
            </td>
            <td>
              <input
                value={this.state.weightage}
                onChange={this.handleChange}
                type="text"
                className="form-control"
                id="weightage"
              />
            </td>
          </tr>
          <tr>
            <td>
              <label>issuer:</label>
            </td>
            <td>
              <select
                value={this.state.issuer}
                onChange={this.handleChange}
                className="form-control"
                id="issuer"
              >
                <option>{this.state.issuer}</option>
                {
                  issuers.map(issuer => {
                    return <option key={issuer}>{issuer}</option>;
                  })
                }
              </select>
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td colSpan="4">
              <div className="form-group row">
                <label>Underlyings:</label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    autoComplete="off"
                    placeholder="Enter Scrip Name to add"
                    className="form-control"
                    id="scrip"
                    onChange={this.fetchsuggestions}
                  />

                  <div className="dropdown">
                    <ul className="list-group">
                      {this.state.suggestions.map((value, index) => {
                        return (
                          <li
                            className="list-group-item list-group-flush"
                            onClick={this.selectedScrip}
                            id={value.code}
                            key={index}
                          >
                            {value.code}
                            &nbsp; ({value.name}) &nbsp;
                            <img src={Flags[value.country_iso]}></img>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <div className="col-sm-3">
                  <input
                    type="text"
                    autoComplete="off"
                    placeholder="Initial Price"
                    className="form-control"
                    id="initial_price"
                    onKeyPress={this.addScripSubmit}
                  />
                </div>
                <div className="col-sm-1">
                  <div className="btn-group" role="group" aria-label="Basic example">
                    <a className="btn btn-primary" id="addScrip" onClick={this.addScrip}>
                      <FaPlus />
                    </a>
                  </div>
                </div>

                <div className="contaier">
                  {this.state.underlying.map((value, index) => {
                    return (
                      <div className="row" key={index}>
                        <div className="col-md-6">
                          <input
                            type="text"
                            disabled="true"
                            className="form-control"
                            value={value.code}
                          />
                        </div>
                        <div className="col-md-1">
                          <input
                            type="text"
                            disabled="true"
                            className="form-control"
                            value={this.state.underlying_count[value.code]}
                          />
                        </div>
                        <div className="col-md-3">
                          <input
                            type="text"
                            disabled="true"
                            className="form-control"
                            value={value.initial_reference_price}
                          />
                        </div>
                        <div className="col-md-2 btn-group" role="group" aria-label="Basic example">
                          <a className="btn btn-primary" onClick={() => this.updateScrip(index)}>
                            <FaPen />
                          </a>
                          <a className="btn btn-danger" onClick={() => this.removeScrip(index)}>
                            <FaTrash />
                          </a>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <label>Upper Barrier:</label>
            </td>
            <td>
              <div className="input-group mb-3">
                <input
                  value={this.state.upper_barrier}
                  onChange={this.handleChange}
                  type="text"
                  className="form-control"
                  id="upper_barrier"
                />
                <div className="input-group-append">
                  <span className="input-group-text">%</span>
                </div>
              </div>
            </td>

            <td>
              <label>Lower Barrier:</label>
            </td>
            <td>
              <div className="input-group mb-3">
                <input
                  value={this.state.lower_barrier}
                  onChange={this.handleChange}
                  type="text"
                  className="form-control"
                  id="lower_barrier"
                />
                <div className="input-group-append">
                  <span className="input-group-text">%</span>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <label>barrier Type:</label>
            </td>
            <td>
              <select
                value={this.state.barrier_type}
                onChange={this.handleChange}
                className="form-control"
                id="barrier_type"
              >
                <option>European</option>
                <option>American EOD</option>
                <option>American CTS</option>
                <option>No barrier</option>
              </select>
            </td>
            <td>
              <label>Strike:</label>
            </td>

            <td>
              <div className="input-group mb-3">
                <input
                  value={this.state.strike}
                  onChange={this.handleChange}
                  type="text"
                  className="form-control"
                  id="strike"
                />
                <div className="input-group-append">
                  <span className="input-group-text">%</span>
                </div>
              </div>
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>
              <label>Participation:</label>
            </td>

            <td>
              <div className="input-group mb-3">
                <input
                  value={this.state.participation}
                  onChange={this.handleChange}
                  type="text"
                  className="form-control"
                  id="participation"
                />
                <div className="input-group-append">
                  <span className="input-group-text">%</span>
                </div>
              </div>
            </td>
            <td>
              <label>Rebate:</label>
            </td>

            <td>
              <div className="input-group mb-3">
                <input
                  value={this.state.rebate}
                  onChange={this.handleChange}
                  type="text"
                  className="form-control"
                  id="rebate"
                />
                <div className="input-group-append">
                  <span className="input-group-text">%</span>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <label>Initial Fixing Date:</label>
            </td>
            <td>
              <input
                value={this.state.initial_fixing_date}
                onChange={this.handleChange}
                type="date"
                className="form-control"
                id="initial_fixing_date"
              />
            </td>

            <td>
              <label>Final Fixing Date:</label>
            </td>
            <td>
              <input
                value={this.state.final_fixing_date}
                onChange={this.handleChange}
                type="date"
                className="form-control"
                id="final_fixing_date"
              />
            </td>
          </tr>
          <tr>
            <td>
              <label>Issue Date:</label>
            </td>
            <td>
              <input
                value={this.state.issue_date}
                onChange={this.handleChange}
                type="date"
                className="form-control"
                id="issue_date"
              />
            </td>
            <td>
              <label>redemption:</label>
            </td>
            <td>
              <input
                value={this.state.redemption}
                onChange={this.handleChange}
                type="date"
                className="form-control"
                id="redemption"
              />
            </td>
          </tr>
          <tr>
            <td>
              <label>Knockin Kickin:</label>
            </td>
            <td>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <div className="input-group-text">
                    <input
                      type="checkbox"
                      checked={this.state.knockin_kickin}
                      id="knockin_kickin"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                {this.state.knockin_kickin && (
                  <input
                    type="number"
                    className="form-control"
                    value={this.state.knockin_kickin_value}
                    id="knockin_kickin_value"
                    disabled={!this.state.knockin_kickin}
                    onChange={this.handleChange}
                  />
                )}
              </div>
            </td>
            <td>
              <label>Asian Delay:</label>
            </td>
            <td>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <div className="input-group-text">
                    <input
                      type="checkbox"
                      checked={this.state.asian_delay}
                      id="asian_delay"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                {this.state.asian_delay && (
                  <input
                    type="number"
                    className="form-control"
                    value={this.state.asian_delay_value}
                    id="asian_delay_value"
                    disabled={!this.state.asian_delay}
                    onChange={this.handleChange}
                  />
                )}
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <label>One Star:</label>
            </td>
            <td>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <div className="input-group-text">
                    <input
                      type="checkbox"
                      checked={this.state.onestar}
                      id="onestar"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                {this.state.onestar && (
                  <input
                    type="number"
                    className="form-control"
                    value={this.state.onestar_value}
                    id="onestar_value"
                    disabled={!this.state.onestar}
                    onChange={this.handleChange}
                  />
                )}
              </div>
            </td>
            <td>
              <label>Upload Term Sheet:</label>
            </td>
            <td>
              <input
                type="file"
                id="termsheet"
                onChange={this.uploadFile}
                className="form-control"
              />
              {this.state.term_sheet && <span className="success">Already Uploaded</span>}
            </td>
          </tr>
        </table>
        <div className="row">
          <div className="btn-group" role="group" aria-label="Basic example">
            <a className="btn btn-primary" data-toggle="modal" data-target="#duplicateProduct">
              
              <FaSave /> Duplicate Product
            </a>
            <a className="btn btn-danger" onClick={this.setProductData}>
              
              <FaWindowClose /> Discard Changes
            </a>
            <a className="btn btn-primary" onClick={this.handleDelete}>
              
              <FaTrash /> Delete Product
            </a>
            {this.state.is_pending ? (
              <a className="btn btn-danger" onClick={this.handleSubmit}>
                
                <FaPen /> Save Changes
              </a>
            ) : (
              <a className="btn btn-primary" data-toggle="modal" data-target="#promoteProduct">
                
                <FaForward /> Promote Product
              </a>
            )}
            {this.state.expired === -1 ? (
              <a className="btn btn-danger" onClick={this.expireProduct}>
                
                <FaCalendar /> Mature Product
              </a>
            ) : (
              <a className="btn btn-danger" onClick={this.expireProduct}>
                
                <FaCalendar /> Revert from Maturity
              </a>
            )}
          </div>
          <div id="promoteProduct" className="modal fade" role="dialog">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal">
                    &times;
                  </button>
                </div>
                <div className="modal-body">
                  <div className="form-group">
                    <label> Final ISIN code:</label>
                    <input
                      value={this.state.new_isin_code}
                      onChange={this.handleChange}
                      type="text"
                      className="form-control"
                      id="new_isin_code"
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-dismiss="modal"
                    onClick={this.promoteProduct}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div id="duplicateProduct" className="modal fade" role="dialog">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal">
                    &times;
                  </button>
                </div>
                <div className="modal-body">
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-primary btn-block"
                      data-dismiss="modal"
                      onClick={this.handleDuplicate}
                    >
                      Confirm Duplicate
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StructureWtinWinUpdate;
