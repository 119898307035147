import axios from 'axios';
import Toast from 'light-toast';
import React, { Component } from 'react';
import './styles.css';

import { fetchAuthSession } from 'aws-amplify/auth';

import cookies from '../utils/cookies.util';
import base_url from '../utils/request.util';

class PortfolioExposure extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: '',
      data: []
    };
  }

  navigateToProduct(code, final_asset_class) {
    if (final_asset_class.toLowerCase() === 'structured product') {
      window.location.href = `/structure/autocallable/report?ISIN=${code}`;
    } else if (final_asset_class === 'bond') {
      window.location.href = `/product/bond?ISIN=${code}`;
    } else {
      window.location.href = `/product/equity?Code=${code}`;
    }
  }

  async componentDidMount() {
    const search = new URLSearchParams(window.location.search);
    const code = (search.get('Code') || search.get('code')).replaceAll('%20', ' ');

    if (code === '') {
      Toast.fail('No Code provided', 1000);
      return;
    }

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      axios
        .get(`${base_url}/portfolio/exposure/${code}`, config)
        .then((res) => {
          this.setState({
            code,
            data: res.data.items
          });
          Toast.success('Success', 500, () => { });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 1000);
        });
    });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="container row">
          <h1>Client Exposure {this.state.code !== '' && ' in ' + this.state.code}:</h1>
        </div>
        <table className="table table-sm">
          <thead className="table-dark">
            <tr>
              <th style={{ position: 'sticky', top: 50 }}>portfolio Id</th>
              <th style={{ position: 'sticky', top: 50, width: '300px' }}>Name</th>
              <th style={{ position: 'sticky', top: 50 }}>Code</th>
              <th style={{ position: 'sticky', top: 50 }}>Asset Class</th>
              <th style={{ position: 'sticky', top: 50 }}>Position</th>
              <th style={{ position: 'sticky', top: 50 }}>Cost Value</th>
              <th style={{ position: 'sticky', top: 50 }}>Cost Rate</th>
              <th style={{ position: 'sticky', top: 50 }}>Market Value</th>
              <th style={{ position: 'sticky', top: 50 }}>Market Rate</th>
              <th style={{ position: 'sticky', top: 50 }}>Profit/Loss</th>
              <th style={{ position: 'sticky', top: 50 }}>PNL Pct</th>
              {this.state.multiple_select && <th style={{ position: 'sticky', top: 50 }}></th>}
            </tr>
          </thead>
          <tbody>
            {this.state.data.map((exposures, asset_class_index) => {
              return (
                <>
                  {exposures.exposure_data.map((item, index) => {
                    return (
                      <>
                        <tr key={index}>
                          {index === 0 && (
                            <td
                              rowSpan={exposures.exposure_data.length}
                              style={{
                                backgroundColor: '#002850',
                                color: '#ffffff',
                                textDecoration: 'none'
                              }}
                            >
                              <strong>{item.portfolio_id != null ? item.portfolio_id : '-'}</strong>
                            </td>
                          )}
                          <td className="table-dark" style={{ width: '300px' }}>
                            {item.final_asset_class !== 'Structured Product' &&
                              item.final_asset_class !== 'structured product'
                              ? item.name != null
                                ? item.name
                                : '-'
                              : item.name != null
                                ? item.name
                                : '-'}
                          </td>
                          <td className="table-dark">
                            {item.code != null ? (
                              <a
                                className="btn btn-link"
                                onClick={() =>
                                  this.navigateToProduct(item.code, item.final_asset_class)
                                }
                              >
                                {item.code}
                              </a>
                            ) : (
                              '-'
                            )}
                          </td>
                          <td className="table-dark">
                            
                            {item.final_asset_class != null ? item.final_asset_class : '-'}
                          </td>
                          <td className="table-dark">
                            
                            {item.quantity != null ? item.quantity : '-'}
                          </td>
                          <td className="table-dark">
                            
                            {item.purchase_value != null
                              ? item.purchase_value.toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                              })
                              : '-'}
                          </td>
                          <td className="table-dark">
                            
                            {item.cost_price != null
                              ? item.cost_price.toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                              })
                              : '-'}
                          </td>
                          <td className="table-dark">
                            
                            {item.current_value != null
                              ? item.current_value.toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                              })
                              : '-'}
                          </td>
                          <td className="table-dark">
                            
                            {item.last_price != null
                              ? item.last_price.toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                              })
                              : '-'}
                          </td>
                          <td
                            className="table-dark"
                            style={{ color: item.profit_loss < 0 ? 'Red' : 'Green' }}
                          >
                            
                            <strong>
                              {item.profit_loss != null
                                ? item.profit_loss.toLocaleString(undefined, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2
                                })
                                : '-'}
                            </strong>
                          </td>
                          <td
                            className="table-dark"
                            style={{ color: item.percentage_updown < 0 ? 'Red' : 'Green' }}
                          >
                            
                            <strong>
                              {item.percentage_updown != null
                                ? item.percentage_updown.toLocaleString(undefined, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2
                                })
                                : '-'}
                            </strong>
                          </td>
                          {this.state.multiple_select && (
                            <td className="table-dark">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                onClick={() => this.selectHolding(item.portfolio_id, item.ISINCode)}
                              />
                            </td>
                          )}
                        </tr>
                      </>
                    );
                  })}
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default PortfolioExposure;
