import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';
import Toast from 'light-toast';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ExportExcel from './exportExcel';
import Flags from './flags';
import './styles.css';

import cookies from '../utils/cookies.util';
import base_url from '../utils/request.util';

class Watchlist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      watchlist_name: '1',
      underlying_count: [],
      selectedWatchlistIndex: 0,
      watchlists: [],
      portfolio_id: 'All',
      portfolio_ids: ['All'],
      multiple_select: false,
      items_to_delete: [],
      suggestions: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSumbit = this.handleSumbit.bind(this);
    this.changeWatchlist = this.changeWatchlist.bind(this);
    this.fetchSuggestions = this.fetchSuggestions.bind(this);
    this.selectedScrip = this.selectedScrip.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
  }

  async handleSumbit() {
    Toast.loading('In Progress', () => { });

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      const data = {
        code: this.state.code,
        watchlist_name: this.state.watchlist_name
      };
      axios
        .post(`${base_url}/user-watchlist/${cookies.get('User-Specified')}`, data, config)
        .then(() => {
          Toast.success('Success', 500, () => { });
          axios
            .get(`${base_url}/user-watchlist/${cookies.get('User-Specified')}`, config)
            .then((res) => {
              this.setState({
                watchlists: res.data.items,
                code: ''
              });
            })
            .catch((error) => {
              console.log(error);
              Toast.fail('Error Performing Action', 3000);
            });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  async changeWatchlist(index) {
    this.setState({
      selectedWatchlistIndex: index
    });
  }

  async fetchSuggestions(event) {
    this.setState({
      code: event.target.value
    });
    if (event.target.value === '') return;

    await fetchAuthSession().then(() => {
      const config = {};
      config.params = { q: event.target.value };
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };

      axios
        .get(`${base_url}/product/suggest`, config)
        .then((res) => {
          this.setState({
            suggestions: res.data.items
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  selectedScrip(event) {
    this.setState({
      code: event.target.id,
      suggestions: []
    });
  }

  async componentDidMount() {
    console.log(cookies.get('myCat'));

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      axios
        .get(`${base_url}/user-watchlist/${cookies.get('User-Specified')}`, config)
        .then((res) => {
          this.setState({
            watchlists: res.data.items
          });
          Toast.success('Success', 500, () => { });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
      config.params = {};
      axios
        .get(`${base_url}/structure-underlying-count`, config)
        .then(async (res) => {
          this.setState({
            underlying_count: res.data.item
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="container row">
          <h1>Watchlist:</h1>
          <div className="col-sm-6">
            <input
              type="text"
              id="code"
              value={this.state.code}
              className="form-control"
              placeholder="Type Something like AAPL US EQUITY ...."
              onChange={this.fetchSuggestions}
              autoComplete="off"
            />
          </div>
          <div className="col-sm-2">
            <select
              className="form-control"
              id="watchlist_name"
              value={this.state.watchlist_name}
              onChange={this.handleChange}
            >
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
          </div>

          <div className="col-sm-2">
            <button className="btn btn-danger" onClick={this.handleSumbit}>
              Submit
            </button>
          </div>
          <div className="col-sm-2">
            <ExportExcel data={this.state.watchlists} fileName="watchlist" exportType="xls" />
          </div>
        </div>
        <div className="dropdown">
          <ul className="list-group">
            {this.state.suggestions.map((value, index) => {
              return (
                <li
                  className="list-group-item list-group-flush"
                  onClick={this.selectedScrip}
                  id={value.code}
                  key={index}
                >
                  {value.code}
                  &nbsp; ({value.name}) &nbsp;
                  <img src={Flags[value.country_iso]} alt={value.country_iso + 'flag'} />
                </li>
              );
            })}
          </ul>
        </div>
        <table className="table table-sm">
          <thead className="table-dark">
            <tr>
              <th>Code</th>
              <th>Name</th>
              <th>LTP</th>
              <th>Up down</th>
              <th>52W H</th>
              <th>52W L</th>
              <th>Mcap/RevTTM</th>
              <th>Rating</th>
              <th>Buy</th>
              <th>Hold</th>
              <th>Sell</th>
              <th>PE Trailing</th>
              <th>PB</th>
              <th>Div Yeild</th>
              <th>IV 3M</th>
              <th>Earnings</th>
            </tr>
          </thead>
          <tbody>
            {this.state.watchlists.length !== 0 &&
              this.state.watchlists[this.state.selectedWatchlistIndex].watchlist.map(
                (item, index) => {
                  return (
                    <tr key={index}>
                      <td
                        style={{
                          backgroundColor: '#002850',
                          color: '#ffffff',
                          textDecoration: 'none',
                          border: '1px solid white'
                        }}
                      >
                        <Link to={`/product/equity?code=${item.code}`}>{item.code}</Link>
                        &nbsp;
                        {this.state.underlying_count.length !== 0 && (
                          <span className="badge badge-primary">
                            {this.state.underlying_count[item.code]}
                          </span>
                        )}
                      </td>
                      <td>
                        {item.name
                          ? item.name.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2
                          })
                          : '-'}
                      </td>
                      <td>
                        {item.day_close
                          ? item.day_close.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2
                          })
                          : '-'}
                      </td>
                      <td
                        style={{
                          color: item.day_change_p > 0 ? 'green' : 'red',
                          fontWeight: 'bold'
                        }}
                      >
                        {item.day_change_p
                          ? item.day_change_p.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2
                          }) + '%'
                          : '-'}
                      </td>
                      <td>
                        {item.week_52_high
                          ? item.week_52_high.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2
                          })
                          : '-'}
                      </td>
                      <td>
                        {item.week_52_low
                          ? item.week_52_low.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2
                          })
                          : '-'}
                      </td>
                      <td>
                        {item.market_capitalization_to_revenue_ttm
                          ? item.market_capitalization_to_revenue_ttm.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2
                          })
                          : '-'}
                      </td>
                      <td>
                        {item.rating
                          ? item.rating.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2
                          })
                          : '-'}
                      </td>
                      <td>
                        {item.buy
                          ? item.buy.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2
                          })
                          : '-'}
                      </td>
                      <td>
                        {item.hold
                          ? item.hold.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2
                          })
                          : '-'}
                      </td>
                      <td>
                        {item.sell
                          ? item.sell.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2
                          })
                          : '-'}
                      </td>
                      <td>
                        {item.trailing_pe
                          ? item.trailing_pe.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2
                          })
                          : '-'}
                      </td>
                      <td>
                        {item.price_book_mrq
                          ? item.price_book_mrq.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2
                          })
                          : '-'}
                      </td>
                      <td>
                        {item.dividend_yield
                          ? item.dividend_yield.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2
                          })
                          : '-'}
                      </td>
                      <td>
                        {item.iv_3months
                          ? item.iv_3months.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2
                          })
                          : '-'}
                      </td>
                      <td>
                        {item.earnings_date
                          ? item.earnings_date.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2
                          })
                          : '-'}
                      </td>
                    </tr>
                  );
                }
              )}
          </tbody>
        </table>
        <div className="btn-group">
          {this.state.watchlists.map((watchlist_item, index) => (
            <button
              type="button"
              className="btn btn-primary"
              id={index}
              key={index}
              onClick={() => this.changeWatchlist(index)}
            >
              {'WATCHLIST ' + watchlist_item.watchlist_name}
            </button>
          ))}
        </div>
      </div>
    );
  }
}

export default Watchlist;
