import axios from 'axios';
import Toast from 'light-toast';
import React, { Component } from 'react';
import Flags from '../flags';

import { fetchAuthSession } from 'aws-amplify/auth';

import TradingChart from './chart';

import cookies from '../../utils/cookies.util';
import base_url from '../../utils/request.util';

class TradingChartPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      weekly_eod: { eod: [], support_resistance: { supports: [], resistances: [] } },
      daily_eod: { eod: [], support_resistance: { supports: [], resistances: [] } },
      hourly_eod: { eod: [], support_resistance: { supports: [], resistances: [] } },
      code: '',
      news_data: [],
      suggestions: []
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.fetchSuggestions = this.fetchSuggestions.bind(this);
    this.selectedScrip = this.selectedScrip.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  async fetchData(code) {
    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      axios
        .get(`${base_url}/product/${code}`, config)
        .then((res) => {
          this.setState({
            data: res.data.item
          });
          code = code.replaceAll('/', ' ');
          document.getElementById('code').value = code;
          Toast.success('Success', 2000, () => { });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
      config.params = { tf: 'w' };
      axios
        .get(`${base_url}/product-eod/${code}`, config)
        .then((res) => {
          this.setState({
            weekly_eod: res.data.item
          });
          code = code.replaceAll('/', ' ');
          document.getElementById('code').value = code;
        })
        .catch((error) => {
          console.log(error);
        });
      config.params = { tf: 'd' };
      axios
        .get(`${base_url}/product-eod/${code}`, config)
        .then((res) => {
          this.setState({
            daily_eod: res.data.item
          });
          code = code.replaceAll('/', ' ');
          document.getElementById('code').value = code;
        })
        .catch((error) => {
          console.log(error);
        });
      config.params = { tf: 'h' };
      axios
        .get(`${base_url}/product-eod/${code}`, config)
        .then((res) => {
          this.setState({
            hourly_eod: res.data.item
          });
          code = code.replaceAll('/', ' ');
          document.getElementById('code').value = code;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  async handleSubmit(event) {
    let code = this.state.code;
    Toast.loading('In Progress', () => { });
    code = code.toUpperCase();
    await this.fetchData(code);
  }

  handleChange(event) {
    let code = event.target.value;
    code = code.toUpperCase();
    this.setState({
      code
    });
  }

  async fetchSuggestions(event) {
    this.setState({
      code: event.target.value
    });
    if (event.target.value === '') return;

    await fetchAuthSession().then(() => {
      const config = {};
      config.params = { q: event.target.value };
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };

      axios
        .get(`${base_url}/product/suggest`, config)
        .then((res) => {
          this.setState({
            suggestions: res.data.items
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  selectedScrip(event) {
    this.setState(
      {
        code: event.target.id,
        suggestions: []
      },
      () => {
        this.handleSubmit();
      }
    );
  }

  async componentDidMount() {
    const search = window.location.search;
    const code = search.replaceAll('?code=', '').replaceAll('?Code=', '').replaceAll('%20', ' ');

    if (code === '') return;
    Toast.loading('In Progress', () => { });
    await this.fetchData(code);
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="container p-3 my-3 border">
          <div className="container input-group mb-3">
            <input
              type="text"
              id="code"
              value={this.state.code}
              className="form-control"
              placeholder="Type Something like AAPL US EQUITY ...."
              onChange={this.fetchSuggestions}
              autoComplete="off"
            />
            <div className="input-group-append">
              <button className="btn btn-success" type="submit" onClick={this.handleSubmit}>
                Search
              </button>
            </div>
          </div>
          <div className="dropdown">
            <ul className="list-group">
              {this.state.suggestions.map((value, index) => {
                return (
                  <li
                    className="list-group-item list-group-flush"
                    onClick={this.selectedScrip}
                    id={value.code}
                    key={index}
                  >
                    {value.code}
                    &nbsp; ({value.name}) &nbsp;
                    <img src={Flags[value.country_iso]}></img>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        {this.state.data != null && this.state.weekly_eod != null && (
          <TradingChart
            eod={this.state.weekly_eod.eod}
            name={this.state.data.name}
            timeframe="weekly"
            dateFormat="MMM YY"
            last_price={this.state.data.last_price}
            supports={
              this.state.weekly_eod.support_resistance.supports != null
                ? this.state.weekly_eod.support_resistance.supports
                : []
            }
            resistances={
              this.state.weekly_eod.support_resistance.resistances != null
                ? this.state.weekly_eod.support_resistance.resistances
                : []
            }
          />
        )}
        {this.state.data != null && (
          <div className="row container">
            <h3>OI Data:</h3>

            <ul className="list-group list-group-flush col-sm-6">
              <li className="list-group-item">
                CALL OI 0:
                {this.state.data.call_oi0.toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2
                })}
              </li>
              <li className="list-group-item">
                CALL OI 1:
                {this.state.data.call_oi1.toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2
                })}
              </li>
              <li className="list-group-item">
                CALL OI 2:
                {this.state.data.call_oi2.toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2
                })}
              </li>
              <li className="list-group-item">
                CALL OI 3:
                {this.state.data.call_oi3.toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2
                })}
              </li>
              <li className="list-group-item">
                CALL OI 4:
                {this.state.data.call_oi4.toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2
                })}
              </li>
            </ul>

            <ul className="list-group list-group-flush col-sm-6">
              <li className="list-group-item">
                PUT OI 0:
                {this.state.data.put_oi0.toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2
                })}
              </li>
              <li className="list-group-item">
                PUT OI 1:
                {this.state.data.put_oi1.toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2
                })}
              </li>
              <li className="list-group-item">
                PUT OI 2:
                {this.state.data.put_oi2.toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2
                })}
              </li>
              <li className="list-group-item">
                PUT OI 3:
                {this.state.data.put_oi3.toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2
                })}
              </li>
              <li className="list-group-item">
                PUT OI 4:
                {this.state.data.put_oi4.toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2
                })}
              </li>
            </ul>
          </div>
        )}
        {this.state.data != null && this.state.daily_eod != null && (
          <TradingChart
            eod={this.state.daily_eod.eod}
            name={this.state.data.name}
            timeframe="daily"
            dateFormat="DD MMM"
            last_price={this.state.data.last_price}
            supports={
              this.state.daily_eod.support_resistance.supports != null
                ? this.state.daily_eod.support_resistance.supports
                : []
            }
            resistances={
              this.state.daily_eod.support_resistance.resistances != null
                ? this.state.daily_eod.support_resistance.resistances
                : []
            }
          />
        )}
        {this.state.data != null && this.state.hourly_eod != null && (
          <TradingChart
            eod={this.state.hourly_eod.eod}
            name={this.state.data.name}
            timeframe="hourly"
            dateFormat="MM-D h:mm A"
            last_price={this.state.data.last_price}
            supports={
              this.state.hourly_eod.support_resistance.supports != null
                ? this.state.hourly_eod.support_resistance.supports
                : []
            }
            resistances={
              this.state.hourly_eod.support_resistance.resistances != null
                ? this.state.hourly_eod.support_resistance.resistances
                : []
            }
          />
        )}
      </div>
    );
  }
}

export default TradingChartPage;
