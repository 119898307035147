import axios from 'axios';
import dayjs from 'dayjs';
import Toast from 'light-toast';
import React, { useState, useEffect } from 'react';

import { fetchAuthSession } from 'aws-amplify/auth';

import { FaTrash } from 'react-icons/fa';
import cookies from '../utils/cookies.util';
import base_url from '../utils/request.util';

const RawPayinPayoutAdd = () => {
  const [rawData, setRawData] = useState([]);
  const [portfolioId, setPortfolioId] = useState(null);

  const handleChange = (event, index) => {
    const updatedRawData = [...rawData];
    const key = event.target.id;
    updatedRawData[index][key] = event.target.value;

    if (key === 'quantity') {
      if (updatedRawData[index]['quantity'] !== '') {
        updatedRawData[index]['cost_price'] =
          (updatedRawData[index]['debit'] - updatedRawData[index]['credit']) /
          updatedRawData[index]['quantity'];
      } else {
        updatedRawData[index]['cost_price'] = '';
      }
    }

    if (key === 'cost_price') {
      if (updatedRawData[index]['cost_price'] !== '') {
        updatedRawData[index]['quantity'] =
          (updatedRawData[index]['debit'] - updatedRawData[index]['credit']) /
          updatedRawData[index]['cost_price'];
      } else {
        updatedRawData[index]['quantity'] = '';
      }
    }

    setRawData(updatedRawData);
  };

  const deleteRow = (index) => {
    setRawData((prevRawData) => {
      const newRawData = [...prevRawData];
      newRawData.splice(index, 1);
      return newRawData;
    });
  };

  const handleSubmit = async () => {
    Toast.loading('In Progress', () => {});

    try {
      await fetchAuthSession();
      const config = {
        headers: {
          Authorization: cookies.get('Authorization'),
          'User-Specified': cookies.get('User-Specified'),
          'Type-Specified': cookies.get('Type-Specified')
        }
      };

      for (let i = 0; i < rawData.length; i++) {
        if (
          ['buy', 'sell'].indexOf(rawData[i].sub_type) > -1 ||
          (['inward', 'outward'].indexOf(rawData[i].sub_type) > -1 && rawData[i].isin_code != null)
        ) {
          const orderData = {
            code: rawData[i].isin_code,
            portfolio_id: portfolioId,
            buying_datetime: rawData[i].buying_datetime,
            cost_price: rawData[i].cost_price,
            quantity: rawData[i].quantity,
            exchange_rate: 1,
            currency: rawData[i].currency || 'USD',
            final_asset_class: 'unknown',
            final_asset_sub_class: 'unknown',
            face_value: 1,
            notional_quantity: rawData[i].quantity,
            remark: rawData[i].remark,
            external_transaction:
              ['inward', 'outward'].indexOf(rawData[i].sub_type) > -1 ? true : false
          };

          await axios.post(`${base_url}/portfolio/order`, orderData, config);
        } else {
          const ppData = {
            portfolio_id: portfolioId,
            buying_datetime: rawData[i].buying_datetime,
            type: rawData[i].credit - rawData[i].debit > 0 ? 'payin' : 'payout',
            sub_type: rawData[i].sub_type,
            amount: rawData[i].credit - rawData[i].debit,
            currency: rawData[i].currency || 'USD',
            remark: rawData[i].remark,
            isin_code: rawData[i].isin_code
          };

          await axios.post(`${base_url}/portfolio/payin-payout`, ppData, config);
        }
      }

      const res = await axios.get(`${base_url}/portfolio/${portfolioId}`, config);
      window.location.href = `/portfolio/ledger?clientId=${res.data.item.client_id}`;

      config.data = {
        portfolio_id: portfolioId
      };

      await axios.delete(`${base_url}/portfolio/raw-payin-payout`, config);
      Toast.success('Success', 1000, () => {});
    } catch (error) {
      console.log(error);
      Toast.fail('Error Performing Action', 3000);
    }
  };

  const fetchData = async () => {
    const search = new URLSearchParams(window.location.search);
    const portfolioId = search.get('portfolioId');

    try {
      await fetchAuthSession();
      const config = {
        headers: {
          Authorization: cookies.get('Authorization'),
          'User-Specified': cookies.get('User-Specified'),
          'Type-Specified': cookies.get('Type-Specified')
        },
        params: {
          portfolio_id: portfolioId
        }
      };

      const res = await axios.get(`${base_url}/portfolio/raw-payin-payout`, config);
      Toast.success('Success', 1000, () => {
        setRawData(res.data.items);
        setPortfolioId(portfolioId);
      });
    } catch (error) {
      console.log(error);
      Toast.fail('Error Performing Action', 3000);
    }
  };

  useEffect(() => {
    const initComponent = async () => {
      try {
        Toast.loading('In Progress', 0);
        await fetchAuthSession();
        await new Promise((resolve) => setTimeout(resolve, 10000));
        await fetchData();
        Toast.success('Success', 1000);
      } catch (error) {
        Toast.hide();
        Toast.error('An error occurred', 1000);
        console.error(error);
      }
    };

    initComponent();
  }, []);

  return (
    <div className="container-fluid">
      <table className="table">
        <thead className="table-dark">
          <tr>
            <th>Datetime</th>
            <th>Remarks</th>
            <th>Credit</th>
            <th>Debit</th>
            <th>Type</th>
            <th>ISIN</th>
            <th>Qty</th>
            <th>Price</th>
            <th>Currency</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {rawData.map((value, index) => (
            <tr key={index}>
              <td>
                <input
                  type="datetime"
                  className="form-control"
                  onChange={(event) => handleChange(event, index)}
                  value={dayjs(value.buying_datetime).format('YYYY-MM-DD HH:mm:ss')}
                />
              </td>
              <td>
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  onChange={(event) => handleChange(event, index)}
                  value={value.remark}
                />
              </td>
              <td>
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  onChange={(event) => handleChange(event, index)}
                  value={value.credit}
                />
              </td>
              <td>
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  onChange={(event) => handleChange(event, index)}
                  value={value.debit}
                />
              </td>
              <td>
                <select
                  value={value.sub_type}
                  onChange={(event) => handleChange(event, index)}
                  className="form-control"
                  required
                  id="sub_type">
                  <option>select</option>
                  <option>buy</option>
                  <option>sell</option>
                  <option>inward</option>
                  <option>outward</option>
                  <option>fees</option>
                  <option>coupon</option>
                  <option>interest</option>
                </select>
              </td>
              <td>
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  onChange={(event) => handleChange(event, index)}
                  value={value.isin}
                  id="isin_code"
                />
              </td>
              <td>
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  onChange={(event) => handleChange(event, index)}
                  value={
                    value.quantity &&
                    value.quantity.toLocaleString(undefined, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 2
                    })
                  }
                  id="quantity"
                />
              </td>
              <td>
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  onChange={(event) => handleChange(event, index)}
                  value={
                    value.cost_price &&
                    value.cost_price.toLocaleString(undefined, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 2
                    })
                  }
                  id="cost_price"
                />
              </td>
              <td>
                <select
                  className="form-control"
                  required
                  id="currency"
                  value={value.currency}
                  onChange={(event) => handleChange(event, index)}>
                  <option>USD</option>
                  <option>GBP</option>
                  <option>EUR</option>
                  <option>CHF</option>
                  <option>INR</option>
                </select>
              </td>
              <td>
                <button className="btn btn-danger" onClick={() => deleteRow(index)}>
                  <FaTrash />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {rawData.length > 0 && (
        <button className="btn btn-danger" onClick={handleSubmit}>
          Update Data
        </button>
      )}
    </div>
  );
};

export default RawPayinPayoutAdd;
