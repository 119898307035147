import axios from 'axios';
import Toast from 'light-toast';
import React, { Component } from 'react';

import { fetchAuthSession } from 'aws-amplify/auth';
import AWS from 'aws-sdk';

import cookies from '../utils/cookies.util';
import base_url, { bucket_name, region } from '../utils/request.util';

class TermSheetUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClear = this.handleClear.bind(this);
  }

  async uploadFile(file) {
    console.log(file);

    const isin_code = file.name.split('.')[0];
    const bucketName = bucket_name;

    fetchAuthSession.then((credentials) => {
      AWS.config.region = region;
      console.log({
        accessKeyId: credentials.credentials.accessKeyId,
        secretAccessKey: credentials.credentials.secretAccessKey,
        sessionToken: credentials.credentials.sessionToken
      });
      AWS.config.update({
        accessKeyId: credentials.credentials.accessKeyId,
        secretAccessKey: credentials.credentials.secretAccessKey,
        sessionToken: credentials.credentials.sessionToken
      });

      const s3 = new AWS.S3({
        apiVersion: '2006-03-01',
        params: { Bucket: bucketName },
        region
      });

      console.log('s3', {
        Bucket: bucketName,
        Key: `structures/term-sheets/${file.name}`,
        Body: file.fileObj,
        ACL: 'public-read'
      });

      s3.upload(
        {
          Bucket: bucketName,
          Key: `structures/term-sheets/${isin_code}.pdf`,
          Body: file.fileObj,
          ACL: 'public-read'
        },
        function (err, data) {
          if (err) {
            console.log(err);
            Toast.fail('there was an error uploading your file', 1000, () => { });
          } else {
            console.log(data);
          }
        }
      );
    });
  }

  handleChange(event) {
    const fileNames = [];
    for (let index = 0; index < event.target.files.length; index++) {
      fileNames.push({ name: event.target.files[index].name, fileObj: event.target.files[index] });
    }
    this.setState({ files: fileNames });
  }

  handleClear(event) {
    document.getElementById('term_sheets').value = '';
    this.setState({ files: [] });
  }

  async handleSubmit(event) {
    Toast.loading('In Progress', () => { });

    await fetchAuthSession().then(() => {
      for (let index = 0; index < this.state.files.length; index++) {
        this.uploadFile(this.state.files[index])
          .then(() => {
            const config = {};
            config.headers = {
              'Authorization': cookies.get('Authorization'),
              'User-Specified': cookies.get('User-Specified'),
              'Type-Specified': cookies.get('Type-Specified')
            };
            config.params = {
              attribute: 'term_sheet',
              value: true
            };
            axios
              .patch(
                `${base_url}/structure-attributes/${this.state.files[index].name.split('.')[0]}`,
                {},
                config
              )
              .then((res) => {
                const files = [...this.state.files];
                files[index].uploadedSuccessfully = 'true';
                this.setState({ files });
              })
              .catch((error) => {
                console.log(error);
                Toast.fail('Error Updating DB', 3000);
              });
          })
          .catch((error) => {
            console.log(error);
            Toast.fail('Error Saving to server', 3000);
          });
      }
    });
    Toast.success('Success', 1000, () => { });
  }

  render() {
    return (
      <div className="container">
        <div className="container hidden" id="portfolioData">
          <div className="form-group">
            <label>Term Sheet (You can also upload multiple files):</label>
            <input
              type="file"
              value={this.state.term_sheets}
              onChange={this.handleChange}
              className="form-control"
              multiple
              id="term_sheets"
            />
          </div>
          <hr />
          <div className="dropdown">
            <ul className="list-group">
              {this.state.files.map((value, index) => {
                return (
                  <li
                    className="list-group-item list-group-flush"
                    onClick={this.selectedScrip}
                    id={value}
                    key={index}
                  >
                    {value.name}
                    &nbsp; ({value.uploadedSuccessfully ? 'Uploaded' : 'Not Uploaded'})
                  </li>
                );
              })}
            </ul>
          </div>
          <a className="btn btn-block btn-danger" onClick={this.handleSubmit}>
            Submit
          </a>
          <a className="btn btn-block btn-primary" onClick={this.handleClear}>
            Clear
          </a>
        </div>
      </div>
    );
  }
}

export default TermSheetUpload;
