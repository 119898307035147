import axios from 'axios';
import Toast from 'light-toast';
import React, { Component } from 'react';

import { fetchAuthSession } from 'aws-amplify/auth';

import cookies from '../utils/cookies.util';
import base_url from '../utils/request.util';

class IndexProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      code: '',
      news_data: [],
      suggestions: [],
      underlying_count: []
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.fetchSuggestions = this.fetchSuggestions.bind(this);
    this.selectedScrip = this.selectedScrip.bind(this);
  }

  async handleSubmit(event) {
    let code = this.state.code;
    Toast.loading('In Progress', () => { });
    code = code.toUpperCase();

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      axios
        .get(`${base_url}/product/${code}`, config)
        .then((res) => {
          this.setState({
            data: res.data.item
          });
          Toast.success('Success', 1000, () => { });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Trouble finding the symbol', 1000, () => { });
        });

      config.params = { exchange: 'US' };
      axios
        .get(`${base_url}/index-components/${code}`, config)
        .then((res) => {
          this.setState({
            index_components: res.data.items
          });
        })
        .catch((error) => {
          console.log(error);
        });

      axios
        .get(`${base_url}/product-news/${code}`, config)
        .then((res) => {
          this.setState({
            news_data: res.data.items
          });
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  handleChange(event) {
    let code = event.target.value;
    code = code.toUpperCase();
    this.setState({
      code
    });
  }

  async fetchSuggestions(event) {
    this.setState({
      code: event.target.value
    });
    if (event.target.value === '') return;

    await fetchAuthSession().then(() => {
      const config = {};
      config.params = { q: event.target.value };
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };

      axios
        .get(`${base_url}/product/suggest`, config)
        .then((res) => {
          this.setState({
            suggestions: res.data.items
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  selectedScrip(event) {
    this.setState(
      {
        code: event.target.id,
        suggestions: []
      },
      () => {
        this.handleSubmit();
      }
    );
  }

  async componentDidMount() {
    const search = window.location.search;
    let code = search.replaceAll('?code=', '').replaceAll('?Code=', '').replaceAll('%20', ' ');

    if (code === '') return;

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      axios
        .get(`${base_url}/product/${code}`, config)
        .then((res) => {
          const equity_data = res.data.item;

          this.setState({
            data: equity_data
          });
          code = code.replaceAll('/', ' ');
          // document.getElementById("code").value = code;
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });

      axios
        .get(`${base_url}/index-components/${code}`, config)
        .then((res) => {
          this.setState({
            index_components: res.data.items
          });
        })
        .catch((error) => {
          console.log(error);
        });

      // axios.get(`${base_url}/product-news/${code}`, config)
      //     .then(res => {
      //         this.setState({
      //             news_data: res.data.items
      //         })
      //     })
      //     .catch((error) => {
      //         console.log(error);
      //     });

      axios
        .get(`${base_url}/structure-underlying-count`, config)
        .then((res) => {
          this.setState({
            underlying_count: res.data.item
          });
        })
        .catch((error) => {
          console.log(error);
        });
    });
    Toast.success('Success', 1000, () => { });
  }

  render() {
    return (
      <div className="container-fluid">
        {/* <div className="container p-3 my-3 border">
                    <div className="container input-group mb-3">
                        <input
                            type="text"
                            id="code"
                            value={this.state.code}
                            className="form-control"
                            placeholder="Type Something like AAPL US EQUITY ...."
                            onChange={this.fetchSuggestions}
                            autoComplete="off"
                        />
                        <div className="input-group-append">
                            <button className="btn btn-success" type="submit" onClick={this.handleSubmit}>Search</button>
                        </div>
                    </div>
                    <div className="dropdown">
                        <ul className="list-group">
                            {
                                this.state.suggestions.map((value, index) => {
                                    return (
                                        <li className="list-group-item list-group-flush"
                                            onClick={this.selectedScrip}
                                            id={value.code}
                                            key={index}>
                                            {value.code}
                                            &nbsp;
                                            ({value.name})
                                            &nbsp;
                                            <img src={Flags[value.country_iso]}></img>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div> */}

        {this.state.data != null && (
          <table className="table table-border">
            <tbody>
              <tr className="table-dark">
                <td>
                  <h3>
                    <img
                      height="35px"
                      src={'https://eodhistoricaldata.com' + this.state.data.logo_url}
                    />
                    &nbsp;
                    {this.state.data.name} Components
                  </h3>
                </td>
              </tr>
              {/* <tr className="table-dark">
                                <td><center><strong>Chart</strong></center></td>
                            </tr> */}
              {/* <tr>
                                <Chart
                                    options={{
                                        chart: {
                                            height: 350,
                                            type: 'area',
                                        },
                                        title: {
                                            text: this.state.data["name"] + " Weekly Chart",
                                            align: 'left'
                                        },
                                        dataLabels: {
                                            enabled: false
                                        },
                                        tooltip: {
                                            enabled: true,
                                        },
                                        xaxis: {
                                            type: 'datetime',
                                            labels: {
                                                formatter: function (val) {
                                                    return dayjs(val).format('MMM YY')
                                                }
                                            }
                                        },
                                        yaxis: {
                                            tooltip: {
                                                enabled: true
                                            }
                                        },
                                        annotations: {
                                            yaxis: [
                                                {
                                                    y: this.state.data["last_price"],
                                                    opacity: 1,
                                                    width: '200%',
                                                    strokeDashArray: 3,
                                                    borderColor: '#000',
                                                    label: {
                                                        borderColor: '#4ACA2D',
                                                        style: {
                                                            fontSize: '12px',
                                                            color: '#fff',
                                                            background: '#4ACA2D',

                                                        },
                                                        orientation: 'horizontal',
                                                        offsetY: 7,
                                                        text: 'CMP: ' + this.state.data["last_price"]
                                                    }
                                                }
                                            ]
                                        },
                                    }}
                                    series={[{
                                        name: this.state.data["name"],
                                        data: this.state.data["eod"]
                                    }]}
                                    type="area"
                                    height={350}
                                />
                            </tr> */}
              {this.state.index_components != null && this.state.index_components.length !== 0 && (
                <>
                  <table className="table table-border">
                    <thead>
                      <tr className="table-dark">
                        <th>Index</th>
                        <th>Name</th>
                        <th></th>
                        <th>Code</th>
                        <th>Mcap (Mln)</th>
                        <th>Last Price</th>
                        <th>Mcap/Rev</th>
                        <th>Industry</th>
                        <th>Sector</th>
                        <th>Buy</th>
                        <th>Hold</th>
                        <th>Sell</th>
                        <th>PE</th>
                        <th>PB</th>
                        <th>Div Yield</th>
                        <th>IV</th>
                        <th>Next Earning</th>
                      </tr>
                    </thead>
                    <thead>
                      {this.state.index_components.map((value, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{value.name}</td>
                            <td>
                              {this.state.underlying_count && value.code != null && (
                                <span className="badge badge-primary">
                                  {this.state.underlying_count[value.code]}
                                </span>
                              )}
                            </td>
                            <td>
                              {value.code &&
                                value.code.toLocaleString(undefined, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2
                                })}
                            </td>
                            <td>
                              {value.market_capitalization_mln &&
                                value.market_capitalization_mln.toLocaleString(undefined, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2
                                })}
                            </td>
                            <td>
                              {value.last_price &&
                                value.last_price.toLocaleString(undefined, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2
                                })}
                            </td>
                            <td>
                              {value.market_capitalization_to_revenue_ttm &&
                                value.market_capitalization_to_revenue_ttm.toLocaleString(
                                  undefined,
                                  { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                )}
                            </td>
                            <td>
                              {value.industry &&
                                value.industry.toLocaleString(undefined, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2
                                })}
                            </td>
                            <td>
                              {value.sector &&
                                value.sector.toLocaleString(undefined, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2
                                })}
                            </td>
                            <td>
                              {value.buy
                                ? value.buy.toLocaleString(undefined, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2
                                })
                                : 0 + value.strong_buy
                                  ? value.strong_buy.toLocaleString(undefined, {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 2
                                  })
                                  : 0}
                            </td>
                            <td>
                              {value.hold &&
                                value.hold.toLocaleString(undefined, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2
                                })}
                            </td>
                            <td>
                              {value.sell
                                ? value.sell.toLocaleString(undefined, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2
                                })
                                : 0 + value.strong_sell
                                  ? value.strong_sell.toLocaleString(undefined, {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 2
                                  })
                                  : 0}
                            </td>
                            <td>
                              {value.pe_ratio &&
                                value.pe_ratio.toLocaleString(undefined, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2
                                })}
                            </td>
                            <td>
                              {value.price_book_mrq &&
                                value.price_book_mrq.toLocaleString(undefined, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2
                                })}
                            </td>
                            <td>
                              {value.dividend_yield &&
                                value.dividend_yield.toLocaleString(undefined, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2
                                })}
                            </td>
                            <td>
                              {value.iv_3months &&
                                value.iv_3months.toLocaleString(undefined, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2
                                })}
                            </td>
                            <td style={{ whiteSpace: 'nowrap' }}>
                              {value.earnings_date &&
                                value.earnings_date.toLocaleString(undefined, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2
                                })}
                            </td>
                          </tr>
                        );
                      })}
                    </thead>
                  </table>
                </>
              )}
            </tbody>
          </table>
        )}

        <ol className="Container-fliud">
          {this.state.news_data && this.state.news_data.length !== 0 && (
            <>
              <h4>News:</h4>
              {this.state.news_data.map((value, index) => {
                return (
                  <li className="">
                    <h5>
                      <a href={value.link}>{value.title}</a>
                    </h5>
                    <b>{value.date}</b>
                    <p>{value.content.substring(0, 500) + '...'}</p>
                  </li>
                );
              })}
            </>
          )}
        </ol>
      </div>
    );
  }
}

export default IndexProduct;
