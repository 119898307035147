import axios from 'axios';
import Toast from 'light-toast';
import React, { Component } from 'react';

import { fetchAuthSession } from 'aws-amplify/auth';

import cookies from '../utils/cookies.util';
import base_url from '../utils/request.util';

class UserAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: '',
      name: '',
      email: '',
      user_type: 'Client'
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
  }

  async handleSubmit() {
    Toast.loading('In Progress', () => { });

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      const data = {
        user_id: this.state.user_id,
        name: this.state.name,
        email: this.state.email,
        user_type: this.state.user_type
      };

      axios
        .post(`${base_url}/user`, data, config)
        .then(() => {
          Toast.success('Success', 1000, () => {
            this.setState({
              user_id: '',
              name: '',
              email: '',
              user_type: ''
            });
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  render() {
    return (
      <div className="container">
        <div className="container hidden" id="portfolioData">
          <div className="form-group">
            <label>User Id:</label>
            <input
              type="text"
              value={this.state.user_id}
              onChange={this.handleChange}
              className="form-control"
              required
              id="user_id"
            />
          </div>
          <div className="form-group">
            <label>User Type:</label>
            <select
              className="form-control"
              required
              id="user_type"
              value={this.state.user_type}
              onChange={this.handleChange}
            >
              <option>Client</option>
              <option>Admin</option>
              <option>BackOffice</option>
              <option>External</option>
              <option>RelationshipManager</option>
            </select>
          </div>
          <div className="form-group">
            <label>Name:</label>
            <input
              type="text"
              value={this.state.name}
              onChange={this.handleChange}
              className="form-control"
              required
              id="name"
            />
          </div>
          <div className="form-group">
            <label>Email:</label>
            <input
              type="text"
              value={this.state.email}
              onChange={this.handleChange}
              className="form-control"
              required
              id="email"
            />
          </div>
          <a className="btn btn-block btn-danger" onClick={this.handleSubmit}>
            Submit
          </a>
        </div>
      </div>
    );
  }
}

export default UserAdd;
