import axios from 'axios';
import Toast from 'light-toast';
import React, { Component } from 'react';

import { fetchAuthSession } from 'aws-amplify/auth';

import cookies from '../utils/cookies.util';
import base_url from '../utils/request.util';

class LinkPortfolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      portfolio_id: '',
      client_id: '',
      name: '',
      added_by: '',
      is_verified: false,
      suggestions: [],
      is_existing_portfolio: false
    };
    this.fetchSuggestions = this.fetchSuggestions.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.selectedUser = this.selectedUser.bind(this);
  }

  async fetchSuggestions(event) {
    if (event.target.value === '') return;

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      config.params = {
        q: event.target.value
      };
      axios
        .get(`${base_url}/user/suggest`, config)
        .then((res) => {
          this.setState({
            suggestions: res.data.items
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  selectedUser(event) {
    this.setState({
      client_id: event.target.id,
      suggestions: []
    });
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
    if (event.target.id === 'client_id') this.fetchSuggestions(event);
    if (event.target.id === 'is_verified' || event.target.id === 'is_existing_portfolio') {
      this.setState({
        [event.target.id]: !this.state[event.target.id]
      });
    }
  }

  async handleSubmit(event) {
    Toast.loading('In Progress', () => { });

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      const data = {
        portfolio_id: this.state.portfolio_id,
        client_id: this.state.client_id,
        name: this.state.name,
        added_by: this.state.added_by,
        is_verified: this.state.is_verified,
        is_existing_portfolio: this.state.is_existing_portfolio
      };

      axios
        .post(`${base_url}/portfolio`, data, config)
        .then((res) => {
          Toast.success('Success', 1000, () => { });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  async componentDidMount() {
    this.setState({
      added_by: cookies.get('User-Specified')
    });
  }

  render() {
    return (
      <div className="container">
        <div className="container" id="portfolioData">
          <div className="form-group">
            <label>Portfolio Id:</label>
            <div className="form-check">
              <input
                type="checkbox"
                value={this.state.is_existing_portfolio}
                onChange={this.handleChange}
                className="form-check-input"
                required
                id="is_existing_portfolio"
              />
              <label className="form-check-label">Existing Portfolio?</label>
            </div>
            <input
              type="text"
              value={this.state.portfolio_id}
              onChange={this.handleChange}
              className="form-control"
              required
              id="portfolio_id"
            />
          </div>
          <div className="form-group">
            <label>Client Id:</label>
            <input
              type="text"
              value={this.state.client_id}
              onChange={this.handleChange}
              className="form-control"
              required
              id="client_id"
            />
            <div className="dropdown">
              <ul className="list-group">
                {this.state.suggestions.map((value, index) => {
                  return (
                    <li
                      className="list-group-item list-group-flush"
                      onClick={this.selectedUser}
                      id={value.user_id}
                      key={index}
                    >
                      {value.user_id}
                    </li>
                  );
                })}
              </ul>
            </div>
            <label>
              Cant fine user? Create one
              <a
                className="btn btn-link"
                onClick={() => {
                  window.location.href = '/add-user';
                }}
              >
                here
              </a>
            </label>
          </div>
          {!this.state.is_existing_portfolio && (
            <>
              <div className="form-group">
                <label>Name:</label>
                <input
                  type="text"
                  value={this.state.name}
                  onChange={this.handleChange}
                  className="form-control"
                  required
                  id="name"
                />
              </div>
              <div className="form-group">
                <label>Added By:</label>
                <input
                  type="text"
                  disabled
                  value={this.state.added_by}
                  onChange={this.handleChange}
                  className="form-control"
                  required
                  id="added_by"
                />
              </div>
              <div className="form-check">
                <input
                  type="checkbox"
                  value={this.state.is_verified}
                  onChange={this.handleChange}
                  className="form-check-input"
                  required
                  id="is_verified"
                />
                <label className="form-check-label">Is Verified</label>
              </div>
            </>
          )}

          <hr />
          <a className="btn btn-block btn-danger" onClick={this.handleSubmit}>
            Submit
          </a>
        </div>
      </div>
    );
  }
}

export default LinkPortfolio;
