export const metadata = {
  'planetary-movements': {
    year: '200',
    month: '11',
    'aspects-start-row': '24',
    'nakshatras-entry-start-row': '13',
    'nakshatras-entry-end-row': '20',
    'signs-entry-start-row': '6',
    'signs-entry-end-row': '9'
  }
};
