import React, { Component } from 'react';

import OrderAdd from './orderAdd';
import PayinPayoutAdd from './payinPayoutAdd';

class LedgerAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formType: "order",
    };
    this.toggleFormType = this.toggleFormType.bind(this);
  }

  toggleFormType(event) {
    this.setState({
      formType: event.target.id
    });
  }

  render() {
    return (
      <div className="container">
        <div className="container">
          <div className="btn-group d-flex">
            <button type="button" onClick={this.toggleFormType} id="order" disabled={this.state.formType == "order"} className="btn btn-primary flex-fill">Trade</button>
            <button type="button" onClick={this.toggleFormType} id="payin_payout" disabled={this.state.formType == "payin_payout"} className="btn btn-primary flex-fill">Payin/Payout</button>
          </div>
          {
            this.state.formType == "order" ?
              <OrderAdd />
              :
              <PayinPayoutAdd />
          }
        </div>
      </div>
    );
  }
}

export default LedgerAdd;
