import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';
import dayjs from 'dayjs';
import Toast from 'light-toast';
import React, { Component } from 'react';
import Chart from 'react-apexcharts';
import {
    FaCheck,
    FaLink,
    FaMailBulk,
    FaPrint
} from 'react-icons/fa';
import { Link } from 'react-router-dom';

import logo from '../assets/logo.png';

import cookies from '../utils/cookies.util';
import base_url from '../utils/request.util';

const frequency = {
  1: 'Monthly',
  2: 'Bimonthly',
  3: 'Three Months',
  4: 'Quarterly',
  6: 'SemiAnnual',
  12: 'Annual',
  18: 'Eighteen Months',
  24: 'Two Years'
};

class ProductReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        underlying: []
      },
      underlying_count: [],
      index_weighage: [],
      normalised_eod: {
        items: [],
        dates: []
      },
      news_data: [],
      compact_view: false,
      structure_public_url: '',
      structure_table: []
    };

    this.gotoEquityPage = this.gotoEquityPage.bind(this);
    this.exportasEmail = this.exportasEmail.bind(this);
    this.toggleView = this.toggleView.bind(this);
    this.approveProduct = this.approveProduct.bind(this);
    this.getPublicUrl = this.getPublicUrl.bind(this);
  }

  gotoEquityPage(event) {
    const code = event.target.id;
    Toast.loading('In Progress', () => { });

    window.location.href = `/product/equity?Code=${code}`;
  }

  toggleView(event) {
    this.setState({
      [event.target.id]: !this.state[event.target.id]
    });
  }

  async getPublicUrl(event) {
    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      axios
        .post(
          `${base_url}/participation-type-public-report/${this.state.data.isin_code}`,
          {},
          config
        )
        .then((res) => {
          Toast.success('Success', 1000, () => { });
          this.setState({ structure_public_url: res.data.item.url });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  async exportasEmail() {
    Toast.loading('In Progress', () => { });

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      axios
        .post(`${base_url}/email-structure/${this.state.data.isin_code}`, {}, config)
        .then((res) => {
          Toast.success('Success', 1000, () => { });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  exportasPDF() {
    // document.getElementsByTagName("body").style["-webkit-print-color-adjust"] = "exact";
    window.print();
  }

  async approveProduct(isinCode) {
    if (cookies.get('Type-Specified') !== 'Admin') {
      Toast.fail('You are not authorized to approve product', 3000, () => { });
    }
    Toast.loading('In Progress', () => { });

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      config.params = {
        attribute: 'is_pending',
        value: null
      };
      axios
        .patch(`${base_url}/structure-attributes/${isinCode}`, {}, config)
        .then(async (res) => {
          Toast.success('Success', 1000, () => {
            window.location.href = '/structure/ideas';
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  async componentDidMount() {
    const search = new URLSearchParams(window.location.search);
    const isin_code = search.get('ISIN');

    if (isin_code === '') return;

    Toast.loading('In Progress', () => { });
    fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      axios
        .get(`${base_url}/participation-type-structure-report/${isin_code}`, config)
        .then((res) => {
          this.setState(
            {
              data: res.data.item
            },
            () => {
              Toast.success('Success', 1000, () => { });
            }
          );
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });

      axios
        .get(`${base_url}/structure-news/${isin_code}`, config)
        .then((res) => {
          this.setState({
            news_data: res.data.items
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
      axios
        .get(`${base_url}/structure-normalised-eod/${isin_code}`, config)
        .then((res) => {
          this.setState({
            normalised_eod: {
              items: res.data.item.items,
              dates: res.data.item.dates
            }
          });
        })
        .catch((error) => {
          Toast.fail('Error Performing Action', 3000);
        });
      config.params = {};
      axios
        .get(`${base_url}/structure-underlying-count`, config)
        .then(async (res) => {
          this.setState({
            underlying_count: res.data.item
          });
        })
        .catch((error) => {
          Toast.fail('Error Performing Action', 3000);
        });
      config.params = {
        isin_code
      };
      axios
        .get(`${base_url}/product-weightage-index/GSPC INDEX`, config)
        .then(async (res) => {
          this.setState({
            index_weighage: res.data.item
          });
        })
        .catch((error) => {
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  render() {
    return (
      <>
        <div className="container-fluid p-3 my-3 border" id="Report">
          <h1>
            {this.state.data.name}
            <br />
            {this.state.data.is_pending && (
              <>
                <a
                  className="btn btn-success btn-large"
                  onClick={() => this.approveProduct(this.state.data.isin_code)}
                >
                  <FaCheck /> Approve {this.state.data.isin_code}
                </a>
              </>
            )}
          </h1>
          <hr />
          <div className="card bg-primary text-white">
            <div className="card-body">
              Parameters: &nbsp;
              {this.state.data.twin_win && 'Twin Win'}
            </div>
          </div>
          <td>
            <img
              src={logo}
              style={{ position: 'fixed', left: '50px', top: '500px', zIndex: '0', opacity: '0.2' }}
            />
          </td>
          {this.state.data.isin_code != null &&
            this.state.data.isin_code.indexOf('TEMP') !== -1 && (
              <table className="table table-bordered table-md" id="paramTable">
                <tr>
                  <td className="table-dark" style={{ width: '15%' }}>
                    Issuer
                  </td>
                  <td style={{ width: '15%' }}>
                    : <b>{this.state.data.issuer}</b>
                  </td>
                  {/* <td style={{width: "1%"}}></td> */}
                  <td className="table-dark" style={{ width: '15%' }}>
                    Initial Fixing Date
                  </td>
                  <td style={{ width: '15%' }}>: {this.state.data.initial_fixing_date}</td>
                </tr>
                <tr>
                  <td className="table-dark" style={{ width: '15%' }}>
                    Barrier Type
                  </td>
                  <td style={{ width: '15%' }}>: {this.state.data.barrier_type}</td>
                  {/* <td style={{width: "1%"}}></td> */}
                  <td className="table-dark" style={{ width: '15%' }}>
                    Currency
                  </td>
                  <td style={{ width: '15%' }}>: {this.state.data.currency}</td>
                </tr>
                <tr>
                  <td className="table-dark" style={{ width: '15%' }}>
                    Upper Barrier
                  </td>
                  <td style={{ width: '15%' }}>: {this.state.data.upper_barrier}</td>
                  {/* <td style={{width: "1%"}}></td> */}
                  <td className="table-dark" style={{ width: '15%' }}>
                    Lower Barrier
                  </td>
                  <td style={{ width: '15%' }}>: {this.state.data.lower_barrier}</td>
                </tr>
                <tr>
                  <td className="table-dark" style={{ width: '15%' }}>
                    Strike (ST)
                  </td>
                  <td style={{ width: '15%' }}>: {this.state.data.strike}%</td>
                  {/* <td style={{width: "1%"}}></td> */}
                  <td className="table-dark" style={{ width: '15%' }}>
                    Capital Guarantee
                  </td>
                  <td style={{ width: '15%' }}>{this.state.data.captial_guarantee}%</td>
                </tr>
                {(this.state.data.asian_delay || this.state.data.onestar) && (
                  <>
                    <tr>
                      {this.state.data.asian_delay && (
                        <>
                          <td className="table-dark" style={{ width: '15%' }}>
                            Asian Delay
                          </td>
                          <td style={{ width: '15%' }}>: {this.state.data.asian_delay_value}</td>
                        </>
                      )}
                      {/* <td style={{width: "1%"}}></td> */}
                      {this.state.data.onestar && (
                        <>
                          <td className="table-dark" style={{ width: '15%' }}>
                            Onestar
                          </td>
                          <td style={{ width: '15%' }}>: {this.state.data.onestar_value}</td>
                        </>
                      )}
                    </tr>
                  </>
                )}
              </table>
            )}

          {this.state.data.isin_code != null &&
            this.state.data.isin_code.indexOf('TEMP') === -1 && (
              <table className="table table-striped table-md">
                <thead className="table-dark">
                  <tr>
                    <th>ISIN Code</th>
                    <th>Parameters</th>
                    <th>Dates</th>
                    <th>Name</th>
                    <th>Code</th>
                    <th>Sector</th>
                    <th>Initial Price</th>
                    <th>LTP</th>
                    <th>% Chg</th>
                    <th>UB</th>
                    <th>DTUB</th>
                    <th>LB</th>
                    <th>DTLB</th>
                    <th>ST</th>
                    <th>DTS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      key={this.state.data}
                      className="table-dark"
                      rowSpan={this.state.data.underlying.length + 1}
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      <strong dataToggle="tooltip" title={this.state.data.last_updated}>
                        <Link to={`/structure/shark-note/report?ISIN=${this.state.data.isin_code}`}>
                          {this.state.data.isin_code}
                        </Link>
                      </strong>
                      <br />
                      <strong>{this.state.data.issuer}</strong>
                      <br />
                      <strong>CG: {this.state.data.captial_guarantee}</strong>
                      <br />
                      <strong>
                        {this.state.data.coupon_type !== 'Conditional with Memory'
                          ? this.state.data.coupon_type
                          : 'Condtl with Memory'}
                      </strong>
                      <br />
                      <strong>
                        {this.state.data.notional_quantity != null &&
                          this.state.data.notional_quantity !== 0 &&
                          this.state.data.notional_quantity.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2
                          })}
                      </strong>
                      <br />
                      <strong>
                        {this.state.data.basket_price_latest != null &&
                          this.state.data.basket_price_latest !== 0 &&
                          this.state.data.basket_price_latest.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2
                          })}
                      </strong>
                    </td>
                    <td
                      className="table-dark"
                      rowSpan={this.state.data.underlying.length + 1}
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      <strong>{this.state.data.barrier_type}</strong>
                      <br />
                      <strong>UB: {this.state.data.upper_barrier + '%'}</strong>
                      <br />
                      <strong>LB: {this.state.data.lower_barrier + '%'}</strong>
                      <br />
                      <strong>ST: {this.state.data.strike + '%'}</strong>
                      <br />
                    </td>
                    <td
                      className="table-dark"
                      rowSpan={this.state.data.underlying.length + 1}
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      <strong>
                        In: {dayjs(this.state.data.initial_fixing_date).format('DD/MM/YY')}
                      </strong>
                      <br />
                      <strong>
                        Fn: {dayjs(this.state.data.final_fixing_date).format('DD/MM/YY')}
                      </strong>
                      <br />
                      <strong>{this.state.data.maturity} Months</strong>
                      <br />
                    </td>
                  </tr>
                  {this.state.data.underlying.map((value) => (
                    <tr>
                      <td>
                        <strong>{value.name.substring(0, 18)}</strong>
                      </td>
                      <td>
                        <Link to={`/product/equity?code=${value.code}`}>{value.code}</Link>
                      </td>
                      <td>{value.sector ? value.sector.substring(0, 18) : '-'}</td>
                      <td>
                        {value.initial_reference_price &&
                          value.initial_reference_price.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2
                          })}
                      </td>
                      <td>
                        {value.last_price &&
                          value.last_price.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2
                          })}
                      </td>
                      <td style={{ color: value.percentage_change > 0 ? 'green' : 'red' }}>
                        <strong>
                          {value.percentage_change &&
                            value.percentage_change.toLocaleString(undefined, {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2
                            })}
                          %
                        </strong>
                      </td>
                      <td>
                        {value.upper_barrier_level &&
                          value.upper_barrier_level.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2
                          })}
                      </td>
                      <td
                        style={{
                          color: value.percentage_distance_from_upper_barrier > 0 ? 'green' : 'red'
                        }}
                      >
                        <strong>
                          {value.percentage_distance_from_upper_barrier &&
                            value.percentage_distance_from_upper_barrier.toLocaleString(undefined, {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2
                            })}
                          %
                        </strong>
                      </td>
                      <td>
                        {value.lower_barrier_level &&
                          value.lower_barrier_level.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2
                          })}
                      </td>
                      <td
                        style={{
                          color: value.percentage_distance_from_lower_barrier > 0 ? 'green' : 'red'
                        }}
                      >
                        <strong>
                          {value.percentage_distance_from_lower_barrier &&
                            value.percentage_distance_from_lower_barrier.toLocaleString(undefined, {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2
                            })}
                          %
                        </strong>
                      </td>
                      <td>
                        {value.strike_level && value.strike_level
                          ? value.strike_level.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2
                          })
                          : '-'}
                      </td>
                      <td
                        style={{
                          color: value.percentage_distance_from_strike > 0 ? 'green' : 'red'
                        }}
                      >
                        <strong>
                          {value.percentage_distance_from_strike &&
                            value.percentage_distance_from_strike &&
                            value.percentage_distance_from_strike !== -100
                            ? value.percentage_distance_from_strike.toLocaleString(undefined, {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2
                            }) + '%'
                            : '-'}
                        </strong>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          <table className="table table-striped table-md">
            <thead>
              <tr>
                <th className="table-dark">Code</th>
                <th className="table-dark">Name</th>
                <th className="table-dark"></th>
                <th className="table-dark">Snp Rank</th>
                <th className="table-dark">LTP</th>
                <th className="table-dark">52W High</th>
                <th className="table-dark">52W Low</th>
                <th className="table-dark">MCap (mln)</th>
                <th className="table-dark">MCap / Revenue</th>
                <th className="table-dark">Analyst Rating</th>
                <th className="table-dark">BUY</th>
                <th className="table-dark">HOLD</th>
                <th className="table-dark">SELL</th>
                <th className="table-dark">PE</th>
                <th className="table-dark">PB</th>
                <th className="table-dark">Div Yield</th>
                <th className="table-dark">IV</th>
                <th className="table-dark">Piotroski Score</th>
                <th className="table-dark">Next Earnings</th>
              </tr>
            </thead>
            <tbody>
              {this.state.data.underlying.map((value) => (
                <tr>
                  <td className="table-dark">
                    {value.code
                      ? value.code.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })
                      : '-'}
                  </td>
                  <td className="table-dark">
                    {value.name
                      ? value.name.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })
                      : '-'}
                  </td>
                  <td>
                    {this.state.underlying_count.length !== 0 && (
                      <span className="badge badge-primary">
                        {this.state.underlying_count[value.code]}
                      </span>
                    )}
                  </td>
                  <td>
                    {this.state.index_weighage.length !== 0 &&
                      this.state.index_weighage[value.code]}
                  </td>
                  <td>
                    {value.last_price
                      ? value.last_price.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })
                      : '-'}
                  </td>
                  <td>
                    {value.week_52_high
                      ? value.week_52_high.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })
                      : '-'}
                  </td>
                  <td>
                    {value.week_52_low
                      ? value.week_52_low.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })
                      : '-'}
                  </td>
                  <td>
                    {value.market_capitalization_mln
                      ? value.market_capitalization_mln.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })
                      : '-'}
                  </td>
                  <td>
                    {value.market_capitalization_to_revenue_ttm
                      ? value.market_capitalization_to_revenue_ttm.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })
                      : '-'}
                  </td>
                  <td>
                    {value.rating
                      ? value.rating.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })
                      : '-'}
                  </td>
                  <td>
                    {value.buy != null && value.strong_buy != null
                      ? value.buy + value.strong_buy
                      : '-'}
                  </td>
                  <td>{value.hold != null ? value.hold : '-'}</td>
                  <td>
                    {value.sell != null && value.strong_sell != null
                      ? value.sell + value.strong_sell
                      : '-'}
                  </td>
                  <td>
                    {value.trailing_pe
                      ? value.trailing_pe.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })
                      : '-'}
                  </td>
                  <td>
                    {value.price_book_mrq
                      ? value.price_book_mrq.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })
                      : '-'}
                  </td>
                  <td>
                    {value.dividend_yield
                      ? value.dividend_yield.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })
                      : '-'}
                  </td>
                  <td>
                    {value.iv_3months
                      ? value.iv_3months.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })
                      : '-'}
                  </td>
                  <td>
                    {value.piotroski_score
                      ? value.piotroski_score.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })
                      : '-'}
                  </td>
                  <td>
                    {value.earnings_date
                      ? value.earnings_date.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })
                      : '-'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Chart
            id="normalisedChart"
            options={{
              chart: {
                height: 350,
                width: 300,
                type: 'line'
              },
              title: {
                text: `Normalised Chart - ${this.state.data.isin_code}`,
                align: 'left'
              },
              dataLabels: {
                enabled: false
              },
              tooltip: {
                enabled: true
              },
              stroke: {
                width: [2, 2, 2],
                curve: 'straight',
                dashArray: [0, 0, 0]
              },
              xaxis: {
                type: 'datetime',
                labels: {
                  formatter: function (val) {
                    return dayjs(val).format('MMM YYYY');
                  }
                },
                categories: this.state.normalised_eod.dates
              },
              yaxis: {
                decimalsInFloat: 2,
                tooltip: {
                  enabled: true
                }
              },

              annotations: {
                yaxis: [
                  {
                    y: this.state.data.barrier,
                    opacity: 1,
                    width: '200%',
                    strokeDashArray: 0,
                    borderColor: '#cc2616',
                    label: {
                      borderColor: '#cc2616',
                      style: {
                        fontSize: '12px',
                        color: '#fff',
                        background: '#cc2616'
                      },
                      orientation: 'horizontal',
                      offsetY: 4,
                      text:
                        this.state.data.barrier &&
                        'Barrier: ' +
                        this.state.data.barrier.toLocaleString(undefined, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2
                        })
                    }
                  }
                ],
                xaxis: [
                  {
                    x: new Date(this.state.data.initial_fixing_date).getTime(),
                    opacity: 1,
                    width: '200%',
                    strokeDashArray: 0,
                    borderColor: '#cc2616',
                    label: {
                      borderColor: '#cc2616',
                      style: {
                        fontSize: '12px',
                        color: '#fff',
                        background: '#cc2616'
                      },
                      orientation: 'horizontal',
                      offsetY: 4,
                      text:
                        this.state.data.initial_fixing_date &&
                        'In: ' + this.state.data.initial_fixing_date
                    }
                  }
                ]
              }
            }}
            series={this.state.normalised_eod.items.map((code_data) => {
              return { name: code_data.name, data: code_data.eod };
            })}
            type="line"
            height={350}
          />
          {/* <hr />
                    <div className="card bg-primary text-white">
                        <div className="card-body">
                            Scrips Info:
                            <button
                                className="btn btn-light"
                                id="compact_view"
                                style={{ float: "right" }}
                                onClick={this.toggleView}
                            >
                                Toggle View
                            </button>
                        </div>
                    </div>
                    {
                        this.state.compact_view ?
                            <table id="table" className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th colSpan="2" className="table-dark">Stocks</th>
                                        {
                                            this.state.data.underlying.map((value, index) => {
                                                return (<>
                                                    <th>{value.name}</th>
                                                </>)
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tr>
                                    <th rowSpan="23" className="table-dark">Basic Info</th>

                                    <th className="table-dark">Code</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td><button className="btn btn-basic" onClick={this.gotoEquityPage} id={value.code}>{value.code}</button></td>
                                            </>)
                                        })
                                    }
                                </tr>
                                <tr>
                                    <th className="table-dark">Market Capitalization Mln</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td>{value.market_capitalization_mln ? value.market_capitalization_mln.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }) : "-"}</td>
                                            </>)
                                        })
                                    }
                                </tr>
                                <tr>
                                    <th className="table-dark">Price</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td>{value.last_price ? value.last_price : "-"}</td>
                                            </>)
                                        })
                                    }
                                </tr>
                                <tr>
                                    <th className="table-dark">52 Weeks High</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td>{value["week_52_high"] ? value["week_52_high"].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }) : "-"}</td>
                                            </>)
                                        })
                                    }
                                </tr>
                                <tr>
                                    <th className="table-dark">52 Weeks Low</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td>{value["week_52_low"] ? value["week_52_low"].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }) : "-"}</td>
                                            </>)
                                        })
                                    }
                                </tr>
                                <tr>
                                    <th className="table-dark">Trailing PE</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td>{value["trailing_pe"].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })}</td>
                                            </>)
                                        })
                                    }
                                </tr>
                                <tr>
                                    <th className="table-dark">Forward PE</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td>{value["forward_pe"].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })}</td>
                                            </>)
                                        })
                                    }

                                </tr>
                                <tr>
                                    <th className="table-dark">Operating Margin</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td>{value["operating_margin_ttm"].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })}%</td>
                                            </>)
                                        })
                                    }

                                </tr>
                                <tr>
                                    <th className="table-dark">Return On Assets</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td>{value["return_on_assets_ttm"].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })}%</td>
                                            </>)
                                        })
                                    }

                                </tr>
                                <tr>
                                    <th className="table-dark">Revenue</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td>{value["revenue_ttm"].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })}</td>
                                            </>)
                                        })
                                    }

                                </tr>
                                <tr>
                                    <th className="table-dark">MCap/Revenue</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td>{value["market_capitalization_to_revenue_ttm"].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })}</td>
                                            </>)
                                        })
                                    }

                                </tr>
                                <tr>
                                    <th className="table-dark">PB Ratio</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td>{value["price_book_mrq"].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })}</td>
                                            </>)
                                        })
                                    }

                                </tr>
                                <tr>
                                    <th className="table-dark">ROE</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td>{value["return_on_equity_ttm"].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })} %</td>
                                            </>)
                                        })
                                    }

                                </tr>
                                <tr>
                                    <th className="table-dark">EPS</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td>{value["diluted_eps_ttm"].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })}</td>
                                            </>)
                                        })
                                    }

                                </tr>
                                <tr>
                                    <th className="table-dark">Dividends Yields</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td>{value["dividend_yield"].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })} %</td>
                                            </>)
                                        })
                                    }

                                </tr>
                                <tr>
                                    <th className="table-dark">Consensus Rating</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td><strong>{value.rating ? value.rating.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }) : "-"}</strong></td>
                                            </>)
                                        })
                                    }

                                </tr>
                                <tr>
                                    <th className="table-dark">Strong Buy</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td style={{ color: "green" }}><strong>{value.strong_buy ? value.strong_buy : "-"}</strong></td>
                                            </>)
                                        })
                                    }

                                </tr>
                                <tr>
                                    <th className="table-dark">Buy</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td style={{ color: "green" }}><strong>{value.buy ? value.buy : "-"}</strong></td>
                                            </>)
                                        })
                                    }

                                </tr>
                                <tr >
                                    <th className="table-dark">Hold</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td style={{ color: "gold" }}><strong>{value.hold ? value.hold : "-"}</strong></td>
                                            </>)
                                        })
                                    }

                                </tr>
                                <tr>
                                    <th className="table-dark">Sell</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td style={{ color: "red" }}><strong>{value.sell ? value.sell : "-"}</strong></td>
                                            </>)
                                        })
                                    }

                                </tr>
                                <tr>
                                    <th className="table-dark">Strong Sell</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td style={{ color: "red" }}><strong>{value.strong_sell ? value.strong_sell : "-"}</strong></td>
                                            </>)
                                        })
                                    }

                                </tr>
                                <tr>
                                    <th className="table-dark">12 Months Target</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td>{value["wall_street_target_price"]}</td>
                                            </>)
                                        })
                                    }

                                </tr>
                                <tr>
                                    <th className="table-dark">Return Potential</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td>{value["return_potential"].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })} %</td>
                                            </>)
                                        })
                                    }

                                </tr>
                                <tr><td colSpan=""></td></tr>
                                <tr>
                                    <th rowSpan="3" className="table-dark">Other Info</th>
                                    <th className="table-dark">Sector</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td>{value.sector ? value.sector : "-"}</td>
                                            </>)
                                        })
                                    }
                                </tr>
                                <tr>
                                    <th className="table-dark">Industry</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td>{value.industry ? value.industry : "-"}</td>
                                            </>)
                                        })
                                    }
                                </tr>
                                <tr>
                                    <th className="table-dark">Currency</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td>{value.currency_code ? value.currency_code : "-"}</td>
                                            </>)
                                        })
                                    }
                                </tr>
                            </table>
                            :
                            <table id="table" className="table table-bordered">
                                <thead>
                                    <tr>

                                        <th colSpan="2" className="table-dark">Stocks</th>
                                        {
                                            this.state.data.underlying.map((value, index) => {
                                                return (<>
                                                    <th>{value.name}</th>
                                                </>)
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tr>
                                    <th rowSpan="8" className="table-dark">Basic Info</th>

                                    <th className="table-dark">Code</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td><button className="btn btn-basic" onClick={this.gotoEquityPage} id={value.code}>{value.code}</button></td>
                                            </>)
                                        })
                                    }
                                </tr>
                                <tr>
                                    <th className="table-dark">Sector</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td>{value.sector ? value.sector : "-"}</td>
                                            </>)
                                        })
                                    }
                                </tr>
                                <tr>
                                    <th className="table-dark">Industry</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td>{value.industry ? value.industry : "-"}</td>
                                            </>)
                                        })
                                    }
                                </tr>
                                <tr>
                                    <th className="table-dark">Market Capitalization Mln</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td>{value.market_capitalization_mln ? value.market_capitalization_mln.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }) : "-"}</td>
                                            </>)
                                        })
                                    }
                                </tr>
                                <tr>
                                    <th className="table-dark">Currency</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td>{value.currency_code ? value.currency_code : "-"}</td>
                                            </>)
                                        })
                                    }
                                </tr>
                                <tr>
                                    <th className="table-dark">Price</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td>{value.last_price ? value.last_price : "-"}</td>
                                            </>)
                                        })
                                    }
                                </tr>
                                <tr>
                                    <th className="table-dark">52 Weeks High</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td>{value["week_52_high"] ? value["week_52_high"].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }) : "-"}</td>
                                            </>)
                                        })
                                    }
                                </tr>
                                <tr>
                                    <th className="table-dark">52 Weeks Low</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td>{value["week_52_low"] ? value["week_52_low"].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }) : "-"}</td>
                                            </>)
                                        })
                                    }
                                </tr>
                                <tr><td colSpan=""></td></tr>
                                <tr>
                                    <th rowSpan="8" className="table-dark">Analysts Rating</th>

                                    <th className="table-dark">Consensus Rating</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td><strong>{value.rating ? value.rating.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }) : "-"}</strong></td>
                                            </>)
                                        })
                                    }

                                </tr>
                                <tr>
                                    <th className="table-dark">Strong Buy</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td style={{ color: "green" }}><strong>{value.strong_buy ? value.strong_buy : "-"}</strong></td>
                                            </>)
                                        })
                                    }

                                </tr>
                                <tr>
                                    <th className="table-dark">Buy</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td style={{ color: "green" }}><strong>{value.buy ? value.buy : "-"}</strong></td>
                                            </>)
                                        })
                                    }

                                </tr>
                                <tr >
                                    <th className="table-dark">Hold</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td style={{ color: "gold" }}><strong>{value.hold ? value.hold : "-"}</strong></td>
                                            </>)
                                        })
                                    }

                                </tr>
                                <tr>
                                    <th className="table-dark">Sell</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td style={{ color: "red" }}><strong>{value.sell ? value.sell : "-"}</strong></td>
                                            </>)
                                        })
                                    }

                                </tr>
                                <tr>
                                    <th className="table-dark">Strong Sell</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td style={{ color: "red" }}><strong>{value.strong_sell ? value.strong_sell : "-"}</strong></td>
                                            </>)
                                        })
                                    }

                                </tr>
                                <tr>
                                    <th className="table-dark">12 Months Target</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td>{value["wall_street_target_price"]}</td>
                                            </>)
                                        })
                                    }

                                </tr>
                                <tr>
                                    <th className="table-dark">Return Potential</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td>{value["return_potential"] ? value["return_potential"].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }) : "-"} %</td>
                                            </>)
                                        })
                                    }

                                </tr>
                                <tr><td colSpan=""></td></tr>
                                <tr>
                                    <th rowSpan="10" className="table-dark">Fundamentals</th>
                                    <th className="table-dark">Trailing PE</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td>{value["trailing_pe"] ? value["trailing_pe"].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }) : "-"}</td>
                                            </>)
                                        })
                                    }
                                </tr>
                                <tr>
                                    <th className="table-dark">Forward PE</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td>{value["forward_pe"] ? value["forward_pe"].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }) : "-"}</td>
                                            </>)
                                        })
                                    }

                                </tr>
                                <tr>
                                    <th className="table-dark">Operating Margin</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td>{value["operating_margin_ttm"] ? value["operating_margin_ttm"].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }) : "-"}%</td>
                                            </>)
                                        })
                                    }

                                </tr>
                                <tr>
                                    <th className="table-dark">Return On Assets</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td>{value["return_on_assets_ttm"] ? value["return_on_assets_ttm"].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }) : "-"}%</td>
                                            </>)
                                        })
                                    }

                                </tr>
                                <tr>
                                    <th className="table-dark">Revenue</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td>{value["revenue_ttm"] ? value["revenue_ttm"].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }) : "-"}</td>
                                            </>)
                                        })
                                    }

                                </tr>
                                <tr>
                                    <th className="table-dark">MCap/Revenue</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td>{value["market_capitalization_to_revenue_ttm"] ? value["market_capitalization_to_revenue_ttm"].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }) : "-"}</td>
                                            </>)
                                        })
                                    }

                                </tr>
                                <tr>
                                    <th className="table-dark">PB Ratio</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td>{value["price_book_mrq"] ? value["price_book_mrq"].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }) : "-"}</td>
                                            </>)
                                        })
                                    }

                                </tr>
                                <tr>
                                    <th className="table-dark">ROE</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td>{value["return_on_equity_ttm"] ? value["return_on_equity_ttm"].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }) : "-"} %</td>
                                            </>)
                                        })
                                    }

                                </tr>
                                <tr>
                                    <th className="table-dark">EPS</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td>{value["diluted_eps_ttm"] ? value["diluted_eps_ttm"].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }) : "-"}</td>
                                            </>)
                                        })
                                    }

                                </tr>
                                <tr>
                                    <th className="table-dark">Dividends Yields</th>
                                    {
                                        this.state.data.underlying.map((value, index) => {
                                            return (<>
                                                <td>{value["dividend_yield"] ? value["dividend_yield"].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }) : "-"} %</td>
                                            </>)
                                        })
                                    }

                                </tr>
                            </table>

                    } */}
          <hr />
          {this.state.data.underlying.map((value, index) => {
            return (
              <>
                <div className="card bg-primary text-white">
                  <div className="card-body">
                    <img height="35px" src={'https://eodhistoricaldata.com' + value.logo_url} />
                    &nbsp;
                    {value.name}
                  </div>
                </div>
                <p>{value.description && value.description.substring(0, 700) + '...'}</p>
                <Chart
                  options={{
                    chart: {
                      height: 350,
                      width: 300,
                      type: 'area'
                    },
                    title: {
                      text: value.name + ' Weekly Chart',
                      align: 'left'
                    },
                    dataLabels: {
                      enabled: false
                    },
                    annotations: {
                      yaxis: [
                        {
                          y: value.upper_barrier_level,
                          opacity: 1,
                          width: '200%',
                          strokeDashArray: 0,
                          borderColor: '#FF0000',
                          label: {
                            borderColor: '#FF0000',
                            style: {
                              fontSize: '12px',
                              color: '#fff',
                              background: '#FF0000'
                            },
                            orientation: 'horizontal',
                            offsetY: 7,
                            text:
                              'UB: ' +
                              value.upper_barrier_level.toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                              })
                          }
                        },
                        {
                          y: value.lower_barrier_level,
                          opacity: 1,
                          width: '200%',
                          strokeDashArray: 0,
                          borderColor: '#FF0000',
                          label: {
                            borderColor: '#FF0000',
                            style: {
                              fontSize: '12px',
                              color: '#fff',
                              background: '#FF0000'
                            },
                            orientation: 'horizontal',
                            offsetY: 7,
                            text:
                              'LB: ' +
                              value.lower_barrier_level.toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                              })
                          }
                        },
                        {
                          y: value.last_price,
                          opacity: 1,
                          width: '200%',
                          strokeDashArray: 0,
                          borderColor: '#4ACA2D',
                          label: {
                            borderColor: '#4ACA2D',
                            style: {
                              fontSize: '12px',
                              color: '#fff',
                              background: '#4ACA2D'
                            },
                            orientation: 'horizontal',
                            offsetY: 4,
                            text:
                              'CMP: ' + value.last_price
                                ? value.last_price.toLocaleString(undefined, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2
                                })
                                : '-'
                          }
                        }
                      ],
                      points: [
                        {
                          x: new Date(this.state.data.initial_fixing_date).getTime(),
                          y: value.initial_reference_price,
                          marker: {
                            size: 8
                          },
                          label: {
                            borderColor: '#FF4560',
                            strokeColors: 'black',
                            color: 'red',
                            fillOpacity: 1,
                            text: 'B'
                          }
                        }
                      ]
                    },
                    tooltip: {
                      enabled: true
                    },
                    xaxis: {
                      type: 'datetime',
                      labels: {
                        formatter: function (val) {
                          return dayjs(val).format('DD MMM YYYY');
                        }
                      }
                    },
                    yaxis: {
                      decimalsInFloat: 2,
                      tooltip: {
                        enabled: true
                      }
                    }
                  }}
                  series={[
                    {
                      name: value.name,
                      data: value.eod
                    }
                  ]}
                  type="area"
                  height={350}
                />
                <hr />
              </>
            );
          })}
        </div>

        <h4>News:</h4>
        <ol className="Container-fliud">
          {this.state.news_data &&
            this.state.news_data.length !== 0 &&
            this.state.news_data.map((value, index) => {
              return (
                <li className="">
                  <h5>
                    <a href={value.link}>{value.title}</a>
                  </h5>
                  <b>{value.date}</b>
                  <p>{value.content.substring(0, 500) + '...'}</p>
                </li>
              );
            })}
        </ol>

        {this.state.structure_public_url !== '' && (
          <div className="alert alert-info">
            <strong>Copy Url:</strong> {this.state.structure_public_url}
          </div>
        )}
        <button type="button" className="btn btn-primary" onClick={this.exportasEmail}>
          
          <FaMailBulk /> Send as Email
        </button>
        <button type="button" className="btn btn-primary" onClick={this.exportasPDF}>
          
          <FaPrint /> Send as PDF
        </button>
        <button type="button" className="btn btn-primary" onClick={this.getPublicUrl}>
          
          <FaLink /> Generate public URL
        </button>

        {/* <Pdf
                    targetRef={ref}
                    options={{
                        orientation: 'portrait',
                        unit: 'mm',
                        format: ""
                    }}
                    x={0}
                    y={0}
                    scale={0.5}
                    filename={this.state.data.isin_code + ".pdf"}
                >
                    {({toPdf}) => (
                        <button className="btn btn-primary" onClick={toPdf}><FaPrint/> Generate pdf</button>
                    )}
                </Pdf> */}
      </>
    );
  }
}

export default ProductReport;
