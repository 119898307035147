import axios from 'axios';
import Toast from 'light-toast';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './styles.css';

import { fetchAuthSession } from 'aws-amplify/auth';
import Flags from './flags';

import cookies from '../utils/cookies.util';
import base_url from '../utils/request.util';

class Indices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      indices: []
    };
  }

  async componentDidMount() {
    Toast.loading('In Progress', () => { });

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      config.data = {
        email: cookies.get('User-Specified')
      };

      const page =
        window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];

      const types = {
        indices: 'INDEX',
        equities: 'EQUITY',
        cryptos: 'CRYPTO',
        currencies: 'FOREX'
      };

      config.params = {
        type: types[page]
      };

      axios
        .get(`${base_url}/product`, config)
        .then((res) => {
          this.setState({
            indices: res.data.items
          });
          Toast.success('Success', 500, () => { });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  async handleDelete() {
    Toast.loading('In Progress', () => { });

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      axios
        .delete(
          `${base_url}/portfolio/order?`,
          {
            data: this.state.items_to_delete
          },
          config
        )
        .then((res) => {
          Toast.success('Success', 3000);
        })
        .catch((error) => {
          Toast.fail('Error Performing Action', 3000);
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="container row">
          <h1>List of Indices:</h1>
        </div>
        <table className="table table-sm">
          <thead className="table-dark">
            <tr>
              <th>Name</th>
              <th>Country</th>
              <th>Code</th>
            </tr>
          </thead>
          <tbody>
            {this.state.indices.map((item, index) => {
              return (
                <tr key={index}>
                  <td
                    style={{
                      backgroundColor: '#002850',
                      color: '#ffffff',
                      textDecoration: 'none',
                      border: '1px solid white',
                      '': 'no-wrap'
                    }}
                  >
                    {item.name}
                  </td>
                  <td className="table-dark">
                    <img
                      src={
                        Flags[item.country_iso]
                          ? Flags[item.country_iso]
                          : `https://flags.fmcdn.net/data/flags/mini/${item.country_iso ? item.country_iso.toLowerCase() : ''
                          }.png`
                      }
                    ></img>
                  </td>

                  <td className="table-dark">
                    {item.code != null ? (
                      <Link to={`/product/equity?code=${item.code}`}>{item.code}</Link>
                    ) : (
                      'N.A.'
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default Indices;
