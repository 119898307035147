import React, { Component } from 'react';

// import dotenv from 'dotenv';
// dotenv.config({ path: './.env' });

class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    // this.logOutUser = this.logOutUser.bind(this);
  }

  render() {
    return (
      <table className="table table-bordered">
        <thead>
          <tr>
            {this.props.headers.map((headers) => {
              return <th key={headers}>{headers}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {this.props.data.map((row, index) => {
            return (
              <tr key={index}>
                {this.props.headers.map((key, index) => {
                  return (
                    <td key={`${row}-${key}`}>
                      {row[key] &&
                        row[key].toLocaleString(undefined, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2
                        })}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}

export default Table;
