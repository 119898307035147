export const attributes = [
  'isin_code',
  'country',
  'issuer',
  'snp',
  'moodys',
  'coupon',
  'coupon_frequency',
  'maturity',
  'years_to_maturity',
  'next_coupon_date',
  'bid',
  'ask',
  'ytm',
  'ytc',
  'mod_duration',
  'currency',
  'amount_mil',
  'reset_rate',
  'msci_esg_rating',
  'esg_bond_type',
  'virtual_assets',
  'risk_rating',
  'credit_direction',
  'recommendation',
  'analyst'
];
export const attr_to_col = {
  isin_code: 'ISIN',
  country: 'Country',
  issuer: 'Issuer',
  snp: 'SNP',
  moodys: 'Moodys',
  coupon: 'Coupon',
  coupon_frequency: 'Coupon Frequency',
  maturity: 'Maturity',
  years_to_maturity: 'Years To Maturity',
  next_coupon_date: 'Next Coupon Date',
  bid: 'Bid',
  ask: 'Ask',
  ytm: 'YTM',
  ytc: 'YTC',
  mod_duration: 'Mod Duration',
  currency: 'Currency',
  amount_mil: 'Amount_Mil',
  reset_rate: 'Reset_Rate',
  msci_esg_rating: 'Msci Esg Rating',
  esg_bond_type: 'Esg Bond Type',
  virtual_assets: 'Virtual Assets',
  risk_rating: 'Risk Rating',
  credit_direction: 'Credit Direction',
  recommendation: 'Recommendation',
  analyst: 'Analyst'
};
