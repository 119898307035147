import { fetchAuthSession } from 'aws-amplify/auth';
import AWS from 'aws-sdk';
import axios from 'axios';
import Toast from 'light-toast';
import React, { Component } from 'react';
import Flags from './flags';

import { FaPen, FaPlus, FaSave, FaTrash } from 'react-icons/fa';
import './styles.css';

import cookies from '../utils/cookies.util';
import { formatDate } from '../utils/date.util';
import base_url, { bucket_name, region } from '../utils/request.util';

import issuers from './constants/issuers.json';

const unwantedAttributes = ['underlying_count'];

class UpdateForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isin_code: '',
      product_type: 'autocallable',
      issuer: null,
      autocall_percentage: '100',
      coupon_percentage: '65',
      autocall_step: '0',
      coupon_step: '0',
      barrier_type: 'European',
      barrier: '65',
      coupon: '8',
      flat_coupon: false,
      strike: '65',
      coupon_type: 'Memory',
      currency: 'USD',
      weightage: 'Worst Off',
      maturity: '24',
      autocall_frequency: '3',
      coupon_frequency: '3',
      autocall_delay: '0',
      coupon_delay: '0',
      initial_fixing_date: formatDate(new Date()),
      issue_date: formatDate(new Date(new Date().setDate(new Date().getDate() + 5))),
      final_fixing_date: formatDate(new Date(new Date().setDate(new Date().getDate() + 730))),
      redemption: formatDate(new Date(new Date().setDate(new Date().getDate() + 735))),
      underlying: [],
      soft_callable: false,
      coupon_memory: true,
      suggestions: [],
      delayed_frequency: '0',
      autocall_table: null,
      coupon_table: null,
      new_isin_code: '',
      knockin_kickin: false,
      knockin_kickin_value: '0',
      asian_delay: false,
      asian_delay_value: '0',
      onestar: false,
      onestar_value: '0',
      underlying_count: {},
      is_pending: null // NOTE This needs to be changed once requirement is back again
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.addScrip = this.addScrip.bind(this);
    this.addScripSubmit = this.addScripSubmit.bind(this);
    this.removeScrip = this.removeScrip.bind(this);
    this.updateScrip = this.updateScrip.bind(this);
    this.fetchsuggestions = this.fetchsuggestions.bind(this);
    this.selectedScrip = this.selectedScrip.bind(this);
    this.discardScrips = this.discardScrips.bind(this);
    this.setProductData = this.setProductData.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
    if (event.target.id === 'maturity') {
      this.setState((prevState) => ({
        final_fixing_date: formatDate(
          new Date(
            new Date(prevState.initial_fixing_date).setMonth(
              new Date(prevState.initial_fixing_date).getMonth() + parseInt(prevState.maturity)
            )
          )
        )
      }));
      this.setState((prevState) => ({
        redemption: formatDate(
          new Date(new Date(prevState.final_fixing_date).setDate(new Date().getDate() + 7))
        )
      }));
    } else if (
      event.target.id === 'soft_callable' ||
      event.target.id === 'coupon_memory' ||
      event.target.id === 'knockin_kickin' ||
      event.target.id === 'asian_delay' ||
      event.target.id === 'onestar' ||
      event.target.id === 'flat_coupon'
    ) {
      this.setState({
        [event.target.id]: !this.state[event.target.id]
      });
    } else if (event.target.id === 'coupon_type') {
      const coupon_memory_val = this.state.coupon_type !== 'No Memory';
      this.setState({
        coupon_memory: coupon_memory_val
      });
    } else if (event.target.id === 'barrier_type') {
      if (event.target.value === 'No barrier') {
        this.setState({
          barrier: '0'
        });
      } else {
        this.setState({
          barrier: '65'
        });
      }
    } else if (event.target.id === 'initial_fixing_date' || event.target.id === 'maturity') {
      this.setState((prevState) => ({
        issue_date: formatDate(
          new Date(new Date(prevState.initial_fixing_date).getTime() + 5 * 24 * 60 * 60 * 1000)
        ),
        final_fixing_date: formatDate(
          new Date(
            new Date(prevState.initial_fixing_date).getTime() +
            (prevState.maturity % 12 === 0
              ? (365 * prevState.maturity) / 12
              : 30 * prevState.maturity) *
            24 *
            60 *
            60 *
            1000
          )
        ),
        redemption: formatDate(
          new Date(
            new Date(prevState.initial_fixing_date).getTime() +
            (prevState.maturity % 12 === 0
              ? (365 * prevState.maturity) / 12
              : 30 * prevState.maturity + 5) *
            24 *
            60 *
            60 *
            1000
          )
        )
      }));
    }
  }

  async handleSubmit(event) {
    Toast.loading('In Progress', () => { });

    await fetchAuthSession()
      .then(() => {
        const config = {};
        config.headers = {
          'Authorization': cookies.get('Authorization'),
          'User-Specified': cookies.get('User-Specified'),
          'Type-Specified': cookies.get('Type-Specified')
        };
        const attributes = JSON.parse(JSON.stringify(this.state));
        unwantedAttributes.forEach((key) => {
          delete attributes[key];
        });
        config.data = { structure: attributes };
        axios
          .post(`${base_url}/autocallable-structure`, config.data, config)
          .then((res) => {
            Toast.success('Success', 3000, () => { });
            const isin_code = res.data.isin_code;
            window.location.href = `/structure/autocallable/report?ISIN=${isin_code}`;
          })
          .catch((error) => {
            console.log(error);
            Toast.fail('Error Performing Action', 3000);
          });
      })
      .catch((error) => {
        console.log(error);
        Toast.fail('Session Expired.', 3000, () => {
          window.location.reload();
        });
      });
  }

  async addScrip() {
    const scrip = document.getElementById('scrip').value;
    const initial_price = document.getElementById('initial_price').value;
    if (this.state.underlying.indexOf({ code: scrip.toUpperCase() }) !== -1) return;
    this.setState(
      (prevState) => ({
        underlying: [
          ...prevState.underlying,
          {
            code: scrip.toUpperCase(),
            initial_reference_price: initial_price
          }
        ],
        suggestions: []
      }),
      () => {
        fetchAuthSession().then(() => {
          const config = {};
          config.headers = {
            'Authorization': cookies.get('Authorization'),
            'User-Specified': cookies.get('User-Specified'),
            'Type-Specified': cookies.get('Type-Specified')
          };
          config.data = { structure: this.state };
          axios
            .get(`${base_url}/structure-underlying-count/${scrip}`, config)
            .then((res) => {
              const underlying_count = JSON.parse(JSON.stringify(this.state.underlying_count));
              underlying_count[scrip] = res.data.item.count;

              this.setState({
                suggestions: [],
                underlying_count
              });
              Toast.hide();
            })
            .catch((error) => {
              console.log(error);
              Toast.fail('Error Performing Action', 3000);
            });
        });
      }
    );
    document.getElementById('scrip').value = '';
    document.getElementById('initial_price').value = '';
  }

  addScripSubmit(event) {
    if (event.charcode !== 13) return;
    this.addScrip();
  }

  removeScrip(scrip_index) {
    const scrip_array = [...this.state.underlying];
    scrip_array.splice(scrip_index, 1);
    console.log(scrip_index, scrip_array);
    this.setState({
      underlying: scrip_array
    });
  }

  updateScrip(event) {
    const scrip_index = event.target.id;
    const scrip_array = [...this.state.underlying];
    let scrip_to_update = scrip_array.splice(scrip_index, 1);
    scrip_to_update = scrip_to_update[0];
    this.setState({
      underlying: scrip_array
    });
    document.getElementById('scrip').value = scrip_to_update.code;
    document.getElementById('initial_price').value = scrip_to_update.initial_reference_price;
  }

  async fetchsuggestions(event) {
    if (event.target.id === '') return;

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      config.params = { q: event.target.value };
      axios
        .get(`${base_url}/product/suggest`, config)
        .then((res) => {
          this.setState({
            suggestions: res.data.items
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  async selectedScrip(event) {
    const scrip = event.target.id;
    if (this.state.underlying.includes({ code: scrip.toUpperCase() })) return;
    Toast.loading('In Progress', () => { });

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      config.params = {
        attribute: 'last_price'
      };
      axios
        .get(`${base_url}/product/attribute/${scrip}`, config)
        .then((res) => {
          const last_price = res.data.last_price;
          document.getElementById('scrip').value = scrip;
          document.getElementById('initial_price').value = last_price;
          document.getElementById('initial_price').focus();
          this.setState({
            suggestions: []
          });
          Toast.hide();
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  discardScrips(event) {
    const scrip = event.target.id;
    if (this.state.underlying.includes({ code: scrip.toUpperCase() })) return;
    this.setState((prevState) => ({
      underlying: [...prevState.underlying, prevState.CurrentScrip],
      suggestions: []
    }));
    document.getElementById('scrip').value = scrip;
    document.getElementById('initial_price').value = '';
  }

  async setProductData() {
    Toast.loading('In Progress', () => { });
    window.location.reload(false);
  }

  async uploadFile(event) {
    Toast.loading('Uploading ...', () => { });
    const isincode = this.state.isin_code;
    const bucketName = bucket_name;

    const credentials = await fetchAuthSession();

    AWS.config.region = region;
    AWS.config.update({
      accessKeyId: credentials.credentials.accessKeyId,
      secretAccessKey: credentials.credentials.secretAccessKey,
      sessionToken: credentials.credentials.sessionToken
    });
    const s3 = new AWS.S3({
      apiVersion: '2006-03-01',
      params: { Bucket: bucketName }
    });

    console.log(event.target.files);

    s3.upload(
      {
        Bucket: bucketName,
        Key: `structures/term-sheets/${isincode}.pdf`,
        Body: event.target.files[0],
        ACL: 'private'
      },
      function (err, data) {
        if (err) {
          Toast.fail('there was an error uploading your file', 1000, () => { });
        } else {
          Toast.success('Success', 1000, () => { });
          console.log(data);
        }
      }
    );

    this.setState({
      term_sheet: true
    });
  }

  render() {
    return (
      <div className="container">
        <table className="table">
          <tr>
            <td>
              <label>Type of Structure:</label>
            </td>
            <td>
              <select
                value={this.state.product_type}
                onChange={this.handleChange}
                className="form-control"
                id="product_type"
              >
                <option>autocallable</option>
              </select>
            </td>
            <td>
              <label>currency:</label>
            </td>
            <td>
              <input
                value={this.state.currency}
                onChange={this.handleChange}
                type="text"
                className="form-control"
                id="currency"
              />
            </td>
          </tr>
          <tr>
            <td>
              <label>maturity in Months:</label>
            </td>
            <td>
              <input
                type="number"
                value={this.state.maturity}
                onChange={this.handleChange}
                className="form-control"
                id="maturity"
              />
            </td>
            <td>
              <label>weightage:</label>
            </td>
            <td>
              <input
                value={this.state.weightage}
                onChange={this.handleChange}
                type="text"
                className="form-control"
                id="weightage"
              />
            </td>
          </tr>
          <tr>
            <td>
              <label>coupon:</label>
            </td>
            <td>
              <div className="input-group mb-3">
                <input
                  value={this.state.coupon}
                  onChange={this.handleChange}
                  type="text"
                  pattern="[\d]+"
                  className="form-control"
                  id="coupon"
                />
                <div className="input-group-append">
                  <span className="input-group-text">
                    {this.state.flat_coupon ? 'flat' : '% pa'}
                  </span>
                </div>
              </div>
            </td>
            <td>
              <label>Flat:</label>
            </td>
            <td>
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <input
                    type="checkbox"
                    checked={this.state.flat_coupon}
                    id="flat_coupon"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <label>issuer:</label>
            </td>
            <td>
              <select
                value={this.state.issuer}
                onChange={this.handleChange}
                className="form-control"
                id="issuer"
              >
                <option>{this.state.issuer}</option>
                {
                  issuers.map(issuer => {
                    return <option key={issuer}>{issuer}</option>;
                  })
                }
              </select>
            </td>
            <td></td>
            <td></td>
          </tr>

          <tr>
            <td colSpan="4">
              <div className="form-group row">
                <label>Underlyings:</label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    autoComplete="off"
                    placeholder="Enter Scrip Name to add"
                    className="form-control"
                    id="scrip"
                    onChange={this.fetchsuggestions}
                  />

                  <div className="dropdown">
                    <ul className="list-group">
                      {this.state.suggestions.map((value, index) => {
                        return (
                          <li
                            className="list-group-item list-group-flush"
                            onClick={this.selectedScrip}
                            id={value.code}
                            key={index}
                          >
                            {value.code}
                            &nbsp; ({value.name}) &nbsp;
                            <img src={Flags[value.country_iso]}></img>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <div className="col-sm-3">
                  <input
                    type="text"
                    autoComplete="off"
                    placeholder="Initial Price"
                    className="form-control"
                    id="initial_price"
                    onKeyPress={this.addScripSubmit}
                  />
                </div>
                <div className="col-sm-1">
                  <div className="btn-group" role="group" aria-label="Basic example">
                    <a className="btn btn-primary" id="addScrip" onClick={this.addScrip}>
                      <FaPlus />
                    </a>
                  </div>
                </div>

                <div className="contaier">
                  {this.state.underlying.map((value, index) => {
                    return (
                      <div className="row" key={index}>
                        <div className="col-md-6">
                          <input
                            type="text"
                            disabled="true"
                            className="form-control"
                            value={value.code}
                          />
                        </div>
                        <div className="col-md-1">
                          <input
                            type="text"
                            disabled="true"
                            className="form-control"
                            value={this.state.underlying_count[value.code]}
                          />
                        </div>
                        <div className="col-md-3">
                          <input
                            type="text"
                            disabled="true"
                            className="form-control"
                            value={value.initial_reference_price}
                          />
                        </div>
                        <div className="col-md-2 btn-group" role="group" aria-label="Basic example">
                          <a className="btn btn-primary" onClick={() => this.updateScrip(index)}>
                            <FaPen />
                          </a>
                          <a className="btn btn-danger" onClick={() => this.removeScrip(index)}>
                            <FaTrash />
                          </a>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <label>barrier:</label>
            </td>
            <td>
              <div className="input-group mb-3">
                <input
                  value={this.state.barrier}
                  onChange={this.handleChange}
                  type="text"
                  className="form-control"
                  id="barrier"
                />
                <div className="input-group-append">
                  <span className="input-group-text">%</span>
                </div>
              </div>
            </td>

            <td>
              <label>barrier Type:</label>
            </td>
            <td>
              <select
                value={this.state.barrier_type}
                onChange={this.handleChange}
                className="form-control"
                id="barrier_type"
              >
                <option>European</option>
                <option>American EOD</option>
                <option>American CTS</option>
                <option>No barrier</option>
              </select>
            </td>
          </tr>
          <tr>
            <td>
              <label>Strike:</label>
            </td>

            <td>
              <div className="input-group mb-3">
                <input
                  value={this.state.strike}
                  onChange={this.handleChange}
                  type="text"
                  className="form-control"
                  id="strike"
                />
                <div className="input-group-append">
                  <span className="input-group-text">%</span>
                </div>
              </div>
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>
              <label>coupon Type:</label>
            </td>

            <td>
              <select
                value={this.state.coupon_type}
                onChange={this.handleChange}
                className="form-control"
                id="coupon_type"
              >
                <option>No Memory</option>
                <option>Memory</option>
                <option>Guaranteed</option>
              </select>
            </td>
            <td>{this.state.coupon_type !== 'Guaranteed' && <label>coupon Trigger:</label>}</td>
            <td>
              {this.state.coupon_type !== 'Guaranteed' && (
                <div className="input-group mb-3">
                  <input
                    value={
                      this.state.coupon_type !== 'Guaranteed' ? this.state.coupon_percentage : '0'
                    }
                    onChange={this.handleChange}
                    type="text"
                    className="form-control"
                    id="coupon_percentage"
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">%</span>
                  </div>
                </div>
              )}
            </td>
          </tr>
          <tr>
            <td>
              <label>Coupon Frequency in Months:</label>
            </td>

            <td>
              <input
                value={this.state.coupon_frequency}
                onChange={this.handleChange}
                type="number"
                className="form-control"
                id="coupon_frequency"
              />
            </td>
            <td>
              <label>Coupon Step:</label>
            </td>
            <td>
              <div className="input-group mb-3">
                <input
                  value={this.state.coupon_step}
                  onChange={this.handleChange}
                  type="text"
                  className="form-control"
                  id="coupon_step"
                />
                <div className="input-group-append">
                  <span className="input-group-text">%</span>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <label>Autocall:</label>
            </td>

            <td>
              <div className="input-group mb-3">
                <input
                  value={this.state.autocall_percentage}
                  onChange={this.handleChange}
                  type="text"
                  className="form-control"
                  id="autocall_percentage"
                />
                <div className="input-group-append">
                  <span className="input-group-text">%</span>
                </div>
              </div>
            </td>

            <td>
              <label>Autocall Frequency in Months:</label>
            </td>

            <td>
              <input
                value={this.state.autocall_frequency}
                onChange={this.handleChange}
                type="number"
                className="form-control"
                id="autocall_frequency"
              />
            </td>
          </tr>
          <tr>
            <td>
              <label>Autocall Step:</label>
            </td>
            <td>
              <div className="input-group mb-3">
                <input
                  value={this.state.autocall_step}
                  onChange={this.handleChange}
                  type="text"
                  className="form-control"
                  id="autocall_step"
                />
                <div className="input-group-append">
                  <span className="input-group-text">%</span>
                </div>
              </div>
            </td>
            <td>
              <label>Autocall Delay:</label>
            </td>

            <td>
              <input
                value={this.state.autocall_delay}
                onChange={this.handleChange}
                type="number"
                className="form-control"
                id="autocall_delay"
              />
            </td>
          </tr>
          <tr>
            <td>
              <label>Initial Fixing Date:</label>
            </td>
            <td>
              <input
                value={this.state.initial_fixing_date}
                onChange={this.handleChange}
                type="date"
                className="form-control"
                id="initial_fixing_date"
              />
            </td>

            <td>
              <label>Final Fixing Date:</label>
            </td>
            <td>
              <input
                value={this.state.final_fixing_date}
                onChange={this.handleChange}
                type="date"
                className="form-control"
                id="final_fixing_date"
              />
            </td>
          </tr>
          <tr>
            <td>
              <label>Issue Date:</label>
            </td>
            <td>
              <input
                value={this.state.issue_date}
                onChange={this.handleChange}
                type="date"
                className="form-control"
                id="issue_date"
              />
            </td>
            <td>
              <label>redemption:</label>
            </td>
            <td>
              <input
                value={this.state.redemption}
                onChange={this.handleChange}
                type="date"
                className="form-control"
                id="redemption"
              />
            </td>
          </tr>
          <tr>
            <td>
              <label>Knockin Kickin:</label>
            </td>
            <td>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <div className="input-group-text">
                    <input
                      type="checkbox"
                      checked={this.state.knockin_kickin}
                      id="knockin_kickin"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                {this.state.knockin_kickin && (
                  <input
                    type="number"
                    className="form-control"
                    value={this.state.knockin_kickin_value}
                    id="knockin_kickin_value"
                    disabled={!this.state.knockin_kickin}
                    onChange={this.handleChange}
                  />
                )}
              </div>
            </td>
            <td>
              <label>Asian Delay:</label>
            </td>
            <td>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <div className="input-group-text">
                    <input
                      type="checkbox"
                      checked={this.state.asian_delay}
                      id="asian_delay"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                {this.state.asian_delay && (
                  <input
                    type="number"
                    className="form-control"
                    value={this.state.asian_delay_value}
                    id="asian_delay_value"
                    disabled={!this.state.asian_delay}
                    onChange={this.handleChange}
                  />
                )}
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <label>One Star:</label>
            </td>
            <td>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <div className="input-group-text">
                    <input
                      type="checkbox"
                      checked={this.state.onestar}
                      id="onestar"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                {this.state.onestar && (
                  <input
                    type="number"
                    className="form-control"
                    value={this.state.onestar_value}
                    id="onestar_value"
                    disabled={!this.state.onestar}
                    onChange={this.handleChange}
                  />
                )}
              </div>
            </td>
            <td>
              <label>Upload Term Sheet:</label>
            </td>
            <td>
              <input
                type="file"
                id="termsheet"
                onChange={this.uploadFile}
                className="form-control"
              />
              {this.state.term_sheet && <span className="success">Already Uploaded</span>}
            </td>
          </tr>
        </table>

        <div className="row">
          <div className="btn-group" role="group" aria-label="Basic example">
            <a className="btn btn-primary" onClick={this.handleSubmit}>
              
              <FaSave /> Submit for approval
            </a>
            <a className="btn btn-danger" onClick={this.setProductData}>
              
              <FaTrash /> Discard Changes
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default UpdateForm;
