import axios from 'axios';
import Toast from 'light-toast';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './styles.css';

import { fetchAuthSession } from 'aws-amplify/auth';

import cookies from '../utils/cookies.util';
import base_url from '../utils/request.util';

class StructureUnderlying extends Component {
  constructor(props) {
    super(props);
    this.state = {
      structure_underlyings: []
    };
  }

  async componentDidMount() {
    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      config.data = {
        email: cookies.get('User-Specified')
      };
      config.params = {
        portfolio_id: this.state.portfolio_id
      };
      axios
        .get(`${base_url}/structure-underlying`, config)
        .then((res) => {
          this.setState({
            structure_underlyings: res.data.items
          });
          Toast.success('Success', 500, () => { });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  render() {
    return (
      <div className="container-fluid">
        <table className="table table-sm">
          <thead className="table-dark">
            <tr>
              <th>ISIN Code</th>
              <th>Code</th>
              <th>Portfolio Id</th>
              <th>Initial Price</th>
              <th>Name</th>
              <th>Last Price</th>
              <th>% Chg</th>
              <th>DTA</th>
              <th>DTB</th>
              <th>DTS</th>
            </tr>
          </thead>
          <tbody>
            {this.state.structure_underlyings.map((value, index) => {
              return (
                <tr key={index}>
                  <td
                    style={{
                      backgroundColor: '#002850',
                      color: '#ffffff',
                      textDecoration: 'none',
                      border: '1px solid white'
                    }}
                  >
                    <Link to={`/structure/autocallable/report?ISIN=${value.isin_code}`}>
                      {value.isin_code}
                    </Link>
                  </td>
                  <td>
                    {value.code != null ? (
                      <Link to={`/product/equity?code=${value.code}`}>{value.code}</Link>
                    ) : (
                      'N.A.'
                    )}
                  </td>
                  <td> {value.portfolio_id != null ? value.portfolio_id : 'N.A.'} </td>
                  {/* <td> {(value["Currency"] != null) ? value["Currency"] : "N.A."} </td> */}
                  <td>
                    
                    {value.initial_reference_price != null
                      ? value.initial_reference_price
                      : 'N.A.'}
                  </td>

                  <td>{value.name}</td>
                  <td>
                    {value.last_price &&
                      value.last_price.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })}
                  </td>
                  <td>
                    <b style={{ color: value.percentage_change > 0 ? 'green' : 'red' }}>
                      {value.percentage_change &&
                        value.percentage_change.toLocaleString(undefined, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 1
                        })}
                      %
                    </b>
                  </td>
                  <td>
                    <b
                      style={{
                        color: value.percentage_distance_from_autocall > 0 ? 'green' : 'red'
                      }}
                    >
                      {value.percentage_distance_from_barrier &&
                        value.percentage_distance_from_barrier.toLocaleString(undefined, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 1
                        })}
                      %
                    </b>
                  </td>
                  <td>
                    <b
                      style={{
                        color: value.percentage_distance_from_barrier > 0 ? 'green' : 'red'
                      }}
                    >
                      {value.percentage_distance_from_barrier &&
                        value.percentage_distance_from_barrier.toLocaleString(undefined, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 1
                        })}
                      %
                    </b>
                  </td>
                  <td>
                    <b
                      style={{ color: value.percentage_distance_from_strike > 0 ? 'green' : 'red' }}
                    >
                      {value.percentage_distance_from_strike &&
                        value.percentage_distance_from_strike.toLocaleString(undefined, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 1
                        })}
                      %
                    </b>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default StructureUnderlying;
