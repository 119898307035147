import axios from 'axios';
import Toast from 'light-toast';
import React, { Component } from 'react';

import { fetchAuthSession } from 'aws-amplify/auth';

import cookies from '../utils/cookies.util';
import { formatDate } from '../utils/date.util';
import base_url from '../utils/request.util';
import FileUpload from './fileUpload';

class PayinPayoutAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      portfolio_id: '',
      orderType: 'BUY',
      quantity: 0,
      cost_price: 0,
      buying_datetime: formatDate(new Date()),
      Status: 'Complete',
      final_asset_class: 'equity',
      currency: 'USD',
      payinPayoutCurrency: 'USD',
      exchange_rate: 1,
      face_value: 0,
      notional_quantity: 0,
      suggestions: [],
      external: false,
      formType: "order",
      type: 'payin',
      sub_type: 'funds',
      isin_code: '',
      amount: '0',
      remark: 'Transaction done',
      uploadMultiple: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleInputType = this.toggleInputType.bind(this);
    this.suggestPortfolios = this.suggestPortfolios.bind(this);
    this.selectedPortfolio = this.selectedPortfolio.bind(this);
  }

  async suggestPortfolios(event) {
    if (event.target.value === '') return;

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      config.params = {
        q: event.target.value
      };
      axios
        .get(`${base_url}/portfolio/suggest`, config)
        .then((res) => {
          this.setState({
            suggestions: res.data.items
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  selectedPortfolio(event) {
    this.setState({
      suggestions: [],
      portfolio_id: event.target.id
    });
  }

  toggleInputType(event) {
    this.setState(prevState => ({
      uploadMultiple: !prevState.uploadMultiple
    }));
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
    if (event.target.id === 'portfolio_id') this.suggestPortfolios(event);
  }

  async handleSubmit() {
    Toast.loading('In Progress', () => { });

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      const ppData = {
        portfolio_id: this.state.portfolio_id,
        buying_datetime: this.state.buying_datetime,
        type: this.state.type,
        sub_type: this.state.sub_type,
        amount: this.state.amount,
        currency: this.state.currency,
        remark: this.state.remark,
        isin_code: this.state.isin_code,
      };

      axios
        .post(`${base_url}/portfolio/payin-payout`, ppData, config)
        .then(() => {
          Toast.success('Success', 1000, () => {
            window.location.href = '/portfolio/orders';
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });

  }

  render() {
    return (
      <div className="container">
        <div className="form-group">
          <label>Portfolio Id:</label>
          <input
            type="text"
            value={this.state.portfolio_id}
            onChange={this.handleChange}
            className="form-control"
            required
            autoComplete='off'
            id="portfolio_id"
          />
        </div>
        <div className="dropdown">
          <ul className="list-group">
            {this.state.suggestions.map((value, index) => {
              return (
                <li
                  className="list-group-item list-group-flush"
                  onClick={this.selectedPortfolio}
                  id={value.portfolio_id}
                  key={index}
                >
                  {value.portfolio_id} ({value.client_id})
                </li>
              );
            })}
          </ul>
        </div>
        <div className="container">
          <button
            type="button"
            className="btn btn-link"
            data-dismiss="modal"
            onClick={this.toggleInputType}
            align='right'
          >
            {this.state.uploadMultiple ? "Upload Single" : "Upload File"}
          </button>
        </div>
        {
          this.state.uploadMultiple ?
            <div className="container">
              <FileUpload file_type="payin_payout" portfolioId={this.state.portfolio_id} />
            </div>
            :
            <div className="container">
              <div className="form-group">
                <label>Type:</label>
                <select
                  value={this.state.type}
                  onChange={this.handleChange}
                  className="form-control"
                  required
                  id="type"
                >
                  <option>payin</option>
                  <option>payout</option>
                </select>
              </div>
              <div className="form-group">
                <label>Sub Type:</label>
                <select
                  value={this.state.sub_type}
                  onChange={this.handleChange}
                  className="form-control"
                  required
                  id="sub_type"
                >
                  <option>fees</option>
                  <option>coupon</option>
                  <option>funds</option>
                  <option>interest</option>
                </select>
              </div>
              <div className="form-group">
                <label>Amount:</label>
                <input
                  type="text"
                  value={this.state.amount}
                  onChange={this.handleChange}
                  className="form-control"
                  autoComplete='off'
                  required
                  id="amount"
                />
              </div>
              <div className="form-group">
                <label>Currency:</label>
                <select
                  className="form-control"
                  required
                  id="currency"
                  value={this.state.currency}
                  onChange={this.handleChange}
                >
                  <option>USD</option>
                  <option>GBP</option>
                  <option>EUR</option>
                  <option>CHF</option>
                  <option>INR</option>
                </select>
              </div>
              <div className="form-group">
                <label>Date:</label>
                <input
                  type="datetime-local"
                  value={this.state.buying_datetime}
                  onChange={this.handleChange}
                  className="form-control"
                  autoComplete='off'
                  required
                  id="buying_datetime"
                />
              </div>
              <div className="form-group">
                <label>ISIN:</label>
                <input
                  type="text"
                  value={this.state.isin_code}
                  onChange={this.handleChange}
                  className="form-control"
                  autoComplete='off'
                  required
                  id="isin_code"
                />
              </div>
              <div className="form-group">
                <label>Remark:</label>
                <input
                  type="text"
                  value={this.state.remark}
                  onChange={this.handleChange}
                  className="form-control"
                  autoComplete='off'
                  required
                  id="remark"
                />
              </div>
              <a className="btn btn-block btn-danger" onClick={this.handleSubmit}>
                Submit
              </a>
            </div>
        }
      </div>
    );
  }
}

export default PayinPayoutAdd;
