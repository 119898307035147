import axios from 'axios';
import Toast from 'light-toast';

import { fetchAuthSession } from 'aws-amplify/auth';
import React, { Component } from 'react';
import Flags from './flags';

import cookies from '../utils/cookies.util';
import base_url from '../utils/request.util';

const round_upto = {
  openInterest: 2,
  impliedVolatility: 1,
  delta: 3,
  gamma: 3,
  theta: 3,
  vega: 3
};

class OptionMatrix extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {},
      put_oi_total: {},
      call_oi_total: {},
      expiries: [],
      strikes: [],
      current_attribute: 'iv / ask',
      code: '',
      suggestions: [],
      show_matrix_type_toggle: 'Show All',
      current_expiry: null
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmitAll = this.handleSubmitAll.bind(this);
    this.handleSubmitAllHighestOi = this.handleSubmitAllHighestOi.bind(this);
    this.fetchSuggestions = this.fetchSuggestions.bind(this);
    this.selectedScrip = this.selectedScrip.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value }, () => {
      if (event.target.id === 'code') {
        this.fetchSuggestions(event.target.value);
      }
    });
  }

  async fetchSuggestions(typed) {
    if (typed === '') return;

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      config.params = {
        q: typed
      };
      axios
        .get(`${base_url}/product/suggest`, config)
        .then((res) => {
          this.setState({
            suggestions: res.data.items
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  selectedScrip(event) {
    const scrip = event.target.id;
    document.getElementById('code').value = scrip;
    Toast.hide();
    this.setState((prevState) => ({
      code: scrip,
      suggestions: []
    }));
  }

  async fetchData(code, shouldFetchHigestOi) {
    Toast.loading('In Progress');

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      config.params = {
        highest_oi: shouldFetchHigestOi,
        expiry_date: this.state.current_expiry
      };
      axios
        .get(`${base_url}/option-matrix/${code}`, config)
        .then((res) => {
          Toast.success('Success', 1000, () => { });

          this.setState({
            options: res.data.item.Options,
            expiries: res.data.item.Expiries,
            strikes: res.data.item.Strikes,
            put_oi_total: res.data.item.PutOiTotal,
            call_oi_total: res.data.item.CallOiTotal,
            current_expiry: res.data.item.Expiries[0],
            code,
            suggestions: []
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  async handleSubmitAll() {
    await this.fetchData(this.state.code, false);
  }

  async handleSubmitAllHighestOi() {
    await this.fetchData(this.state.code, true);
  }

  async componentDidMount() {
    const search = window.location.search;
    const code = search.replaceAll('?code=', '').replaceAll('?Code=', '').replaceAll('%20', ' ');
    if (code === '') return;
    await this.fetchData(code, true);
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="container p-3 my-3 border">
          <div className="container input-group mb-3">
            <input
              type="text"
              autoComplete="off"
              placeholder="Enter Scrip Name to add"
              className="form-control"
              id="code"
              onChange={this.handleChange}
              value={this.state.code}
            />
            <select
              className="form-control"
              id="current_attribute"
              value={this.state.current_attribute}
              onChange={this.handleChange}
            >
              <option>openInterest</option>
              <option>impliedVolatility</option>
              <option>delta</option>
              <option>gamma</option>
              <option>theta</option>
              <option>vega</option>
              <option>iv / ask</option>
              <option>ask</option>
              <option>bid</option>
            </select>
            <select
              className="form-control"
              id="current_expiry"
              value={this.state.current_expiry}
              onChange={this.handleChange}
            >
              {this.state.expiries.map((value, index) => {
                return (
                  <option
                    className="list-group-item list-group-flush"
                    onClick={this.handleChange}
                    id={value}
                    key={index}
                  >
                    {value}
                  </option>
                );
              })}
            </select>
            <div className="input-group-append">
              <button className="btn btn-primary" onClick={this.handleSubmitAllHighestOi}>
                Show Highest OI
              </button>
            </div>
            <div className="input-group-append">
              <button className="btn btn-success" onClick={this.handleSubmitAll}>
                Show All
              </button>
            </div>
          </div>
          <div className="dropdown">
            <ul className="list-group">
              {this.state.suggestions.map((value, index) => {
                return (
                  <li
                    className="list-group-item list-group-flush"
                    onClick={this.selectedScrip}
                    id={value.code}
                    key={index}
                  >
                    {value.code}
                    &nbsp; ({value.name}) &nbsp;
                    <img src={Flags[value.country_iso]}></img>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        {this.state.expiries.length !== 0 && (
          <table className="table">
            <thead
              style={{
                backgroundColor: '#002850',
                color: '#ffffff',
                textDecoration: 'none',
                border: '1px solid white'
              }}
            >
              <tr>
                <th className="text-center">Total CALL OI</th>
                {[...this.state.expiries].reverse().map((value, index) => (
                  <th id={index} key={index}>
                    {value}
                  </th>
                ))}
                <th> Strike Price</th>
                {this.state.expiries.map((value, index) => (
                  <th id={index} key={index}>
                    {value}
                  </th>
                ))}
                <th className="text-center">Total PUT OI</th>
              </tr>
              <tr>
                <th className="text-center" colSpan={this.state.expiries.length + 1}>
                  CALL
                </th>
                <th> </th>
                <th className="text-center" colSpan={this.state.expiries.length + 1}>
                  PUT
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.strikes.map((StrikePrice) => {
                return (
                  <tr>
                    <td className="table-secondary" style={{ whiteSpace: 'nowrap' }}>
                      {this.state.call_oi_total != null &&
                        this.state.call_oi_total[StrikePrice] != null
                        ? this.state.call_oi_total[StrikePrice]
                        : '-'}
                    </td>
                    {[...this.state.expiries].reverse().map((Expiry) => {
                      return this.state.options[StrikePrice] != null &&
                        this.state.options[StrikePrice][Expiry] != null &&
                        this.state.options[StrikePrice][Expiry].CALL != null &&
                        (this.state.options[StrikePrice][Expiry].CALL[
                          this.state.current_attribute
                        ] != null ||
                          this.state.current_attribute === 'iv / ask') ? (
                        <td
                          className={
                            this.state.options[StrikePrice][Expiry].CALL.inTheMoney === 'TRUE'
                              ? 'table-success'
                              : 'table-danger'
                          }
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          {this.state.current_attribute === 'iv / ask' ? (
                            <>
                              {this.state.options[StrikePrice][Expiry].CALL.openInterest &&
                                this.state.options[StrikePrice][Expiry].CALL.ask && (
                                  <>
                                    {this.state.options[StrikePrice][
                                      Expiry
                                    ].CALL.openInterest.toLocaleString(undefined, {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: round_upto[2]
                                    })}
                                    (
                                    {this.state.options[StrikePrice][
                                      Expiry
                                    ].CALL.ask.toLocaleString(undefined, {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: round_upto[2]
                                    })}
                                    )
                                  </>
                                )}
                            </>
                          ) : (
                            <>
                              {this.state.options[StrikePrice][Expiry].CALL[
                                this.state.current_attribute
                              ] && (
                                  <>
                                    {this.state.options[StrikePrice][Expiry].CALL[
                                      this.state.current_attribute
                                    ].toLocaleString(undefined, {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: round_upto[this.state.current_attribute]
                                    })}
                                  </>
                                )}
                            </>
                          )}
                        </td>
                      ) : (
                        <td className="table-warning" style={{ whiteSpace: 'nowrap' }}>
                          -
                        </td>
                      );
                    })}
                    <td
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}
                    >
                      {StrikePrice}
                    </td>
                    {this.state.expiries.map((Expiry) => {
                      return this.state.options[StrikePrice] != null &&
                        this.state.options[StrikePrice][Expiry] != null &&
                        this.state.options[StrikePrice][Expiry].PUT != null &&
                        (this.state.options[StrikePrice][Expiry].PUT[
                          this.state.current_attribute
                        ] != null ||
                          this.state.current_attribute === 'iv / ask') ? (
                        <td
                          className={
                            this.state.options[StrikePrice][Expiry].PUT.inTheMoney === 'TRUE'
                              ? 'table-success'
                              : 'table-danger'
                          }
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          {this.state.current_attribute === 'iv / ask' ? (
                            <>
                              {this.state.options[StrikePrice][Expiry].PUT.openInterest &&
                                this.state.options[StrikePrice][Expiry].PUT.ask && (
                                  <>
                                    {this.state.options[StrikePrice][
                                      Expiry
                                    ].PUT.openInterest.toLocaleString(undefined, {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: round_upto[2]
                                    })}
                                    (
                                    {this.state.options[StrikePrice][Expiry].PUT.ask.toLocaleString(
                                      undefined,
                                      {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: round_upto[2]
                                      }
                                    )}
                                    )
                                  </>
                                )}
                            </>
                          ) : (
                            <>
                              {this.state.options[StrikePrice][Expiry].PUT[
                                this.state.current_attribute
                              ] && (
                                  <>
                                    {this.state.options[StrikePrice][Expiry].PUT[
                                      this.state.current_attribute
                                    ].toLocaleString(undefined, {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: round_upto[this.state.current_attribute]
                                    })}
                                  </>
                                )}
                            </>
                          )}
                        </td>
                      ) : (
                        <td className="table-warning" style={{ whiteSpace: 'nowrap' }}>
                          -
                        </td>
                      );
                    })}
                    <td className="table-secondary" style={{ whiteSpace: 'nowrap' }}>
                      {this.state.put_oi_total != null &&
                        this.state.put_oi_total[StrikePrice] != null
                        ? this.state.put_oi_total[StrikePrice]
                        : '-'}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    );
  }
}

export default OptionMatrix;
