import axios from 'axios';
import Toast from 'light-toast';
import React, { Component } from 'react';

import { fetchAuthSession } from 'aws-amplify/auth';
import Flags from './flags';

import cookies from '../utils/cookies.util';
import base_url from '../utils/request.util';

function returnHeaders(array) {
  const arr = [...array];
  return arr.concat('Strike Price').concat(arr.reverse());
}

class OptionChain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {},
      expiries: [],
      strikes: [],
      code: null,
      suggestions: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fetchSuggestions = this.fetchSuggestions.bind(this);
    this.selectedScrip = this.selectedScrip.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
  }

  async fetchSuggestions(event) {
    if (event.target.value === '') return;

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      config.params = {
        q: event.target.value
      };
      axios
        .get(`${base_url}/product/suggest`, config)
        .then((res) => {
          this.setState({
            suggestions: res.data.items,
            last_price: 0
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  selectedScrip(event) {
    const scrip = event.target.id;
    document.getElementById('code').value = scrip;
    Toast.hide();
    this.setState((prevState) => ({
      code: scrip,
      suggestions: []
    }));
  }

  async handleSubmit() {
    Toast.loading('In Progress');

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      axios
        .get(`${base_url}/option-chain/${this.state.code}`, config)
        .then((res) => {
          Toast.success('Success', 1000, () => { });
          this.setState({
            options: res.data.item.Options,
            expiries: res.data.item.Expiries,
            strikes: res.data.item.Strikes,
            expiry: res.data.item.Expiries[0],
            last_price: res.data.item.LastPrice
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="container p-3 my-3 border">
          <div className="container input-group mb-3">
            <input
              type="text"
              autoComplete="off"
              placeholder="Enter Scrip Name to add"
              className="form-control"
              id="code"
              onChange={this.fetchSuggestions}
            />
            <input
              type="text"
              autoComplete="off"
              className="form-control"
              disabled="true"
              value={this.state.last_price}
            />
            <select
              className="form-control"
              id="expiry"
              value={this.state.expiry}
              onChange={this.handleChange}
            >
              {this.state.expiries.map((value, index) => (
                <option id={index} key={index}>
                  {value}
                </option>
              ))}
            </select>
            <div className="input-group-append">
              <button className="btn btn-success" onClick={this.handleSubmit}>
                Search
              </button>
            </div>
          </div>
          <div className="dropdown">
            <ul className="list-group">
              {this.state.suggestions.map((value, index) => {
                return (
                  <li
                    className="list-group-item list-group-flush"
                    onClick={this.selectedScrip}
                    id={value.code}
                    key={index}
                  >
                    {value.code}
                    &nbsp; ({value.name}) &nbsp;
                    <img src={Flags[value.country_iso]}></img>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        {this.state.expiries.length !== 0 && (
          <table
            className="table table-bordered"
            style={{ marginLeft: 'auto', marginRight: 'auto', margin: '0px auto' }}
          >
            <thead
              style={{
                backgroundColor: '#002850',
                color: '#ffffff',
                textDecoration: 'none',
                border: '1px solid white'
              }}
            >
              <tr>
                <th colSpan="8" style={{ textAlign: 'center' }}>
                  
                  CALL
                </th>
                <th style={{ textAlign: 'center' }}> STRIKE PRICE </th>
                <th colSpan="8" style={{ textAlign: 'center' }}>
                  
                  PUT
                </th>
              </tr>
              <tr>
                <th>OI</th>
                <th>VOLUME</th>
                <th>CHNG</th>
                <th>GAMMA</th>
                <th>THETA</th>
                <th>DELTA</th>
                <th>IV</th>
                <th>LTP</th>
                {/* <th>RHO</th> */}
                <th></th>
                {/* <th>RHO</th> */}
                <th>LTP</th>
                <th>IV</th>
                <th>DELTA</th>
                <th>THETA</th>
                <th>GAMMA</th>
                <th>CHNG</th>
                <th>VOLUME</th>
                <th>OI</th>
              </tr>
            </thead>

            {this.state.strikes.map((strike, index) => {
              const value = this.state.options[this.state.expiry][strike];

              if (value === undefined) return;

              let put_table_color = 'table';
              put_table_color =
                parseInt(strike) >= this.state.last_price ? 'table-success' : 'table-warning';

              let call_table_color = 'table';
              call_table_color =
                parseInt(strike) <= this.state.last_price ? 'table-success' : 'table-warning';
              return (
                <tr key={index}>
                  <td className={call_table_color}>
                    {value.CALL.openInterest
                      ? value.CALL.openInterest.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })
                      : '-'}
                  </td>
                  <td className={call_table_color}>
                    {value.CALL.volume
                      ? value.CALL.volume.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })
                      : '-'}
                  </td>
                  <td className={call_table_color}>
                    {value.CALL.changePercent
                      ? value.CALL.changePercent.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })
                      : '-'}
                  </td>
                  <td className={call_table_color}>
                    {value.CALL.gamma
                      ? value.CALL.gamma.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 3
                      })
                      : '-'}
                  </td>
                  <td className={call_table_color}>
                    {value.CALL.theta
                      ? value.CALL.theta.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 3
                      })
                      : '-'}
                  </td>
                  <td className={call_table_color}>
                    {value.CALL.delta
                      ? value.CALL.delta.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 3
                      })
                      : '-'}
                  </td>
                  <td className={call_table_color}>
                    {value.CALL.impliedVolatility
                      ? value.CALL.impliedVolatility.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 1
                      })
                      : '-'}
                  </td>
                  <td className={call_table_color}>
                    {value.CALL.lastPrice
                      ? value.CALL.lastPrice.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })
                      : '-'}
                  </td>
                  {/* <td className={call_table_color}>{value["CALL"]["rho"] ? value["CALL"]["rho"].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }) : "-"}</td> */}
                  <td
                    style={{
                      backgroundColor: '#002850',
                      color: '#ffffff',
                      textDecoration: 'none',
                      border: '1px solid white'
                    }}
                  >
                    {strike}
                  </td>
                  {/* <td className={put_table_color}>{value["PUT"]["rho"] ? value["PUT"]["rho"].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }) : "-"}</td> */}
                  <td className={put_table_color}>
                    {value.PUT.lastPrice
                      ? value.PUT.lastPrice.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })
                      : '-'}
                  </td>
                  <td className={put_table_color}>
                    {value.PUT.impliedVolatility
                      ? value.PUT.impliedVolatility.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 1
                      })
                      : '-'}
                  </td>
                  <td className={put_table_color}>
                    {value.PUT.delta
                      ? value.PUT.delta.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 3
                      })
                      : '-'}
                  </td>
                  <td className={put_table_color}>
                    {value.PUT.theta
                      ? value.PUT.theta.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 3
                      })
                      : '-'}
                  </td>
                  <td className={put_table_color}>
                    {value.PUT.gamma
                      ? value.PUT.gamma.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 3
                      })
                      : '-'}
                  </td>
                  <td className={put_table_color}>
                    {value.PUT.changePercent
                      ? value.PUT.changePercent.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })
                      : '-'}
                  </td>
                  <td className={put_table_color}>
                    {value.PUT.volume
                      ? value.PUT.volume.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })
                      : '-'}
                  </td>
                  <td className={put_table_color}>
                    {value.PUT.openInterest
                      ? value.PUT.openInterest.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })
                      : '-'}
                  </td>
                </tr>
              );
            })}
          </table>
        )}
      </div>
    );
  }
}

export default OptionChain;
