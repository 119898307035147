import axios from 'axios';
import Toast from 'light-toast';
import React, { Component } from 'react';

import { fetchAuthSession } from 'aws-amplify/auth';
import { Link } from 'react-router-dom';

import cookies from '../utils/cookies.util';
import base_url from '../utils/request.util';

class IndexComponents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      code: '',
      news_data: [],
      suggestions: [],
      underlying_count: {},
      holding_count: {}
    };
  }

  async componentDidMount() {
    const search = window.location.search;
    let code = search.replaceAll('?code=', '').replaceAll('?Code=', '').replaceAll('%20', ' ');
    let equity_data;
    Toast.loading('In Progress', () => { });

    if (code === '') return;

    await fetchAuthSession().then(() => {
      const config = {};
      config.params = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      axios
        .get(`${base_url}/product/${code}`, config)
        .then((res) => {
          equity_data = res.data.item;

          this.setState({
            data: equity_data
          });
          code = code.replaceAll('/', ' ');

          config.params = { exchange: equity_data.country_iso === 'US' ? 'US' : null };
          axios.get(`${base_url}/index-components/${code}`, config).then((res) => {
            this.setState({
              index_components: res.data.items
            });
            Toast.success('Success', 1000, () => { });
          });
          config.params = {};
          axios.get(`${base_url}/structure-underlying-count`, config).then((res) => {
            this.setState({
              underlying_count: res.data.item
            });
          });
          config.params = {};
          axios.get(`${base_url}/structure-holding-count`, config).then(async (res) => {
            this.setState({
              holding_count: res.data.item
            });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  render() {
    return (
      <div className="container-fluid">
        {this.state.data != null && (
          <table className="table table-border">
            <tbody>
              <tr className="table-dark">
                <td>
                  <h3>
                    <img
                      height="35px"
                      src={'https://eodhistoricaldata.com' + this.state.data.logo_url}
                    />
                    &nbsp;
                    {this.state.data.name} Components
                  </h3>
                </td>
              </tr>
              {this.state.index_components != null && this.state.index_components.length !== 0 ? (
                <>
                  <table className="table table-border">
                    <thead>
                      <tr className="table-dark">
                        <th style={{ position: 'sticky', top: 50 }}>Index</th>
                        <th style={{ position: 'sticky', top: 50 }}>Name</th>
                        <th style={{ position: 'sticky', top: 50 }}></th>
                        <th style={{ position: 'sticky', top: 50 }}>Code</th>
                        <th style={{ position: 'sticky', top: 50 }}>Mcap (Mln)</th>
                        <th style={{ position: 'sticky', top: 50 }}>Last Price</th>
                        <th style={{ position: 'sticky', top: 50 }}>Mcap/Rev</th>
                        <th style={{ position: 'sticky', top: 50 }}>Industry</th>
                        <th style={{ position: 'sticky', top: 50 }}>Sector</th>
                        <th style={{ position: 'sticky', top: 50 }}>Buy</th>
                        <th style={{ position: 'sticky', top: 50 }}>Hold</th>
                        <th style={{ position: 'sticky', top: 50 }}>Sell</th>
                        <th style={{ position: 'sticky', top: 50 }}>PE</th>
                        <th style={{ position: 'sticky', top: 50 }}>PB</th>
                        <th style={{ position: 'sticky', top: 50 }}>Div Yield</th>
                        <th style={{ position: 'sticky', top: 50 }}>IV</th>
                        <th style={{ position: 'sticky', top: 50 }}>Rating</th>
                        <th style={{ position: 'sticky', top: 50 }}>52WHigh</th>
                        <th style={{ position: 'sticky', top: 50 }}>52WLow</th>
                        <th style={{ position: 'sticky', top: 50 }}>Beta</th>
                        <th style={{ position: 'sticky', top: 50 }}>Piotroski Score</th>
                        <th style={{ position: 'sticky', top: 50 }}>Next Earning</th>
                      </tr>
                    </thead>
                    <thead>
                      {this.state.index_components.map((value, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{value.name}</td>
                            <td>
                              {this.state.underlying_count.length !== 0 &&
                                window.location.pathname !== '/structure/pending/details' &&
                                this.state.underlying_count[value.code] &&
                                this.state.holding_count.length !== 0 &&
                                window.location.pathname !== '/structure/pending/details' && (
                                  <span
                                    className={
                                      this.state.underlying_count.length !== 0 &&
                                        window.location.pathname !== '/structure/pending/details' &&
                                        this.state.holding_count[value.code]
                                        ? 'badge badge-danger'
                                        : 'badge badge-primary'
                                    }
                                  >
                                    {this.state.underlying_count[value.code]}
                                  </span>
                                )}
                            </td>
                            <td>
                              {value.code ? (
                                <Link to={`/product/equity?Code=${value.code}`}>{value.code}</Link>
                              ) : (
                                value.symbol
                              )}
                            </td>
                            <td>
                              {value.market_capitalization_mln &&
                                value.market_capitalization_mln.toLocaleString(undefined, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2
                                })}
                            </td>
                            <td>
                              {value.last_price &&
                                value.last_price.toLocaleString(undefined, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2
                                })}
                            </td>
                            <td>
                              {value.market_capitalization_to_revenue_ttm &&
                                value.market_capitalization_to_revenue_ttm.toLocaleString(
                                  undefined,
                                  { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                )}
                            </td>
                            <td>
                              {value.industry &&
                                value.industry.toLocaleString(undefined, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2
                                })}
                            </td>
                            <td>
                              {value.sector &&
                                value.sector.toLocaleString(undefined, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2
                                })}
                            </td>
                            <td>
                              {(
                                (value.buy ? value.buy : 0) +
                                (value.strong_buy ? value.strong_buy : 0)
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                              })}
                            </td>
                            <td>
                              {value.hold &&
                                value.hold.toLocaleString(undefined, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2
                                })}
                            </td>
                            <td>
                              {(
                                (value.sell ? value.sell : 0) +
                                (value.strong_sell ? value.strong_sell : 0)
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                              })}
                            </td>
                            <td>
                              {value.pe_ratio &&
                                value.pe_ratio.toLocaleString(undefined, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2
                                })}
                            </td>
                            <td>
                              {value.price_book_mrq &&
                                value.price_book_mrq.toLocaleString(undefined, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2
                                })}
                            </td>
                            <td>
                              {value.dividend_yield &&
                                value.dividend_yield.toLocaleString(undefined, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2
                                })}
                            </td>
                            <td>
                              {value.iv_3months &&
                                value.iv_3months.toLocaleString(undefined, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2
                                })}
                            </td>
                            <td>
                              {value.rating &&
                                value.rating.toLocaleString(undefined, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2
                                })}
                            </td>
                            <td>
                              {value.week_52_high &&
                                value.week_52_high.toLocaleString(undefined, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2
                                })}
                            </td>
                            <td>
                              {value.week_52_low &&
                                value.week_52_low.toLocaleString(undefined, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2
                                })}
                            </td>
                            <td>
                              {value.beta &&
                                value.beta.toLocaleString(undefined, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2
                                })}
                            </td>
                            <td>
                              {value.piotroski_score &&
                                value.piotroski_score.toLocaleString(undefined, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2
                                })}
                            </td>
                            <td style={{ whiteSpace: 'nowrap' }}>
                              {value.earnings_date &&
                                value.earnings_date.toLocaleString(undefined, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2
                                })}
                            </td>
                          </tr>
                        );
                      })}
                    </thead>
                  </table>
                </>
              ) : (
                <h3>Data Not found for {this.state.data.name}</h3>
              )}
            </tbody>
          </table>
        )}
      </div>
    );
  }
}

export default IndexComponents;
