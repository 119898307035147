import axios from 'axios';
import Toast from 'light-toast';
import React, { Component } from 'react';

import { fetchAuthSession } from 'aws-amplify/auth';

import cookies from '../utils/cookies.util';
import base_url from '../utils/request.util';

class HoldingUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
  }

  async handleSubmit(event) {
    Toast.loading('In Progress', () => { });

    await fetchAuthSession().then(() => {
      const config = {};
      config.params = { code: this.state.code };
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      const data = this.state;

      axios
        .patch(`${base_url}/portfolio/client-holding/${this.state.portfolio_id}`, data, config)
        .then((res) => {
          Toast.success('Success', 1000, () => {
            window.location.href = '/portfolio/all';
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  async componentDidMount() {
    const search = window.location.search;
    const urlParams = new URLSearchParams(search);
    const code = urlParams.get('code');
    const portfolioId = urlParams.get('portfolioId');

    if (portfolioId === '' || code === '' || portfolioId == null || code == null) return;

    await fetchAuthSession().then(() => {
      const config = {};
      config.params = { code };
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      axios
        .get(`${base_url}/portfolio/client-holding/${portfolioId}`, config)
        .then((res) => {
          this.setState({
            exchange_rate: res.data.item.exchange_rate,
            code: res.data.item.code,
            purchase_value: res.data.item.purchase_value,
            purchase_value_given_currency: res.data.item.purchase_value_given_currency,
            final_asset_class: res.data.item.final_asset_class,
            cost_price: res.data.item.cost_price,
            quantity: res.data.item.quantity,
            current_value: res.data.item.current_value,
            percentage_updown: res.data.item.percentage_updown,
            portfolio_id: res.data.item.portfolio_id,
            current_value_given_currency: res.data.item.current_value_given_currency,
            last_updated: res.data.item.last_updated,
            last_price: res.data.item.last_price,
            currency: res.data.item.currency,
            profit_loss: res.data.item.profit_loss,
            face_value: res.data.item.face_value,
            notional_quantity: res.data.item.notional_quantity
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
    Toast.success('Success', 1000, () => { });
  }

  render() {
    return (
      <div className="container">
        <div className="container hidden" id="userData">
          <div className="form-group">
            <label>Portfolio Id:</label>
            <input
              type="text"
              disabled
              value={this.state.portfolio_id}
              onChange={this.handleChange}
              className="form-control"
              required
              id="portfolio_id"
            />
          </div>
          <div className="form-group">
            <label>Code:</label>
            <input
              type="text"
              disabled
              value={this.state.code}
              onChange={this.handleChange}
              className="form-control"
              required
              id="code"
            />
          </div>
          <div className="form-group">
            <label>Final Asset Class:</label>
            <select
              className="form-control"
              required
              id="final_asset_class"
              value={this.state.final_asset_class}
              onChange={this.handleChange}
            >
              <option>equity</option>
              <option>structured product</option>
              <option>fixed income</option>
              <option>forex</option>
              <option>etf</option>
              <option>fixed advance</option>
              <option>funds</option>
              <option>bond</option>
              <option>commodity</option>
              <option>cryptocurrency</option>
              <option>futures, options and warrants</option>
              <option>unassigned</option>
            </select>
          </div>
          <div className="form-group">
            <label>Cost Price:</label>
            <input
              type="text"
              disabled
              value={this.state.cost_price}
              onChange={this.handleChange}
              className="form-control"
              required
              id="cost_price"
            />
          </div>
          <div className="form-group">
            <label>Quantities:</label>
            <input
              type="number"
              disabled
              value={this.state.quantity}
              onChange={this.handleChange}
              className="form-control"
              required
              id="quantity"
            />
          </div>
          <div className="form-group">
            <label>Currency:</label>
            <select
              className="form-control"
              required
              id="currency"
              value={this.state.currency}
              onChange={this.handleChange}
              disabled
            >
              <option>USD</option>
              <option>GBP</option>
              <option>EUR</option>
              <option>CHF</option>
              <option>INR</option>
            </select>
          </div>
          <div className="form-group">
            <label>Exchnage Rate:</label>
            <input
              type="text"
              disabled
              value={this.state.exchange_rate}
              onChange={this.handleChange}
              className="form-control"
              required
              id="exchange_rate"
            />
          </div>
          <a className="btn btn-block btn-danger" onClick={this.handleSubmit}>
            Submit
          </a>
        </div>
      </div>
    );
  }
}

export default HoldingUpdate;
