import React, { Component } from 'react';

import { fetchAuthSession, updateUserAttributes } from 'aws-amplify/auth';
import axios from 'axios';
import { Link } from 'react-router-dom';

import Toast from 'light-toast';
import { FaCloud, FaCog, FaPen, FaTrash } from 'react-icons/fa';
import './styles.css';

import base_url from '../utils/request.util';

const columnNames = {
  // "Barrier": "Barrier",
  // "Strike": "Strike",

  Code: 'Code',
  Name: 'Name',
  Sector: 'GicSector',
  'Initial Price': 'InitialReferencePrice',
  'Last Price': 'LastPrice',

  'Basket Price Latest': 'BasketPriceLatest',
  'Maturity Date': 'DateDetermination',
  'First Autocall Date': 'FirstAutocallDate',
  Currency: 'Currency',

  'Barrier Price': 'BarrierLevel',
  'Strike Price': 'StrikeLevel',
  'Coupon Level': 'CouponLevel',
  'Autocall Level': 'NextAutocallLevel',
  'Next Autocall Level': 'NextAutocallLevel',

  'Autocall Percentage': 'AutocallPercentage',
  'Next Autocall %': 'NextAutocallPercentage',
  'Next Autocall Date': 'NextAutocallDate',
  'Initial Fixing Date': 'InitialFixingDate',

  '% Change': 'PercentageChange',
  '% Distance From Autocall': 'PercentageDistanceFromAutocall',
  '% Down From Barrier': 'PercentageupDownFromBarrier',

  Status: 'Status',
  Type: 'Type'
};

class ProductPortfolioInsights extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Columns: [],
      ProductDetails: {},
      PortfolioIds: ['All'],
      PortfolioId: null
    };
    this.updateColumnName = this.updateColumnName.bind(this);
    this.saveConfig = this.saveConfig.bind(this);
    this.resetConfig = this.resetConfig.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSumbit = this.handleSumbit.bind(this);
  }

  handleChange(event) {
    this.setState({ PortfolioId: event.target.value });
  }

  handleSumbit() {
    Toast.loading('Seaching for ' + this.state.PortfolioId, () => { });
    axios
      .get(
        `${base_url}/structured-product/portfolios-mapping?PortfolioId=${this.state.PortfolioId}`
      )
      .then((res) => {
        Toast.loading('In Progress', () => { });
        this.setState({
          ProductDetails: res.data.data,
          PortfolioIds: res.data.PortfolioIds
        });
      })
      .catch((error) => {
        console.log(error);
        Toast.fail('Error Performing Action', 3000);
      });
  }

  updateColumnName(event) {
    if (event.target.checked) {
      this.setState((prevState) => ({
        Columns: [...prevState.Columns, event.target.id]
      }));
    } else {
      const columns = this.state.Columns;
      columns.splice(columns.indexOf(event.target.id), 1);
      this.setState({
        Columns: columns
      });
    }
  }

  async saveConfig() {
    const user = await fetchAuthSession();
    await updateUserAttributes(user, {
      'custom:ProductDetails': JSON.stringify(this.state.Columns)
    });
  }

  async resetConfig() {
    const defaultCOlumns = [
      'Name',
      'Sector',
      'Initial Price',
      'Last Price',

      '% Change',
      'Barrier Price',
      'Strike Price',
      'Coupon Level',
      'Autocall Level'
    ];
    Toast.loading('In Progress', () => { });
    const user = await fetchAuthSession();
    await updateUserAttributes(user, {
      'custom:ProductDetails': JSON.stringify(defaultCOlumns)
    });
    this.setState({
      Columns: defaultCOlumns
    });
    Toast.success('Success', 1000, () => { });
  }

  componentDidMount() {
    Toast.loading('In Progress', () => { });
    axios
      .get(`${base_url}/structured-product/portfolios-mapping`)
      .then(async (res) => {
        Toast.success('Success', 1000, () => { });
        const user = await fetchAuthSession();
        const Columns = JSON.parse(user.attributes['custom:ProductDetails']);
        this.setState({
          ProductDetails: res.data.data,
          Columns,
          PortfolioIds: res.data.PortfolioIds
        });
      })
      .catch((error) => {
        console.log(error);
        Toast.fail('Error Performing Action', 3000);
      });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="container p-3 my-3 row">
          <div className="col-md-10">
            <div className="input-group mb-3">
              <select
                value={this.state.PortfolioId}
                onChange={this.handleChange}
                className="form-control"
                id="PortfolioId"
              >
                {this.state.PortfolioIds.map((item, index) => {
                  return <option>{item}</option>;
                })}
              </select>
              <div className="input-group-append">
                <a className="input-group-text btn btn-danger" onClick={this.handleSumbit}>
                  Search
                </a>
              </div>
              <div className="input-group-append">
                <a className="input-group-text btn btn-secondary" onClick={this.handleClear}>
                  Clear
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <button className="btn btn-primary" data-toggle="modal" data-target="#myModal">
              <FaCog />
            </button>

            <div className="modal" id="myModal">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">Columns</h4>
                    <button type="button" className="close" data-dismiss="modal">
                      &times;
                    </button>
                  </div>

                  <div className="modal-body">
                    <div className="form-check">
                      <input type="checkbox" className="form-check-input" checked disabled />
                      <label className="form-group form-check-label hidden">ISIN Code</label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        onChange={this.updateColumnName}
                        id="Code"
                        checked={this.state.Columns.includes('Code') && true}
                      />
                      <label className="form-group form-check-label hidden">Code</label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        onChange={this.updateColumnName}
                        id="Name"
                        checked={this.state.Columns.includes('Name') && true}
                      />
                      <label className="form-group form-check-label hidden">Name</label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        onChange={this.updateColumnName}
                        id="Sector"
                        checked={this.state.Columns.includes('Sector') && true}
                      />
                      <label className="form-group form-check-label hidden">Sector</label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        onChange={this.updateColumnName}
                        id="Last Price"
                        checked={this.state.Columns.includes('Last Price') && true}
                      />
                      <label className="form-group form-check-label hidden">Last Price</label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        onChange={this.updateColumnName}
                        id="Currency"
                        checked={this.state.Columns.includes('Currency') && true}
                      />
                      <label className="form-group form-check-label hidden">Currency</label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        onChange={this.updateColumnName}
                        id="Barrier Level"
                        checked={this.state.Columns.includes('Barrier Level') && true}
                      />
                      <label className="form-group form-check-label hidden">Barrier Level</label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        onChange={this.updateColumnName}
                        id="Next Autocall %"
                        checked={this.state.Columns.includes('Next Autocall %') && true}
                      />
                      <label className="form-group form-check-label hidden">Next Autocall %</label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        onChange={this.updateColumnName}
                        id="Strike Price"
                        checked={this.state.Columns.includes('Strike Price') && true}
                      />
                      <label className="form-group form-check-label hidden">Strike Price</label>
                    </div>
                    {/* <div className="form-check">
                                            <input type="checkbox" className="form-check-input" onChange={this.updateColumnName} id="Barrier" checked={this.state.Columns.includes("Barrier") && true}/>
                                            <label className="form-group form-check-label hidden">Barrier</label>
                                        </div> */}
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        onChange={this.updateColumnName}
                        id="Basket Price Latest"
                        checked={this.state.Columns.includes('Basket Price Latest') && true}
                      />
                      <label className="form-group form-check-label hidden">
                        Basket Price Latest
                      </label>
                    </div>
                    {/* <div className="form-check">
                                            <input type="checkbox" className="form-check-input" onChange={this.updateColumnName} id="Coupon" checked={this.state.Columns.includes("Coupon") && true}/>
                                            <label className="form-group form-check-label hidden">Coupon</label>
                                        </div> */}
                    {/* <div className="form-check">
                                            <input type="checkbox" className="form-check-input" onChange={this.updateColumnName} id="Coupon Trigger" checked={this.state.Columns.includes("Coupon Trigger") && true}/>
                                            <label className="form-group form-check-label hidden">Coupon Trigger</label>
                                        </div> */}
                    {/* <div className="form-check">
                                            <input type="checkbox" className="form-check-input" onChange={this.updateColumnName} id="Maturity Date" checked={this.state.Columns.includes("Maturity Date") && true}/>
                                            <label className="form-group form-check-label hidden">Maturity Date</label>
                                        </div> */}
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        onChange={this.updateColumnName}
                        id="First Autocall Date"
                        checked={this.state.Columns.includes('First Autocall Date') && true}
                      />
                      <label className="form-group form-check-label hidden">
                        First Autocall Date
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        onChange={this.updateColumnName}
                        id="Initial Fixing Date"
                        checked={this.state.Columns.includes('Initial Fixing Date') && true}
                      />
                      <label className="form-group form-check-label hidden">
                        Initial Fixing Date
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        onChange={this.updateColumnName}
                        id="Initial Price"
                        checked={this.state.Columns.includes('Initial Price') && true}
                      />
                      <label className="form-group form-check-label hidden">Initial Price</label>
                    </div>
                    {/* <div className="form-check">
                                            <input type="checkbox" className="form-check-input" onChange={this.updateColumnName} id="Issuer" checked={this.state.Columns.includes("Issuer") && true}/>
                                            <label className="form-group form-check-label hidden">Issuer</label>
                                        </div> */}
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        onChange={this.updateColumnName}
                        id="Next Autocall Date"
                        checked={this.state.Columns.includes('Next Autocall Date') && true}
                      />
                      <label className="form-group form-check-label hidden">
                        Next Autocall Date
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        onChange={this.updateColumnName}
                        id="Next Autocall Level"
                        checked={this.state.Columns.includes('Next Autocall Level') && true}
                      />
                      <label className="form-group form-check-label hidden">
                        Next Autocall Level
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        onChange={this.updateColumnName}
                        id="Autocall Percentage"
                        checked={this.state.Columns.includes('Autocall Percentage') && true}
                      />
                      <label className="form-group form-check-label hidden">
                        Autocall Percentage
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        onChange={this.updateColumnName}
                        id="% Distance From Autocall"
                        checked={this.state.Columns.includes('% Distance From Autocall') && true}
                      />
                      <label className="form-group form-check-label hidden">
                        % Distance From Autocall
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        onChange={this.updateColumnName}
                        id="% Change"
                        checked={this.state.Columns.includes('% Change') && true}
                      />
                      <label className="form-group form-check-label hidden">% Change</label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        onChange={this.updateColumnName}
                        id="% Down From Barrier"
                        checked={this.state.Columns.includes('% Down From Barrier') && true}
                      />
                      <label className="form-group form-check-label hidden">
                        % Down From Barrier
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        onChange={this.updateColumnName}
                        id="Status"
                        checked={this.state.Columns.includes('Status') && true}
                      />
                      <label className="form-group form-check-label hidden">Status</label>
                    </div>
                    {/* <div className="form-check">
                                            <input type="checkbox" className="form-check-input" onChange={this.updateColumnName} id="Strike" checked={this.state.Columns.includes("Strike") && true}/>
                                            <label className="form-group form-check-label hidden">Strike</label>
                                        </div> */}
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        onChange={this.updateColumnName}
                        id="Type"
                        checked={this.state.Columns.includes('Type') && true}
                      />
                      <label className="form-group form-check-label hidden">Type</label>
                    </div>
                  </div>

                  <div className="modal-footer btn-group">
                    <button
                      type="button"
                      className="btn btn-primary btn-block"
                      data-dismiss="modal"
                      onClick={this.saveConfig}
                    >
                      Save Configuration <FaCloud />
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger btn-block"
                      data-dismiss="modal"
                      onClick={this.resetConfig}
                    >
                      Reset Configuration <FaTrash />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <table className="table table-sm">
          <thead className="table-dark">
            <tr>
              <th>ISIN Code</th>
              <th>Parameters</th>
              {this.state.Columns.map((value, index) => {
                return (
                  <th id={index} key={index}>
                    {value}
                  </th>
                );
              })}
              <th></th>
            </tr>
          </thead>
          <tbody className="custom-info">
            {Object.keys(this.state.ProductDetails).map((key) => {
              if (key !== 'Columns') {
                return (
                  <>
                    {this.state.ProductDetails[key].map((value, index) => {
                      return (
                        <>
                          <tr>
                            {index === 0 && (
                              <>
                                <td
                                  className="table-dark"
                                  rowSpan={this.state.ProductDetails[key].length}
                                  style={{
                                    backgroundColor: '#002850',
                                    color: '#ffffff',
                                    textDecoration: 'none',
                                    border: '1px solid white'
                                  }}
                                >
                                  <strong>
                                    <Link to={`/structured-product/product-report?ISIN=${key}`}>
                                      {key}
                                    </Link>
                                  </strong>
                                  <br />
                                  <strong>
                                    Issuer: {this.state.ProductDetails[key][0].Issuer}
                                  </strong>
                                  <br />
                                  <strong>CPN: {this.state.ProductDetails[key][0].Coupon}</strong>
                                  <br />
                                  <strong>
                                    Strike: {this.state.ProductDetails[key][0].Strike}
                                  </strong>
                                </td>
                                <td
                                  className="table-dark"
                                  rowSpan={this.state.ProductDetails[key].length}
                                  style={{
                                    backgroundColor: '#002850',
                                    color: '#ffffff',
                                    textDecoration: 'none',
                                    border: '1px solid white'
                                  }}
                                >
                                  <strong>
                                    Barrier: {this.state.ProductDetails[key][0].Barrier}
                                  </strong>
                                  <br />
                                  <strong>
                                    Strike: {this.state.ProductDetails[key][0].Strike}
                                  </strong>
                                  <br />
                                  <strong>
                                    Cpn T: {this.state.ProductDetails[key][0].CouponTrigger}
                                  </strong>
                                  <br />
                                  <strong>
                                    Autocall: {this.state.ProductDetails[key][0].Autocall}
                                  </strong>
                                </td>
                              </>
                            )}
                            {this.state.Columns.map((column, columnIndex) => {
                              return (
                                <td className="table-dark" key={columnIndex}>
                                  {value[column] != null ? value[columnNames[column]] : 'N.A.'}
                                </td>
                              );
                            })}
                            {index === 0 && (
                              <td rowSpan={this.state.ProductDetails[key].length}>
                                
                                <button className="btn btn-light">
                                  <Link to={`/structured-product/product-update?ISIN=${key}`}>
                                    <FaPen />
                                  </Link>
                                </button>
                              </td>
                            )}
                          </tr>
                        </>
                      );
                    })}
                    <tr>
                      <td
                        colSpan={this.state.Columns.length + 1}
                        className="blank-row"
                        style={{ backgroundColor: ' #ffffff', height: '10px' }}
                      ></td>
                    </tr>
                  </>
                );
              }
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default ProductPortfolioInsights;
