import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Toast from 'light-toast';

import cookies from '../utils/cookies.util';
import base_url from '../utils/request.util';

class AlertPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      autocall: [],
      maturity: [],
      strike: [],
      barrier: [],
      coupon: []
    };
  }

  async componentDidMount() {
    Toast.loading('In Progress', () => { });
    await fetchAuthSession().then(() => {
      const config = {};

      if (cookies.get('Portfolio-Specified') != null) {
        config.params = { portfolio_id: cookies.get('Portfolio-Specified') };
      }

      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      axios
        .get(`${base_url}/autocallable-structure/expiry/autocall`, config)
        .then(async (res) => {
          this.setState({
            autocall: res.data.items
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });

      axios
        .get(`${base_url}/autocallable-structure/expiry/maturity`, config)
        .then(async (res) => {
          this.setState({
            maturity: res.data.items
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });

      axios
        .get(`${base_url}/autocallable-structure/expiry/strike`, config)
        .then(async (res) => {
          this.setState({
            strike: res.data.items
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });

      axios
        .get(`${base_url}/autocallable-structure/expiry/barrier`, config)
        .then(async (res) => {
          this.setState({
            barrier: res.data.items
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
      Toast.success('Success', 1000, () => { });

      axios
        .get(`${base_url}/autocallable-structure/expiry/coupon`, config)
        .then(async (res) => {
          this.setState({
            coupon: res.data.items
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
      Toast.success('Success', 1000, () => { });
    });
  }

  render() {
    return (
      <>
        <h3>Autocall Alerts</h3>
        <table className="table table-sm">
          <thead className="table-dark">
            <tr>
              <th>ISIN Code</th>
              <th>Autocall Date</th>
              <th>Days to Autocall</th>
              <th>Worst Performing Asset</th>
              <th>Name</th>
              <th>LTP</th>
              <th>% Chg</th>
              <th>DTA</th>
              <th>Portfolio Ids</th>
              <th>Notional Qty</th>
            </tr>
          </thead>
          <tbody className="custom-info">
            {this.state.autocall.map((value, index) => {
              return (
                <tr key={index}>
                  <td
                    style={{
                      backgroundColor: '#002850',
                      color: '#ffffff',
                      textDecoration: 'none',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    <Link to={`/structure/autocallable/report?ISIN=${value.isin_code}`}>
                      {value.isin_code}
                    </Link>
                  </td>
                  <td>{value.next_autocall_date}</td>
                  <td>{value.days_to_autocall}</td>
                  <td>
                    <strong>
                      <Link to={`/product/equity?Code=${value.code}`}>{value.code}</Link>
                    </strong>
                  </td>
                  <td>{value.name}</td>
                  <td>
                    {value.last_price &&
                      value.last_price.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })}
                  </td>
                  <td>
                    <b style={{ color: value.percentage_change > 0 ? 'green' : 'red' }}>
                      {value.percentage_change &&
                        value.percentage_change.toLocaleString(undefined, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 1
                        })}
                      %
                    </b>
                  </td>
                  <td>
                    <b
                      style={{
                        color: value.percentage_distance_from_autocall > 0 ? 'green' : 'red'
                      }}
                    >
                      {value.percentage_distance_from_autocall &&
                        value.percentage_distance_from_autocall.toLocaleString(undefined, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 1
                        })}
                      %
                    </b>
                  </td>
                  <td>{value.portfolio_id}</td>
                  <td>
                    {value.final_notional_quantity &&
                      value.final_notional_quantity.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 1
                      })}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <h3>Coupon Alerts</h3>
        <table className="table table-sm">
          <thead className="table-dark">
            <tr>
              <th>ISIN Code</th>
              <th>Coupon Date</th>
              <th>Days to Coupon</th>
              <th>Worst Performing Asset</th>
              <th>Name</th>
              <th>LTP</th>
              <th>% Chg</th>
              <th>DTC</th>
              <th>Portfolio Ids</th>
              <th>Notional Qty</th>
            </tr>
          </thead>
          <tbody className="custom-info">
            {this.state.coupon.map((value, index) => {
              return (
                <tr key={index}>
                  <td
                    style={{
                      backgroundColor: '#002850',
                      color: '#ffffff',
                      textDecoration: 'none',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    <Link to={`/structure/autocallable/report?ISIN=${value.isin_code}`}>
                      {value.isin_code}
                    </Link>
                  </td>
                  <td>{value.next_coupon_date}</td>
                  <td>{value.days_to_coupon}</td>
                  <td>
                    <strong>
                      <Link to={`/product/equity?Code=${value.code}`}>{value.code}</Link>
                    </strong>
                  </td>
                  <td>{value.name}</td>
                  <td>
                    {value.last_price &&
                      value.last_price.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })}
                  </td>
                  <td>
                    <b style={{ color: value.percentage_change > 0 ? 'green' : 'red' }}>
                      {value.percentage_change &&
                        value.percentage_change.toLocaleString(undefined, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 1
                        })}
                      %
                    </b>
                  </td>
                  <td>
                    <b
                      style={{ color: value.percentage_distance_from_coupon > 0 ? 'green' : 'red' }}
                    >
                      {value.percentage_distance_from_coupon &&
                        value.percentage_distance_from_coupon.toLocaleString(undefined, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 1
                        })}
                      %
                    </b>
                  </td>
                  <td>{value.portfolio_id}</td>
                  <td>
                    {value.final_notional_quantity &&
                      value.final_notional_quantity.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 1
                      })}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <h3>Maturity Alerts</h3>
        <table className="table table-sm">
          <thead className="table-dark">
            <tr>
              <th>ISIN Code</th>
              <th>Maturity</th>
              <th>Days To Expire</th>
              <th>Worst Performing Asset</th>
              <th>Name</th>
              <th>LTP</th>
              <th>% Chg</th>
              <th>DTB</th>
              <th>DTS</th>
              <th>Portfolio Ids</th>
              <th>Notional Qty</th>
            </tr>
          </thead>
          <tbody className="custom-info">
            {this.state.maturity.map((value, index) => {
              return (
                <tr key={index}>
                  <td
                    style={{
                      backgroundColor: '#002850',
                      color: '#ffffff',
                      textDecoration: 'none',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    <Link to={`/structure/autocallable/report?ISIN=${value.isin_code}`}>
                      {value.isin_code}
                    </Link>
                  </td>
                  <td>{value.final_fixing_date}</td>
                  <td>{value.days_to_expire}</td>
                  <td>
                    <strong>
                      <Link to={`/product/equity?Code=${value.code}`}>{value.code}</Link>
                    </strong>
                  </td>
                  <td>{value.name}</td>
                  <td>
                    {value.last_price &&
                      value.last_price.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })}
                  </td>
                  <td>
                    <b style={{ color: value.percentage_change > 0 ? 'green' : 'red' }}>
                      {value.percentage_change &&
                        value.percentage_change.toLocaleString(undefined, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 1
                        })}
                      %
                    </b>
                  </td>
                  <td>
                    <b
                      style={{
                        color: value.percentage_distance_from_barrier > 0 ? 'green' : 'red'
                      }}
                    >
                      {value.percentage_distance_from_barrier &&
                        value.percentage_distance_from_barrier.toLocaleString(undefined, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 1
                        })}
                      %
                    </b>
                  </td>
                  <td>
                    <b
                      style={{ color: value.percentage_distance_from_strike > 0 ? 'green' : 'red' }}
                    >
                      {value.percentage_distance_from_strike &&
                        value.percentage_distance_from_strike.toLocaleString(undefined, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 1
                        })}
                      %
                    </b>
                  </td>

                  <td>{value.portfolio_id}</td>
                  <td>
                    {value.final_notional_quantity &&
                      value.final_notional_quantity.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {/* <h3>Strike Alerts</h3>
                        <table className="table table-sm">
                            <thead className="table-dark">
                                <tr>
                                    <th>ISIN Code</th>
                                    <th>Expiry</th>
                                    <th>Worst Performing Asset</th>
                                    <th>Name</th>
                                    <th>DTS</th>
                                    <th>Portfolio Ids</th>
                                    <th>Notional Qty</th>
                                </tr>
                            </thead>
                            <tbody className="custom-info">

                                {
                                    this.state.strike.map((value, index) => {
                                        return (
                                            <tr>
                                                <td style={{ backgroundColor: "#002850", color: "#ffffff", textDecoration: "none", whiteSpace: "nowrap" }}>
                                                    <Link to={`/structure/autocallable/report?ISIN=${value.isin_code}`}>
                                                        {value.isin_code}
                                                    </Link>
                                                </td>
                                                <td>{value.final_fixing_date}</td>
                                                <td>
                                                    <strong>
                                                        <Link to={`/product/equity?Code=${value.code}`}>
                                                            {value.code}
                                                        </Link>
                                                    </strong>
                                                </td>
                                                <td>
                                                    <b style={{ color: value.percentage_distance_from_strike > 0 ? "green" : "red" }}>{value.percentage_distance_from_strike && value.percentage_distance_from_strike.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })}%</b>
                                                </td>
                                                <td>
                                                    {value.portfolio_id}
                                                </td>
                                                <td>{value.final_notional_quantity  && value.final_notional_quantity.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })}</td>
                                            </tr>
                                        )
                                    })
                                }

                            </tbody>
                        </table> */}

        <h3>Barrier Alerts</h3>
        <table className="table table-sm">
          <thead className="table-dark">
            <tr>
              <th>ISIN Code</th>
              <th>Expiry</th>
              <th>Days To Expire</th>
              <th>Worst Performing Asset</th>
              <th>Name</th>
              <th>LTP</th>
              <th>% Chg</th>
              <th>DTB</th>
              <th>DTS</th>
              <th>Portfolio Ids</th>
              <th>Notional Qty</th>
            </tr>
          </thead>
          <tbody className="custom-info">
            {this.state.barrier.map((value, index) => {
              return (
                <tr key={index}>
                  <td
                    style={{
                      backgroundColor: '#002850',
                      color: '#ffffff',
                      textDecoration: 'none',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    <Link to={`/structure/autocallable/report?ISIN=${value.isin_code}`}>
                      {value.isin_code}
                    </Link>
                  </td>
                  <td>{value.final_fixing_date}</td>
                  <td>{value.days_to_expire}</td>
                  <td>
                    <strong>
                      <Link to={`/product/equity?Code=${value.code}`}>{value.code}</Link>
                    </strong>
                  </td>
                  <td>{value.name}</td>
                  <td>
                    {value.last_price &&
                      value.last_price.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })}
                  </td>
                  <td>
                    <b style={{ color: value.percentage_change > 0 ? 'green' : 'red' }}>
                      {value.percentage_change &&
                        value.percentage_change.toLocaleString(undefined, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 1
                        })}
                      %
                    </b>
                  </td>
                  <td>
                    <b
                      style={{
                        color: value.percentage_distance_from_barrier > 0 ? 'green' : 'red'
                      }}
                    >
                      {value.percentage_distance_from_barrier &&
                        value.percentage_distance_from_barrier.toLocaleString(undefined, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 1
                        })}
                      %
                    </b>
                  </td>
                  <td>
                    <b
                      style={{ color: value.percentage_distance_from_strike > 0 ? 'green' : 'red' }}
                    >
                      {value.percentage_distance_from_strike &&
                        value.percentage_distance_from_strike.toLocaleString(undefined, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 1
                        })}
                      %
                    </b>
                  </td>

                  <td>{value.portfolio_id}</td>
                  <td>
                    {value.final_notional_quantity &&
                      value.final_notional_quantity.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  }
}

export default AlertPage;
