import axios from 'axios';
import Toast from 'light-toast';
import React, { Component } from 'react';

import { fetchAuthSession } from 'aws-amplify/auth';
import Flags from './flags';

import cookies from '../utils/cookies.util';
import base_url from '../utils/request.util';

class HandleStockSplit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: null,
      split_from: 1,
      split_to: 1,
      suggestions: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fetchsuggestions = this.fetchsuggestions.bind(this);
    this.selectedScrip = this.selectedScrip.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
  }

  async handleSubmit(event) {
    Toast.loading('In Progress', () => { });

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      const data = {
        split_from: this.state.ssplit_from,
        split_to: this.state.split_to
      };
      axios
        .patch(`${base_url}/product-split-processor/${this.state.code}`, data, config)
        .then((res) => {
          Toast.success('Success', 1000, () => { });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Updating DB', 3000);
        });
    });
    Toast.success('Success', 1000, () => { });
  }

  async fetchsuggestions(event) {
    this.setState({
      code: event.target.value
    });
    if (event.target.id === '') return;

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      config.params = { q: event.target.value };
      axios
        .get(`${base_url}/product/suggest`, config)
        .then((res) => {
          this.setState({
            suggestions: res.data.items
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  async selectedScrip(event) {
    this.setState({
      code: event.target.id.toUpperCase(),
      suggestions: []
    });
  }

  render() {
    return (
      <div className="container">
        <div className="container">
          <div className="row">
            <label className="col-sm-10">Underlyings:</label>
            <div className="col-sm-12">
              <input
                type="text"
                autoComplete="off"
                placeholder="Enter Scrip Name to add"
                className="form-control"
                id="code"
                value={this.state.code}
                onChange={this.fetchsuggestions}
              />

              <div className="dropdown">
                <ul className="list-group">
                  {this.state.suggestions.map((value, index) => {
                    return (
                      <li
                        className="list-group-item list-group-flush"
                        key={index}
                        onClick={this.selectedScrip}
                        id={value.code}
                      >
                        {value.code}
                        &nbsp; ({value.name}) &nbsp;
                        <img src={Flags[value.country_iso]}></img>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>

          <hr />

          <div className="row">
            <div className="col-sm-4">
              <input
                type="text"
                autoComplete="off"
                placeholder="Split From"
                className="form-control"
                id="split_from"
                value={this.state.split_from}
                onChange={this.handleChange}
              />
            </div>
            <div className="col-sm-4">
              <input
                type="text"
                autoComplete="off"
                placeholder="Split To"
                className="form-control"
                id="split_from"
                value={this.state.split_to}
                onChange={this.handleChange}
              />
            </div>
          </div>

          <hr />
          <a className="btn btn-block btn-danger" onClick={this.handleSubmit}>
            Submit
          </a>
          <a className="btn btn-block btn-primary" onClick={this.handleClear}>
            Clear
          </a>
        </div>
      </div>
    );
  }
}

export default HandleStockSplit;
