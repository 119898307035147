import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';
import Toast from 'light-toast';
import PropTypes from 'prop-types';
import React from 'react';
import {
  FaBars,
  FaChartBar,
  FaChartLine,
  FaCheck,
  FaCopy,
  FaHeart,
  FaPen,
  FaShoppingCart
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';
import './styles.css';

import cookies from '../utils/cookies.util';
import base_url from '../utils/request.util';

const StructurePopover = ({ userType, path, isin_code, TermSheet, userId, openFactSheetHandler, sendEmailHandler, approveProductHandler }) => {

  const addToCuratedStructure = async (isinCode) => {
    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      const data = {
        isin_code: isinCode,
      };

      axios
        .post(`${base_url}/structure/curated/${'acd'}`, data, config)
        .then(async () => {
          Toast.success('Success', 1000, () => {
            window.location.href = '/structure/autocallable/curated/details';
          });
        })
        .catch(() => {
          Toast.fail('Error Performing Action', 3000);
        });
    });
  };

  const deleteFromCuratedStructure = async (isinCode) => {
    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      config.data = {
        isin_code: isinCode,
      };

      axios
        .delete(`${base_url}/structure/curated/${'acd'}`, config)
        .then(async () => {
          Toast.success('Success', 1000, () => {
            window.location.href = '/structure/autocallable/curated/details';
          });
        })
        .catch(() => {
          Toast.fail('Error Performing Action', 3000);
        });
    });
  };


  return (
    <div className="well">
      <Popup
        trigger={
          <button type="button" className="btn btn-small">
            <FaBars />
          </button>
        }
        position="left center"
      >
        <>
          {(userType === 'Admin' || userType === 'BackOffice') && (
            <a className="btn btn-link">
              {path.indexOf('/structure/autocallable') !== -1 && (
                <Link to={`/structure/autocallable/update?ISIN=${isin_code}`}>
                  <FaPen /> &nbsp; Edit Structure
                </Link>
              )}
              {path.indexOf('/structure/dispersion-warrent') !== -1 && (
                <Link to={`/structure/dispersion-warrent/update?ISIN=${isin_code}`}>
                  <FaPen /> &nbsp; Edit Structure
                </Link>
              )}
              {path.indexOf('/structure/twin-win') !== -1 && (
                <Link to={`/structure/twin-win/update?ISIN=${isin_code}`}>
                  <FaPen /> &nbsp; Edit Structure
                </Link>
              )}
              {path.indexOf('/structure/shark-note') !== -1 && (
                <Link to={`/structure/shark-note/update?ISIN=${isin_code}`}>
                  <FaPen /> &nbsp; Edit Structure
                </Link>
              )}
            </a>
          )}
          {TermSheet && (
            <a
              className="btn btn-link"
              onClick={() => openFactSheetHandler(isin_code)}
            >
              <FaCopy /> &nbsp; Term Sheet
            </a>
          )}
          <a className="btn btn-link">
            <Link to={`/options/analyse?ISIN=${isin_code}`}>
              <FaChartBar /> &nbsp; Options Analysier
            </Link>
          </a>
          <a className="btn btn-link">
            <Link to={`/options/strategy?ISIN=${isin_code}`}>
              <FaChartLine /> &nbsp; Put Writing
            </Link>
          </a>
          {path === '/structure/autocallable/details' && (
            <a className="btn btn-link">
              <a
                onClick={() => {
                  sendEmailHandler(isin_code, userId);
                }}
              >
                <FaShoppingCart /> &nbsp;
                Buy this product
              </a>
            </a>
          )}
          {path === '/structure/dispersion-warrent/details' && (
            <a className="btn btn-link">
              <a
                onClick={() => {
                  sendEmailHandler(isin_code, userId);
                }}
              >
                <FaShoppingCart /> &nbsp;
                Buy this product
              </a>
            </a>
          )}
          {path === '/structure/twin-win/details' && (
            <a className="btn btn-link">
              <a
                onClick={() => {
                  sendEmailHandler(isin_code, userId);
                }}
              >
                <FaShoppingCart /> &nbsp;
                Buy this product
              </a>
            </a>
          )}
          <a className="btn btn-link">

            {path !== '/structure/autocallable/curated/details' ? (
              <a
                onClick={() => {
                  addToCuratedStructure(isin_code);
                }}
              >
                <FaHeart /> &nbsp;
                Add to favourite
              </a>
            ) : (
              <a
                onClick={() => {
                  deleteFromCuratedStructure(isin_code);
                }}
              >
                <FaHeart /> &nbsp;
                Remove favourite
              </a>
            )}
          </a>
          {path === '/structure/autocallable/pending/details' && (
            <a className="btn btn-link">
              <a onClick={() => approveProductHandler(isin_code)}>
                <FaCheck /> &nbsp; Approve Product
              </a>
            </a>
          )}
          {path === '/structure/dispersion-warrent/pending/details' && (
            <a className="btn btn-link">
              <a onClick={() => approveProductHandler(isin_code)}>
                <FaCheck /> &nbsp; Approve Product
              </a>
            </a>
          )}
          {path === '/structure/twin-win/pending/details' && (
            <a className="btn btn-link">
              <a onClick={() => approveProductHandler(isin_code)}>
                <FaCheck /> &nbsp; Approve Product
              </a>
            </a>
          )}
          {path === '/structure/shark-note/pending/details' && (
            <a className="btn btn-link">
              <a onClick={() => approveProductHandler(isin_code)}>
                <FaCheck /> &nbsp; Approve Product
              </a>
            </a>
          )}
        </>
      </Popup>
    </div>
  );
};

StructurePopover.propTypes = {
  userType: PropTypes.oneOf(['Admin', 'BackOffice']),
  path: PropTypes.string,
  isin_code: PropTypes.string,
  TermSheet: PropTypes.bool,
  openFactSheetHandler: PropTypes.func,
  userId: PropTypes.string,
  sendEmailHandler: PropTypes.func,
  approveProductHandler: PropTypes.func
};

export default StructurePopover;
