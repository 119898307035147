import Toast from 'light-toast';
import React, { Component } from 'react';

import { fetchAuthSession } from 'aws-amplify/auth';
import AWS from 'aws-sdk';

import { metadata } from '../mappings/uploadFile';
import { bucket_name, region } from '../utils/request.util';

class FileUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
  }

  async uploadFile(file) {
    console.log("here", this.props);

    const bucketName = bucket_name;

    const credentials = await fetchAuthSession();

    AWS.config.region = region;
    AWS.config.update({
      accessKeyId: credentials.credentials.accessKeyId,
      secretAccessKey: credentials.credentials.secretAccessKey,
      sessionToken: credentials.credentials.sessionToken
    });

    const s3 = new AWS.S3({
      apiVersion: '2006-03-01',
    params: { Bucket: bucketName },
      region
    });

    console.log('s3', {
      Bucket: bucketName,
      Key: `import/${this.props.file_type}`,
      Body: file.fileObj,
      file: file,
    });

    const fileMetadata = metadata[this.state.fileType] || {};
    fileMetadata['portfolio-id'] = this.props.portfolioId;


    s3.upload(
      {
        Bucket: bucketName,
        Key: `import/${this.props.file_type}.xlsx`,
        Body: file.fileObj,
        Metadata: fileMetadata
      },
      function (err, data) {
        if (err) {
          console.log(err);
          Toast.fail('there was an error uploading your file', 1000, () => { });
        } else {
          window.location.href = `/raw-payin-payout?portfolioId=${fileMetadata['portfolio-id']}`
        }
      }
    );
  }

  handleChange(event) {
    const fileNames = [];
    for (let index = 0; index < event.target.files.length; index++) {
      fileNames.push({ name: event.target.files[index].name, fileObj: event.target.files[index] });
    }
    this.setState({ files: fileNames });
  }

  handleClear(event) {
    document.getElementById('file').value = '';
    this.setState({ files: [] });
  }

  async handleSubmit(event) {
    Toast.loading('In Progress', () => { });

    await fetchAuthSession().then(async () => {
      for (let index = 0; index < this.state.files.length; index++) {
        await this.uploadFile(this.state.files[index])
      }
    });
    Toast.success('Success', 1000, () => { });
  }

  render() {
    console.log(this.props);
    return (
      <div className="container">
        <div className="container hidden" id="portfolioData">
          <div className="form-group">
            <label>Upload {this.props.file_type} File:</label>
            <input
              type="file"
              value={this.state.file}
              onChange={this.handleChange}
              className="form-control"
              multiple
              id="file"
            />
          </div>
          <hr />
          <div className="dropdown">
            <ul className="list-group">
              {this.state.files.map((value, index) => {
                return (
                  <li
                    className="list-group-item list-group-flush"
                    onClick={this.selectedScrip}
                    id={value}
                    key={index}
                  >
                    {value.name}
                    &nbsp; ({value.uploadedSuccessfully ? 'Uploaded' : 'Not Uploaded'})
                  </li>
                );
              })}
            </ul>
          </div>
          <a className="btn btn-block btn-danger" onClick={this.handleSubmit}>
            Submit
          </a>
          <a className="btn btn-block btn-primary" onClick={this.handleClear}>
            Clear
          </a>
        </div>
      </div>
    );
  }
}

export default FileUpload;
