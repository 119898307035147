import axios from 'axios';
import Toast from 'light-toast';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './styles.css';

import { fetchAuthSession } from 'aws-amplify/auth';

import cookies from '../utils/cookies.util';
import base_url from '../utils/request.util';

class ETFFunds extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }

  async componentDidMount() {
    Toast.loading('In Progress', () => { });
    await fetchAuthSession().then(() => {
      var config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      axios
        .get(`${base_url}/product-etf`, config)
        .then((res) => {
          this.setState({
            data: res.data.items
          });
          Toast.success('Success', 500, () => { });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  render() {
    return (
      <div className="container-fluid">
        <table className="table table">
          <thead className="table-dark">
            <tr>
              <th style={{ position: 'sticky', top: 50 }}>Code</th>
              <th style={{ position: 'sticky', top: 50 }}>Name</th>
              <th style={{ position: 'sticky', top: 50 }}>LTP</th>
              <th style={{ position: 'sticky', top: 50 }}>52W High</th>
              <th style={{ position: 'sticky', top: 50 }}>52W Low</th>
              <th style={{ position: 'sticky', top: 50 }}>IV</th>
              <th style={{ position: 'sticky', top: 50 }}>YTD</th>
              <th style={{ position: 'sticky', top: 50 }}>1 Year</th>
              <th style={{ position: 'sticky', top: 50 }}>3 Year</th>
              <th style={{ position: 'sticky', top: 50 }}>5 Year</th>
              {this.state.multiple_select && <th style={{ position: 'sticky', top: 50 }}></th>}
            </tr>
          </thead>
          <tbody>
            {this.state.data.map((item, i) => {
              return (
                <tr key={i}>
                  <td
                    style={{
                      backgroundColor: '#002850',
                      color: '#ffffff',
                      textDecoration: 'none',
                      textAlign: 'center'
                    }}
                  >
                    {item.code != null ? (
                      <Link className="btn btn-link" to={`/product/etf?code=${item.code}`}>
                        {item.code}
                      </Link>
                    ) : (
                      '-'
                    )}
                  </td>
                  <td
                    style={{ backgroundColor: '#002850', color: '#ffffff', textDecoration: 'none' }}
                  >
                    {item.name}
                  </td>
                  <td className="table-dark">{item.last_price ? item.last_price : '-'}</td>
                  <td className="table-dark">{item.week_52_low ? item.week_52_low : '-'}</td>
                  <td className="table-dark">{item.week_52_high ? item.week_52_high : '-'}</td>
                  <td className="table-dark">{item.volatility_1y ? item.volatility_1y : '-'}</td>
                  <td
                    className="table-dark"
                    style={{ color: item.returns_ytd > 0 ? 'green' : 'red' }}
                  >
                    {item.returns_ytd ? item.returns_ytd + '%' : '-'}
                  </td>
                  <td
                    className="table-dark"
                    style={{ color: item.returns_1y > 0 ? 'green' : 'red' }}
                  >
                    {item.returns_1y ? item.returns_1y + '%' : '-'}
                  </td>
                  <td
                    className="table-dark"
                    style={{ color: item.returns_3y > 0 ? 'green' : 'red' }}
                  >
                    {item.returns_3y ? item.returns_3y + '%' : '-'}
                  </td>
                  <td
                    className="table-dark"
                    style={{ color: item.returns_5y > 0 ? 'green' : 'red' }}
                  >
                    {item.returns_5y ? item.returns_5y + '%' : '-'}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default ETFFunds;
