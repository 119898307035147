import { fetchAuthSession } from 'aws-amplify/auth';
import AWS from 'aws-sdk';
import axios from 'axios';
import Toast from 'light-toast';
import React, { Component } from 'react';
import Flags from './flags';

import { FaPen, FaPlus, FaSave, FaTrash } from 'react-icons/fa';
import './styles.css';

import cookies from '../utils/cookies.util';
import { formatDate } from '../utils/date.util';
import base_url, { bucket_name, region } from '../utils/request.util';



import issuers from './constants/issuers.json';
const unwantedAttributes = ['suggestions', 'underlying_count'];

class StructureDispersionWarrentAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product_type: 'dispersion-warrent',
      strike: '21',
      premium: '5',
      currency: 'USD',
      issuer: '',
      issue_date: formatDate(new Date(new Date().setDate(new Date().getDate()))),
      final_fixing_date: formatDate(new Date(new Date().setDate(new Date().getDate() + 730))),
      underlying: [],
      suggestions: [],
      new_isin_code: '',
      net_payoff: '-2.2',
      underlying_count: {},
      maturity: '12',
      is_pending: true
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.addScrip = this.addScrip.bind(this);
    this.addScripSubmit = this.addScripSubmit.bind(this);
    this.removeScrip = this.removeScrip.bind(this);
    this.updateScrip = this.updateScrip.bind(this);
    this.fetchsuggestions = this.fetchsuggestions.bind(this);
    this.selectedScrip = this.selectedScrip.bind(this);
    this.discardScrips = this.discardScrips.bind(this);
    this.setProductData = this.setProductData.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
    if (event.target.id === 'initial_fixing_date' || event.target.id === 'maturity') {
      this.setState((prevState) => ({
        issue_date: formatDate(
          new Date(new Date(prevState.initial_fixing_date).getTime() + 5 * 24 * 60 * 60 * 1000)
        ),
        final_fixing_date: formatDate(
          new Date(
            new Date(prevState.initial_fixing_date).getTime() +
            (prevState.maturity % 12 === 0
              ? (365 * prevState.maturity) / 12
              : 30 * prevState.maturity) *
            24 *
            60 *
            60 *
            1000
          )
        )
      }));
    }
  }

  async handleSubmit(event) {
    Toast.loading('In Progress', () => { });

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      const attributes = JSON.parse(JSON.stringify(this.state));
      unwantedAttributes.forEach((key) => {
        delete attributes[key];
      });
      config.data = { structure: attributes };
      console.log('structure_data', attributes);
      axios
        .post(`${base_url}/dispersion-warrent-structure`, config.data, config)
        .then((res) => {
          Toast.success('Success', 3000, () => { });
          const isin_code = res.data.isin_code;
          window.location.href = `/structure/dispersion-warrent/report?ISIN=${isin_code}`;
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  async addScrip() {
    const scrip = document.getElementById('scrip').value;
    const initial_price = document.getElementById('initial_price').value;
    if (this.state.underlying.indexOf({ code: scrip.toUpperCase() }) !== -1) return;
    this.setState(
      (prevState) => ({
        underlying: [
          ...prevState.underlying,
          {
            code: scrip.toUpperCase(),
            initial_reference_price: initial_price
          }
        ],
        suggestions: []
      }),
      () => {
        fetchAuthSession().then(() => {
          const config = {};
          config.headers = {
            'Authorization': cookies.get('Authorization'),
            'User-Specified': cookies.get('User-Specified'),
            'Type-Specified': cookies.get('Type-Specified')
          };
          config.data = { structure: this.state };
          axios
            .get(`${base_url}/structure-underlying-count/${scrip}`, config)
            .then((res) => {
              const underlying_count = JSON.parse(JSON.stringify(this.state.underlying_count));
              underlying_count[scrip] = res.data.item.count;

              this.setState({
                suggestions: [],
                underlying_count
              });
              Toast.hide();
            })
            .catch((error) => {
              console.log(error);
              Toast.fail('Error Performing Action', 3000);
            });
        });
      }
    );
    document.getElementById('scrip').value = '';
    document.getElementById('initial_price').value = '';
  }

  addScripSubmit(event) {
    if (event.charcode !== 13) return;
    this.addScrip();
  }

  removeScrip(scrip_index) {
    const scrip_array = [...this.state.underlying];
    scrip_array.splice(scrip_index, 1);
    console.log(scrip_index, scrip_array);
    this.setState({
      underlying: scrip_array
    });
  }

  updateScrip(event) {
    const scrip_index = event.target.id;
    const scrip_array = [...this.state.underlying];
    let scrip_to_update = scrip_array.splice(scrip_index, 1);
    scrip_to_update = scrip_to_update[0];
    this.setState({
      underlying: scrip_array
    });
    document.getElementById('scrip').value = scrip_to_update.code;
    document.getElementById('initial_price').value = scrip_to_update.initial_reference_price;
  }

  async fetchsuggestions(event) {
    if (event.target.id === '') return;

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      config.params = { q: event.target.value };
      axios
        .get(`${base_url}/product/suggest`, config)
        .then((res) => {
          this.setState({
            suggestions: res.data.items
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  async selectedScrip(event) {
    const scrip = event.target.id;
    if (this.state.underlying.includes({ code: scrip.toUpperCase() })) return;
    Toast.loading('In Progress', () => { });

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      config.params = {
        attribute: 'last_price'
      };
      axios
        .get(`${base_url}/product/attribute/${scrip}`, config)
        .then((res) => {
          const last_price = res.data.last_price;
          document.getElementById('scrip').value = scrip;
          document.getElementById('initial_price').value = last_price;
          document.getElementById('initial_price').focus();
          this.setState({
            suggestions: []
          });
          Toast.hide();
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  discardScrips(event) {
    const scrip = event.target.id;
    if (this.state.underlying.includes({ code: scrip.toUpperCase() })) return;
    this.setState((prevState) => ({
      underlying: [...prevState.underlying, prevState.CurrentScrip],
      suggestions: []
    }));
    document.getElementById('scrip').value = scrip;
    document.getElementById('initial_price').value = '';
  }

  async setProductData() {
    Toast.loading('In Progress', () => { });

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      config.data = this.state;

      axios
        .get(`${base_url}/autocallable-structure`, config)
        .then((res) => {
          const data = res.data.item;

          this.setState({
            isin_code: data.isin_code,
            product_type: data.product_type,
            strike: data.strike,
            currency: data.currency,
            premium: data.premium,
            issuer: data.issuer,
            maturity: data.maturity,
            issue_date: data.issue_date,
            final_fixing_date: data.final_fixing_date,
            underlying: data.underlying,
            net_payoff: data.net_payoff,
            underlying_count: data.underlying_count,
            is_pending: data.is_pending
          });
          Toast.success('Success', 1000, () => { });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  async uploadFile(event) {
    Toast.loading('In Progress', () => { });
    const isincode = this.state.isin_code;
    const bucketName = bucket_name;

    const credentials = await fetchAuthSession();

    AWS.config.region = region;
    AWS.config.update({
      accessKeyId: credentials.credentials.accessKeyId,
      secretAccessKey: credentials.credentials.secretAccessKey,
      sessionToken: credentials.credentials.sessionToken
    });
    const s3 = new AWS.S3({
      apiVersion: '2006-03-01',
      params: { Bucket: bucketName }
    });

    console.log(event.target.files);

    s3.upload(
      {
        Bucket: bucketName,
        Key: `structures/term-sheets/${isincode}.pdf`,
        Body: event.target.files[0],
        ACL: 'private'
      },
      function (err, data) {
        if (err) {
          Toast.fail('there was an error uploading your file', 1000, () => { });
        } else {
          Toast.success('Sheet updated Successfully', 1000, () => { });
          console.log(data);
        }
      }
    );

    this.setState({
      term_sheet: true
    });
  }

  render() {
    return (
      <div className="container">
        <table className="table">
          <tr>
            <td>
              <label>Type of Structure:</label>
            </td>
            <td>
              <select
                value={this.state.product_type}
                onChange={this.handleChange}
                className="form-control"
                id="product_type"
              >
                <option>dispersion-warrent</option>
              </select>
            </td>
            <td>
              <label>currency:</label>
            </td>
            <td>
              <input
                value={this.state.currency}
                onChange={this.handleChange}
                type="text"
                className="form-control"
                id="currency"
              />
            </td>
          </tr>
          <tr>
            <td>
              <label>issuer:</label>
            </td>
            <td>
              <select
                value={this.state.issuer}
                onChange={this.handleChange}
                className="form-control"
                id="issuer"
              >
                <option>{this.state.issuer}</option>
                {
                  issuers.map(issuer => {
                    return <option key={issuer}>{issuer}</option>;
                  })
                }
              </select>
            </td>
            <td>
              <label>Strike:</label>
            </td>
            <td>
              <input
                value={this.state.strike}
                onChange={this.handleChange}
                type="number"
                className="form-control"
                id="strike"
              />
            </td>
          </tr>
          <tr>
            <td colSpan="4">
              <div className="form-group row">
                <label>Underlyings:</label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    autoComplete="off"
                    placeholder="Enter Scrip Name to add"
                    className="form-control"
                    id="scrip"
                    onChange={this.fetchsuggestions}
                  />

                  <div className="dropdown">
                    <ul className="list-group">
                      {this.state.suggestions.map((value, index) => {
                        return (
                          <li
                            className="list-group-item list-group-flush"
                            onClick={this.selectedScrip}
                            id={value.code}
                            key={index}
                          >
                            {value.code}
                            &nbsp; ({value.name}) &nbsp;
                            <img src={Flags[value.country_iso]}></img>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <div className="col-sm-3">
                  <input
                    type="text"
                    autoComplete="off"
                    placeholder="Initial Price"
                    className="form-control"
                    id="initial_price"
                    onKeyPress={this.addScripSubmit}
                  />
                </div>
                <div className="col-sm-1">
                  <div className="btn-group" role="group" aria-label="Basic example">
                    <a className="btn btn-primary" id="addScrip" onClick={this.addScrip}>
                      <FaPlus />
                    </a>
                  </div>
                </div>

                <div className="contaier">
                  {this.state.underlying.map((value, index) => {
                    return (
                      <div className="row" key={index}>
                        <div className="col-md-6">
                          <input
                            type="text"
                            disabled="true"
                            className="form-control"
                            value={value.code}
                          />
                        </div>
                        <div className="col-md-1">
                          <input
                            type="text"
                            disabled="true"
                            className="form-control"
                            value={this.state.underlying_count[value.code]}
                          />
                        </div>
                        <div className="col-md-3">
                          <input
                            type="text"
                            disabled="true"
                            className="form-control"
                            value={value.initial_reference_price}
                          />
                        </div>
                        <div className="col-md-2 btn-group" role="group" aria-label="Basic example">
                          <a className="btn btn-primary" onClick={() => this.updateScrip(index)}>
                            <FaPen />
                          </a>
                          <a className="btn btn-danger" onClick={() => this.removeScrip(index)}>
                            <FaTrash />
                          </a>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <label>Maturity in Months:</label>
            </td>
            <td>
              <input
                value={this.state.maturity}
                onChange={this.handleChange}
                type="text"
                className="form-control"
                id="maturity"
              />
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>
              <label>Issue Date:</label>
            </td>
            <td>
              <input
                value={this.state.issue_date}
                onChange={this.handleChange}
                type="date"
                className="form-control"
                id="issue_date"
              />
            </td>
            <td>
              <label>Final Fixing Date:</label>
            </td>
            <td>
              <input
                value={this.state.final_fixing_date}
                onChange={this.handleChange}
                type="date"
                className="form-control"
                id="final_fixing_date"
              />
            </td>
          </tr>
          <tr>
            <td>
              <label>Premium:</label>
            </td>
            <td>
              <input
                value={this.state.premium}
                onChange={this.handleChange}
                type="number"
                className="form-control"
                id="premium"
              />
            </td>
            <td>
              <label>Net Payoff:</label>
            </td>
            <td>
              <input
                value={this.state.net_payoff}
                onChange={this.handleChange}
                type="number"
                className="form-control"
                id="net_payoff"
              />
            </td>
          </tr>
        </table>

        <div className="row">
          <div className="btn-group" role="group" aria-label="Basic example">
            <a className="btn btn-primary" onClick={this.handleSubmit}>
              
              <FaSave /> Submit for approval
            </a>
            <a className="btn btn-danger" onClick={this.setProductData}>
              
              <FaTrash /> Discard Changes
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default StructureDispersionWarrentAdd;
