import axios from 'axios';
import Toast from 'light-toast';
import React, { Component } from 'react';

import { fetchAuthSession } from 'aws-amplify/auth';
import Flags from './flags';

import cookies from '../utils/cookies.util';
import { formatDate } from '../utils/date.util';
import base_url from '../utils/request.util';

class OrderAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      portfolio_id: '',
      type: 'BUY',
      isin_code: '',
      quantity: 0,
      cost_price: 0,
      buying_datetime: formatDate(new Date()),
      Status: 'Complete',
      final_asset_class: 'equity',
      currency: 'USD',
      exchange_rate: 1,
      face_value: 0,
      notional_quantity: 0,
      suggestions: [],
      suggestionsPortfolio: [],
      external_transaction: false
    };
    this.fetchSuggestions = this.fetchSuggestions.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.suggestPortfolios = this.suggestPortfolios.bind(this);
    this.selectedPortfolio = this.selectedPortfolio.bind(this);
    this.selectedScrip = this.selectedScrip.bind(this);
    this.updateEchangeRate = this.updateEchangeRate.bind(this);
  }

  async fetchSuggestions(event) {
    if (event.target.value === '') return;

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      config.params = {
        q: event.target.value
      };
      axios
        .get(`${base_url}/product/suggest`, config)
        .then((res) => {
          this.setState({
            suggestions: res.data.items,
            isin_code: event.target.value
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  async suggestPortfolios(event) {
    if (event.target.value === '') return;

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      config.params = {
        q: event.target.value
      };
      axios
        .get(`${base_url}/portfolio/suggest`, config)
        .then((res) => {
          this.setState({
            suggestionsPortfolio: res.data.items
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  selectedPortfolio(event) {
    this.setState({
      suggestionsPortfolio: [],
      portfolio_id: event.target.id
    });
  }

  async selectedScrip(event) {
    const scrip = event.target.id;
    Toast.loading('In Progress', () => { });

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      config.params = {
        attribute: 'last_price'
      };
      axios
        .get(`${base_url}/product/attribute/${scrip}`, config)
        .then((res) => {
          const last_price = res.data.last_price;
          document.getElementById('cost_price').focus();
          this.setState({
            suggestions: [],
            isin_code: event.target.id,
            cost_price: last_price
          });
          Toast.hide();
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  async updateEchangeRate(event) {
    Toast.loading('In Progress', () => { });

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      config.params = {
        attribute: 'last_price'
      };
      axios
        .get(`${base_url}/product/attribute/${this.state.currency} FOREX`, config)
        .then((res) => {
          const last_price = res.data.last_price;
          document.getElementById('cost_price').focus();
          this.setState({
            suggestions: [],
            exchange_rate: last_price
          });
          Toast.hide();
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
    if (event.target.id === 'portfolio_id') this.suggestPortfolios(event);
    if (event.target.id === 'isin_code') this.fetchSuggestions(event);
    if (event.target.id === 'currency') this.updateEchangeRate(event);
    if (event.target.id === 'face_value') {
      this.setState((prevState) => {
        return { notional_quantity: prevState.quantity * event.target.value };
      });
    }
    if (event.target.id === 'external_transaction') {
      this.setState((prevState) => {
        return {
          suggestions: [],
          [event.target.id]: !this.state[event.target.id]
        };
      });
    }
  }

  async handleSubmit(event) {
    Toast.loading('In Progress', () => { });

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      const data = {
        code: this.state.isin_code,
        portfolio_id: this.state.portfolio_id,
        buying_datetime: this.state.buying_datetime,
        cost_price: this.state.cost_price,
        quantity: this.state.type === 'BUY' ? this.state.quantity : this.state.quantity * -1,
        exchange_rate: this.state.exchange_rate,
        currency: this.state.currency,
        final_asset_class: this.state.final_asset_class,
        final_asset_sub_class: this.state.final_asset_sub_class,
        face_value: this.state.face_value,
        notional_quantity: this.state.notional_quantity,
        remark: this.state.remark,
        external_transaction: this.state.external_transaction
      };

      axios
        .post(`${base_url}/portfolio/order`, data, config)
        .then((res) => {
          Toast.success('Success', 1000, () => {
            window.location.href = '/portfolio/orders';
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  render() {
    return (
      <div className="container">
        <div className="container hidden" id="portfolioData">
          <div className="form-group">
            <label>Portfolio Id:</label>
            <input
              type="text"
              value={this.state.portfolio_id}
              onChange={this.handleChange}
              className="form-control"
              required
              id="portfolio_id"
            />
          </div>
          <div className="dropdown">
            <ul className="list-group">
              {this.state.suggestionsPortfolio.map((value, index) => {
                return (
                  <li
                    className="list-group-item list-group-flush"
                    onClick={this.selectedPortfolio}
                    id={value.portfolio_id}
                    key={index}
                  >
                    {value.portfolio_id} ({value.client_id})
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="form-group">
            <label>type:</label>
            <select
              value={this.state.type}
              onChange={this.handleChange}
              className="form-control"
              required
              id="type"
            >
              <option>BUY</option>
              <option>SELL</option>
            </select>
          </div>
          <div className="form-group">
            <label>Final Asset Class:</label>
            <select
              className="form-control"
              required
              id="final_asset_class"
              value={this.state.final_asset_class}
              onChange={this.handleChange}
            >
              <option>equity</option>
              <option>structured product</option>
              <option>fixed income</option>
              <option>forex</option>
              <option>etf</option>
              <option>fixed advance</option>
              <option>funds</option>
              <option>bond</option>
              <option>commodity</option>
              <option>cryptocurrency</option>
              <option>futures, options and warrants</option>
              <option>unassigned</option>
            </select>
          </div>
          <div className="form-group">
            {this.state.final_asset_class === 'equity' ||
              this.state.final_asset_class === 'forex' ? (
              <label>Code:</label>
            ) : (
              <label>ISIN Code:</label>
            )}
            <input
              type="text"
              value={this.state.isin_code}
              onChange={this.handleChange}
              className="form-control"
              required
              id="isin_code"
            />
          </div>
          <div className="dropdown">
            <ul className="list-group">
              {this.state.suggestions.map((value, index) => {
                return (
                  <li
                    className="list-group-item list-group-flush"
                    onClick={this.selectedScrip}
                    id={value.code}
                    key={index}
                  >
                    {value.code}
                    &nbsp; ({value.name}) &nbsp;
                    <img src={Flags[value.country_iso]}></img>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="form-group">
            <label>Cost Price:</label>
            <input
              type="text"
              value={this.state.cost_price}
              onChange={this.handleChange}
              className="form-control"
              required
              id="cost_price"
            />
          </div>
          <div className="form-group">
            <label>Quantities:</label>
            <input
              type="number"
              value={this.state.quantity}
              onChange={this.handleChange}
              className="form-control"
              required
              id="quantity"
            />
          </div>
          <div className="form-group">
            <label>Currency:</label>
            <select
              className="form-control"
              required
              id="currency"
              value={this.state.currency}
              onChange={this.handleChange}
            >
              <option>USD</option>
              <option>GBP</option>
              <option>EUR</option>
              <option>CHF</option>
              <option>INR</option>
            </select>
          </div>
          <div className="form-group">
            <label>Exchnage Rate:</label>
            <input
              type="text"
              value={this.state.exchange_rate}
              onChange={this.handleChange}
              className="form-control"
              required
              id="exchange_rate"
            />
          </div>
          <div className="form-group">
            <label>External Transaction: </label> &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
            <input
              type="checkbox"
              value={this.state.external_transaction}
              onChange={this.handleChange}
              className="form-check-input"
              required
              id="external_transaction"
            />
          </div>
          {this.state.final_asset_class !== 'equity' &&
            this.state.final_asset_class !== 'forex' && (
              <>
                <div className="form-group">
                  <label>Face value:</label>
                  <input
                    type="text"
                    value={this.state.face_value}
                    onChange={this.handleChange}
                    className="form-control"
                    required
                    id="face_value"
                  />
                </div>

                <div className="form-group">
                  <label>Notional Qauntity:</label>
                  <input
                    type="text"
                    value={this.state.notional_quantity}
                    onChange={this.handleChange}
                    className="form-control"
                    required
                    id="notional_quantity"
                  />
                </div>
              </>
            )}
          <div className="form-group">
            <label>Date:</label>
            <input
              type="datetime-local"
              value={this.state.buying_datetime}
              onChange={this.handleChange}
              className="form-control"
              required
              id="buying_datetime"
            />
          </div>
          <div className="form-group">
            <label>Remark:</label>
            <input
              type="text"
              value={this.state.remark}
              onChange={this.handleChange}
              className="form-control"
              required
              id="remark"
            />
          </div>
          <a className="btn btn-block btn-danger" onClick={this.handleSubmit}>
            Submit
          </a>
        </div>
      </div>
    );
  }
}

export default OrderAdd;
