import Toast from 'light-toast';
import React, { Component } from 'react';

import { fetchAuthSession } from 'aws-amplify/auth';
import AWS from 'aws-sdk';
import { metadata } from '../mappings/uploadFile';

import { bucket_name, region } from '../utils/request.util';

class UploadFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileType: ''
    };
    this.uploadFile = this.uploadFile.bind(this);
  }

  componentDidMount() {
    this.setState({
      fileType: window.location.pathname.replace('/upload', '').replace('/', '')
    });
  }

  async uploadFile(event) {
    if (event.target.files.length < 1) return;

    const fileExtension = event.target.files[0].name.split('.').pop();
    Toast.loading('In Progress', () => { });
    const bucketName = bucket_name;

    const credentials = await fetchAuthSession();

    AWS.config.region = region;
    AWS.config.update({
      accessKeyId: credentials.credentials.accessKeyId,
      secretAccessKey: credentials.credentials.secretAccessKey,
      sessionToken: credentials.credentials.sessionToken
    });
    const s3 = new AWS.S3({
      apiVersion: '2006-03-01',
      params: { Bucket: bucketName }
    });

    s3.upload(
      {
        Bucket: bucketName,
        Key: `import/${this.state.fileType}.${fileExtension}`,
        Body: event.target.files[0],
        ACL: 'private',
        Metadata: metadata[this.state.fileType] || {}
      },
      function (err, data) {
        if (err) {
          console.log(err);
          Toast.fail('there was an error uploading your file', 1000, () => { });
        } else {
          Toast.success('Success', 1000, () => { });
        }
      }
    );
  }

  render() {
    return (
      <div className="container">
        {this.state.fileType !== '' ? (
          <>

            <div className="container">
              <input
                type="file"
                id="termsheet"
                onChange={this.uploadFile}
                className="form-control"
              />
            </div>
          </>
        ) : (
          <>
            <div className="jumbotron">
              <h1>Incorrect URL</h1>
              <p>Please check your redirection</p>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default UploadFile;
