import axios from 'axios';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './styles.css';

import { fetchAuthSession } from 'aws-amplify/auth';
import { FaPen } from 'react-icons/fa';
import ExportExcel from './exportExcel';

import cookies from '../utils/cookies.util';
import base_url from '../utils/request.util';

class ViewOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      portfolio_id: 'All',
      portfolio_ids: ['All'],
      multiple_select: false,
      items_to_delete: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSumbit = this.handleSumbit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.selectOrder = this.selectOrder.bind(this);
    this.navigateToProduct = this.navigateToProduct.bind(this);
    this.toggleMultipleSelect = this.toggleMultipleSelect.bind(this);
  }

  toggleMultipleSelect() {
    this.setState((prevState) => ({
      multiple_select: !prevState.multiple_select
    }));
    this.setState({
      items_to_delete: []
    });
  }

  navigateToProduct(code, final_asset_class) {
    if (final_asset_class === 'Structured Product' && final_asset_class === 'structured product') {
      window.location.href = `/structure/autocallable/report?ISIN=${code}`;
    } else if (final_asset_class === 'bond') {
      window.location.href = `/product/bond?ISIN=${code}`;
    } else {
      window.location.href = `/product/equity?Code=${code}`;
    }
  }

  handleChange(event) {
    this.setState({ portfolio_id: event.target.value });
  }

  async handleSumbit(event) {
    const portfolio_id = event.target.value;
    const params = '';

    // Toast.loading('In Progress', () => { });

    await fetchAuthSession().then(() => {
      const config = {};

      if (portfolio_id !== 'All') config.params = { portfolio_id };
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      axios
        .get(`${base_url}/portfolio/order`, config)
        .then((res) => {
          // Toast.success('Success', 500, () => { });
          this.setState({
            orders: res.data.items,
            items_to_delete: []
          });
        })
        .catch((error) => {
          console.log(error);
          // Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  async handleClear() {
    // Toast.loading('In Progress', () => { });
    let url = '/portfolio/order/';
    if (this.state.portfolio_id !== 'All') url = `/portfolio/order/${this.state.portfolio_id}`;

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      axios
        .get(`${base_url}${url}`, config)
        .then((res) => {
          // Toast.success('Success', 500, () => { });
          this.setState({
            orders: res.data.items,
            portfolio_id: 'All',
            items_to_delete: []
          });
        })
        .catch((error) => {
          console.log(error);
          // Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  async selectOrder(portfolio_id, isin_code) {
    let alreadyPresent = -1;
    this.state.items_to_delete.map((value, index) => {
      if (value.portfolio_id === portfolio_id && value.isin_code === isin_code) {
        alreadyPresent = index;
      }
    });
    if (alreadyPresent !== -1) {
      const items_to_delete = [...this.state.items_to_delete];
      items_to_delete.splice(alreadyPresent, 1);
      this.setState({
        items_to_delete
      });
    } else {
      this.setState((prevState) => ({
        items_to_delete: [
          ...prevState.items_to_delete,
          {
            portfolio_id,
            isin_code
          }
        ]
      }));
    }
  }

  async handleDelete() {
    // Toast.loading('In Progress', () => { });
    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      axios
        .delete(
          `${base_url}/portfolio/order?`,
          {
            data: this.state.items_to_delete
          },
          config
        )
        .then((res) => {
          // Toast.success('Success', 3000);
        })
        .catch((error) => {
          // Toast.fail('Error Performing Action', 3000);
          console.log(error);
          // Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  async componentDidMount() {
    // const loadingToast = toast.loading("Loading...");

    const portfolio_id =
      cookies.get('Portfolio-Specified') != null
        ? cookies.get('Portfolio-Specified')
        : this.state.portfolio_id;

    // Toast.loading('In Progress', () => { });
    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      config.params = {
        email: cookies.get('User-Specified')
      };

      if (portfolio_id !== 'All') {
        config.params.portfolio_id = cookies.get('Portfolio-Specified');
      } else {
        config.params = {
          portfolio_id: this.state.portfolio_id
        };
      }
      axios
        .get(`${base_url}/portfolio/order`, config)
        .then((res) => {
          this.setState({
            orders: res.data.items
          });
        })
        .catch((error) => {
          console.log(error);
          // Toast.fail('Error Performing Action', 3000);
        });
      axios
        .get(`${base_url}/portfolio`, config)
        .then((res) => {
          this.setState({
            portfolio_ids:
              cookies.get('Portfolio-Specified') == null
                ? [{ portfolio_id: 'All' }].concat(res.data.items)
                : [{ portfolio_id: cookies.get('Portfolio-Specified') }]
          });
        })
        .catch((error) => {
          console.log(error);
          // Toast.fail('Error Performing Action', 3000);
        });

      // Toast.success('Success', 500, () => { });
    });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="container row">
          <h1>Client Orders:</h1>
          <div className="col-sm-8 container">
            <select onChange={this.handleSumbit} className="form-control">
              {this.state.portfolio_ids.map((value, index) => {
                return <option>{value.portfolio_id}</option>;
              })}
            </select>
            {/* <div className="input-group-append">
                            <a className="input-group-text btn btn-danger" onClick={this.handleSumbit}>Search</a>
                        </div>
                        <div className="input-group-append">
                            <a className="input-group-text btn btn-secondary" onClick={this.handleClear}>Clear</a>
                        </div> */}
          </div>
          <div className="col-sm-2 container">
            <ExportExcel data={this.state.orders} fileName="orders" exportType="xls" />
          </div>
          <div className="col-sm-2 container">
            {!this.state.multiple_select ? (
              <button className="btn btn-primary" onClick={this.toggleMultipleSelect}>
                Select
              </button>
            ) : (
              <button className="btn btn-secondary" onClick={this.toggleMultipleSelect}>
                Cancel
              </button>
            )}
            {this.state.multiple_select && this.state.items_to_delete.length !== 0 && (
              <button className="btn btn-danger" onClick={this.handleDelete}>
                Delete
              </button>
            )}
          </div>
        </div>
        <br></br>
        <table className="table table-sm">
          <thead className="table-dark">
            <tr>
              <th style={{ position: 'sticky', top: 50 }}></th>
              <th style={{ position: 'sticky', top: 50 }}></th>
              <th style={{ position: 'sticky', top: 50 }}>Portfolio Id</th>
              <th style={{ position: 'sticky', top: 50 }}>ISIN Code</th>
              <th style={{ position: 'sticky', top: 50 }}>Quantity</th>
              <th style={{ position: 'sticky', top: 50 }}>Buying Date</th>
              {/* <th style={{position:"sticky", top: 50 }}>Currency</th> */}
              <th style={{ position: 'sticky', top: 50 }}>Buying Value</th>
              {/* <th style={{position:"sticky", top: 50 }}>Purchase Value USD</th> */}
              <th style={{ position: 'sticky', top: 50 }}>Face Value</th>
              <th style={{ position: 'sticky', top: 50 }}>notional Quantity</th>
              <th style={{ position: 'sticky', top: 50 }}></th>
              {this.state.multiple_select && <th style={{ position: 'sticky', top: 50 }}></th>}
            </tr>
          </thead>
          <tbody>
            {this.state.orders.map((asset_class, asset_class_index) => {
              return (
                <>
                  <tr key={asset_class_index}>
                    <td
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        textAlign: 'center'
                      }}
                    >
                      <strong>{asset_class.final_asset_class.toUpperCase()}</strong>
                    </td>
                    <td
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none'
                      }}
                    ></td>
                    <td
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none'
                      }}
                    ></td>
                    <td
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none'
                      }}
                    ></td>
                    <td
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none'
                      }}
                    ></td>
                    <td
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none'
                      }}
                    ></td>
                    <td
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none'
                      }}
                    ></td>
                    <td
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none'
                      }}
                    ></td>
                    <td
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none'
                      }}
                    ></td>
                    <td
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none'
                      }}
                    ></td>
                    {this.state.multiple_select && (
                      <td
                        style={{
                          backgroundColor: '#002850',
                          color: '#ffffff',
                          textDecoration: 'none'
                        }}
                      ></td>
                    )}
                  </tr>

                  {asset_class.orders.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td
                          style={{
                            backgroundColor: '#002850',
                            color: '#ffffff',
                            textDecoration: 'none'
                          }}
                        ></td>
                        <td
                          style={{
                            backgroundColor: '#002850',
                            color: '#ffffff',
                            textDecoration: 'none',
                            borderRight: '1px solid white'
                          }}
                        >
                          <strong>{item.currency}</strong>
                        </td>
                        <td
                          style={{
                            backgroundColor: '#002850',
                            color: '#ffffff',
                            textDecoration: 'none'
                          }}
                        >
                          <strong>{item.portfolio_id != null ? item.portfolio_id : '-'}</strong>
                        </td>
                        <td className="table-dark">
                          {item.code != null ? (
                            <a
                              className="btn btn-link"
                              onClick={() =>
                                this.navigateToProduct(item.code, item.final_asset_class)
                              }
                            >
                              {item.code}
                            </a>
                          ) : (
                            'N.A.'
                          )}
                        </td>
                        <td className="table-dark">
                          
                          {item.quantity != null ? item.quantity : 'N.A.'}
                        </td>
                        {/* <td className="table-dark"> {(item["Currency"] != null) ? item["Currency"] : "N.A."} </td> */}
                        <td className="table-dark">
                          
                          {item.buying_datetime != null ? item.buying_datetime : 'N.A.'}
                        </td>
                        <td className="table-dark">
                          
                          {item.cost_price != null ? item.cost_price : 'N.A.'}
                        </td>
                        <td className="table-dark">
                          
                          {item.face_value != null && item.face_value !== 0
                            ? item.face_value
                            : 'N.A.'}
                        </td>
                        <td className="table-dark">
                          
                          {item.notional_quantity != null && item.notional_quantity !== 0
                            ? item.notional_quantity
                            : 'N.A.'}
                        </td>
                        <td className="table-dark">
                          <Link to={`/portfolio/order?id=${item.order_id}`}>
                            <FaPen />
                          </Link>
                        </td>
                        {this.state.multiple_select && (
                          <td className="table-dark">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              onClick={() => this.selectOrder(item.portfolio_id, item.isin_code)}
                            />
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default ViewOrders;
