import axios from 'axios';
import Toast from 'light-toast';
import React, { Component } from 'react';
import './styles.css';

import { fetchAuthSession } from 'aws-amplify/auth';

import cookies from '../utils/cookies.util';
import base_url from '../utils/request.util';

class StructureAnalaysis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total_issuer_risk: [],
      total_issuer_risk_total: {},
      sector_risk_analysis: [],
      sector_risk_analysis_total: {},
      top_concentraion_risk: []
    };
  }

  async componentDidMount() {
    Toast.loading('In Progress', () => { });
    await fetchAuthSession().then(() => {
      const config = {};
      config.params = { type: 'total_issuer_risk' };
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      if (cookies.get('Portfolio-Specified') != null) {
        config.params.portfolio_id = cookies.get('Portfolio-Specified');
      }
      axios
        .get(`${base_url}/structure-analysis`, config)
        .then((res) => {
          this.setState({
            total_issuer_risk: res.data.items,
            total_issuer_risk_total: res.data.total
          });
          Toast.success('Success', 500, () => { });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
    await fetchAuthSession().then(() => {
      const config = {};
      config.params = { type: 'sector_risk_analysis' };
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      if (cookies.get('Portfolio-Specified') != null) {
        config.params.portfolio_id = cookies.get('Portfolio-Specified');
      }
      axios
        .get(`${base_url}/structure-analysis`, config)
        .then((res) => {
          this.setState({
            sector_risk_analysis: res.data.items,
            sector_risk_analysis_total: res.data.total
          });
          // Toast.success("Success", 500, () => { });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
    await fetchAuthSession().then(() => {
      const config = {};
      config.params = { type: 'top_concentraion_risk' };
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      if (cookies.get('Portfolio-Specified') != null) {
        config.params.portfolio_id = cookies.get('Portfolio-Specified');
      }
      axios
        .get(`${base_url}/structure-analysis`, config)
        .then((res) => {
          this.setState({
            top_concentraion_risk: res.data.items
          });
          // Toast.success("Success", 500, () => { });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  render() {
    return (
      <div className="container-fluid">
        {this.state.total_issuer_risk.length > 0 && (
          <div className="container-fluid">
            <h2>Total Issuer Risk</h2>

            <table className="table table-bordered">
              <thead
                style={{
                  backgroundColor: '#002850',
                  color: '#ffffff',
                  textDecoration: 'none',
                  border: '1px solid white'
                }}
              >
                <tr>
                  {['Issuer', 'Notes', 'Total Notional Amt', 'Exposure %'].map((headers) => {
                    return <th key={headers}>{headers}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {this.state.total_issuer_risk.map((row, index) => {
                  return (
                    <tr key={index}>
                      {Object.keys(this.state.total_issuer_risk[0]).map((key, index) => {
                        return (
                          <td key={`${row}-${key}`}>
                            {row[key]
                              ? row[key].toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                              })
                              : '-'}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
                <tr
                  style={{
                    backgroundColor: '#002850',
                    color: '#ffffff',
                    textDecoration: 'none',
                    border: '1px solid white'
                  }}
                >
                  <td>Total</td>
                  <td>
                    {this.state.total_issuer_risk_total.notes &&
                      this.state.total_issuer_risk_total.notes.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })}
                  </td>
                  <td>
                    {this.state.total_issuer_risk_total.total_notional_amt &&
                      this.state.total_issuer_risk_total.total_notional_amt.toLocaleString(
                        undefined,
                        { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                      )}
                  </td>
                  <td>
                    {this.state.total_issuer_risk_total.exposure &&
                      this.state.total_issuer_risk_total.exposure.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        {this.state.sector_risk_analysis.length > 0 && (
          <div className="container-fluid">
            <h2>Sector Risk Analysis (Only Equity Structure and Equity)</h2>

            <table className="table table-bordered">
              <thead
                style={{
                  backgroundColor: '#002850',
                  color: '#ffffff',
                  textDecoration: 'none',
                  border: '1px solid white'
                }}
              >
                <tr>
                  {['Sector', 'Notes', 'Total Notional Amt', 'Exposure %'].map((headers) => {
                    return <th key={headers}>{headers}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {this.state.sector_risk_analysis.map((row, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        
                        {row.sector != null
                          ? row.sector.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2
                          })
                          : 'Diversified'}
                      </td>
                      <td>
                        
                        {row.notes
                          ? row.notes.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2
                          })
                          : '-'}
                      </td>
                      <td>
                        
                        {row.total_notional_amt
                          ? row.total_notional_amt.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2
                          })
                          : '-'}
                      </td>
                      <td>
                        
                        {row.exposure
                          ? row.exposure.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2
                          })
                          : '-'}
                      </td>
                    </tr>
                  );
                })}
                <tr
                  style={{
                    backgroundColor: '#002850',
                    color: '#ffffff',
                    textDecoration: 'none',
                    border: '1px solid white'
                  }}
                >
                  <td>Total</td>
                  <td>
                    {this.state.sector_risk_analysis_total.notes &&
                      this.state.sector_risk_analysis_total.notes.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })}
                  </td>
                  <td>
                    {this.state.sector_risk_analysis_total.total_notional_amt &&
                      this.state.sector_risk_analysis_total.total_notional_amt.toLocaleString(
                        undefined,
                        { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                      )}
                  </td>
                  <td>
                    {this.state.sector_risk_analysis_total.exposure &&
                      this.state.sector_risk_analysis_total.exposure.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}

        {this.state.top_concentraion_risk.length > 0 && (
          <div className="container-fluid">
            <h2>Top Concentration Risk</h2>

            <table className="table table-bordered">
              <thead
                style={{
                  backgroundColor: '#002850',
                  color: '#ffffff',
                  textDecoration: 'none',
                  border: '1px solid white'
                }}
              >
                <tr>
                  {['ISIN', 'Code', '# Basket Count', 'Worst Performing %'].map((headers) => {
                    return <th key={headers}>{headers}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {this.state.top_concentraion_risk.map((row, index) => {
                  return (
                    <tr key={index}>
                      {Object.keys(this.state.top_concentraion_risk[0]).map((key, index) => {
                        return (
                          <td key={`${row}-${key}`}>
                            {row[key]
                              ? row[key].toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                              })
                              : '-'}
                            {key === 'worst_performing' && (
                              <div className="progress">
                                <div
                                  className={
                                    row[key] <= 0
                                      ? 'progress-bar bg-danger'
                                      : 'progress-bar bg-success'
                                  }
                                  style={{ width: `${Math.abs(row[key])}%` }}
                                ></div>
                              </div>
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  }
}

export default StructureAnalaysis;
