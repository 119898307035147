import axios from 'axios';
import Toast from 'light-toast';
import React, { Component } from 'react';

import { fetchAuthSession } from 'aws-amplify/auth';

import cookies from '../utils/cookies.util';
import base_url from '../utils/request.util';

class RMLinkUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rm_id: 'Select',
      client_id: '',
      name: '',
      added_by: '',
      is_verified: false,
      suggestions: [],
      rms: []
    };
    this.fetchSuggestions = this.fetchSuggestions.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.selectedUser = this.selectedUser.bind(this);
  }

  async fetchSuggestions(event) {
    if (event.target.value === '') return;

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      config.params = {
        q: event.target.value
      };
      axios
        .get(`${base_url}/user/suggest`, config)
        .then((res) => {
          this.setState({
            suggestions: res.data.items
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  selectedUser(event) {
    this.setState({
      client_id: event.target.id,
      suggestions: []
    });
  }

  handleChange(event) {
    console.log(event.target.value, event.target.id);
    this.setState({ [event.target.id]: event.target.value });
    if (event.target.id === 'client_id') this.fetchSuggestions(event);
    if (event.target.id === 'is_verified' || event.target.id === 'is_existing_portfolio') {
      this.setState({
        [event.target.id]: !this.state[event.target.id]
      });
    }
  }

  async handleSubmit(event) {
    Toast.loading('In Progress', () => { });

    if (this.state.rm_id === 'Select') {
      Toast.fail('Please select RM', 3000);
      return;
    }
    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };

      const rm_id = this.state.rm_id.split(' | ')[0];

      const data = {
        rm_id,
        client_id: this.state.client_id,
        added_by: this.state.added_by
      };

      axios
        .patch(`${base_url}/relationship-management/${rm_id}`, data, config)
        .then((res) => {
          Toast.success('Success', 1000, () => { });

          this.setState({
            rm_id: 'Select',
            client_id: '',
            name: '',
            added_by: '',
            is_verified: false,
            suggestions: [],
            rms: []
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  async componentDidMount() {
    Toast.loading('In Progress', () => { });

    this.setState({
      added_by: cookies.get('User-Specified')
    });
    Toast.success('Success', 1000, () => { });

    await fetchAuthSession().then((user) => {
      const config = {};
      config.params = { user_type: 'RelationshipManager' };
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': user.tokens.accessToken.payload.username,
        'Type-Specified': user.tokens.accessToken.payload['cognito:groups']
      };
      axios
        .get(`${base_url}/user`, config)
        .then((res) => {
          this.setState({
            rms: [{ user_id: 'Select' }].concat(res.data.items)
          });
          Toast.hide();
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  render() {
    return (
      <div className="container">
        <div className="container" id="rmData">
          <div className="form-group">
            <label>RM Id:</label>
            <select
              type="text"
              value={this.state.rm_id}
              onChange={this.handleChange}
              className="form-control"
              required
              id="rm_id"
            >
              {this.state.rms.map((value, index) => {
                return (
                  <option>
                    {value.user_id}
                    {value.name && ' | ' + value.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="form-group">
            <label>Client Id:</label>
            <input
              type="text"
              value={this.state.client_id}
              onChange={this.handleChange}
              className="form-control"
              required
              id="client_id"
            />
            <div className="dropdown">
              <ul className="list-group">
                {this.state.suggestions.map((value, index) => {
                  return (
                    <li
                      className="list-group-item list-group-flush"
                      onClick={this.selectedUser}
                      id={value.user_id}
                      key={index}
                    >
                      {value.user_id}
                    </li>
                  );
                })}
              </ul>
            </div>
            <label>
              Cant fine user? Create one
              <a
                className="btn btn-link"
                onClick={() => {
                  window.location.href = '/add-user';
                }}
              >
                here
              </a>
            </label>
          </div>

          <div className="form-group">
            <label>Added By:</label>
            <input
              type="text"
              disabled
              value={this.state.added_by}
              onChange={this.handleChange}
              className="form-control"
              required
              id="added_by"
            />
          </div>
          <hr />
          <a className="btn btn-block btn-danger" onClick={this.handleSubmit}>
            Submit
          </a>
        </div>
      </div>
    );
  }
}

export default RMLinkUser;
