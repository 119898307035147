import axios from 'axios';
import Toast from 'light-toast';
import React, { Component } from 'react';
import './styles.css';

import { fetchAuthSession } from 'aws-amplify/auth';
import Table from './table';

import cookies from '../utils/cookies.util';
import base_url from '../utils/request.util';

class PlanetaryMovement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }

  async componentDidMount() {
    Toast.loading('In Progress', () => { });
    await fetchAuthSession().then(() => {
      var config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      axios
        .get(`${base_url}/planetary-movement`, config)
        .then((res) => {
          this.setState({
            data: res.data.items
          });
          Toast.success('Success', 500, () => { });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  render() {
    return (
      <div className="container-fluid">
        <h1>Planetary Movement</h1>
        {this.state.data.map((item, i) => {
          return (
            <>
              <h3>{item.data_type}</h3>
              {item.pm_data.length > 0 && (
                <Table data={item.pm_data} headers={Object.keys(item.pm_data[0])} />
              )}
            </>
          );
        })}
      </div>
    );
  }
}

export default PlanetaryMovement;
