import axios from 'axios';
import Toast from 'light-toast';
import React, { useState, useEffect } from 'react';
import './styles.css';

import { fetchAuthSession } from 'aws-amplify/auth';
import { FaPen } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import EquityPopover from './equityPopover';
import ExportExcel from './exportExcel';

import cookies from '../utils/cookies.util';
import base_url from '../utils/request.util';

function returnKey(finalAssetClass) {
  switch (finalAssetClass) {
    case 'structured product':
      return 's_name';
    case 'funds':
      return 'b_name';
    default:
      return 'name';
  }
}

const PortfolioView = () => {
  const [portfolioDetails, setPortfolioDetails] = useState([]);
  const [portfolioId, setPortfolioId] = useState('All');
  const [portfolioIds, setPortfolioIds] = useState([]);
  const [multipleSelect, setMultipleSelect] = useState(false);
  const [itemsToDelete, setItemsToDelete] = useState([]);

  const toggleMultipleSelect = () => {
    setMultipleSelect(prevState => !prevState);
    setItemsToDelete([]);
  };

  const handleChange = (event) => {
    setPortfolioId(event.target.value);
  };

  const handleSubmit = async (event) => {
    const portfolio_id = event.target.value;

    Toast.loading('In Progress', () => {});

    try {
      await fetchAuthSession();
      const config = {
        headers: {
          'Authorization': cookies.get('Authorization'),
          'User-Specified': cookies.get('User-Specified'),
          'Type-Specified': cookies.get('Type-Specified')
        }
      };
      if (portfolio_id !== 'All') {
        config.params = { portfolio_id };
      }
      const res = await axios.get(`${base_url}/portfolio/client-holding`, config);
      Toast.success('Success', 1000, () => {});
      setPortfolioDetails(res.data.items);
      setItemsToDelete([]);
      setPortfolioId(portfolio_id);
    } catch (error) {
      Toast.fail('Error Performing Action', 3000);
    }
  };

  const handleClear = async () => {
    Toast.loading('In Progress', () => {});
    let url = '/portfolio/holding/';
    if (portfolioId !== 'All') url = `/portfolio/holding/${portfolioId}`;

    try {
      await fetchAuthSession();
      const config = {
        headers: {
          'Authorization': cookies.get('Authorization'),
          'User-Specified': cookies.get('User-Specified'),
          'Type-Specified': cookies.get('Type-Specified')
        }
      };
      const res = await axios.get(`${base_url}`, config);
      Toast.success('Success', 1000, () => {});
      setPortfolioDetails(res.data.items);
      setPortfolioId('All');
      setItemsToDelete([]);
    } catch (error) {
      Toast.fail('Error Performing Action', 3000);
    }
  };

  const navigateToProduct = (code, final_asset_class) => {
    if (final_asset_class.toLowerCase() === 'structured product') {
      window.location.href = `/structure/autocallable/report?ISIN=${code}`;
    } else if (final_asset_class === 'bond') {
      window.location.href = `/product/bond?ISIN=${code}`;
    } else {
      window.location.href = `/product/equity?Code=${code}`;
    }
  };

  const selectHolding = (portfolio_id, ISINCode) => {
    setItemsToDelete(prevItems => {
      const index = prevItems.findIndex(item => 
        item.portfolio_id === portfolio_id && item.ISINCode === ISINCode
      );
      if (index !== -1) {
        return prevItems.filter((_, i) => i !== index);
      } else {
        return [...prevItems, { portfolio_id, ISINCode }];
      }
    });
  };

  const handleDelete = async () => {
    Toast.loading('In Progress', () => {});

    try {
      await fetchAuthSession();
      const config = {
        headers: {
          'Authorization': cookies.get('Authorization'),
          'User-Specified': cookies.get('User-Specified'),
          'Type-Specified': cookies.get('Type-Specified')
        }
      };
      await axios.delete(
        `${base_url}/portfolio/client-holding`,
        {
          data: itemsToDelete
        },
        config
      );
      Toast.success('Success', 3000);
    } catch (error) {
      console.log(error);
      Toast.fail('Error Performing Action', 3000);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const portfolio_id =
        cookies.get('Portfolio-Specified') != null
          ? cookies.get('Portfolio-Specified')
          : portfolioId;

      Toast.loading('In Progress', () => {});

      try {
        await fetchAuthSession();
        const config = {
          headers: {
            'Authorization': cookies.get('Authorization'),
            'User-Specified': cookies.get('User-Specified'),
            'Type-Specified': cookies.get('Type-Specified')
          },
          params: { email: cookies.get('User-Specified') }
        };
        if (portfolio_id !== 'All') {
          config.params.portfolio_id = cookies.get('Portfolio-Specified');
        }
        const holdingsRes = await axios.get(`${base_url}/portfolio/client-holding`, config);
        setPortfolioDetails(holdingsRes.data.items);
        Toast.success('Success', 500, () => {});

        const portfoliosRes = await axios.get(`${base_url}/portfolio`, config);
        setPortfolioIds(
          cookies.get('Portfolio-Specified') == null
            ? [{ portfolio_id: 'All' }].concat(portfoliosRes.data.items)
            : [{ portfolio_id: cookies.get('Portfolio-Specified') }]
        );
      } catch (error) {
        console.log(error);
        Toast.fail('Error Performing Action', 1000);
      }
    };

    fetchData();
  }, []);

    return (
      <div className="container-fluid">
        <div className="container row">
          <div className="col-sm-8 container">
            <select onChange={handleSubmit} className="form-control">
              {portfolioIds.map((value, index) => {
                return <option key={index}>{value.portfolio_id}</option>;
              })}
            </select>
          </div>
          <div className="col-sm-2 container">
            <ExportExcel
              data={portfolioDetails}
              fileName="portfolio"
              exportType="xls"
            />
          </div>
          <div className="col-sm-2 container">
            {!multipleSelect ? (
              <button className="btn btn-primary" onClick={toggleMultipleSelect}>
                Select
              </button>
            ) : (
              <button className="btn btn-secondary" onClick={toggleMultipleSelect}>
                Cancel
              </button>
            )}
            {multipleSelect && itemsToDelete.length !== 0 && (
              <button className="btn btn-danger" onClick={handleDelete}>
                Delete
              </button>
            )}
          </div>
        </div>
        <br></br>
        <table className="table table-sm">
          <thead className="table-dark">
            <tr>
              <th style={{ position: 'sticky', top: 50 }}></th>
              <th style={{ position: 'sticky', top: 50 }}></th>
              <th style={{ position: 'sticky', top: 50 }}>portfolio Id</th>
              <th style={{ position: 'sticky', top: 50, width: '300px' }}>Name</th>
              <th style={{ position: 'sticky', top: 50 }}>Code</th>
              <th style={{ position: 'sticky', top: 50 }}></th>
              <th style={{ position: 'sticky', top: 50 }}>Position</th>
              <th style={{ position: 'sticky', top: 50 }}>Cost Value</th>
              <th style={{ position: 'sticky', top: 50 }}>Cost Rate</th>
              <th style={{ position: 'sticky', top: 50 }}>Market Value</th>
              <th style={{ position: 'sticky', top: 50 }}>Market Rate</th>
              <th style={{ position: 'sticky', top: 50 }}>Profit/Loss</th>
              <th style={{ position: 'sticky', top: 50 }}>PNL Pct</th>
              <th style={{ position: 'sticky', top: 50 }}></th>
              {multipleSelect && <th style={{ position: 'sticky', top: 50 }}></th>}
            </tr>
          </thead>
          <tbody>
            {portfolioDetails.map((asset_class, asset_class_index) => {
              return (
                <>
                  <tr key={asset_class_index}>
                    <td
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        textAlign: 'center'
                      }}
                    >
                      <strong>{asset_class.final_asset_class.toUpperCase()}</strong>
                    </td>
                    <td
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none'
                      }}
                    ></td>
                    <td
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none'
                      }}
                    ></td>
                    <td
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none'
                      }}
                    ></td>
                    <td
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none'
                      }}
                    ></td>
                    <td
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none'
                      }}
                    ></td>
                    <td
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none'
                      }}
                    ></td>
                    {/* <td style={{ backgroundColor: "#002850", color: "#ffffff", textDecoration: "none"}}> {(item["Currency"] != null) ? item["Currency"] : "-"} </td> */}
                    <td
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none'
                      }}
                    ></td>
                    <td
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none'
                      }}
                    ></td>
                    <td
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none'
                      }}
                    ></td>
                    <td
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none'
                      }}
                    ></td>
                    <td
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none'
                      }}
                    ></td>
                    <td
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none'
                      }}
                    ></td>
                    <td
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none'
                      }}
                    ></td>
                    {multipleSelect && (
                      <td
                        style={{
                          backgroundColor: '#002850',
                          color: '#ffffff',
                          textDecoration: 'none'
                        }}
                      ></td>
                    )}
                  </tr>

                  {asset_class.holdings.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td
                          style={{
                            backgroundColor: '#002850',
                            color: '#ffffff',
                            textDecoration: 'none'
                          }}
                        ></td>
                        <td
                          style={{
                            backgroundColor: '#002850',
                            color: '#ffffff',
                            textDecoration: 'none',
                            borderRight: '1px solid white'
                          }}
                        >
                          <strong>{item.currency}</strong>
                        </td>
                        <td
                          style={{
                            backgroundColor: '#002850',
                            color: '#ffffff',
                            textDecoration: 'none'
                          }}
                        >
                          <strong>{item.portfolio_id != null ? item.portfolio_id : '-'}</strong>
                        </td>
                        <td className="table-dark" style={{ width: '300px' }}>
                          {item[returnKey(item.final_asset_class)] != null
                            ? item[returnKey(item.final_asset_class)]
                            : '-'}
                        </td>
                        <td className="table-dark">
                          {item.code != null ? (
                            <a
                              className="btn btn-link"
                              onClick={() =>
                                navigateToProduct(item.code, item.final_asset_class)
                              }
                            >
                              {item.code}
                            </a>
                          ) : (
                            '-'
                          )}
                        </td>
                        <td className="table-dark">
                          <EquityPopover code={item.code} />
                        </td>
                        <td className="table-dark">

                          {item.quantity != null ? item.quantity : '-'}
                        </td>
                        <td className="table-dark">

                          {item.purchase_value != null
                            ? item.purchase_value.toLocaleString(undefined, {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2
                            })
                            : '-'}
                        </td>
                        <td className="table-dark">

                          {item.cost_price != null
                            ? item.cost_price.toLocaleString(undefined, {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2
                            })
                            : '-'}
                        </td>
                        <td className="table-dark">

                          {item.current_value != null
                            ? item.current_value.toLocaleString(undefined, {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2
                            })
                            : '-'}
                        </td>
                        <td className="table-dark">

                          {item.last_price != null
                            ? item.last_price.toLocaleString(undefined, {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2
                            })
                            : '-'}
                        </td>
                        <td
                          className="table-dark"
                          style={{ color: item.profit_loss < 0 ? 'Red' : 'Green' }}
                        >

                          <strong>
                            {item.profit_loss != null
                              ? item.profit_loss.toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                              })
                              : '-'}
                          </strong>
                        </td>
                        <td
                          className="table-dark"
                          style={{ color: item.percentage_updown < 0 ? 'Red' : 'Green' }}
                        >

                          <strong>
                            {item.percentage_updown != null
                              ? item.percentage_updown.toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                              })
                              : '-'}
                          </strong>
                        </td>
                        <td className="table-dark">

                          <Link
                            to={`/holding/update?code=${item.code}&portfolioId=${item.portfolio_id}`}
                          >
                            <FaPen />
                          </Link>
                        </td>
                        {multipleSelect && (
                          <td className="table-dark">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              onClick={() => selectHolding(item.portfolio_id, item.ISINCode)}
                            />
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  export default PortfolioView;
