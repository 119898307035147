import axios from 'axios';
import Toast from 'light-toast';
import React, { Component } from 'react';
import './styles.css';

import { fetchAuthSession } from 'aws-amplify/auth';
import moment from 'moment';
import { Link } from 'react-router-dom';

import cookies from '../utils/cookies.util';
import base_url from '../utils/request.util';

class StructureCouponCashflow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cashflowData: [],
      monthwiseTotal: {},
      months: [],
      portfolioIds: [],
      portfolioId: 'all',
      expiredCashflowData: [],
      expiredMonthwiseTotal: {},
      expiredMonths: [],
      expiredPortfolioIds: [],
      expiredPortfolioId: 'all'
    };

    this.fetchCashflowData = this.fetchCashflowData.bind(this);
    this.handleSumbit = this.handleSumbit.bind(this);
  }

  async fetchCashflowData(portfolioId) {
    Toast.loading('In Progress', () => { });
    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      axios
        .get(`${base_url}/structure-coupon-cashflow/${portfolioId}`, config)
        .then((res) => {
          this.setState({
            portfolioId,
            cashflowData: res.data.item.data,
            monthwiseTotal: res.data.item.monthwise_total,
            months: res.data.item.months,
            ytdData: res.data.item.ytd_data,
            ytdDataTotal: res.data.item.ytd_data_total
          });
          if (res.data.item.data.length !== 0) {
            Toast.success('Success', 500, () => { });
          } else {
            Toast.success('No Data Found for ' + portfolioId, 500, () => { });
          }
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 1000);
        });

      config.params = { expired: 1 };
      axios
        .get(`${base_url}/structure-coupon-cashflow/${portfolioId}`, config)
        .then((res) => {
          this.setState({
            expiredCashflowData: res.data.item.data,
            expiredMonthwiseTotal: res.data.item.monthwise_total,
            expiredMonths: res.data.item.months,
            expiredYtdData: res.data.item.ytd_data,
            expiredYtdDataTotal: res.data.item.ytd_data_total
          });
          if (res.data.item.data.length !== 0) {
            Toast.success('Success', 500, () => { });
          } else {
            Toast.success('No Data Found for ' + portfolioId, 500, () => { });
          }
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 1000);
        });
    });
  }

  async handleSumbit(event) {
    const portfolioId = event.target.value;

    this.setState(
      {
        data: [],
        monthwiseTotal: {},
        months: [],
        ytdData: {},
        ytdDataTotal: 0,
        expiredData: [],
        expiredMonthwiseTotal: {},
        expiredMonths: [],
        expiredYtdData: {},
        expiredYtdDataTotal: 0,
        portfolioId
      },
      () => {
        if (portfolioId !== 'all') this.fetchCashflowData(portfolioId);
      }
    );
  }

  async componentDidMount() {
    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      axios
        .get(`${base_url}/portfolio`, config)
        .then((res) => {
          this.setState({
            portfolioIds:
              cookies.get('Portfolio-Specified') == null
                ? [{ portfolio_id: 'All' }].concat(res.data.items)
                : [{ portfolio_id: cookies.get('Portfolio-Specified') }]
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 1000);
        });

      const search = new URLSearchParams(window.location.search);
      const portfolioId = search.get('PortfolioId') ? search.get('PortfolioId').replaceAll('%20', ' ') : '';

      if (portfolioId !== '') {
        this.fetchCashflowData(portfolioId);
      } else {
        this.fetchCashflowData(this.state.portfolioId);
      }
    });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="container row">
          <h1>Coupon cashflow:</h1>
        </div>
        <div className="container m-4">
          <div className="card">
            <select
              onChange={this.handleSumbit}
              className="form-control"
              value={this.state.portfolioId}
            >
              {this.state.portfolioIds.map((value, index) => {
                return <option key={index}>{value.portfolio_id}</option>;
              })}
            </select>
          </div>
        </div>
        {this.state.cashflowData.length !== 0 && (
          <table className="table table-sm">
            <thead className="table-dark">
              <tr>
                <th style={{ position: 'sticky', top: 50 }}>ISIN</th>
                <th style={{ position: 'sticky', top: 50 }}>Notional</th>
                <th style={{ position: 'sticky', top: 50 }}>Coupon</th>
                <th style={{ position: 'sticky', top: 50 }}>Underlying</th>
                {this.state.months.map((month, index) => {
                  return (
                    this.state.monthwiseTotal[month] !== 0 && (
                      <th key={{ index }} style={{ position: 'sticky', top: 50 }}>
                        {month}
                      </th>
                    )
                  );
                })}
                <th style={{ position: 'sticky', top: 50 }}>YTD</th>
                <th style={{ position: 'sticky', top: 50 }}>Total</th>
              </tr>
            </thead>
            <tbody>
              {this.state.cashflowData.map((isin_wise_data, index) => {
                return (
                  <tr key={index}>
                    <td
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        whiteSpace: 'wrap'
                      }}
                    >
                      <Link to={`/structure/autocallable/report?ISIN=${isin_wise_data.isin_code}`}>
                        {isin_wise_data.isin_code}
                      </Link>
                    </td>
                    <td
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        whiteSpace: 'wrap'
                      }}
                    >
                      {isin_wise_data.notional_quantity}
                    </td>
                    <td
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        whiteSpace: 'wrap'
                      }}
                    >
                      {isin_wise_data.coupon}
                    </td>
                    <td
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        whiteSpace: 'wrap'
                      }}
                    >
                      {isin_wise_data.name.split('(')[0].split(' ')[2]}
                    </td>
                    {this.state.months.map((month, innerIndex) => {
                      return (
                        this.state.monthwiseTotal[month] !== 0 && (
                          <td
                            className={
                              moment(new Date()).format('YYYY-MM') > month
                                ? 'table-success'
                                : 'table-dark'
                            }
                            key={innerIndex}
                          >
                            {isin_wise_data.monthwise_coupon_data[month].coupon_paid
                              ? isin_wise_data.monthwise_coupon_data[
                                month
                              ].coupon_paid.toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                              })
                              : '-'}
                          </td>
                        )
                      );
                    })}
                    <td className={'table-success'}>
                      <strong>
                        {this.state.ytdData[isin_wise_data.isin_code]
                          ? this.state.ytdData[isin_wise_data.isin_code].toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2
                          })
                          : '-'}
                      </strong>
                    </td>
                    <td
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none'
                      }}
                    >
                      <strong>
                        {isin_wise_data.total_coupon_paid.toLocaleString(undefined, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2
                        })}
                      </strong>
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td
                  style={{ backgroundColor: '#002850', color: '#ffffff', textDecoration: 'none' }}
                >
                  <strong>Total</strong>
                </td>
                <td
                  style={{ backgroundColor: '#002850', color: '#ffffff', textDecoration: 'none' }}
                ></td>
                <td
                  style={{ backgroundColor: '#002850', color: '#ffffff', textDecoration: 'none' }}
                ></td>
                <td
                  style={{ backgroundColor: '#002850', color: '#ffffff', textDecoration: 'none' }}
                ></td>
                {this.state.months.map((month, index) => {
                  return (
                    this.state.monthwiseTotal[month] !== 0 && (
                      <td
                        style={{
                          backgroundColor: '#002850',
                          color: '#ffffff',
                          textDecoration: 'none'
                        }}
                        key={index}
                      >
                        <strong>
                          {this.state.monthwiseTotal[month]
                            ? this.state.monthwiseTotal[month].toLocaleString(undefined, {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2
                            })
                            : '-'}
                        </strong>
                      </td>
                    )
                  );
                })}
                <td
                  style={{ backgroundColor: '#002850', color: '#ffffff', textDecoration: 'none' }}
                >
                  <strong>
                    {this.state.ytdDataTotal
                      ? this.state.ytdDataTotal.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })
                      : '-'}
                  </strong>
                </td>
                <td
                  style={{ backgroundColor: '#002850', color: '#ffffff', textDecoration: 'none' }}
                ></td>
              </tr>
            </tbody>
          </table>
        )}

        {this.state.expiredCashflowData.length !== 0 && (
          <table className="table table-sm">
            <thead className="table-dark">
              <tr>
                <th style={{ position: 'sticky', top: 50 }}>ISIN</th>
                <th style={{ position: 'sticky', top: 50 }}>Notional</th>
                <th style={{ position: 'sticky', top: 50 }}>Coupon</th>
                <th style={{ position: 'sticky', top: 50 }}>Underlying</th>
                {this.state.expiredMonths.map((month, index) => {
                  return (
                    this.state.expiredMonthwiseTotal[month] !== 0 && (
                      <th key={{ index }} style={{ position: 'sticky', top: 50 }}>
                        {month}
                      </th>
                    )
                  );
                })}
                <th style={{ position: 'sticky', top: 50 }}>YTD</th>
                <th style={{ position: 'sticky', top: 50 }}>Total</th>
              </tr>
            </thead>
            <tbody>
              {this.state.expiredCashflowData.map((isin_wise_data, index) => {
                return (
                  <tr key={index}>
                    <td
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        whiteSpace: 'wrap'
                      }}
                    >
                      <Link to={`/structure/autocallable/report?ISIN=${isin_wise_data.isin_code}`}>
                        {isin_wise_data.isin_code}
                      </Link>
                    </td>
                    <td
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        whiteSpace: 'wrap'
                      }}
                    >
                      {isin_wise_data.notional_quantity}
                    </td>
                    <td
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        whiteSpace: 'wrap'
                      }}
                    >
                      {isin_wise_data.coupon}
                    </td>
                    <td
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        whiteSpace: 'wrap'
                      }}
                    >
                      {isin_wise_data.name.split('(')[0].split(' ')[2]}
                    </td>
                    {this.state.expiredMonths.map((month, innerIndex) => {
                      return (
                        this.state.expiredMonthwiseTotal[month] !== 0 && (
                          <td
                            className={
                              moment(new Date()).format('YYYY-MM') > month
                                ? 'table-success'
                                : 'table-dark'
                            }
                            key={innerIndex}
                          >
                            {isin_wise_data.monthwise_coupon_data[month].coupon_paid
                              ? isin_wise_data.monthwise_coupon_data[
                                month
                              ].coupon_paid.toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                              })
                              : '-'}
                          </td>
                        )
                      );
                    })}
                    <td className={'table-success'}>
                      <strong>
                        {this.state.expiredYtdData[isin_wise_data.isin_code]
                          ? this.state.expiredYtdData[isin_wise_data.isin_code].toLocaleString(
                            undefined,
                            { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                          )
                          : '-'}
                      </strong>
                    </td>
                    <td
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none'
                      }}
                    >
                      <strong>
                        {isin_wise_data.total_coupon_paid.toLocaleString(undefined, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2
                        })}
                      </strong>
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td
                  style={{ backgroundColor: '#002850', color: '#ffffff', textDecoration: 'none' }}
                >
                  <strong>Total</strong>
                </td>
                <td
                  style={{ backgroundColor: '#002850', color: '#ffffff', textDecoration: 'none' }}
                ></td>
                <td
                  style={{ backgroundColor: '#002850', color: '#ffffff', textDecoration: 'none' }}
                ></td>
                <td
                  style={{ backgroundColor: '#002850', color: '#ffffff', textDecoration: 'none' }}
                ></td>
                {this.state.expiredMonths.map((month, index) => {
                  return (
                    this.state.expiredMonthwiseTotal[month] !== 0 && (
                      <td
                        style={{
                          backgroundColor: '#002850',
                          color: '#ffffff',
                          textDecoration: 'none'
                        }}
                        key={index}
                      >
                        <strong>
                          {this.state.expiredMonthwiseTotal[month]
                            ? this.state.expiredMonthwiseTotal[month].toLocaleString(undefined, {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2
                            })
                            : '-'}
                        </strong>
                      </td>
                    )
                  );
                })}
                <td
                  style={{ backgroundColor: '#002850', color: '#ffffff', textDecoration: 'none' }}
                >
                  <strong>
                    {this.state.expiredYtdDataTotal
                      ? this.state.expiredYtdDataTotal.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      })
                      : '-'}
                  </strong>
                </td>
                <td
                  style={{ backgroundColor: '#002850', color: '#ffffff', textDecoration: 'none' }}
                ></td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    );
  }
}

export default StructureCouponCashflow;
