/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_cognito_identity_pool_id": "ap-south-1:abe13294-0c7f-4c57-a408-8787394b9be8",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_Ti95KijT2",
    "aws_user_pools_web_client_id": "4pbk64f8vvb2fnooh22457srs0",
    "oauth": {
        "domain": "admin-project-calculus.auth.ap-south-1.amazoncognito.com",
        "scope": [
            "aws.cognito.signin.user.admin",
            "email",
            "openid",
            "phone",
            "profile"
        ],
        "redirectSignIn": "http://localhost:3000/",
        "redirectSignOut": "http://localhost:3000/",
        "responseType": "token"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [],
    "aws_content_delivery_bucket": "development-deplyment-dev",
    "aws_content_delivery_bucket_region": "ap-south-1",
    "aws_content_delivery_url": "https://d3fs9f8l9e67m9.cloudfront.net"
};


export default awsmobile;
